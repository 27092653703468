define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/SimDataGenerationInit',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/ScalableGroupIseTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/AccessPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/TCP_UDP_Template_1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/TCP_UDP_Template_2',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceFDCfsConfigStatusTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/NetworkApplicationTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/scalablegroup/AppListTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ApplicationPolicyPreviewTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/sensor/ApModeChangeTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimConnectivityDomainData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/policy/ApplicationPolicyTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimScheduledCaptureData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/icap/ScheduledGlobalSettings'
], function (SimLokiDatabaseActions, SimTaskDataGenerator, SimDataGenerationInit, ScalableGroupIseTemplate,
             UtilityFunctions, DefaultConfig, AccessPolicyTemplate, TCP_UDP_Template_1, TCP_UDP_Template_2,
             DeviceConfigTemplate,NetworkApplicationTemplate,AppListTemplate,ApplicationPolicyPreviewTemplate,SimulationUtility,
             ApModeChangeTemplate,SimConnectivityDomainData,ApplicationPolicyTemplate,SimScheduledCaptureData,
             ScheduledGlobalSettings) {

    var apWirelessServiceType = 'ApWirelessConfiguration';
    var is126OrAbove = true;//change this field to false, in case it is being deployed for DNAC 1.2.5 or below

    function getVNAssociatedWithFD(fdName){
         //It is the virtualnetwork name is passed as mentioned below, hence name is extralcted like that...
         // api/v2/data/customer-facing-service/VirtualNetwork?name=.*test.*
         console.log(fdName);

         var name = fdName.substring(2, fdName.length - 2);
         //workaround fix -ui issue
        // Exp- "Global/North America/USA/California/San Jose/SJC01_Default_LAN_Fabric"
        // Act- "Global/North_America/USA/California/San_Jose/SJC01_Default_LAN_Fabric"
       /*  var array =name.split("/");

            for(var i=0; i<array.length-1;i++){
               var part =array[i];
               part =part.replace(/_/g, " ");
               array[i]=part;
            }
            name =array.join('/'); */
         // end of workaround

         var type = 'ConnectivityDomain';

         var doc = document.getElementsByClassName("leftHierarchy--tree__list--treeNodeLabelContainer level-1 selected");
         if(doc.length>0) {
             domainName = doc[0].textContent;
             domainName= domainName.split(' ').join('_');
             var cd = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {
                                                            '$and': [
                                                                    {
                                                                        'domainType': 'FABRIC_SITE'
                                                                    }, {
                                                                         'name':  name
                                                                    }]
                                                             });
             if(cd.length==0) {
                 domainName =domainName+"_"+name;
                 name =domainName;
             }
         } else {

           var docObj = document.getElementsByClassName("leftHierarchy--tree__list--treeNodeLabelContainer selected");
           if(docObj.length>0) {
             var siteId= docObj[0].attributes["data-id"].textContent;

             var cd = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {
                                                            '$and': [
                                                                    {
                                                                        'domainType': 'FABRIC_SITE'
                                                                    }, {
                                                                         'name':  name
                                                                    }]
                                                             });

             if(cd.length==0) {
                var siteFabric = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {
                                                            '$and': [
                                                                    {
                                                                        'domainType': 'FABRIC_SITE'
                                                                    }, {
                                                                         'siteId':  siteId
                                                                    }]
                                                             });
                if(siteFabric.length>0)  {

                 name =siteFabric[0].name;

                }
             }
           }
         }

         var fdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'name' : name});

         if(fdObj && fdObj.length){
              type = 'VirtualNetwork';
              var fdId = fdObj[0].id;
              var vnObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', {'namespace' : fdId});
              if(vnObj && vnObj.length){
                  return vnObj;
              }
         }
         return [];
    }

     function getVNAssociatedWithFDNew(domainId){


         var fdObj = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id' : domainId});

         if(fdObj && fdObj.length){
              type = 'VirtualNetwork';
              var fdId = fdObj[0].id;
              var vnObj = SimLokiDatabaseActions.getFilteredRecordHandler('VirtualNetwork', {'namespace' : fdId});
              if(vnObj && vnObj.length){
                  return vnObj;
              }
         }
         return [];
    }

    function getDeviceDetail(data, name, filterField) {
        var tData = [];
        for (var i = 0; i < data.length; i++) {
            if (filterField == 'name' && name == data[i].name) {
                return [data[i]];
            }
        }
        return tData;
    }

    function getCount(type, key, value) {
        var count = 0;
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {key: value});
        if (recordObj && recordObj.length) {
            count = recordObj.length;
        }
        return count;
    }

    function getQualifierMatch(filter) {
        var qualifier = ['access', 'copy', 'scalableGroupType', 'name', 'networkDeviceId','cdVnList','status'];
        if (filter != undefined && filter.length) {
            for (var i = 0; i < qualifier.length; i++) {
                if (filter[qualifier[i]] != undefined) {
                    return {key: qualifier[i], value: filter[qualifier[i]]};
                }
            }
        }
        return {key: '', value: ''};
    }

    function getLokiDbName(subtype,type){
        var dbname = "";
        dbname = subtype+"-"+type;
        return dbname;
    }

    function getDeviceSpecificConfigStatus(cdId) {
        var deviceObjs = SimLokiDatabaseActions.getAll('DeviceInfo');
        if(deviceObjs == undefined || deviceObjs.length == 0){
            return [];
        }
        var f = 'connectivityDomain';
        var tIds = deviceObjs.filter(function(v) { var t = v[f]; return t != undefined && t.indexOf(cdId) >= 0; });
        var result = [];
        for(var i = 0; i < tIds.length; i++) {
            var id = tIds[i].networkDeviceId;
            var obj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id' : id});
            if(obj && obj.length) {
                var configObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'deviceId':id});
                var deviceConfigId = configObj && configObj.length ? configObj[0].id: '';
                var configObj = JSON.parse(JSON.stringify(DeviceConfigTemplate.Template ));
                configObj.id = deviceConfigId;
                configObj.deviceId = obj[0].id;
                configObj.namespace = cdId;
                configObj.hostname = obj[0].hostname;
                configObj.type = obj[0].type;
                configObj.family = obj[0].family;
                configObj.managementIpAddress = obj[0].managementIpAddress;
                configObj.role = obj[0].role;
                configObj.groupId = obj[0].siteId;
                if(DefaultConfig.BAD_DEVICE.indexOf(obj[0].hostname) >= 0) {
                    configObj.status = 'FAILED';
                }
                result.push(configObj);
            }
        }
        return result;
    }

    function formQuery(filter) {
        var query;
        var qualifier = ['name', 'identitySource.type', 'scalableGroupType','state'];
        var list= [];
         for(var i=0;i<filter.length;i++) {
           var key = filter[i];
            if (qualifier.indexOf(key)>=0) {
                 list.push({[key]:filter[key]});
            }
         }
         query={ '$and':list};
      return query;
    }

    function getNetworkApplication(jsonObj) {
       var response =[];
        for(var i=0;i<jsonObj.length;i++) {
             var tjson= JSON.parse(JSON.stringify(NetworkApplicationTemplate.ScalableGroup_Network_Application_Template));
             tjson.id =jsonObj[i].id;
             tjson.instanceId =jsonObj[i].instanceId;
             tjson.displayName =jsonObj[i].displayName;
             tjson.identitySourceType =jsonObj[i].identitySource.type;
             tjson.name =jsonObj[i].name;
             tjson.applicationType =jsonObj[i].networkApplications[0].applicationType;
             tjson.parentScalableGroup =jsonObj[i].parentScalableGroup.idRef;

              recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'id' : jsonObj[i].parentScalableGroup.idRef});

             tjson.parentScalableGroupName =recordObj[0].name;
             tjson.rank =jsonObj[i].networkApplications[0].rank;
             tjson.trafficClass =jsonObj[i].networkApplications[0].trafficClass;
             response.push(tjson);
        }
       return  response;
    }

     function getDeviceList(siteId) {

     var childrenSites=[];
     var siteList =SimulationUtility.getChildrenSites(siteId,childrenSites);
     var siteIdList =[];
     var deviceIdList=[];
         siteIdList.push(siteId);
         for(var i=0;i<siteList.length;i++) {

             siteIdList.push(siteList[i].id);
         }
         var deviceList =   SimLokiDatabaseActions.getFilteredRecordHandler('network-device',{'siteId': { '$containsAny' :  siteIdList}});

     return  deviceList;
    }


    return {
        init: function () {
        },

        getCfsCount: function (url, filter, controllerCallback) {
            var t = url.split('/');
            var type = t[6];
            type = type.split('?')[0];
            var count = 0;

            var t6 = t.length == 6 ? t[6] : '';
            var t7 = t.length == 7 ? t[7] : '';

            var result = getQualifierMatch(filter);
            if(result.key != ''){

                if(result.key == 'scalableGroupType') {

                   var  recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup',formQuery(filter));
                    if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    if(result.value=="APPLICATION" || result.value == "APPLICATION_GROUP") {

                    var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));

                      recordObj= recordObj.concat(json);
                      count = count+recordObj.length;
                    }

                    controllerCallback(count == undefined ? 0 : count);
                    return;
                }
            }

            if(t[6] =='networkapplicationcategory'){
                var recordObj = SimLokiDatabaseActions.getAll('networkapplicationcategory');
                if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    controllerCallback(count == undefined ? 0 : count);
                    return;
            }

            if(type == 'copydestination'){
                var recordObj = SimLokiDatabaseActions.getAll('copy-destination');
                if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    controllerCallback(count == undefined ? 0 : count);
                    return;

            }
            if(type == 'policy' || type == 'contract'){
                var subtype = t[7];
                if(subtype =='access' || subtype == 'copy'){
                    var count = 0;
                    var recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
                    if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    controllerCallback(count == undefined ? 0 : count);
                    return;
                }
                else {
                    count = SimLokiDatabaseActions.getCount(type);
                    controllerCallback(count == undefined ? 0 : count);
                    return;
                }
                if(t.length == 8 && t[7] != undefined){
                    //qualifier e.g. 1)access 2)copy etc.. for policy
                    //qualifier e.g. 1)access for contract
                    count = getCount(type, 'qualifier', t[7].split('?')[0]);
                    controllerCallback(count);
                    return;
                }
            }

            if(type == 'scalablegroup' ) {
                if(filter['identitySource.type'] == 'ISE'){
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'identitySource.type' : 'ISE'});
                    if(recordObj && recordObj.length){
                        count = recordObj.length;
                    }
                    controllerCallback(count == undefined ? 0 : count);
                    return;
                }
                if(t[7] != undefined){
                    if(t[7].indexOf("?indicativeNetworkIdentity.protocol=TCP,UDP") != -1){
                    var t_Json1 = JSON.parse(JSON.stringify(TCP_UDP_Template_1.TCP_UDP_TEMPLATE_1));
                    var t_Json2 = JSON.parse(JSON.stringify(TCP_UDP_Template_2.TCP_UDP_TEMPLATE_2));
                    var t_Json3 = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkApplications': {'$size': 1}});

                    if(t_Json1 && t_Json1.length){
                        count = t_Json1.length;
                    }

                    if(t_Json2 && t_Json2.length){
                        count = count + t_Json2.length;
                    }

                    if(t_Json3 && t_Json3.length){
                        count = count + t_Json3.length;
                    }

                    controllerCallback(count == undefined ? 0 : count);
                    return;
                    }
                }

            }

            count = SimLokiDatabaseActions.getCount(type);
            controllerCallback(count == undefined ? 0 : count);
        },

        getCfsDetails: function (url, filter, controllerCallback) {
            var t = url.split('/');
            var type = t[5], count = 0;
            type = type.split('?')[0];

            var t6 = t.length == 6 ? t[6] : '';
            var t7 = t.length == 7 ? t[7] : '';

            if(type =='APModeChange') {
                var recordObj = JSON.parse(JSON.stringify(ApModeChangeTemplate.ApModeChangeTemplate));
                recordObj[0].id = UtilityFunctions.generate_uuid();
                controllerCallback(recordObj);
                return;
            }

            if(type =='count'){
                var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : filter['scalableGroupType']});
                if(recordObj && recordObj.length){
                    count = recordObj.length;
                }
                controllerCallback(count == undefined ? 0 : count);
                return;
            }

            if(type =='scratchpad') {

            var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('application-policy-preview', {'namespace' : filter['namespace']});
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;

            }

            if(type =='summary' &&  filter["cdFSiteList"]==undefined && filter["fLanDeviceList"]==undefined && filter["siteDeviceList"]==undefined && filter["vncList"]==undefined){
                console.log(t[6] +" "+t[7]);


                if(t[7] && t[7].split('?')[0]=="application" && filter["scratchPadId"]!=undefined) {

                var recordObj = JSON.parse(JSON.stringify(ApplicationPolicyPreviewTemplate.Application_Policy_Preview_Device_Summary_Template));
                var deviceList = getDeviceList(filter["sites"]);
                var summary=[]
                recordObj[0].count =deviceList.length;
                for(var i=0;i<deviceList.length;i++) {
                  var tJson =recordObj[0].applicationPolicyScopeResolutionDeviceSummary[0];
                   tJson.deviceId =  deviceList[0].id;
                   tJson.deviceName =deviceList[0].name;
                   tJson.deviceType = deviceList[0].type;
                   tJson.groupNameHierarchy ="";
                   tJson.managementIpAddress =deviceList[0].managementIpAddress;
                  summary.push(tJson);
                }
                recordObj[0].applicationPolicyScopeResolutionDeviceSummary=summary;
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;

                }

                if(t[7] && t[7].split('?')[0]=="application" && filter["miniDashboardFilter"]!=undefined) {

                     var policyList = SimLokiDatabaseActions.getAll('policy-summary');

                     var recordObj = JSON.parse(JSON.stringify(ApplicationPolicyTemplate.Application_Dashbaord_Template));
                     if(policyList.length>0) {
                         recordObj[0].successfullPolicies=policyList[0].applicationPolicySummary.length;
                         recordObj[0].totalPolicies=policyList[0].applicationPolicySummary.length;

                     }
                     controllerCallback(recordObj && recordObj.length ? recordObj : []);
                    return;
                }

                if(t[7] && t[7].split('?')[0]=="application") {
                 var recordObj = SimLokiDatabaseActions.getAll('policy-summary');
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;

                }

                if(t[6] == 'policy' && t[7] && t[7]=='copy'){
                    var recordObj = SimLokiDatabaseActions.getAll('copy-policy-summary');
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;
                }
                var recordObj = SimLokiDatabaseActions.getAll('policy-summary');
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;
            }

            if(type == 'haInfo') {
                //api/v2/data/customer-facing-service/haInfo?networkDeviceId=6ec38edc-772a-45cf-b853-d073639e6121
                return [];
            }

            if(type == 'copydestination'){
                var recordObj = SimLokiDatabaseActions.getAll('copy-destination');
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;
            }

            if(type =='policy' || type == 'contract'){
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                var recordObj;
                if(subtype=="application" && filter["name"]!=undefined) {
                 recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(getLokiDbName(subtype,type),{"name":filter["name"]});

                } else if(subtype=="application" && filter["policyScope"]!=undefined) {
                 recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(getLokiDbName(subtype,type),{"policyScope":filter["policyScope"]});

                } else if (filter['id'] != undefined) {
                 recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(getLokiDbName(subtype,type),{"id":filter["id"]});

                } else {

                recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
                }
             /*   if(recordObj != undefined){
                        for (var i = 0; i < recordObj.length; i++) {
                            delete recordObj[i]['$loki'];//check with Vel/Srini
                        }
                    } */
                console.log(recordObj);
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;
            }

            if(type =='Wlan'){
                var recordObj = SimLokiDatabaseActions.getAll('wlan-ssid');
                controllerCallback(recordObj && recordObj.length ? recordObj : []);
                return;
            }

            if(type =='DeviceInfo'){
               var deviceInfoObjs = [];
               if(filter['networkDeviceId'] != undefined) {
                   var deviceIds = filter['networkDeviceId'].split(',')
                       for(var deviceIndex in deviceIds) {
                           var deviceId = deviceIds[deviceIndex];
                           recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'networkDeviceId' : deviceId});
                           if(recordObj && recordObj.length > 0) {
                               deviceInfoObjs.push(recordObj[0]);
                           }
                       }
               } else if(filter['siteDeviceList'] != undefined && filter['roles'] != undefined) {
                     var recordObj = SimConnectivityDomainData.getDeviceInfoSummary(filter) ;
                     controllerCallback(recordObj && recordObj.length ? recordObj : []);
                   return;
               }/* else if(filter['siteDeviceList'] != undefined) {
                   var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'siteId' : filter['siteDeviceList']});
                     controllerCallback(recordObj && recordObj.length ? recordObj : []);
                   return;
               }  */else if(filter['siteId'] != undefined) {
                   var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'siteId' : filter['siteId']});
                     controllerCallback(recordObj && recordObj.length ? recordObj : []);
                   return;
               } else if(filter['id'] != undefined) {
                   var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', {'id' : filter['id']});
                     controllerCallback(recordObj && recordObj.length ? recordObj : []);
                   return;
               } else {

                   var deviceInfoObjs = SimLokiDatabaseActions.getAll('DeviceInfo');
                   console.log(deviceInfoObjs);
               }
               controllerCallback(deviceInfoObjs && deviceInfoObjs.length ? deviceInfoObjs : []);
               return;
            }

            if(type == apWirelessServiceType)
           {
             if(filter['siteId'] != undefined)
             {
               var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(apWirelessServiceType, {'siteId' : filter['siteId']});
             } else if (filter['id'] != undefined)
             {
               var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(apWirelessServiceType, {'id' : filter['id']});
             }
             console.log('Output==>'+apWirelessServiceType);
             console.log('Get==>'+recordObj);
             controllerCallback(recordObj && recordObj.length ? recordObj : []);
             return;
           }

            if(type == "airsense") {
                var subType = t[6];
                if(t[6].split('?')[0] == "anomaly") {
                    var tObj = [];
                    //anomaly call flow is from this file in 1.2.6
                    if(is126OrAbove) {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {'$and': [
                            {"name": "anomaly" },{"siteId": filter['siteId']}, {"isGlobal": filter['isGlobal']},
                            {"isEnable": filter['isEnable']}, {"isDisable": filter['isDisable']},
                            {"isClientAnomalyDetectionPacketTrace": filter['isClientAnomalyDetectionPacketTrace']} ]});
                    } else {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings',   {'$and': [ {"name": "anomaly" },{"siteId": filter['siteId'] },{"isClientAnomalyDetectionPacketTrace": filter['isClientAnomalyDetectionPacketTrace'] }]});
                    }
                     console.log(tObj);
                     controllerCallback(tObj);
                     return;

                } else if(t[6].split('?')[0] == "ap-statistic") {
                    //ap-statistic call goes to SimCfsModelsv1 in 1.2.6
                    var tObj = [];
                    if(is126OrAbove) {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings_126', {'$and': [{"name": "ap-statistic" },{"siteId": filter['siteId']} ]});
                    } else {
                        tObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-capture-settings',   {'$and': [ {"name": "ap-statistic" },{"siteId": filter['siteId'] },{"apStatisticsType": filter['apStatisticsType'] }]});
                    }
                    controllerCallback(tObj);
                    return;
                }
            }

            var result = getQualifierMatch(filter);
            if(result.key != ''){
                var Value = result.value;

                if(result.key == 'scalableGroupType') {
                    var recordObj= SimLokiDatabaseActions.getFilteredRecordHandler(type, formQuery(filter));
                   /* if(recordObj != undefined){
                        for (var i = 0; i < recordObj.length; i++) {
                            delete recordObj[i]['$loki'];//check with Vel/Srini
                        }
                    } */
                    if(Value=="APPLICATION") {

                    if(recordObj != undefined){
                        for (var i = 0; i < recordObj.length; i++) {
                            delete recordObj[i]['$loki'];//check with Vel/Srini
                        }
                    }

                    var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));

                      recordObj= recordObj.concat(json);
                    }
                    controllerCallback(recordObj && recordObj.length ? recordObj : []);
                    return;
                }
                if(result.key == 'networkDeviceId') {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkDeviceId' : Value});
                    controllerCallback(recordObj && recordObj.length ? recordObj : []);
                    return;
                }
                else if(result.key == 'name'){
                    if(type == 'VirtualNetwork') {
                        controllerCallback( getVNAssociatedWithFD(result.value));
                    }
                    else {
                        var value = result.value;
                        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'name' : value});
                        controllerCallback(recordObj && recordObj.length ? recordObj : []);
                        return;
                    }
                }else if(result.key == 'cdVnList'){
                    if(type == 'VirtualNetwork') {
                        controllerCallback( getVNAssociatedWithFDNew(result.value));
                        return;
                    }

                }
                else {
                    controllerCallback([]);
                }
                return;
            }


            if(t.length == 7 && t[6] != undefined){

                if (t[6].indexOf("?indicativeNetworkIdentity.protocol=TCP,UDP") != -1) {

                    var recordObj1 = JSON.parse(JSON.stringify(TCP_UDP_Template_1.TCP_UDP_TEMPLATE_1));
                    var recordObj2 = JSON.parse(JSON.stringify(TCP_UDP_Template_2.TCP_UDP_TEMPLATE_2));
                    var recordObj3 = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkApplications': {'$size': 1}});

                    Array.prototype.push.apply(recordObj1, recordObj2);

                    if(recordObj3 && recordObj3.length){
                        Array.prototype.push.apply(recordObj1, recordObj3);
                    }
                    //var recordObj = recordObj1.push(recordObj2);
                    //var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'networkApplications': {'$size': 1}});
                    controllerCallback(recordObj1 && recordObj1.length ? recordObj1 : []);
                    return;
                }

                if(type =='Segment' ) {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'id' : t[6]});
                    controllerCallback(recordObj && recordObj.length ? recordObj : []);
                    return;
                }

                if(type=="summary"  && filter['siteDeviceList'] != undefined && filter['roles'] != undefined) {


                    var data = SimConnectivityDomainData.getDeviceInfoSummary(filter) ;

                    controllerCallback(data == undefined ? [] : data);
                    return;

                }

                if(type=="summary"  && filter['cdFSiteList'] != undefined) {


                    var data = SimConnectivityDomainData.getFabricSummary(filter['cdFSiteList']) ;

                    controllerCallback(data == undefined ? [] : data);
                    return;

                }

                 if(type=="summary"  && filter['fLanDeviceList'] != undefined) {


                    var data = SimConnectivityDomainData.getLanFabricSummary(filter) ;

                    controllerCallback(data == undefined ? [] : data);
                    return;

                }

                if(type=="summary"  && filter['vncList'] != undefined) {


                    var data = SimConnectivityDomainData.getVirtualNetworkContextSummary(filter) ;

                    controllerCallback(data == undefined ? [] : data);
                    return;

                }

                if(type == 'scalablegroup'){
                    var recordObj;
                    if(filter['identitySource.type'] == 'ISE'){
                        //recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'identitySource.type' : 'ISE'});
                        recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : 'USER_DEVICE'});
                    } else if(filter['attributes'] == 'networkApplications'){
                       recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : 'APPLICATION'});
                       var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));
                       recordObj= recordObj.concat(json);
                       recordObj = getNetworkApplication(recordObj);
                    } else if(filter['scalableGroupType'] == 'APPLICATION'){
                       recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('scalablegroup', {'scalableGroupType' : 'APPLICATION'});
                       var json= JSON.parse(JSON.stringify(AppListTemplate.Application_Template));
                       recordObj= recordObj.concat(json);
                    } else {
                        recordObj = SimLokiDatabaseActions.getAll('scalablegroup');
                    }

                    controllerCallback(recordObj && recordObj.length ? recordObj : []);
                    return;
                }
                else {
                    var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler(type, {'qualifier' : t[6].split('?')[0]});
                    controllerCallback(recordObj && recordObj.length ? recordObj : []);
                    return;
                }
            }

            if(type == 'scalablegroup' && filter['id'] != undefined && filter['id'].length ){
                var result = [], j;
                var scalableGroupHash = new Object();
                var tGroup = SimLokiDatabaseActions.getAll(type);
                var recordObj2 = JSON.parse(JSON.stringify(TCP_UDP_Template_1.TCP_UDP_TEMPLATE_1));

                Array.prototype.push.apply(tGroup, recordObj2);


                for(j =0; j < tGroup.length; j++){
                   scalableGroupHash[tGroup[j].id] = j;
                }

                var tArr = filter['id'].split(',');
                var t_Arr1 = UtilityFunctions.remove_duplicates(tArr);

                var res = getScalablegroupForPolicy(t_Arr1);
                if(res.bPolicy){
                    controllerCallback(res.policy);
                    return;
                }
                res = getCopyPolicy(t_Arr1);
                if(res.bCopyPolicy){
                    controllerCallback(res.policy);
                    return;
                }

                for(j=0; j < t_Arr1.length; j++){
                    if(scalableGroupHash[t_Arr1[j]] != undefined) {
                        result.push( tGroup[ scalableGroupHash[t_Arr1[j]] ] );
                    } else {
                        var res = getContract(t_Arr1[j]);
                        if(res.bContract){
                            result.push(res.contract[0]);
                        }
                    }
                }
                /*for(var i=0; i < ScalableGroupIseTemplate.ScalableGroupIse_Template.length; i++) {
                    if( ScalableGroupIseTemplate.ScalableGroupIse_Template[i].scalableGroupType == 'USER_DEVICE') {
                        var t_Json = JSON.parse(JSON.stringify( ScalableGroupIseTemplate.ScalableGroupIse_Template[i]));
                        //tVnContextJson.scalableGroup.push({ idRef: t_Json.id });
                        result.push(t_Json);
                    }
                }*/
                controllerCallback(result);
                return;
            }

            if(type == 'virtualnetworkcontext' && filter['id'] != undefined && filter['id'] != ''){
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('virtualnetworkcontext', {'id' : filter['id']});
                controllerCallback(data == undefined ? [] : data);
                return;
            }

            if(type=="ConnectivityDomain"  && filter['domainType'] != undefined) {

                var filterValue = filter['domainType'].split(",");
                console.log(filterValue);
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'domainType' : { '$containsAny' :  filterValue}});

                controllerCallback(data == undefined ? [] : data);
                return;

            }
            if(type=="ConnectivityDomain"  && filter['id'] != undefined) {

                if(filter['id'].indexOf("%2C")>=0 ) {

                    var filterValue = filter['id'].split("%2C");
                console.log(filterValue);
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id' : { '$containsAny' :  filterValue}});

                controllerCallback(data == undefined ? [] : data);
                return;

                }
                var data = SimLokiDatabaseActions.getFilteredRecordHandler('ConnectivityDomain', {'id' : filter['id'] });

                controllerCallback(data == undefined ? [] : data);
                return;

            }



            var data = SimLokiDatabaseActions.getAll(type);
            //if(type == 'VirtualNetwork'){
                console.log(data);
            //}
            controllerCallback(data == undefined ? [] : data);
        },

        getAudit: function (url, controllerCallback) {
            var recordObj = SimLokiDatabaseActions.getAll('audit');
            controllerCallback(recordObj && recordObj.length ? recordObj : []);
        },

        CfsPost: function (url, jsonObj, controllerCallback) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var type = '';

            var t = url.split('/');
            var type = t[5];
            type = type.split('?')[0];
            console.log("type = " + type);

            if (type == 'APModeChange') {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            if (type == 'copydestination') {
                type = "CopyDestination";
                SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }


            if (type == 'policy' || type == 'contract') {
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                if (subtype == 'access') {
                    if (type == 'policy') {
                        type = 'AccessPolicy';
                    } else {
                        type = 'ContractAccess';
                    }
                } else if (subtype == 'copy') {
                    if (type == 'policy') {
                        type = 'CopyPolicy';
                    } else {
                        type = 'copyContract';
                    }

                } else if (subtype == 'application') {
                    type = 'ApplicationContract';
                }
                SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }


            if (type == 'scratchpad') {
               type = 'ApplicationPolicyPreview';
                SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            if (type == 'scalablegroup') {
                SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            if(type == "airsense") {
                var subType =  t[6];
                SimScheduledCaptureData.updateSettings(subType,jsonObj)
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;

            }

            if(type == apWirelessServiceType) {
                SimDataGenerationInit.cfsCUDoperation(type,'post', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            type = jsonObj.type;
            if (jsonObj.virtualNetworkContextType != undefined && jsonObj.virtualNetworkContextType != '') {
                type = 'virtualnetworkcontext';
            }

            SimDataGenerationInit.cfsCUDoperation(type, 'post', jsonObj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            controllerCallback(resultObj);
        },

        CfsPut: function (url, jsonObj, controllerCallback) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            //var type = jsonObj.type;
            var type;

            if($.isEmptyObject(jsonObj) == false){
              type = jsonObj.type;
            }

            var t = url.split('/');
            var type = t[5];
            type = type.split('?')[0];

            if (type == 'APModeChange') {
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            if (type == 'scalablegroup') {
                SimDataGenerationInit.cfsCUDoperation(type, 'PUT', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            if (type == 'copydestination') {
                type = "CopyDestination";
                SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            } else if (type == 'policy' || type == 'contract') {
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                if (subtype == 'access') {
                    if (type == 'policy') {
                        type = 'AccessPolicy';
                    } else {
                        type = 'ContractAccess';
                    }
                } else if (subtype == 'copy') {

                    if (type == 'policy') {
                        type = 'CopyPolicy';
                    } else {
                        type = 'copyContract';
                    }
                }  else if (subtype == 'application') {
                    type = 'ApplicationContract';
                }
                SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }else if(type == apWirelessServiceType) {
                SimDataGenerationInit.cfsCUDoperation(type,'put', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            if (jsonObj != undefined && jsonObj.namespace == 'VirtualNetworkContext') {
                type = 'virtualnetworkcontext';
            }
            SimDataGenerationInit.cfsCUDoperation(type, 'put', jsonObj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            controllerCallback(resultObj);
        },

        CfsDelete: function (url, controllerCallback) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var t = url.split('/');
            var type = t[5];
            var jsonObj = {id: ''};
            if (type == 'scalablegroup') {
                jsonObj.id = t[6];
                SimDataGenerationInit.cfsCUDoperation(type, 'DELETE', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            }

            if (type == 'copydestination') {
                jsonObj.id = t[7];
                type = "CopyDestination";
                SimDataGenerationInit.cfsCUDoperation(type, 'delete', jsonObj);
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
                controllerCallback(resultObj);
                return;
            } else if (type == 'policy' || type == 'contract') {
                var subtype = t[6];
                subtype = subtype.split('?')[0];
                if (subtype == 'access') {
                    jsonObj.id = t[7];
                    if (type == 'policy') {
                        type = 'AccessPolicy';
                    } else {
                        type = 'ContractAccess';
                    }
                } else if (subtype == 'copy') {
                    jsonObj.id = t[7];

                    if (type == 'policy') {
                        type = 'CopyPolicy';
                    } else {
                        type = 'copyContract';
                    }
                } else if (subtype == 'application') {
                    jsonObj.id = t[7];
                    type = 'ApplicationContract';
                }
            } else if(type == 'airsense') {

                resultObj = SimScheduledCaptureData.deleteSettings(t[7],t[6])
                 controllerCallback(resultObj);
                 return
            }
            else {
                jsonObj.id = t[6];
            }
            SimDataGenerationInit.cfsCUDoperation(type, 'delete', jsonObj);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            controllerCallback(resultObj);
        },

        getAuditCount: function (url, controllerCallback) {
            var count = 0;
            var recordObj = SimLokiDatabaseActions.getAll('audit');

            if (recordObj && recordObj.length) {
                count = recordObj.length;
            }
            controllerCallback(count);
        },

        getDeviceConfigStatusCount: function(filter, controllerCallback) {
           var count = 0;
           if (filter['cfsNamespace'] != undefined && filter['cfsNamespace'].length > 0) {
               if(filter['cfsNamespace'].search(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/) == 0){
                   var data = getDeviceSpecificConfigStatus(filter['cfsNamespace']);
                   return controllerCallback(data && data.length ? data : []);
               }


               var configName = filter['cfsNamespace'].split(':');
               if (configName[0] == 'policy' && configName[1] == 'access') {
                   var recordObj = SimLokiDatabaseActions.getAll('access-policy');
                   var t_Json = JSON.parse(JSON.stringify(AccessPolicyTemplate.ACCESS_POLICY_STATUS_TEMPLATE));
                   var data = [];
                   if (recordObj && recordObj.length) {
                       count = recordObj.length;
                       t_Json.id = UtilityFunctions.generate_uuid();
                       t_Json.createTime = UtilityFunctions.getTimeStamp();
                       t_Json.lastUpdateTime = UtilityFunctions.getTimeStamp();
                       t_Json.namespace = filter['cfsNamespace'];
                       t_Json.provisioningData["Access-Policy-Count-Details"] = {
                            "Success_Count": count,
                            "Failure_Count": "0"
                       };
                       data.push(t_Json);
                   }
                   controllerCallback(data && data.length ? data : []);
                   return;
               }
              if (configName[0] == 'policy' && configName[1] == 'application') {
                 var data;
                 if(filter['status']!=undefined) {
                    var statusFilterVal = filter['status'];
                    var statusFilter = statusFilterVal.split("%2");

                    data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',  {'$and': [ {
                                                                  'namespace': filter['cfsNamespace']
                                                                },
                                                                 {
                                                                   'status': { '$containsAny' :  statusFilter}}]});

                  } else {

                    data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'namespace': filter['cfsNamespace']});
                  }
                controllerCallback(data && data.length );
              }
           }

            if (filter['status'] != undefined ) {

             data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',  {
                                                               'aggregatedStatus':"Not Provisioned"});
              controllerCallback(data && data.length );
              return

            }

            if (filter['aggregatedStatus'] != undefined) {
                data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'aggregatedStatus':decodeURI(filter['aggregatedStatus'])});
                controllerCallback(data && data.length);
                return;   
            }

        },

         getDeviceConfigStatus: function(filter, controllerCallback) {

           var data = [];
            if( filter['deviceId'] != undefined) {
                var commaSeperatedId = filter['deviceId'];
                if(commaSeperatedId) {
                    var deviceId = commaSeperatedId.split(',');
                    var aggregateStatus = {};

                    for (commaIndex=0; commaIndex<deviceId.length;commaIndex++) {
                        var record = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', {'deviceId': deviceId[commaIndex]});
                        if(record && record.length>0) {
                            data.push(record[0]);
                        }
                    }
                }
               return controllerCallback(data == undefined ? [] : data);
            }


             var data;

             if(filter['status']!=undefined) {

                var statusFilterVal = filter['status'];
                var statusFilter = statusFilterVal.split("%2");

                 data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',  {'$and': [ {
                                                                  'namespace': filter['cfsNamespace']
                                                                },
                                                                 {
                                                                   'status': { '$containsAny' :  statusFilter}}]});
              } else {

                data = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',{'namespace': filter['cfsNamespace']});
              }


            controllerCallback(data == undefined ? [] : data);
         }
          ,
        getGroupNames : function(url,filter, controllerCallback){

            var data = SimLokiDatabaseActions.getAll('group-name');
            controllerCallback(data);

        },

        CfsSsid : function(url,controllerCallback){
            var data = SimLokiDatabaseActions.getAll('segment-ssid');
            controllerCallback(data);
        },

        CfsIntent: function(url, jsonObj, controllerCallback){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

            var t = url.split('/');
            var type = t[5];
            type = 'AccessPolicy';

            for (i = 0; i < jsonObj.deleteList.length; i++) {
                SimDataGenerationInit.cfsCUDoperation(type, 'delete', {"id":jsonObj.deleteList[i]});
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            controllerCallback(resultObj);
            return;
        },

        CfsIntentScalableGroup: function(url, jsonObj, controllerCallback){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

            for (i = 0; i < jsonObj.deleteList.length; i++) {
                SimDataGenerationInit.cfsCUDoperation('scalablegroup', 'DELETE', {"id":jsonObj.deleteList[i]});
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            controllerCallback(resultObj);
            return;
        },

        CfsIntentCopyPolicy: function(url, jsonObj, controllerCallback){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

            for (i = 0; i < jsonObj.deleteList.length; i++) {
                SimDataGenerationInit.cfsCUDoperation('CopyPolicy', 'delete', {"id":jsonObj.deleteList[i]});
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            controllerCallback(resultObj);
            return;
        },

        CfsIntentContractAccess: function(url, jsonObj, controllerCallback){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

            for (i = 0; i < jsonObj.deleteList.length; i++) {
                SimDataGenerationInit.cfsCUDoperation('ContractAccess', 'delete', {"id":jsonObj.deleteList[i]});
            }
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });
            controllerCallback(resultObj);
            return;
        },
        CfsIntentApplicationPolicy: function(url, jsonObj, controllerCallback){
            var resultObj = SimTaskDataGenerator.createTask('SPFService');

           setTimeout(function() {
           SimDataGenerationInit.cfsCUDoperation('ApplicationPolicy', 'post', jsonObj);
           SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {isError: false });


           }, 5000)

            controllerCallback(resultObj);
            return;
        },

    };

    function getCopyPolicy(tArr) {
        var result = { 'bCopyPolicy': false, 'policy': []};
        if(tArr.length == 1) {
            var recordObj = SimLokiDatabaseActions.getAll('copy-policy');
            if (recordObj && recordObj.length) {
                var j;
                for(j=0; j < recordObj.length; j++){
                    if( (recordObj[j].id === tArr[0]) == true) {
                        result.bCopyPolicy = true;
                        result.policy.push(recordObj[j]);
                        return result;
                    }
                }
            }
        }
        return result;
    }

    function getScalablegroupForPolicy(tArr) {
        var result = { 'bPolicy': false, 'policy': []};
        if(tArr.length == 1) {
            var subtype = 'access', type = 'policy';
            var recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
            if (recordObj && recordObj.length) {
                var j;
                for(j=0; j < recordObj.length; j++){
                    if( (recordObj[j].id === tArr[0]) == true) {
                        result.bPolicy = true;
                        result.policy.push(recordObj[j]);
                        return result;
                    }
                }
            }
        }
        return result;
    }

    function getContract(tId) {
        var result = { 'bContract': false, 'contract': []};
        var subtype = 'access', type = 'contract';
        var recordObj = SimLokiDatabaseActions.getAll(getLokiDbName(subtype,type));
        if (recordObj && recordObj.length) {
            for(j=0; j < recordObj.length; j++){
                if( (recordObj[j].id === tId) == true) {
                    result.bContract = true;
                    result.contract.push(recordObj[j]);
                    return result;
                }
            }
        }

        var recordObj = SimLokiDatabaseActions.getAll('copy-contract');
        if (recordObj && recordObj.length) {
            for(j=0; j < recordObj.length; j++){
                if( (recordObj[j].id === tId) == true) {
                    result.bContract = true;
                    result.contract.push(recordObj[j]);
                    return result;
                }
            }
        }

        var recordObj = SimLokiDatabaseActions.getAll('copy-destination');
        if (recordObj && recordObj.length) {
            for(j=0; j < recordObj.length; j++){
                if( (recordObj[j].id === tId) == true) {
                    result.bContract = true;
                    result.contract.push(recordObj[j]);
                    return result;
                }
            }
        }

        return result;
    }

});
