define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sinonmock/models/SimSinonMockModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/assurance/models/SimAssuranceModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/group/models/SimGroupModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/network-device/models/SimNetworkDeviceModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/network-device/models/SimNetworkDeviceCredentialModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/interface/models/SimInterfaceModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/task/models/SimTaskModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/topology/models/SimTopologyModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/service-mapper/SimServiceMapper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/design/models/SimDesignModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/dna-maps/models/SimDnaMapsModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/design/models/SimIppoolModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/design/models/SimSiteProfileModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/design/models/SimDNAWirelessModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/design/models/SimCommonSettingModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/swim/models/SimSwimModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/swim/models/SimDeviceImageModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/verify/models/SimVerifyModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/discovery/models/SimDiscoveryModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/global-credential/models/SimGlobalCredentialModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/network-orchestration/models/SimNetworkOrchestration',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/identitymgmt/models/SimIdentityMgmtModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/scheduled-job/models/SimScheduledJobModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/command-runner/models/SimCommandRunnerModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/service-deployment-status/models/SimServiceDeploymentStatusModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/workflow-status/models/SimWorkFlowStatusModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/network-device/models/SimTagMemberModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/license-manage/models/SimLicenseManagerModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/cfs/models/SimCfsModelsv1',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/nfvis/models/SimNfvisModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/network-device/models/SimMdfModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/templateProgammer/models/SimTemplateProgammerModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/templateProgammer/models/SimNetworkProgrammerModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sda-helper-service/index',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sda-helper-service/SimSDAHelperServiceModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/graphql/SimGraphQL',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/server-management/SimServerManagement',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/ncp-node/models/SimNcpNodeModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/network-device/models/SimDeviceReplacementModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/bonjour/models/SimBonjourModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/kairos/models/SimKairosModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/backup/models/SimBackupModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/settings/models/SimEventModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/settings/models/SimSettingModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/cloud-provisioning/models/SimCloudProvisionModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/app-hoisting/SimAppHoistingModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/reports/models/SimROIModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/systemsettings/SimIntegrityVerificationModel',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/systemsettings/SimSftpModel',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/systemsettings/SimSystemsettingsModel',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/systemsettings/SimPkiModel',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/systemsettings/SimProxyCertificateModel',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/systemsettings/SimCertificateAuthorityModel',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/systemsettings/SimNfvProvisioningServiceModel'

], function (SimSinonMockModels, SimAssuranceModels, SimGroupModels,
             SimNetworkDeviceModels, SimNetworkDeviceCredentialModels, SimInterfaceModels, SimTaskModels,
             SimTopologyModels, SimServiceMapper, SimDesignModels, SimDnaMapsModels, SimIppoolModels,
             SimSiteProfileModels, SimDNAWirelessModels,SimCommonSettingModels, SimSwimModels, SimDeviceImageModels,
             SimVerifyModels, SimDiscoveryModels, SimGlobalCredentialModels, SimNetworkOrchestration,
             SimIdentityMgmtModels, SimScheduledJobModels, SimCommandRunnerModels,SimServiceDeploymentStatusModels,
             SimWorkFlowStatusModels,SimTagMemberModels,SimLicenseManagerModels,SimCfsModelsv1, SimNfvisModels, SimMdfModels,
             SimTemplateProgammerModels, SimNetworkProgrammerModels,SimSDAHelperService, SimSDAHelperServiceModels, SimGraphQL, SimServerManagement, SimNcpNodeModels,
             SimDeviceReplacementModels, SimBonjourModels, SimKairosModels, SimBackupModels, SimEventModels, SimSettingModels,
             SimCloudProvisionModels, SimAppHoistingModels, SimROIModels, SimIntegrityVerificationModel, SimSftpModel, SimSystemsettingsModel,
             SimPkiModel, SimProxyCertificateModel, SimCertificateAuthorityModel, SimNfvProvisioningServiceModel) {


    var serviceHandler = {
       defaultSinonModelHandler: function(urlAction) {
           return SimSinonMockModels.doProcessRestRequest(urlAction);
       },
       aaa :function(urlAction) {
           return SimDesignModels.doProcessRestRequest(urlAction);
       },
       app: function(urlAction){
           return SimAssuranceModels.doProcessRestRequest(urlAction);
       },
       assurance: function(urlAction){
           return SimAssuranceModels.doProcessRestRequest(urlAction);
       },
       'archive-config': function(urlAction) {
           return SimDeviceReplacementModels.doProcessRestRequest(urlAction);
       },
       'deploy-config': function(urlAction) {
           return SimDeviceReplacementModels.doProcessRestRequest(urlAction);
       },
       commonsetting: function(urlAction) {
           //return SimDesignModels.doProcessRestRequest(urlAction);
           return SimCommonSettingModels.doProcessRestRequest(urlAction);
       },
       'core-services': function(urlAction) {
           return SimKairosModels.doProcessRestRequest(urlAction);
       },
       'customer-facing-service': function(urlAction) {
           return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       'device-config-status': function(urlAction) {
           return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       audit: function(urlAction) {
           return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       "cfs-intent": function(urlAction) {
           return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       "aca-controller-service": function(urlAction) {
           return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       // for GBPolicy by Karan 1.3.1
       "cfs-intent": function(urlAction) {
           return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       'device-credential' :function(urlAction){
          //return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
          return SimNetworkDeviceCredentialModels.doProcessRestRequest(urlAction);
       },
       'device-image' :function(urlAction) {
           return SimDeviceImageModels.doProcessRestRequest(urlAction);
       },
       'device-replacement' : function(urlAction) {
           return SimDeviceReplacementModels.doProcessRestRequest(urlAction);
       },
       discovery: function(urlAction){
           return SimDiscoveryModels.doProcessRestRequest(urlAction);
       },
       devicepkg: function(urlAction){
           return SimSiteProfileModels.doProcessRestRequest(urlAction);
       },
       'dna-maps-service': function(urlAction) {
           return SimDnaMapsModels.doProcessRestRequest(urlAction);
       },
       'dna-wireless-service' :function(urlAction) {
           return SimDNAWirelessModels.doProcessRestRequest(urlAction);
       },
       'dna': function(urlAction){
           return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
       },
       'dashboard' : function(urlAction){
           return SimBonjourModels.doProcessRestRequest(urlAction);
       },
       'event': function(urlAction){
           return SimEventModels.doProcessRestRequest(urlAction);
       },
       'events': function(urlAction){
        return SimEventModels.doProcessRestRequest(urlAction);
    },
       'eox-status': function(urlAction){
           return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
       },
       'onboarding': function(urlAction){
           return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
       },
       fedsearch: function(urlAction){
           return SimAssuranceModels.doProcessRestRequest(urlAction);
       },
       file: function(urlAction) {
           //return SimAssuranceModels.doProcessRestRequest(urlAction);
           return SimCommandRunnerModels.doProcessRestRequest(urlAction);
       },
       'flow-analysis': function(urlAction) {
           return SimAssuranceModels.doProcessRestRequest(urlAction);
       },
       'global-credential': function(urlAction) {
           //return SimDiscoveryModels.doProcessRestRequest(urlAction);
           return SimGlobalCredentialModels.doProcessRestRequest(urlAction);
       },
       ippool :function(urlAction) {
           return SimIppoolModels.doProcessRestRequest(urlAction);
       },
       group: function(urlAction){
           return SimGroupModels.doProcessRestRequest(urlAction);
       },
       image :function(urlAction) {
           return SimSwimModels.doProcessRestRequest(urlAction);
       },
       interface: function(urlAction){
           //return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
           return SimInterfaceModels.doProcessRestRequest(urlAction);
       },
       inventory :function(urlAction) {
           return SimDiscoveryModels.doProcessRestRequest(urlAction);
       },
       /*kairos: function(urlAction) {
        if(urlAction.url.endsWith('graphql')) return SimGraphQL.getData(urlAction.restPayload)
        else return {"isOnboarded":true}*/ //commenting for now
       kairos : function(urlAction) {
           return SimKairosModels.doProcessRestRequest(urlAction);
       },
        //Transit PubSub/List changes - start
        'lazy-load': function(urlAction) {
            return SimCfsModelsv1.doProcessRestRequest(urlAction);
        },
        //Transit PubSub/List changes - end
       license: function(urlAction) {
           return SimLicenseManagerModels.doProcessRestRequest(urlAction);
       },
       licensemanager: function(urlAction) {
           return SimLicenseManagerModels.doProcessRestRequest(urlAction);
       },
       "cisco.dna.licenseManagement": function(urlAction) {
           return SimLicenseManagerModels.doProcessRestRequest(urlAction);
       },
       mdf: function(urlAction){
           return SimMdfModels.doProcessRestRequest(urlAction);
       },
       mdfdata: function(urlAction){
           return SimMdfModels.doProcessRestRequest(urlAction);
       },
       member: function(urlAction) {
           return SimTagMemberModels.doProcessRestRequest(urlAction);
       },
       ndp: function(urlAction){
         return SimAssuranceModels.doProcessRestRequest(urlAction);
       },
       na: function(urlAction) {
           return SimAssuranceModels.doProcessRestRequest(urlAction);
       },
        'ncp-node': function(urlAction){
            return SimNcpNodeModels.doProcessRestRequest(urlAction);
        },
       'network-device': function(urlAction){
           return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
       },
       'network-device-poller': function(urlAction) {
           return SimCommandRunnerModels.doProcessRestRequest(urlAction);
       },
       'network-orchestration': function(urlAction){
           return SimNetworkOrchestration.doProcessRestRequest(urlAction);
       },
       'network-programmer': function(urlAction){
           return SimNetworkProgrammerModels.doProcessRestRequest(urlAction);
       },
       'nfv-provisioning-service': function(urlAction){
           return SimNfvisModels.doProcessRestRequest(urlAction);
       },
       'nfv-service-chaining': function(urlAction){
           return SimNfvisModels.doProcessRestRequest(urlAction);
       },
       'orchestration-engine': function(urlAction) {
         return SimWorkFlowStatusModels.doProcessRestRequest(urlAction);
       },
       persistance: function(urlAction){
         return SimAssuranceModels.doProcessRestRequest(urlAction);
       },
       'query-domain' : function(urlAction){
           return SimBonjourModels.doProcessRestRequest(urlAction);
       },
       'resource-manager' :function(urlAction) {
           return SimNetworkProgrammerModels.doProcessRestRequest(urlAction);
       },
       'scheduled-job' :function(urlAction) {
           return SimScheduledJobModels.doProcessRestRequest(urlAction);
       },
       system :function(urlAction) {
           return SimIdentityMgmtModels.doProcessRestRequest(urlAction);
       },
       siteprofile: function(urlAction){
           return SimSiteProfileModels.doProcessRestRequest(urlAction);
       },
       'service-deployment-status': function(urlAction){
           return SimServiceDeploymentStatusModels.doProcessRestRequest(urlAction);
       },
       'sd-mdns-preferences' : function(urlAction){
           return SimBonjourModels.doProcessRestRequest(urlAction);
       },
       'service-policy' : function(urlAction){
           return SimBonjourModels.doProcessRestRequest(urlAction);
       },
       'service-entry' : function(urlAction){
           return SimBonjourModels.doProcessRestRequest(urlAction);
       },
       'sdg-node' : function(urlAction){
           return SimBonjourModels.doProcessRestRequest(urlAction);
       },
       'service-type' : function(urlAction){
           return SimBonjourModels.doProcessRestRequest(urlAction);
       },
       task: function(urlAction) {
           return SimTaskModels.doProcessRestRequest(urlAction);
       },
       'tag': function(urlAction) {
           return SimTagMemberModels.doProcessRestRequest(urlAction);
       },
       'template-programmer': function(urlAction) {
            return SimTemplateProgammerModels.doProcessRestRequest(urlAction);
        },
       topology: function(urlAction) {
           return SimTopologyModels.doProcessRestRequest(urlAction);
       },
       validation: function(urlAction) {
           return SimVerifyModels.doProcessRestRequest(urlAction);
       },
      'workflow-status': function(urlAction) {
           return SimWorkFlowStatusModels.doProcessRestRequest(urlAction);
       },
       'cisco.dna.provision.uci': function(urlAction){
           return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
       },
       'cisco.dna.provision.uci.virtual_networks': function(urlAction){
           return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
       },
       'cisco.dna.provision': function(urlAction){
           return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
       },
       'cisco.dna.rmaDeviceView': function(urlAction){
           return SimDeviceReplacementModels.doProcessRestRequest(urlAction);
       },
       'sda-helper-service': function(urlAction){
           return SimSDAHelperServiceModels.doProcessRestRequest(urlAction); // Commented by Karan as i dnt find SimSDAHelperService file 1.3.3
           // return {"response":[],"version":"1.0"}
       },
       'server-management': function(urlAction){
           return SimServerManagement.doProcessRestRequest(urlAction);
       },
       'health': function(urlAction){
           return SimServerManagement.doProcessRestRequest(urlAction);
       },
       'reasoner': function(urlAction){
           return SimAssuranceModels.doProcessRestRequest(urlAction); // adding reasoner for loop issue rootCauseAnalysis in assurance
       },
       "troubleshooting":function(urlAction){
          return  SimAssuranceModels.doProcessRestRequest(urlAction); // adding troubleshooting for loop issue rootCauseAnalysis in assurance
       },
       'pxgridsecuritygroup':function(urlAction){
          return  SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       'magellan': function(urlAction){
            return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       'dcs': function(urlAction){
        return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       'endpoint-analytics': function(urlAction){
        return SimCfsModelsv1.doProcessRestRequest(urlAction);
       },
       'disaster-recovery': function(urlAction){
            return {"response": {"state": "disabled"}, "version": "1.5.1"}
       },
       'backup': function(urlAction){
            return SimBackupModels.doProcessRestRequest(urlAction);
       },
       'restore': function(urlAction){
            return SimBackupModels.doProcessRestRequest(urlAction);
       },
       'schedule': function(urlAction){
            return SimBackupModels.doProcessRestRequest(urlAction);
       },
       'token-registration': function(urlAction){
            return {"key":"sabretooth","data":"true"};
       },
       'cisco-ise': function(urlAction){
            return {"upnEnabled":true};
       },
       'cisco.dna.wireless-workflows': function(urlAction){
           return SimWorkFlowStatusModels.doProcessRestRequest(urlAction);
       },
       'cisco.dna.core.dashboard': function(urlAction){
            return SimAssuranceModels.doProcessRestRequest(urlAction);
        },
        'cisco.dna.design.swimnew': function(urlAction){
            return SimSwimModels.doProcessRestRequest(urlAction);
        },
        'compliance': function(urlAction){
            return SimDeviceReplacementModels.doProcessRestRequest(urlAction);
            //this is generic for all devices. but moving here as replace device uses another call that is made, related to compliance summary.
        },

        'security-advisory': function(urlAction){
            return SimDeviceReplacementModels.doProcessRestRequest(urlAction);
            //this is generic for all devices. but moving here as replace device uses another call that is made, related to compliance summary.
        },
        'snmp-property': function(urlAction){
            return SimSettingModels.doProcessRestRequest(urlAction); //{"response":[],"version":"1.0"}
        },
        'ipam': function(urlAction){
            return SimSettingModels.doProcessRestRequest(urlAction);
        },
        "cloud-provisioning" : function(urlAction){
            return SimCloudProvisionModels.doProcessRestRequest(urlAction);
        },        
        "diagnostics" : function(urlAction){
            return SimSettingModels.doProcessRestRequest(urlAction);
        },
        "cisco.dna.securityAdvisories" : function(urlAction){
            return SimSettingModels.doProcessRestRequest(urlAction);
        },
        'iox': function(urlAction){
            return SimAppHoistingModels.doProcessRestRequest(urlAction);
        },
        'cisco.dna.appHosting': function(urlAction){
            return SimAppHoistingModels.doProcessRestRequest(urlAction);
        },
        'workflow': function(urlAction){
            return SimAssuranceModels.doProcessRestRequest(urlAction);
        },
        'cloud-connectivity-sense': function(urlAction){
            return SimROIModels.doProcessRestRequest(urlAction);
        },
        'integrity-verification': function(urlAction){
            return SimIntegrityVerificationModel.doProcessRestRequest(urlAction);
        },
        'sftp': function(urlAction){
            return SimSftpModel.doProcessRestRequest(urlAction);
        },
        'systemsettings': function(urlAction){
            return SimSystemsettingsModel.doProcessRestRequest(urlAction);
        },
        'pki': function(urlAction){
            return SimPkiModel.doProcessRestRequest(urlAction);
        },
        'proxy-certificate': function(urlAction){
            return SimProxyCertificateModel.doProcessRestRequest(urlAction);
        },
        'certificate-authority': function(urlAction){
            return SimCertificateAuthorityModel.doProcessRestRequest(urlAction);
        },
        'nfv-provisioning-service': function(urlAction){
            return SimNfvProvisioningServiceModel.doProcessRestRequest(urlAction);
        },
        'vmanage-service': function(urlAction){
            return SimNetworkDeviceModels.doProcessRestRequest(urlAction);
        },
        'registration' : function(urlAction){
            return SimWorkFlowStatusModels.doProcessRestRequest(urlAction);             
        }        
    };

    return {
        init: function() {
            SimServiceMapper.init();
            SimCommandRunnerModels.init();
        },

        doProcessRequest: function(urlAction) {    
            var data;
            //console.log(urlAction.url + ' -- ' + urlAction.method);
            if(urlAction.isDefaultHandling) {
                data = serviceHandler['defaultSinonModelHandler'](urlAction);
            } else {
                data = serviceHandler[urlAction.serviceName](urlAction);
            }
            return SimServiceMapper.encodeRestResponse(urlAction, data);
        }

    };

});
