define({
    //Airtime efficiency insight = (axATE - nonAxATE)*100/nonAxATE
    //Latency insight = (nonAxLatency-axLatency)*100/axLatency. May be summation too.
    "WIFI6_VOICE_AIRTIME_EFFICIENCY":{
        "records":[
            {"modificationTime":1588703100000,"timestamp":"2020-05-05T18:25:00.00+000",
                "axATE":28.945722165524614,"nonAxATE":20.652949577165046,"axBytes":5.4252662E7,"nonAxBytes":4.1376012E7,
                "axAirtime":2142680.0,"nonAxAirtime":1975392.0}
        ],
        "insights":40.15
    },

    "WIFI6_VIDEO_AIRTIME_EFFICIENCY":{
        "records":[
            {"modificationtime":1588703100000,"timestamp":"2020-05-05T18:25:00.000+0000",
                "axATE":50.67252073349526,"nonAxATE":41.78811999483337,"axBytes":8.5487541E7,"nonAxBytes":7.764567E7,
                "axAirtime":1858080.0,"nonAxAirtime":1827960.0}
        ],
        "insights":21.26
    },

    "WIFI6_BACKGROUND_AIRTIME_EFFICIENCY":{
        "records":[
            {"modificationtime":1588703100000,"timestamp":"2020-05-05T18:25:00.000+0000",
            "axATE":24.62748417488591,"nonAxATE":15.659733248650365,"axBytes":1301150.0,"nonAxBytes":560490.0,
            "axAirtime":62980.0,"nonAxAirtime":27172.0}
        ],
        "insights":57.26
    },

    "WIFI6_BEST_EFFORTS_AIRTIME_EFFICIENCY":{
        "records":[
            {"modificationtime":1588703100000,"timestamp":"2020-05-05T18:25:00.000+0000",
            "axATE":69.27241532437124,"nonAxATE":51.433712767513285,"axBytes":6.863788E7,"nonAxBytes":2.9541673E7,
            "axAirtime":990840.0,"nonAxAirtime":574364.0}
        ],
        "insights":34.68
    },

    "WIFI6_ALL_AIRTIME_EFFICIENCY":{
        "records":[
            {"modificationtime":1588703100000,"timestamp":"2020-05-05T18:25:00.000+0000",
                "axATE":40.79638724975698,"nonAxATE":34.41483867999542,"axBytes":1.88220052E8,"nonAxBytes":1.58583026E8,
                "axAirtime":4851484.0,"nonAxAirtime":4607984.0}
        ],
        "insights":18.54
    },

    "WIFI6_VOICE_LATENCY_BY_CLIENT_COUNT":{
        "records":[
            {"nonAxLatency":459824.0,"axLatency":255116.0,"clientCountBucket":"10"},
            {"nonAxLatency":481234.0,"axLatency":265841.0,"clientCountBucket":"20"},
            {"nonAxLatency":510462.0,"axLatency":280456.0,"clientCountBucket":"30"},
            {"nonAxLatency":547231.0,"axLatency":309356.0,"clientCountBucket":"40"},
            {"nonAxLatency":573504.0,"axLatency":348487.0,"clientCountBucket":"50"},
            {"nonAxLatency":579999.0,"axLatency":350125.0,"clientCountBucket":"50+"}
        ],
        "insights":74.90
    },

    "WIFI6_VIDEO_LATENCY_BY_CLIENT_COUNT":{
        "records":[
            {"nonAxLatency":329224.0,"axLatency":205116.0,"clientCountBucket":"10"},
            {"nonAxLatency":341234.0,"axLatency":224541.0,"clientCountBucket":"20"},
            {"nonAxLatency":380462.0,"axLatency":240576.0,"clientCountBucket":"30"},
            {"nonAxLatency":407231.0,"axLatency":269353.0,"clientCountBucket":"40"},
            {"nonAxLatency":433504.0,"axLatency":308487.0,"clientCountBucket":"50"},
            {"nonAxLatency":499979.0,"axLatency":340124.0,"clientCountBucket":"50+"}
        ],
        "insights":74.90
    },

    "WIFI6_BE_LATENCY_BY_CLIENT_COUNT":{
        "records":[
            {"nonAxLatency":209828.0,"axLatency":175116.0,"clientCountBucket":"10"},
            {"nonAxLatency":241286.0,"axLatency":185841.0,"clientCountBucket":"20"},
            {"nonAxLatency":250952.0,"axLatency":200456.0,"clientCountBucket":"30"},
            {"nonAxLatency":275731.0,"axLatency":239356.0,"clientCountBucket":"40"},
            {"nonAxLatency":276047.0,"axLatency":248487.0,"clientCountBucket":"50"},
            {"nonAxLatency":319949.0,"axLatency":270125.0,"clientCountBucket":"50+"}
        ],
        "insights":74.90
    },

    "WIFI6_BG_LATENCY_BY_CLIENT_COUNT":{
        "records":[
            {"nonAxLatency":509824.0,"axLatency":451163.0,"clientCountBucket":"10"},
            {"nonAxLatency":512345.0,"axLatency":465324.0,"clientCountBucket":"20"},
            {"nonAxLatency":520462.0,"axLatency":484666.0,"clientCountBucket":"30"},
            {"nonAxLatency":557231.0,"axLatency":503436.0,"clientCountBucket":"40"},
            {"nonAxLatency":573504.0,"axLatency":541434.0,"clientCountBucket":"50"},
            {"nonAxLatency":639999.0,"axLatency":559628.0,"clientCountBucket":"50+"}
        ],
        "insights":74.90
    }

});