define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sinonmock/rest/SimSinonMockRestEndPoints',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/sinonmock/rest/SimSinonMockRestHelper',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/SimulationAppRegister'
], function (SimSinonMockRestEndPoints, SimSinonMockRestHelper, SimulationAppRegister) {

    var restEndPoints = [
        { name: 'aaa', endPoint: '/api/v([1-2])/aaa\*' },
        { name: 'aca-controller-service', endPoint: '/api/v([1-2])/aca-controller-service\*'},
        { name: 'assurance', endPoint: '/api/assurance/v\*' },
        { name: 'audit', endPoint: '/api/v([1-2])/audit\*'},
        { name: 'archive-config', endPoint: '/api/v([1-2])/archive-config\*'},
        { name: 'deploy-config', endPoint: '/api/v([1-2])/deploy-config/\*'},

        { name: 'backup', endPoint: '/api/system/v([1-2])/maglev/backup\*' },

        { name: 'certificate-authority', endPoint: '/api/v([1-2])/certificate-authority' },
        { name: 'certificate-authority', endPoint: '/api/v([1-2])/certificate-authority/update/default/trustpool' },
        { name: 'cfs-intent', endPoint: '/api/v([1-2])/data/cfs-intent/\*' },
        { name: 'cisco.dna.core.dashboard', endPoint: '/persistence/cisco.dna.core.dashboard' },      
        { name: 'cisco.dna.licenseManagement', endPoint: '/persistence/cisco.dna.licenseManagement'},
        { name: 'cisco.dna.provision.uci.virtual_networks', endPoint: '/persistence/cisco.dna.provision.uci.virtual_networks\*' },
        { name: 'cisco.dna.provision.uci', endPoint: '/persistence/cisco.dna.provision.uci\*' },
        { name: 'cisco.dna.provision', endPoint: '/persistence/cisco.dna.provision' },
        { name: 'cisco.dna.provision', endPoint: '/persistence/cisco.dna.provision/\*' },
        { name: 'cisco.dna.design.swimnew', endPoint: '/persistence/cisco.dna.design.swimnew/\*'},
        { name: 'cisco.dna.wireless-workflows', endPoint: '/persistence/cisco.dna.wireless-workflows' },
        { name: 'cisco.dna.securityAdvisories', endPoint: '/persistence/cisco.dna.securityAdvisories' },
        { name: 'cisco.dna.rmaDeviceView', endPoint: '/persistence/cisco.dna.rmaDeviceView' },
        { name: 'cisco-ise', endPoint: '/api/v([1-2])/cisco-ise/\*' },
        { name: 'cloud-provisioning', endPoint: '/api/v([1-2])/cloud-provisioning/\*' },
        { name: 'commonsetting', endPoint: '/api/v([1-2])/commonsetting\*' },
        { name: 'compliance', endPoint: '/api/v([1-2])/data/compliance\*'},
        { name: 'customer-facing-service', endPoint: '/api/v([1-2])/data/customer-facing-service\*' },
        { name: 'cloud-connectivity-sense', endPoint: '/api/cloud-connectivity-sense/v([1-2])/sense-op/insight/\*'}, //Reports-->Usage Insights
        
        { name: 'dashboard', endPoint: '/api/v([1-2])/dashboard\*' },
        { name: 'dcs', endPoint: '/api/dcs/v\*' },
        { name: 'device-config-status', endPoint: '/api/v([1-2])/data/device-config-status\*'},
        { name: 'device-credential', endPoint: '/api/v([1-2])/device-credential\*' },
        { name: 'device-image', endPoint: '/api/v([1-2])/device-image\*' },
        { name: 'device-replacement', endPoint: '/api/v([1-2])/device-replacement\*' },
        { name: 'devicepkg', endPoint: '/api/v([1-2])/devicepkg/\*' },
        { name: 'diagnostics',endPoint: '/api/v([1-2])/diagnostics/\*'},
        { name: 'disaster-recovery', endPoint: '/api/system/v([1-2])/maglev/disaster-recovery/\*' },
        { name: 'discovery', endPoint: '/api/v([1-2])/discovery\*' },
        { name: 'dna-maps-service', endPoint: '/api/v([1-2])/dna-maps-service\*' },
        { name: 'dna-wireless-service', endPoint: '/api/v([1-2])/dna-wireless-service\*' },
        { name: 'dna', endPoint: '/dna/system/api/v1/event/artifact\*' },
        { name: 'dna', endPoint: '/dna/data/api/v1/event/event-series/audit-log\*' },///dna/system/api/v1/event/artifact\*, /dna/data/api/v1/event/event-series/audit-log\*
        { name: 'dna', endPoint: '/api/v([1-2])/dna/\*' },
        
        { name: 'endpoint-analytics', endPoint: '/api/endpoint-analytics/v\*' },
        { name: 'event', endPoint: '/dna/intent/api/v([1-2])/event\*' },///dna/intent call specifically mapped to event, as per logic in restendpoints file
        { name: 'eox-status', endPoint: '/api/v([1-2])/eox-status\*' },
        
        { name: 'fedsearch', endPoint: '/api/fedsearch/v\*' },
        { name: 'file', endPoint: '/api/v([1-2])/file\*' },
        { name: 'flow-analysis', endPoint: '/api/v([1-2])/flow-analysis\*' },
        
        { name: 'global-credential', endPoint: '/api/v([1-2])/global-credential\*' },
        { name: 'group', endPoint: '/api/v([1-2])/group\*' },
        
        { name: 'health', endPoint: '/api/v([1-2])/system/health/\*' },
        { name: 'host', endPoint: '/api/v([1-2])/host\*' },
        
        { name: 'image', endPoint: '/api/v([1-2])/image/\*' },
        { name: 'interface', endPoint: '/api/v([1-2])/interface/\*' },
        { name: 'integrity-verification', endPoint: '/api/v([1-2])/integrity-verification/kgvdb/file-info' },
        { name: 'inventory', endPoint: '/api/v([1-2])/inventory/\*' },
        { name: 'ipam', endPoint: '/api/v([1-2])/ipam/\*' },
        { name: 'ippool', endPoint: '/api/v([1-2])/ippool\*' },

        { name: 'iox', endPoint:'/api/iox/service/api/v1/appmgr\*'}, 
        { name: 'kairos', endPoint:'/api/kairos/v([1-2])/\*'},

        { name: 'lazy-load', endPoint: '/api/v([1-2])/data/lazy-load/\*' },//Transit PubSub/List changes -start
        
        { name: 'license', endPoint: '/api/system/v([1-2])/license/\*' },
        { name: 'licensemanager', endPoint: '/api/v([1-2])/licensemanager/\*' },   

        { name: 'magellan', endPoint: '/api/system/v([1-2])/maglev/appstacks/magellan/\*'},
        { name: 'magellan', endPoint: '/api/magellan/v\*' },
        { name: 'mdf', endPoint: '/api/v([1-2])/mdf/\*' },
        { name: 'mdfdata', endPoint: '/api/v([1-2])/mdfdata/\*' },
        { name: 'member', endPoint: '/api/v([1-2])/member/\*' },

        { name: 'na', endPoint: '/api/na/v\*' },
        { name: 'ncp-node', endPoint: '/api/v([1-2])/ncp-node/*'},
        { name: 'ndp', endPoint: '/api/ndp\*' },
        { name: 'network-device', endPoint: '/dna/intent/api/v([1-2])/network-device\*' },
        { name: 'network-device', endPoint: '/api/v([1-2])/network-device\*' },
        { name: 'network-device-poller', endPoint: '/api/v([1-2])/network-device-poller\*' },
        { name: 'network-orchestration', endPoint: '/api/v([1-2])/network-orchestration\*' },
        { name: 'network-programmer', endPoint: '/api/v([1-2])/network-programmer/\*' },
        { name: 'nfv-provisioning-service', endPoint: '/api/v([1-2])/nfv-provisioning-service\*' },
        { name: 'nfv-provisioning-service', endPoint: '/api/v([1-2])/nfv-provisioning-service' },
        { name: 'nfv-service-chaining', endPoint: '/api/v([1-2])/nfv-service-chaining\*' },


        { name: 'onboarding', endPoint: '/api/v([1-2])/onboarding\*' },
        { name: 'orchestration-engine', endPoint: '/api/v([1-2])/orchestration-engine/\*' },

        { name: 'pki', endPoint: '/api/v([1-2])/pki/config' },
        { name: 'proxy-certificate', endPoint: '/api/v([1-2])/proxy-certificate' },
        { name: 'pxgridsecuritygroup', endPoint: '/api/v([1-2])/pxgridsecuritygroup/\*'},

        { name: 'query-domain', endPoint: '/api/v([1-2])/query-domain\*' },

        { name: 'reasoner', endPoint: '/api/v([1-2])/reasoner/\*' },
        { name: 'resource-manager', endPoint: '/api/v([1-2])/resource-manager/\*' },
        { name: 'restore', endPoint: '/api/system/v([1-2])/maglev/restore\*' },

        { name: 'schedule', endPoint: '/api/system/v([1-2])/maglev/schedule/\*' },
        { name: 'scheduled-job', endPoint: '/api/v([1-2])/scheduled-job\*' },
        { name: 'sd-mdns-preferences', endPoint: '/api/v([1-2])/sd-mdns-preferences' },
        { name: 'sda-helper-service', endPoint: '/api/v([1-2])/sda-helper-service\*'},
        { name: 'sdg-node', endPoint: '/api/v([1-2])/sdg-node\*' },
        { name: 'security-advisory', endPoint: '/api/v([1-2])/security-advisory/\*' },
        { name: 'server-management', endPoint: '/api/v([1-2])/server-management\*'},
        { name: 'service-deployment-status', endPoint: '/api/v([1-2])/service-deployment-status\*' },
        { name: 'service-entry', endPoint: '/api/v([1-2])/service-entry\*' },
        { name: 'service-policy', endPoint: '/api/v([1-2])/service-policy\*' },
        { name: 'service-type', endPoint: '/api/v([1-2])/service-type\*' },
        { name: 'siteprofile', endPoint: '/api/v([1-2])/siteprofile\*' },
        { name: 'sftp', endPoint: '/api/v([1-2])/sftp' },
        { name: 'snmp-property', endPoint: '/api/v([1-2])/snmp-property' },
        { name: 'systemsettings', endPoint: '/api/v([1-2])/systemsettings/getProperty?propertyName=toAnonymize' },

        { name: 'tag', endPoint: '/api/v([1-2])/tag\*' },
        { name: 'task', endPoint: '/api/v([1-2])/task\*' },
        { name: 'template-programmer', endPoint: '/api/v([1-2])/template-programmer\*' },
        { name: 'token-registration', endPoint: '/api/v([1-2])/token-registration/\*' },
        { name: 'topology', endPoint: '/api/v([1-2])/topology\*' },
        { name: 'topology', endPoint: '/dna/intent/api/v([1-2])/topology*' },
        { name: 'troubleshooting', endPoint: '/api/v([1-2])/troubleshooting/\*' },

        { name: 'validation', endPoint: '/api/v([1-2])/validation\*' },
        { name: 'vmanage-service', endPoint: '/api/v([1-2])/vmanage-service/vmanage/deviceuuid' },

        { name: 'workflow', endPoint: '/api/v([1-2])/workflow/\*' },
        { name: 'workflow-status', endPoint: '/api/v([1-2])/workflow-status\*' },
        { name: 'cisco.dna.appHosting', endPoint: '/persistence/cisco.dna.appHosting' },
        { name: 'registration', endPoint: '/api/registration/v([1-2])/status'}

        
    ];

    return {
        init: function (sinonHandler) {
            SimSinonMockRestHelper.init(restEndPoints);
            SimSinonMockRestEndPoints.init(sinonHandler, restEndPoints);
        },

        isRestRequestHandled: function (url) {
            // This below code is to hide the software updates and user management from Settings page
            if ((window.location.href.indexOf('dna/systemSettings/system360') > -1)) {
                setTimeout(function () {
                    $(".dashlet-name:contains('Software Management')").parents('.ciscoDnaCoreDashboard--components-dashlet-dashlet_scss--content').hide();
                }, 1000)
            }
            if ((window.location.href.indexOf('dna/systemSettings/softwareUpdates') > -1) || (window.location.href.indexOf('dna/systemSettings/softwareManagement') > -1)) {
                $('.ciscoDnaCoreAppManagement--components-newSoftwareManagement-components-mainPage_scss--mainPageContainer').hide();
                $('.ciscoDnaCoreAppManagement--components-appManagement-appManagement_less--appManagement').hide();
            }
            if ((window.location.href.indexOf('dna/systemSettings/userManagement') > -1)) {
                $('.ciscoDnaCoreUserManagement--home-home_less--userManagement').hide();
            }

            if ((window.location.href.indexOf('dna/myProfileAndSettings/myAccount') > -1)) {
                $('.ciscoDnaCoreUserPreference--Container_scss--container').hide();
            }

            // code to change UPN portal link using simulation code
            if(window.location.href.indexOf('dna/workflows/upn-workflow/firstView') >-1) {
                var upnPortalUrl = window.location.origin +"/upnWebPortal/#/"
                $('#configureCloudService').parent().hide()
                if($('#upnPortalLink').length === 0){
                    $('#configureCloudService').parent().parent().append('<a id="upnPortalLink" href='+upnPortalUrl+' target="_blank" style="color: white;background-color: #0096d6;padding: 13px 57px;border-radius: 6px;font-size: 15px;">Configure Cloud Service</a>');
                }                
            }

            // code to change thousand eyes simulation link in assurance/health/application/ms office
            if(window.location.href.indexOf('dna/assurance/dashboards/health/application') >-1) {
                $('a:contains("Office 365 Login")').attr("href", "https://adxdhpftau.share.thousandeyes.com/view/tests/?roundId=1647534060&metric=loss&scenarioId=pathVisualization&testId=2638585");

                //$('a:contains("San Jose Branch to Data Center")').attr("href", "https://10.18.180.244:54443/view/tests/?roundId=1674043800&metric=loss&scenarioId=netMetrics&testId=1172189");

                //$('a:contains("San Jose Branch to Data Center (Qwest)")').attr("href", "https://10.18.180.244:54443/view/tests/?roundId=1674043800&metric=loss&scenarioId=netMetrics&testId=1056648");                

                //$('a:contains("San Jose Branch to Data Center (AT&T)")').attr("href", "https://10.18.180.244:54443/view/tests/?roundId=1674043560&metric=loss&scenarioId=netMetrics&testId=1056904");
             }
            
            /* Sinon addFilter -- false == mocked, true == not mocked
               Hence toggling the result.. */
            return !SimSinonMockRestHelper.isRestUrlMocked(url);
        }
    };
    
});
