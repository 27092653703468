define({
"Template":
{
  "version": "1.0",
  "response": {
   "id": "5460420d-b8fe-402a-9226-9f8fefad8aeb",
    "name": "wlc_monitor",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "10.79.46.23",
    "groupBy": "Unknown",
    "category": "Availability",
    "severity": "HIGH",
    "summary": "Cisco DNA Center is not receiving WSA data from DeviceNamePlaceHolder Wireless Controller.",
    "groupId": "wlc_issues_grouping",
    "groupName": "Wireless Controller Issues",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "WLC",
    "timestamp": 1563896517455,
    "description": "This WLC DeviceNamePlaceHolder is not exporting WSA data. It was previously connected to the switch BLD1-FLR2-DST1 and port GigabitEthernet1/13. The switch port is currently up.",
    "suggestions": [
      {
        "message": "Verify the WLC is receiving power.",
        "steps": [

        ]
      },
      {
        "message": "Verify the WLC is connected to the network.",
        "steps": [

        ]
      },
      {
        "message": "Verify the nsa exporter configuration.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "nsa exporter configuration",
            "command": "show network assurance summary",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify the connecting switch configuration.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Connecting switch configuration",
            "command": "show run interface GigabitEthernet1/13",
            "stepType": "command-Runner"
          }
        ]
      }
    ],
    "additionalParams": [
      {
        "key": "managementIpAddr",
        "value": "10.13.2.7"
      },
      {
        "key": "updownTimeStamp",
        "value": "1537864981600"
      },
      {
        "key": "o_entity",
        "value": "WLC"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "__entity_type__",
        "value": "NetworkDevice"
      },
      {
        "key": "updownStatus",
        "value": "DOWN"
      },
      {
        "key": "entityId",
        "value": "10.13.2.7"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "wlcName",
        "value": "MSK5508NO1"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "WLC"
      },
      {
        "key": "category",
        "value": "Availability"
      },
      {
        "key": "timestring",
        "value": "2018-09-25T13:11:12.523+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})