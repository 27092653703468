//CAT9K family Cisco Catalyst 9500 Switch 
define({
  "runningImageList": [
    {
      "name": "cat9k_iosxe.17.06.01.SPA.bin",
      "imageUuid": "c7c3adac-2234-416f-9e8b-21704458702b",
      "version": "17.6.1",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "true",
      "md5Checksum": "fdb9c92bae37f9130d0ee6761afe2919",
      "shaCheckSum": "a504f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
      "createdTime": "2021-09-14 19:31:12.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "59fbd410-2222-4a6b-a3a0-fa8071f39733",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.03.03.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 901741775,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 1,
      "imageAttributes": [
        {
            "propertyName": "MEDIA",
            "propertyValue": "5"
        },{
            "propertyName": "COMPRESSION_CODE",
            "propertyValue": "-1"
        },{
            "propertyName": "deviceMNEId",
            "propertyValue": "23030007"
        },{
            "propertyName": "udi",
            "propertyValue": "PID: C9500-24Y4C VID: V01, SN: CAT2345L1LR"
        },{
            "propertyName": "metadata_trans_id",
            "propertyValue": "330079917"
        },{
            "propertyName": "MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },{
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "release_fcs_date",
            "propertyValue": "05/Mar/2021"
        },{
            "propertyName": "BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
        },{
            "propertyName": "image_size",
            "propertyValue": "901741775"
        },{
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
        },{
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FEATURE",
            "propertyValue": ""
        },{
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "min_dram",
            "propertyValue": "8192"
        },{
            "propertyName": "Description",
            "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },{
            "propertyName": "min_flash",
            "propertyValue": "16384"
        },{
            "propertyName": "image_guid",
            "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
        },{
            "propertyName": "GAIA_FEATURE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FULL_VERSION",
            "propertyValue": "17.03.03.0.4762"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
            "taggedGolden": true,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286315863",
          "productName": "Cisco Catalyst 9500 Switch",
          "productId": [
            "C9500-40X",
            "C9500-24Q",
            "C9500-12Q",
            "C9500-16X"
          ],
          "sites": [],
          "show": false,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true,
      "ccoreverseSync": true
    },
    {
      "name": "cat9k_iosxe.17.03.03.SPA.bin",
      "imageUuid": "c7c3adac-2222-416f-9e8b-21704458702b",
      "version": "17.3.3",
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "89c98b1ed44cf6cb1190eca977edb9a5",
      "shaCheckSum": "a91e2c868b8fd64b12e44c18b54e7e00947ecaf73fc5a809739908d57bafde08c62da4b175b26e9f4c60e4e59b36aad9b4cc30a9ad3714aad708f37c03fe62c0",
      "createdTime": "2021-03-09 19:31:12.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "59fbd410-2222-4a6b-a3a0-fa8071f39733",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.03.03.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 901741775,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 1,
      "imageAttributes": [
        {
            "propertyName": "MEDIA",
            "propertyValue": "5"
        },{
            "propertyName": "COMPRESSION_CODE",
            "propertyValue": "-1"
        },{
            "propertyName": "deviceMNEId",
            "propertyValue": "23030007"
        },{
            "propertyName": "udi",
            "propertyValue": "PID: C9500-24Y4C VID: V01, SN: CAT2345L1LR"
        },{
            "propertyName": "metadata_trans_id",
            "propertyValue": "330079917"
        },{
            "propertyName": "MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "image_description",
            "propertyValue": "CAT9300/9300L/9400/9500/9500H/9600 Universal"
        },{
            "propertyName": "DEFAULT_BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "release_fcs_date",
            "propertyValue": "05/Mar/2021"
        },{
            "propertyName": "BOOTROM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "encryption_software_indicator",
            "propertyValue": "Y"
        },{
            "propertyName": "image_size",
            "propertyValue": "901741775"
        },{
            "propertyName": "field_notices",
            "propertyValue": "Field Notices"
        },{
            "propertyName": "DEFAULT_MINFLASHSIZE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FEATURE",
            "propertyValue": ""
        },{
            "propertyName": "DEFAULT_RAM",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "min_dram",
            "propertyValue": "8192"
        },{
            "propertyName": "Description",
            "propertyValue": "Cisco IOS Software, IOS-XE Software"
        },{
            "propertyName": "min_flash",
            "propertyValue": "16384"
        },{
            "propertyName": "image_guid",
            "propertyValue": "AD6DBB9D8FB18E4FFA5667EEA27D99584DFB9BF8"
        },{
            "propertyName": "GAIA_FEATURE",
            "propertyValue": "UNKNOWN"
        },{
            "propertyName": "FULL_VERSION",
            "propertyValue": "17.03.03.0.4762"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
            "taggedGolden": false,
            "role": "ALL",
            "inheritedSiteName": "",
            "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286315863",
          "productName": "Cisco Catalyst 9500 Switch",
          "productId": [
            "C9500-40X",
            "C9500-24Q",
            "C9500-12Q",
            "C9500-16X"
          ],
          "sites": [],
          "show": false,
          "userDefined": false
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true,
      "ccoreverseSync": true
    },
    {
      "name": "cat9k_iosxe.17.03.01.SPA.bin",
      "imageUuid": "b8464d61-aaaa-1112-aaaa-3d9402377aea",
      "version": "17.3.1",//Amsterdam-17.3.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "b90c6ef495af2ed6f9dcfe98455622eb",
      "shaCheckSum": "25c2fd958e35fb0b5e62d2617610548bd361df234bda52803ce6027bce26b7d9404df558e85195767b893fbf4d90315914a2d8c5e68eebec30371eef01168533",
      "createdTime": "2020-10-16 14:31:00.0",//09 Aug 2020 Release
      "applicationType": null,
      "feature": "",
      "fileServiceId": "99fa6d6b-fbfb-1111-92a5-daa2fafdecda",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.23.60287//localdisk/Staging/cat9k_iosxe.17.03.01.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 892195673,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "-1"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286315863",
          "productName": "Cisco Catalyst 9500 Switch",
          "productId": [
            "C9500-40X",
            "C9500-24Q",
            "C9500-12Q",
            "C9500-16X"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_iosxe.17.02.01.SPA.bin",
      "imageUuid": "b8464d61-e226-1112-aaaa-3d9402377aea",
      "version": "17.2.1",//Amsterdam-17.2.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "4ed2c1ed0eba5d0347770e64e25f6548",
      "shaCheckSum": "b22eb5905d0b364e355750248042dd79a551070728165ca72203020dd831df1ed578c9e6a18b81ba9e7d4ce52502311970e3f0afb34af9fad72e03f70ba301e9",
      "createdTime": "2020-04-28 21:16:00.0",//28 Mar 2020 Release
      "applicationType": null,
      "feature": "",
      "fileServiceId": "99fa6d6b-fb37-1111-92a5-daa2fafdecda",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.23.60287//localdisk/Staging/cat9k_iosxe.17.02.01.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 871066119,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "-1"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286315863",
          "productName": "Cisco Catalyst 9500 Switch",
          "productId": [
            "C9500-40X",
            "C9500-24Q",
            "C9500-12Q",
            "C9500-16X"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_iosxe.17.01.01.SPA.bin",
      "imageUuid": "b8464d61-e226-3335-2222-3d9402377aea",
      "version": "17.1.1",//Amsterdam-17.1.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "7fac63beb33ebfb34c3a688a194780f3",
      "shaCheckSum": "7dc3d644aca4872cd3e2ffe8cb28064fafc04c76ce087639fe1d11f28e5a1209a5c2f7b113c1425122738e65cef9eb62cc37ae9a72bb7d20b65809ad39024a99",
      "createdTime": "2019-11-23 11:05:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "99fa6d6b-fb37-1111-92a5-daa2fafdecda",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.23.60287//localdisk/Staging/cat9k_iosxe.17.01.01.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 699939396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "-1"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286315863",
          "productName": "Cisco Catalyst 9500 Switch",
          "productId": [
            "C9500-40X",
            "C9500-24Q",
            "C9500-12Q",
            "C9500-16X"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": true
    },
    {
      "name": "cat9k_iosxe.16.12.01s.SPA.bin",
      "imageUuid": "b8464d61-e226-2345-2222-3d9402377aea",
      "version": "16.12.1s",//Gibraltar-16.12.1
      "softwareType": null,
      "imageType": "SYSTEM_SW",
      "isTaggedGolden": "false",
      "md5Checksum": "51ca433438ef3cd2cf330595a90bed38",
      "shaCheckSum": "1bc61eafcbe78b486f3e271a88a4f89107b594fb54191636fd606210bfe08570a64c50699a2dbdcdbb2661bfe1a4e3e098bef88e14850f130866f2c0f010bde2",
      "createdTime": "2019-09-04 11:05:00.0",
      "applicationType": null,
      "feature": "",
      "fileServiceId": "99fa6d6b-fb37-1111-92a5-daa2fafdecda",
      "imageSeries": null,
      "imageSource": "file:/opt/maglev/services/swim-service/7.1.23.60287//localdisk/Staging/cat9k_iosxe.16.12.01s.SPA.bin",
      "imageSourceType": "Imported from File System",
      "imageFamily": "CAT9K",
      "vendor": "CISCO",
      "filesize": 699939396,
      "deviceCount": 0,
      "deviceUuidList": [],
      "smuCount": 0,
      "imageAttributes": [
        {
          "propertyName": "MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "MEDIA",
          "propertyValue": "5"
        },
        {
          "propertyName": "COMPRESSION_CODE",
          "propertyValue": "-1"
        },
        {
          "propertyName": "DEFAULT_MINFLASHSIZE",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "FEATURE",
          "propertyValue": ""
        },
        {
          "propertyName": "DEFAULT_RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "DEFAULT_BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "BOOTROM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "RAM",
          "propertyValue": "UNKNOWN"
        },
        {
          "propertyName": "GAIA_FEATURE",
          "propertyValue": "UNKNOWN"
        }
      ],
      "isSMUApplicable": true,
      "goldenTagInheritedFromSiteUuid": null,
      "goldenTagInheritedFromSiteName": null,
      "importSupported": true,
      "reasonForNotSupportingImport": null,
      "tagList": [
        {
          "taggedGolden": false,
          "role": "ALL",
          "inheritedSiteName": "",
          "inheritedSiteUuid": ""
        }
      ],
      "imageCategory": "PHYSICAL",
      "imageIntegrityStatus": "VERIFIED",
      "imageImported": true,
      "applicableDevicesForImage": [
        {
          "mdfId": "286315874",
          "productName": "Cisco Catalyst 9300 Switch",
          "productId": [
            "c9300-24P"
          ]
        },
        {
          "mdfId": "286315863",
          "productName": "Cisco Catalyst 9500 Switch",
          "productId": [
            "C9500-24Q"
          ]
        },
        {
          "mdfId": "286315863",
          "productName": "Cisco Catalyst 9500 Switch",
          "productId": [
            "C9500-16X"
          ]
        }
      ],
      "profileInfo": null,
      "physicallyAvailable": true,
      "assignCount": 0,
      "showUnassign": false,
      "disableUnassign": false,
      "ccorecommended": false,
      "ccolatest": false
    }
  ]
});