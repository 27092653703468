define({
    "KEY_Template":  [{
    "aaa.server.secondary":"aaa.setting",
    "device.banner":"banner.setting",
    "timezone.site":"timezone.setting",
    "aaa.endpoint.server":"aaa.setting",
    "syslog.server":"ip.address",
    "netflow.collector":"ip.address",
    "aaa.server.pan":"pan.setting",
    "credential.snmp_v2_write":"reference.setting",
    "credential.http.read":"reference.setting",
    "credential.snmp_v2_read":"reference.setting",
    "sp.info":"sp.setting",
    "ip.pool.management":"reference.setting",
    "aaa.network.server":"aaa.setting",
    "dhcp.server":"ip.address",
    "interface.info":"interface.setting",
    "ip.pool.lan":"reference.setting",
    "ip.pool.service":"reference.setting",
    "credential.snmp_v3":"reference.setting",
    "aaa.server.primary":"aaa.setting",
    "ntp.server":"ip.address",
    "ip.pool.generic":"reference.setting",
    "snmp.trap.receiver":"ip.address",
    "dns.server":"dns.setting",
    "credential.cli":"reference.setting",
    "ip.pool.wan":"reference.setting",
    "credential.http.write":"reference.setting"
  }]
});
