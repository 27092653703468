define({
    "Template":
   {
  "version": "1.0",
  "response": {
    "categories": [
      {
        "categoryName": "Onboarding",
        "issues": [
          {
            "issueName": "Wireless client onboarding issues",
            "triggerName": "wireless_client_onboarding",
            "instances": 1533,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless client failed to connect or took longer than 10 seconds to connect",
            "rootCauses": [
              {
                "name": "AUTH_SEC_PARAM_MISMATCH",
                "description": "Wireless client failed to connect - Security Parameter Mismatch"
              },
              {
                "name": "AUTH_SEC_PARAM_MISMATCH",
                "description": "Wireless client failed to roam - Security Parameter Mismatch"
              },
              {
                "name": "AUTH_AAA_REJECTED",
                "description": "Wireless client failed to connect - AAA Server Rejected Client"
              },
              {
                "name": "AUTH_AAA_REJECTED",
                "description": "Wireless client failed to connect - AAA Server Rejected Client"
              },
              {
                "name": "AUTH_AAA_REJECTED",
                "description": "Wireless client failed to connect - AAA Server Rejected Client"
              },
              {
                "name": "AUTH_AAA_REJECTED",
                "description": "Wireless client failed to connect - AAA Server Rejected Client"
              },
              {
                "name": "AUTH_WLC_MSG_ERROR",
                "description": "Wireless client failed to connect - Client PMK Not Found"
              },
              {
                "name": "AUTH_WLC_MSG_ERROR",
                "description": "Wireless client failed to roam - Client PMK Not Found"
              },
              {
                "name": "AUTH_WLC_CONFIG_ERROR",
                "description": "Wireless client failed to connect - WLC configuration error"
              },
              {
                "name": "AUTH_WLC_CONFIG_ERROR",
                "description": "Wireless client failed to roam - WLC configuration error"
              },
              {
                "name": "AUTH_WLC_INTERNAL_ERROR",
                "description": "Wireless client failed to connect - WLC Internal Error"
              },
              {
                "name": "AUTH_WLC_INTERNAL_ERROR",
                "description": "Wireless client failed to roam - WLC Internal Error"
              },
              {
                "name": "AUTH_CL_TIMEOUT_OR_RF",
                "description": "Wireless client failed to connect - Failed to authenticate due to Client Timeout"
              },
              {
                "name": "AUTH_CL_TIMEOUT_OR_RF",
                "description": "Wireless client failed to roam - Failed to authenticate due to Client Timeout"
              },
              {
                "name": "AUTH_AAA_NO_RESPONSE",
                "description": "Wireless client failed to connect - AAA Server Timeout"
              },
              {
                "name": "AUTH_AAA_NO_RESPONSE",
                "description": "Wireless client failed to roam - AAA Server Timeout"
              },
              {
                "name": "AUTH_AAA_NO_RESPONSE",
                "description": "Wireless client failed to connect - AAA Server Timeout"
              },
              {
                "name": "AUTH_AAA_NO_RESPONSE",
                "description": "Wireless client failed to roam - AAA Server Timeout"
              },
              {
                "name": "DHCP_SERVER_OR_CLIENT_TIMEOUT",
                "description": "Wireless client failed to connect - DHCP Timeout"
              },
              {
                "name": "DHCP_SERVER_TIMEOUT",
                "description": "Wireless client failed to connect - DHCP Server Timeout"
              },
              {
                "name": "DHCP_SERVER_TIMEOUT",
                "description": "Wireless client failed to connect - DHCP Server Timeout"
              },
              {
                "name": "DHCP_CLIENT_TIMEOUT",
                "description": "Wireless client failed to connect - Failed to get an IP Address due to Client Timeout"
              },
              {
                "name": "EXCESSIVE_ASSOC_FAIL",
                "description": "Wireless client took a long time to connect - Excessive time due to Association failures"
              },
              {
                "name": "EXCESSIVE_ASSOC",
                "description": "Wireless client took a long time to connect - Excessive time for Association"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_SERVER",
                "description": "Wireless client took a long time to connect - Excessive time due to failed credentials"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_WLC",
                "description": "Wireless client took a long time to connect - Excessive time due to WLC failures"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_CLIENT",
                "description": "Wireless client took a long time to connect - Excessive time due to RF issues"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_CLIENT",
                "description": "Wireless client took a long time to connect - Excessive time due to RF issues"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_NETWORK",
                "description": "Wireless client took a long time to connect - Excessive time due to slow network"
              },
              {
                "name": "EXCESSIVE_AUTH_POOR_RF",
                "description": "Wireless client took a long time to connect - Excessive time for Authentication due to RF issues"
              },
              {
                "name": "EXCESSIVE_AUTH_SERVER",
                "description": "Wireless client took a long time to connect - Excessive time for Authentication due to AAA server or Network delays"
              },
              {
                "name": "EXCESSIVE_DHCP_FAIL",
                "description": "Wireless client took a long time to connect - Excessive time due to DHCP failures"
              },
              {
                "name": "EXCESSIVE_DHCP_FAIL",
                "description": "Wireless client took a long time to connect - Excessive time due to DHCP failures"
              },
              {
                "name": "EXCESSIVE_DHCP",
                "description": "Wireless client took a long time to connect - Excessive time to get an IP Address"
              },
              {
                "name": "EXCESSIVE_TOTAL",
                "description": "Wireless client took a long time to connect - Excessive time for Onboarding"
              },
              {
                "name": "BLACKLIST_WEB_AUTH_FAILURE",
                "description": "Wireless client excluded - Too Many Web Authentication Failures"
              },
              {
                "name": "BLACKLIST_AUTH_FAILURE",
                "description": "Wireless client excluded - Too Many Authentication Failures"
              },
              {
                "name": "BLACKLIST_IP_THEFT",
                "description": "Wireless client excluded - IP Theft Issue"
              },
              {
                "name": "BLACKLIST_IDS_SHUN_LIST",
                "description": "Wireless client excluded - IDS Shunned List"
              },
              {
                "name": "BLACKLIST_ASSOC_FAIL",
                "description": "Wireless client excluded - Too Many Association Failures"
              },
              {
                "name": "BLACKLIST_ROAMING_FAILURE",
                "description": "Wireless client failed to roam - Client was Excluded before roaming"
              },
              {
                "name": "MOBILITY_FAIL_WLC_CONFIG_ISSUES",
                "description": "Wireless client failed to roam between APs - WLC configuration mismatch"
              },
              {
                "name": "MOBILITY_FAIL_GENERAL_ERRORS",
                "description": "Wireless client failed to roam between APs - External Error"
              }
            ]
          },
          {
            "issueName": "Excessive time for onboarding due to AAA server",
            "triggerName": "client_global_issue_by_aaa_30min_5percent_5",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients took longer than 10 seconds to connect or failed to connect during  authentication due to AAA server issues",
            "rootCauses": [
              {
                "name": "EXCESSIVE_AUTH_SERVER",
                "description": "Wireless clients took a long time to connect - Excessive time for Authentication due to AAA server or Network delays"
              },
              {
                "name": "AUTH_AAA_REJECTED",
                "description": "Wireless clients failed to connect - AAA Server Rejected Clients"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_SERVER",
                "description": "Wireless clients took a long time to connect - Excessive time due to failed credentials"
              },
              {
                "name": "AUTH_AAA_NO_RESPONSE",
                "description": "Wireless clients failed to connect - AAA Server Timeout"
              }
            ]
          },
          {
            "issueName": "802.11r client roaming slowly",
            "triggerName": "wireless_client_roaming_slow",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "While roaming, a wireless client capable of Fast Roaming is doing full authentication instead of fast authentication",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Onboarding issues due to Security Parameter Mismatch",
            "triggerName": "client_global_issue_by_ssid_30min",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients failed to connect due to Security Parameter mismatch during authentication",
            "rootCauses": [
              {
                "name": "AUTH_SEC_PARAM_MISMATCH",
                "description": "Wireless clients failed to connect - Security Parameter Mismatch"
              }
            ]
          },
          {
            "issueName": "Excessive time for onboarding - Excessive time to get an IP Address",
            "triggerName": "client_global_issue_by_dhcp_30min_5percent_5",
            "instances": 233,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients took longer than 10 seconds to connect. The delay was in the IP Addressing phase",
            "rootCauses": [
              {
                "name": "EXCESSIVE_DHCP",
                "description": "Wireless clients took a long time to connect - Excessive time to get an IP Address"
              },
              {
                "name": "EXCESSIVE_DHCP_FAIL",
                "description": "Wireless clients took a long time to connect - Excessive time due to DHCP failures"
              },
              {
                "name": "DHCP_SERVER_TIMEOUT",
                "description": "Wireless clients failed to connect - DHCP Server Timeout"
              },
              {
                "name": "DHCP_SERVER_OR_CLIENT_TIMEOUT",
                "description": "Wireless clients failed to connect - DHCP Timeout"
              }
            ]
          },
          {
            "issueName": "Onboarding issues due to WLC or AAA server",
            "triggerName": "client_global_issue_by_wlc_30min",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients failed to connect or took more than 10 seconds to connect due to WLC issues",
            "rootCauses": [
              {
                "name": "AUTH_WLC_MSG_ERROR",
                "description": "Wireless clients failed to connect - Client PMK Not Found"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_WLC",
                "description": "Wireless clients took a long time to connect - Excessive time due to WLC failures"
              }
            ]
          },
          {
            "issueName": "Excessive time for onboarding",
            "triggerName": "client_global_issue_by_ssid_30min_10percent_20",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients took more than 10 seconds to connect",
            "rootCauses": [
              {
                "name": "EXCESSIVE_TOTAL",
                "description": "Wireless clients took a long time to connect"
              }
            ]
          },
          {
            "issueName": "Onboarding issues due to DHCP Timeout",
            "triggerName": "client_global_issue_by_site_30min_5percent_5",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients took longer than 10 seconds to connect. The delay was in the IP Addressing phase due to DHCP Server or Client timeout",
            "rootCauses": [
              {
                "name": "DHCP_SERVER_OR_CLIENT_TIMEOUT",
                "description": "Wireless client failed to connect - DHCP Timeout in an AP Group"
              },
              {
                "name": "DHCP_SERVER_OR_CLIENT_TIMEOUT",
                "description": "Wireless clients failed to connect - DHCP Timeout in a Site"
              },
              {
                "name": "DHCP_CLIENT_TIMEOUT",
                "description": "Wireless clients failed to connect - Failed to get an IP Address due to Client Timeout in an AP Group"
              },
              {
                "name": "DHCP_CLIENT_TIMEOUT",
                "description": "Wireless clients failed to connect - Failed to get an IP Address due to Client Timeout in a Site"
              }
            ]
          },
          {
            "issueName": "Client authentication failures",
            "triggerName": "wired_client_onboarding_failures_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wired Client authentication failed due to dot1X or MAC Authentication Bypass issues",
            "rootCauses": [
              {
                "name": "DOT1X-5-FAIL",
                "description": "Client authentication failed on DOT1X"
              },
              {
                "name": "MAB-5-FAIL",
                "description": "Client authentication failed on MAB"
              }
            ]
          },
          {
            "issueName": "Client DNS reachability issue",
            "triggerName": "dns_reachability_issue",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "The client failed to obtain a response from the DNS server.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Onboarding issues due to DHCP failures",
            "triggerName": "client_global_issue_by_apGroup_30min_5percent_5",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients took longer than 10 seconds to connect or failed to connect. The delay or failure was in the IP Addressing phase",
            "rootCauses": [
              {
                "name": "EXCESSIVE_DHCP_FAIL",
                "description": "Wireless clients took a long time to connect - Excessive time due to DHCP failures"
              },
              {
                "name": "DHCP_SERVER_TIMEOUT",
                "description": "Wireless clients failed to connect - DHCP Server Timeout"
              }
            ]
          },
          {
            "issueName": "Client DHCP reachability issue",
            "triggerName": "dhcp_reachability_issue",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "The client has failed to obtain an IPv4 address from DHCP server.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Wireless clients failed to roam",
            "triggerName": "client_mobility_wlc_config_global",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients failed to connect during roaming between APs due to a WLC configuration mismatch",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Onboarding issues due to WLC errors",
            "triggerName": "client_global_issue_by_wlc_15min",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients failed to connect due to WLC errors",
            "rootCauses": [
              {
                "name": "AUTH_WLC_CONFIG_ERROR",
                "description": "Wireless clients failed to connect - WLC configuration error"
              },
              {
                "name": "AUTH_WLC_INTERNAL_ERROR",
                "description": "Wireless clients failed to connect - WLC Internal Error"
              },
              {
                "name": "AUTH_AAA_NO_RESPONSE",
                "description": "Wireless clients failed to connect - AAA Server Timeout"
              }
            ]
          },
          {
            "issueName": "Onboarding issues due to client or RF issues",
            "triggerName": "client_global_issue_by_site_30min",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients took longer than 10 seconds to connect or failed to connect due to client or RF issues",
            "rootCauses": [
              {
                "name": "EXCESSIVE_ASSOC",
                "description": "Wireless clients took a long time to connect - Excessive time for Association"
              },
              {
                "name": "EXCESSIVE_ASSOC",
                "description": "Wireless clients took a long time to connect - Excessive time for Association"
              },
              {
                "name": "AUTH_CL_TIMEOUT_OR_RF",
                "description": "Wireless clients failed to connect - Failed to authenticate due to Client Timeout in a Site"
              },
              {
                "name": "AUTH_CL_TIMEOUT_OR_RF",
                "description": "Wireless clients failed to connect - Failed to authenticate due to Client Timeout in an AP Group"
              },
              {
                "name": "EXCESSIVE_AUTH_POOR_RF)",
                "description": "Wireless clients took a long time to connect - Excessive time for Authentication due to RF issues in an AP Group"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_CLIENT)",
                "description": "Wireless clients took a long time to connect - Excessive time due to RF issues in an AP Group"
              },
              {
                "name": "EXCESSIVE_AUTH_POOR_RF)",
                "description": "Wireless clients took a long time to connect - Excessive time for Authentication due to RF issues"
              },
              {
                "name": "EXCESSIVE_AUTH_FAIL_CLIENT)",
                "description": "Wireless clients took a long time to connect - Excessive time due to RF issues"
              }
            ]
          },
          {
            "issueName": "Onboarding issues due to client exclusion or roaming",
            "triggerName": "client_global_issue_by_wlc_30min_5percent_5",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless clients failed to connect due to the clients being excluded or failed to roam. The clients were excluded due to Client Exclusion Policies configured on the WLC",
            "rootCauses": [
              {
                "name": "MOBILITY_FAIL_GENERAL_ERRORS",
                "description": "Wireless clients failed to roam - External Errors"
              },
              {
                "name": "BLACKLIST_WEB_AUTH_FAILURE",
                "description": "Wireless clients excluded - Too Many Web Authentication Failures"
              },
              {
                "name": "BLACKLIST_AUTH_FAILURE",
                "description": "Wireless clients excluded - Authentication Failures"
              },
              {
                "name": "BLACKLIST_IP_THEFT",
                "description": "Wireless clients excluded - IP Theft Issue"
              },
              {
                "name": "BLACKLIST_IDS_SHUN_LIST",
                "description": "Wireless clients excluded - IDS Shunned List"
              },
              {
                "name": "BLACKLIST_ASSOC_FAIL",
                "description": "Wireless clients excluded - Too Many Association Failures"
              },
              {
                "name": "BLACKLIST_ROAMING_FAILURE",
                "description": "Wireless clients failed to roam - Clients were Excluded before roaming"
              }
            ]
          }
        ]
      },
      {
        "categoryName": "Connectivity",
        "issues": [
          {
            "issueName": "Apple iOS client disconnected from network",
            "triggerName": "ios_client_disassoc_single",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Apple iOS client with software version iOS11 or newer has disconnected from network",
            "rootCauses": [
              {
                "name": "IOS_DISASSOC_FAIL_INTERNAL",
                "description": "Apple iOS client has disconnected from SSID due to client internal event"
              },
              {
                "name": "IOS_DISASSOC_FAIL_DECRYPT",
                "description": "Apple iOS client has disconnected from SSID due to decryption failure"
              },
              {
                "name": "IOS_DISASSOC_FAIL_CAPTIVE_PORTAL",
                "description": "Apple iOS client has disconnected from SSID due to captive portal verification failure"
              }
            ]
          },
          {
            "issueName": "Multiple Apple iOS clients disconnected from network",
            "triggerName": "ios_client_disassoc_global",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Multiple Apple iOS clients with software versions iOS11 or newer have disconnected from network",
            "rootCauses": [
              {
                "name": "IOS_DISASSOC_FAIL_DECRYPT",
                "description": "Multiple Apple iOS clients have disconnected from their SSID due to decryption failure"
              },
              {
                "name": "IOS_DISASSOC_FAIL_CAPTIVE_PORTAL",
                "description": "Multiple Apple iOS clients have disconnected from their SSID due to captive portal verification failure"
              }
            ]
          },
          {
            "issueName": "Network Device IPSLA Connectivity",
            "triggerName": "network_ipsla_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "IPSLA probe Failures/Over-thresholds on Network Device",
            "rootCauses": [
              {
                "name": "IPSLA-Timeout",
                "description": "IPSLA probe has timed out"
              },
              {
                "name": "IPSLA-RTTThresh",
                "description": "IPSLA probe above threshold"
              },
              {
                "name": "Other",
                "description": "IPSLA Probe trap occured"
              }
            ]
          },
          {
            "issueName": "BGP Tunnel Connectivity",
            "triggerName": "network_connectivity_trigger_BGP",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "BGP Connectivity Failure with Peer due to Wrong AS",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Network Device Interface Connectivity",
            "triggerName": "network_connectivity_trigger",
            "instances": 3,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Interface Connectivity Failures on Network Device",
            "rootCauses": [
              {
                "name": "502",
                "description": "BGP Connectivity is Flapping with Neighbor."
              },
              {
                "name": "503",
                "description": "Interface on Device is Down."
              },
              {
                "name": "504",
                "description": "OSPF Adjacency Failed with Neighbor."
              },
              {
                "name": "505",
                "description": "ISIS Adjacency Failed on Device."
              },
              {
                "name": "EIGRP_PEERING",
                "description": "EIGRP Adjacency Failed with Neighbor."
              },
              {
                "name": "NFVIS_INTERFACE_STATUS",
                "description": "Interface on Device is Down."
              }
            ]
          }
        ]
      },
      {
        "categoryName": "Connected",
        "issues": [
          {
            "issueName": "Fabric Devices Connectivity",
            "triggerName": "fabric_reachability_session",
            "instances": 5,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Fabric Connectivity Failures on Fabric Devices",
            "rootCauses": [
              {
                "name": "NONE",
                "description": "Fabric Edge Lost Connectivity to the Fabric Border in the Virtual Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Edge Lost Connectivity to the Fabric Border in the Physical Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Border Lost Connectivity to the DHCP Server in the Virtual Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Border Lost Connectivity to the DHCP Server in the Physical Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Border Lost Connectivity to the DNS Server in the Virtual Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Border Lost Connectivity to the DNS Server in the Physical Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Edge Lost Connectivity to the ISE Server in the Physical Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Node Lost Connectivity to the Fabric Control Plane Device in the Physical Network"
              },
              {
                "name": "NONE",
                "description": "Fabric Edge Lost Connectivity to the Co-located Fabric Border and Control Plane in the Physical Network"
              },
              {
                "name": "NONE",
                "description": "The Fabric Border cannot reach user-provisioned external URL"
              }
            ]
          },
          {
            "issueName": "Unable to download SGT access policy from the Policy Server",
            "triggerName": "sgacl_download_source_list_error",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Failure to download the source list for access policy for SGT.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Interface high inout/output utilization",
            "triggerName": "switch_interface_excess_rx_tx_util",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "High input/output utilization on interfaces",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Uninstall of SGT access policy failed on the device",
            "triggerName": "sgacl_uninstall_error",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Failure to uninstall an SGACL access policy for SGT.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "SGT access policy installation failed on the device",
            "triggerName": "sgacl_install_error_ace",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Failure to install an access policy for SGT - Policy rule error found in RBACL.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Interface high input/output error",
            "triggerName": "switch_interface_input_output_errors",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "High input/output error on interfaces",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Fabric WLC to MapServer Connectivity",
            "triggerName": "fabric_wlc_reachability",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Fabric WLC Lost Connectivity to the Fabric Control Plane Node",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Dual Band capable client prefers 2.4 GHz over 5 GHz",
            "triggerName": "connected_misbehaving_dual_band_capable_client_preferring_2_4ghz",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Dual band capable client is consistently connecting to 2.4 GHz radio even though 5 GHz radio, providing a better experience, is available",
            "rootCauses": [
              {
                "name": "poor_5G_coverage",
                "description": "Insufficient 5 GHz Coverage - Dual Band capable client prefers 2.4 GHz over 5 GHz"
              },
              {
                "name": "client_poor_band_selection",
                "description": "Dual Band capable client prefers 2.4 GHz over 5 GHz"
              }
            ]
          },
          {
            "issueName": "Wireless client - sticky behavior",
            "triggerName": "connected_sticky_client",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless client is maintaining an association with an AP which has a weaker signal. It should roam to available AP that has the stronger signal",
            "rootCauses": [

            ]
          },
          {
            "issueName": "SGT access policy download failed on the device",
            "triggerName": "download_sgacl_aces",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Failed to download SGACL ACEs for SGT.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Failure to install an access policy for SGT",
            "triggerName": "sgacl_install_error",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Failure to install an SGACL access policy for SGT.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Wireless client - Poor RF",
            "triggerName": "connected_poor_rssi",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Wireless client is experiencing poor RF condition because the client has no better neighboring APs to roam to",
            "rootCauses": [

            ]
          }
        ]
      },
      {
        "categoryName": "Device",
        "issues": [
          {
            "issueName": "Switch fan failure",
            "triggerName": "switch_issue_fan",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Fan failure on switch",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Issues based on Syslog events",
            "triggerName": "default_syslog_event_trigger",
            "instances": 2,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Issues created by single occurrence of syslog event",
            "rootCauses": [
              {
                "name": "ILPOWER-3-CONTROLLER_ERR",
                "description": "POE power controller error"
              },
              {
                "name": "ILPOWER-3-SHUT_OVERDRAWN",
                "description": "Interface is drawing more power than allocated power limit"
              },
              {
                "name": "CMRP_ENVMON-3-TEMP_WARN_CRITICAL",
                "description": "Inlet sensor temperature high"
              },
              {
                "name": "ENVM-1-OVERTEMP_ALERT",
                "description": "Environmental monitoring reported over temperature alert"
              },
              {
                "name": "PLATFORM_THERMAL-3-OVERTEMP",
                "description": "Platform temperature monitoring reported over temperature"
              },
              {
                "name": "ENVIRONMENT-3-OVERTEMP",
                "description": "Device internal temperature crossed maximum threshold"
              },
              {
                "name": "SYS-1-OVERTEMP",
                "description": "Device temperature sensor detected an OVERTEMPERATURE condition"
              },
              {
                "name": "SPA-2-TEMP_CRITICAL",
                "description": "Device temperature critical on sensor"
              },
              {
                "name": "C4K_IOSMODPORTMAN-2-CRITICALTEMP",
                "description": "Chassis temperature is at or over critical threshold"
              },
              {
                "name": "C4K_IOSMODPORTMAN-2-MODULECRITICALTEMP",
                "description": "Sensor is at or over shutdown threshold"
              },
              {
                "name": "C6KENV-SW1-2-MAJORTEMPALARM || {rootCause}",
                "description": "Device has exceeded allowed operating temperature range"
              },
              {
                "name": "C6KENV-SW1-4-TERMINATOR_PS_TEMP_MAJORALARM",
                "description": "Device switch 1 experiencing high temperature"
              },
              {
                "name": "C6KENV-SW2-4-TERMINATOR_PS_TEMP_MAJORALARM",
                "description": "Device switch 2 experiencing high temperature"
              },
              {
                "name": "C4K_IOSMODPORTMAN-4-MODULETEMPHIGH",
                "description": "Sensor temperature is at or over threshold"
              },
              {
                "name": "C4K_IOSMODPORTMAN-4-TEMPHIGH",
                "description": "Chassis temperature is at or over threshold"
              },
              {
                "name": "C4K_IOSMODPORTMAN-4-CRITICALTEMP",
                "description": "Chassis temperature is at or over critical threshold"
              },
              {
                "name": "C4K_IOSMODPORTMAN-4-MODULECRITICALTEMP",
                "description": "Sensor temperature is at or over critical threshold"
              }
            ]
          },
          {
            "issueName": "TCAM Utilization High Issues",
            "triggerName": "tcam_util_high",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Issues for TCAM exhaustion in layer 2, layer 3, QoS and SGACL",
            "rootCauses": [
              {
                "name": "l2",
                "description": "Device is Experiencing TCAM exhaustion due to Layer2 utilization"
              },
              {
                "name": "l3",
                "description": "Device is Experiencing TCAM exhaustion due to Layer3 utilization"
              },
              {
                "name": "qos",
                "description": "Device is Experiencing TCAM exhaustion due to QoS utilization"
              },
              {
                "name": "sgacl",
                "description": "Device is Experiencing TCAM exhaustion due to SGACL utilization"
              }
            ]
          },
          {
            "issueName": "DNA Center and WLC time has drifted",
            "triggerName": "wlc_drift_time_high",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Excessive time lag between Cisco DNA Center and WLC",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Switch incompatible image",
            "triggerName": "stack_switch_version_mismatch_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Stackmember running an incompatible image",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Switch power failure",
            "triggerName": "switch_issue_power",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Power supply failure on switch",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Interface flaps",
            "triggerName": "switch_port_flaps",
            "instances": 2,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Interface flapping",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Trap events issue",
            "triggerName": "default_trap_event_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Issues created by single occurrence of trap event",
            "rootCauses": [
              {
                "name": "NONE",
                "description": "Stack member removed from stack"
              },
              {
                "name": "NONE",
                "description": "Stack active member fail over to standby"
              }
            ]
          },
          {
            "issueName": "Switch is rebooting",
            "triggerName": "switch_issue_reboot",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Switch is rebooting",
            "rootCauses": [

            ]
          },
          {
            "issueName": "CPU high alert",
            "triggerName": "snmp_cpu_high_80",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Device experiencing high CPU utilization",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Memory high alert",
            "triggerName": "snmp_memory_high_80",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Device experiencing high memory utilization",
            "rootCauses": [

            ]
          }
        ]
      },
      {
        "categoryName": "Availability",
        "issues": [
          {
            "issueName": "AP Flap",
            "triggerName": "ap_flap",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "AP has flapped.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "AP Down",
            "triggerName": "ap_down",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "AP went down.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "AP Coverage Hole",
            "triggerName": "ap_coverage_hole",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "AP has a coverage hole.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Device unreachable",
            "triggerName": "snmp_device_down",
            "instances": 2,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Network device unreachable from controller",
            "rootCauses": [

            ]
          },
          {
            "issueName": "WLC Power Supply Failure",
            "triggerName": "wlc_power_supply_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Power supply has failed on this WLC.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "WLC Reboot Crash",
            "triggerName": "wlc_reboot_crash_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "WLC has rebooted due to a hardware or software crash.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Fabric facing interface connectivity",
            "triggerName": "fabric_interface_down",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Fabric Facing interface down",
            "rootCauses": [

            ]
          },
          {
            "issueName": "AP Reboot Crash",
            "triggerName": "ap_reboot_crash_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "AP has rebooted due to a hardware or software crash.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "WLC Monitor",
            "triggerName": "wlc_monitor",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Network controller is not receiving data from WLC",
            "rootCauses": [
              {
                "name": "WLC",
                "description": "Network controller is not receiving WSA data from WLC"
              },
              {
                "name": "AP",
                "description": "Network controller is not receiving AP data from WLC"
              },
              {
                "name": "CLIENT",
                "description": "Network controller is not receiving Client data from WLC"
              }
            ]
          }
        ]
      },
      {
        "categoryName": "Utilization",
        "issues": [
          {
            "issueName": "Radio High Utilization",
            "triggerName": "radio_util_trigger",
            "instances": 2,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "2.4 GHz/5 GHz radio on AP is experiencing high utilization.",
            "rootCauses": [
              {
                "name": "NONE",
                "description": "2.4 GHz radio on AP is experiencing high utilization."
              },
              {
                "name": "NONE",
                "description": "5 GHz radio on AP is experiencing high utilization."
              }
            ]
          },
          {
            "issueName": "AP CPU High Utilization",
            "triggerName": "ap_cpu_threshold_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "AP is experiencing high CPU utilization.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "AP License Exhausted on WLC",
            "triggerName": "wlc_ap_license_exhausted",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "WLC currently has no free AP licenses.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "AP License Utilization on WLC",
            "triggerName": "wlc_ap_license_utilization",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "WLC has currently reached threshold percentage of its license capacity.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "AP Memory High Utilization",
            "triggerName": "ap_memory_threshold_trigger",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "AP is experiencing high memory utilization.",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Map cache limit reached",
            "triggerName": "snmp_map_cache_limit_reached",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "Map cache entries have exceeded limit on map server",
            "rootCauses": [

            ]
          },
          {
            "issueName": "WLC Memory High Utilization",
            "triggerName": "wlc_memory_high",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "WLC is experiencing high memory utilization",
            "rootCauses": [

            ]
          },
          {
            "issueName": "Network Device high disk usage",
            "triggerName": "nwDevice_disk_threshold",
            "instances": 0,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "High disk usage observed on device.",
            "rootCauses": [
              {
                "name": "NFVIS_DISK_THRESHOLD",
                "description": "Device has high disk usage."
              }
            ]
          }
        ]
      },
      {
        "categoryName": "Application",
        "issues": [
          {
            "issueName": "Application Experience Issues",
            "triggerName": "Application_Experience_Issues_trigger",
            "instances": 3,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "All Issues Pertaining to Application Experience.",
            "rootCauses": [
              {
                "name": "latency_issue",
                "description": "Network Latency for Application is Above Threshold Value. "
              },
              {
                "name": "latency_issue",
                "description": "Network Latency for Application '{serverAddress} : {serverPort}' is Above the Threshold Value of {threshold}ms."
              }
            ]
          }
        ]
      },
      {
        "categoryName": "Sensor Test",
        "issues": [
          {
            "issueName": "Sensor Global Issues",
            "triggerName": "sensor_test_result_global_issue",
            "instances": 9,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "List of all Sensor Global Issues",
            "rootCauses": [
              {
                "name": "sensor_connected_dns_global_server_reachability_failed",
                "description": "Unreachable DNS server. Pings are failing. This will impact user connectivity."
              },
              {
                "name": "sensor_connected_dns_global_server_availability_failed",
                "description": "Failed to resolve domain name with DNS Server"
              },
              {
                "name": "sensor_connected_dns_global_server_reachability_slow",
                "description": "Slow response from DNS Server host"
              },
              {
                "name": "sensor_connected_dns_global_server_availability_slow",
                "description": "Slow name resolution from DNS Server"
              },
              {
                "name": "sensor_connected_global_onboarding_wireless_status_failed",
                "description": "Sensors failed to associate during onboarding"
              },
              {
                "name": "sensor_global_onboarding_failed",
                "description": "Sensors failed to connect to the wireless network"
              },
              {
                "name": "sensor_global_onboarding_authentication_slow",
                "description": "Slow onboarding of sensors due to delays in the 802.1x authentication phase."
              },
              {
                "name": "sensor_global_onboarding_association_slow",
                "description": "Slow onboarding of sensors due to delays in the association phase."
              },
              {
                "name": "sensor_connected_global_onboarding_defaultgw_failed",
                "description": "No response from local gateway"
              },
              {
                "name": "sensor_global_ipv4_addressing_slow",
                "description": "Slow to get an IPv4 address"
              },
              {
                "name": "sensor_dhcp_global_multiple_failures",
                "description": "Failed to get an IPv4 address"
              },
              {
                "name": "sensor_connected_server_global_ping_failed",
                "description": "Unreachable Host"
              },
              {
                "name": "sensor_connected_server_global_ping_slow",
                "description": "Slow response from host"
              },
              {
                "name": "sensor_connected_global_server_first_hop_slow",
                "description": "Slow response from local gateway"
              },
              {
                "name": "sensor_connected_global_radius_test_failed",
                "description": "Failed to authenticate with Radius Server"
              },
              {
                "name": "sensor_connector_global_radius_not_reachable",
                "description": "Slow Authentication time from Radius server"
              },
              {
                "name": "sensor_connected_global_auth_server_slow",
                "description": "Unreachable Radius server"
              },
              {
                "name": "sensor_connected_server_global_radius_reachability_slow",
                "description": "Slow response from Radius server"
              },
              {
                "name": "sensor_connected_global_outlookserver_not_reachable",
                "description": "Unreachable Outlook Web Access host"
              },
              {
                "name": "sensor_connected_global_outlookserver_reachability_slow",
                "description": "Slow response from Outlook Web Access host"
              },
              {
                "name": "sensor_connected_global_outlookserver_first_hop_slow",
                "description": "Slow response from Outlook Web Access first hop gateway"
              },
              {
                "name": "sensor_connected_global_outlookserver_response_slow",
                "description": "Slow mail connection time to Outlook Web Access"
              },
              {
                "name": "sensor_connected_global_webserver_not_reachable",
                "description": "Unreachable Web server"
              },
              {
                "name": "sensor_connected_global_webserver_reachability_slow",
                "description": "Slow response from Web servers"
              },
              {
                "name": "sensor_connected_global_webserver_first_hop_slow",
                "description": "Slow response from Web server first hop gateway"
              },
              {
                "name": "sensor_connected_global_webserver_response_slow",
                "description": "Slow web response time from Web servers"
              },
              {
                "name": "sensor_connected_global_ssh_not_reachable",
                "description": "Unreachable ssh server"
              },
              {
                "name": "sensor_connected_global_ssh_reachability_slow",
                "description": "Slow response from ssh server"
              },
              {
                "name": "sensor_connected_global_ssh_response_slow",
                "description": "Slow ssh login time to ssh server"
              },
              {
                "name": "sensor_connected_global_ssh_test_failed",
                "description": "Connection failed to ssh servers"
              },
              {
                "name": "sensor_connected_global_mailserver_not_reachable",
                "description": "Unreachable Mail server"
              },
              {
                "name": "sensor_connected_global_mailserver_reachability_slow",
                "description": "Slow response from Mail server"
              },
              {
                "name": "sensor_connected_global_mailserver_response_slow",
                "description": "Slow connection time to Mail server"
              },
              {
                "name": "sensor_connected_global_mailserver_test_failed",
                "description": "Failed to connect to Mail Server"
              },
              {
                "name": "sensor_connected_global_ftp_not_reachable",
                "description": "Unreachable FTP server"
              },
              {
                "name": "sensor_connected_global_ftp_reachability_slow",
                "description": "Slow response from FTP server hosts"
              },
              {
                "name": "sensor_connected_global_ftp_test_failed",
                "description": "Unable to connect to FTP server"
              },
              {
                "name": "sensor_connected_global_ftp_transfer_response_slow",
                "description": "Slow ftp transfer time with FTP server"
              },
              {
                "name": "sensor_connected_global_ftp_transfer_status_failed",
                "description": "Failed to transfer file with FTP server"
              },
              {
                "name": "sensor_connected_global_outlookserver_test_failed",
                "description": "Failed to connect to Outlook Web Access"
              },
              {
                "name": "sensor_connected_global_webserver_test_failed",
                "description": "Failed to load page from Web Servers"
              },
              {
                "name": "sensor_global_webauth_failed",
                "description": "Clients are failing web authentication test"
              },
              {
                "name": "sensor_global_webauth_config_failure",
                "description": "Invalid web authentication configuration reported for sensors in '{locationName}'."
              },
              {
                "name": "sensor_global_webauth_http_failure",
                "description": "HTTP error reported for web authentication of sensors in '{locationName}'."
              },
              {
                "name": "sensor_global_webauth_slow",
                "description": "Slow web authentication for sensors in '{locationName}'."
              },
              {
                "name": "sensor_connected_global_ipsla_test_failed",
                "description": "IPSLA test failed"
              },
              {
                "name": "sensor_global_ipsla_test_unsupported_probe_type",
                "description": "IPSLA unsupported probe type"
              },
              {
                "name": "sensor_global_ipsla_test_invalid_probe_count",
                "description": "IPSLA invalid probe count"
              },
              {
                "name": "sensor_global_ipsla_test_socket_error",
                "description": "IPSLA Socket Error"
              },
              {
                "name": "sensor_global_ipsla_test_no_ipaddress",
                "description": "IPSLA test IP Address Not Received from DNAC"
              },
              {
                "name": "sensor_global_ipsla_test_responder_no_response",
                "description": "IPSLA Test - No Response from IPSLA Responder"
              },
              {
                "name": "sensor_connected_global_speed_test_failed",
                "description": "Speed Test failed"
              },
              {
                "name": "sensor_global_speed_test_generic_error",
                "description": "Speed Test generic failure"
              },
              {
                "name": "sensor_global_speed_test_http_error",
                "description": "Speed Test HTTP error while accessing query server"
              },
              {
                "name": "sensor_global_speed_test_url_error",
                "description": "Speed Test URL error while accessing query server"
              },
              {
                "name": "sensor_global_speed_test_query_server_timeout",
                "description": "Speed Test Query server timeout"
              },
              {
                "name": "sensor_global_speed_test_ndt_server_nonexist",
                "description": "Speed Test NDT server does not exist"
              },
              {
                "name": "sensor_global_speed_test_websocket_error",
                "description": "Speed Test Websocket error during the test"
              },
              {
                "name": "sensor_global_speed_test_uplink_timeout",
                "description": "Speed Test Uplink test timeout"
              },
              {
                "name": "sensor_global_speed_test_uplink_proxy_error",
                "description": "Speed Test Uplink test proxy error"
              }
            ]
          }
        ]
      },
      {
        "categoryName": "AP Anomaly",
        "issues": [
          {
            "issueName": "AP Anomaly",
            "triggerName": "ap_ilm_event",
            "instances": 1,
            "triggerStatus": "RUNNING",
            "licensing": null,
            "description": "AP encountered anomaly issue",
            "rootCauses": [
              {
                "name": "NONE",
                "description": "AP missed during beacon scan"
              }
            ]
          }
        ]
      }
    ],
    "timeRange": {
      "start": 1545192042465,
      "end": 1545278442465
    }
  }
}
})
