define({
    "SITE_BASIC_DATA": {
        parentId: "",
        additionalInfo: [
            {
                nameSpace: "System Settings",
                attributes: {
                    'group.count.total': "0",
                    hasChild: "FALSE",
                    'group.count.direct': "0",
                    'group.hierarchy.groupType': "SITE",
                    'member.count.total': "0",
                    'member.count.direct': "0"
                }
            }],
        groupNameHierarchy: "",
        groupTypeList: ["SITE"],
        name: "",
        id: "",
        groupHierarchy:"",
        instanceTenantId:"5cd7c6876ecc9d00bac532eb",
        systemGroup:false
    },
    "SITE_FLOOR_BASIC_DATA": {
        parentId: "",
        additionalInfo: [
            {
                nameSpace: "System Settings",
                attributes: {
                    'group.count.total': "0",
                    hasChild: "FALSE",
                    'group.count.direct': "0",
                    'group.hierarchy.groupType': "SITE",
                    'member.count.total': "0",
                    'member.count.direct': "0"
                }
            },
            {
                "nameSpace": "mapsSummary",
                 "attributes": {
                    "rfModel": "54055",
                    "imageURL": "",
                    "isCadFile": "false",
                    "locationGroup": "",
                    "floorIndex": "1"
                 }
            },
            {
                  "nameSpace": "mapGeometry",
                  "attributes": {}
            },
            {
                   "nameSpace": "Location",
                   "attributes": {
                      "address": "",
                      "addressInheritedFrom": "",
                      "type": "floor"
                   }
            },
            {
                "nameSpace": "com.wireless.managingwlc",
                "attributes": {
                  "secondaryWlcInheritedFrom": "14b0eb9d-b69a-4db5-8868-a36af76414b2",
                  "anchorWlcInheritedFrom": "14b0eb9d-b69a-4db5-8868-a36af76414b2",
                  "tertiaryWlcInheritedFrom": "14b0eb9d-b69a-4db5-8868-a36af76414b2",
                  "primaryWlcInheritedFrom": "14b0eb9d-b69a-4db5-8868-a36af76414b2"
                }
            }
            ],
            groupNameHierarchy: "",
            groupTypeList: ["SITE"],
            name: "",
            id: "",
            groupHierarchy:"",
            instanceTenantId:"5cd7c6876ecc9d00bac532eb",
            systemGroup:false
    },

    "SITE_ERROR_DATA": {
        "errorCode": "BadRequest",
        "message": "Invalid Group ID. undefined does not conform to expected UUID pattern",
        "detail": "Input data is invalid",
        "statusCode": 400
    }
});
