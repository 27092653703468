define({
"Template":{
  "request": {
    "key": "secure-http",
    "type": "Application",
    "label": "secure-http",
    "collection": false,
    "componentContent": [
      {
        "component": "fusion",
        "additional": "44793105-24f8-49a3-8c21-8f46c29b741d",
        "origType": "Application"
      }
    ]
  },
  "data": {
    "response": {
      "id": "44793105-24f8-49a3-8c21-8f46c29b741d",
      "instanceId": 16113,
      "displayName": "16113",
      "instanceVersion": 0,
      "createTime": 1565642662109,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1565642662109,
      "name": "secure-http",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [],
      "type": "scalablegroup",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "isDeleted": false,
      "iseReserved": false,
      "propagateToAci": false,
      "pushed": false,
      "scalableGroupExternalHandle": "secure-http",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
        "instanceId": 11011,
        "displayName": "11011",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [],
      "networkApplications": [
        {
          "id": "6594fbdd-addb-42d7-af3b-07f311f65e48",
          "instanceId": 17055,
          "displayName": "17055",
          "instanceVersion": 0,
          "applicationGroup": "other",
          "applicationSubType": "NONE",
          "applicationType": "DEFAULT",
          "categoryId": "b49a0272-2080-4b7b-b842-ac61988c6612",
          "createTime": 1565642662109,
          "encrypted": "true",
          "engineId": "3",
          "helpString": "DEPRECATED, traffic will not match",
          "ignoreConflict": false,
          "lastUpdateTime": 1565642662109,
          "longDescription": "DEPRECATED: Secure Hypertext Transfer Protocol(S-HTTP) is a little-used alternative to the HTTPS URI scheme for encrypting web communications carried over HTTP.",
          "name": "secure-http",
          "nbarId": "16",
          "p2pTechnology": "false",
          "popularity": 0,
          "rank": 65535,
          "references": "http://tools.ietf.org/html/rfc2660",
          "selectorId": "443",
          "serverNames": [],
          "subCategory": "8433522e-cc49-428b-ac4e-208a335cfe05",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [],
      "parentScalableGroup": {
        "idRef": "d9bedadf-90d8-49a9-aec3-e5951b8b1b3d"
      },
      "user": [],
      "userGroup": []
    }
  },
  "result": [
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.1"
      },
      "response": {
        "id": "44793105-24f8-49a3-8c21-8f46c29b741d",
        "instanceId": 16113,
        "displayName": "16113",
        "instanceVersion": 0,
        "createTime": 1565642662109,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1565642662109,
        "name": "secure-http",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "isDeleted": false,
        "iseReserved": false,
        "propagateToAci": false,
        "pushed": false,
        "scalableGroupExternalHandle": "secure-http",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "50c060fe-8fc5-4057-b0e2-ca08b59af73a",
          "instanceId": 11011,
          "displayName": "11011",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "6594fbdd-addb-42d7-af3b-07f311f65e48",
            "instanceId": 17055,
            "displayName": "17055",
            "instanceVersion": 0,
            "applicationGroup": "other",
            "applicationSubType": "NONE",
            "applicationType": "DEFAULT",
            "categoryId": "b49a0272-2080-4b7b-b842-ac61988c6612",
            "createTime": 1565642662109,
            "encrypted": "true",
            "engineId": "3",
            "helpString": "DEPRECATED, traffic will not match",
            "ignoreConflict": false,
            "lastUpdateTime": 1565642662109,
            "longDescription": "DEPRECATED: Secure Hypertext Transfer Protocol(S-HTTP) is a little-used alternative to the HTTPS URI scheme for encrypting web communications carried over HTTP.",
            "name": "secure-http",
            "nbarId": "16",
            "p2pTechnology": "false",
            "popularity": 0,
            "rank": 65535,
            "references": "http://tools.ietf.org/html/rfc2660",
            "selectorId": "443",
            "serverNames": [],
            "subCategory": "8433522e-cc49-428b-ac4e-208a335cfe05",
            "trafficClass": "TRANSACTIONAL_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [],
        "parentScalableGroup": {
          "idRef": "d9bedadf-90d8-49a9-aec3-e5951b8b1b3d"
        },
        "user": [],
        "userGroup": []
      }
    }
  ]
}

})