define({
    "WIFI6_AT_VOICE": {
        "records": [
            {
                "modificationtime": 1588617300000,
                "axATE": 20.911918493246716,
                "nonAxBytes": 60861514,
                "axBytes": 44745232,
                "axAirtime": 2139700,
                "nonAxAirtime": 2951944,
                "nonAxATE": 20.61743515459643,
                "timestamp": "2020-05-04T18:35:00.000+0000"
            },
            {
                "modificationtime": 1588617600000,
                "axATE": 20.75997761719118,
                "nonAxBytes": 53069836,
                "axBytes": 44965032,
                "axAirtime": 2165948,
                "nonAxAirtime": 2571432,
                "nonAxATE": 20.638242037899506,
                "timestamp": "2020-05-04T18:40:00.000+0000"
            },
            {
                "modificationtime": 1588617900000,
                "axATE": 21.05690620084614,
                "nonAxBytes": 50282970,
                "axBytes": 41071664,
                "axAirtime": 1950508,
                "nonAxAirtime": 2434576,
                "nonAxATE": 20.65368671998738,
                "timestamp": "2020-05-04T18:45:00.000+0000"
            },
            {
                "modificationtime": 1588618200000,
                "axATE": 20.87679104506582,
                "nonAxBytes": 49901948,
                "axBytes": 43023058,
                "axAirtime": 2060808,
                "nonAxAirtime": 2416676,
                "nonAxATE": 20.649002183163983,
                "timestamp": "2020-05-04T18:50:00.000+0000"
            },
            {
                "modificationtime": 1588618500000,
                "axATE": 20.910515563056496,
                "nonAxBytes": 51072928,
                "axBytes": 43314962,
                "axAirtime": 2071444,
                "nonAxAirtime": 2473000,
                "nonAxATE": 20.652215123331985,
                "timestamp": "2020-05-04T18:55:00.000+0000"
            },
            {
                "modificationtime": 1588618800000,
                "axATE": 20.893623867847225,
                "nonAxBytes": 51324198,
                "axBytes": 46100026,
                "axAirtime": 2206416,
                "nonAxAirtime": 2496664,
                "nonAxATE": 20.557110608395842,
                "timestamp": "2020-05-04T19:00:00.000+0000"
            },
            {
                "modificationtime": 1588619100000,
                "axATE": 20.93856986263492,
                "nonAxBytes": 51201536,
                "axBytes": 42406048,
                "axAirtime": 2025260,
                "nonAxAirtime": 2478988,
                "nonAxATE": 20.654208894920025,
                "timestamp": "2020-05-04T19:05:00.000+0000"
            },
            {
                "modificationtime": 1588619400000,
                "axATE": 20.88525336359982,
                "nonAxBytes": 50117656,
                "axBytes": 42396396,
                "axAirtime": 2029968,
                "nonAxAirtime": 2426336,
                "nonAxATE": 20.655694841934505,
                "timestamp": "2020-05-04T19:10:00.000+0000"
            },
            {
                "modificationtime": 1588619700000,
                "axATE": 20.9532537538269,
                "nonAxBytes": 50556150,
                "axBytes": 44732850,
                "axAirtime": 2134888,
                "nonAxAirtime": 2447732,
                "nonAxATE": 20.65428323035365,
                "timestamp": "2020-05-04T19:15:00.000+0000"
            },
            {
                "modificationtime": 1588620000000,
                "axATE": 20.86721574225724,
                "nonAxBytes": 55976100,
                "axBytes": 45255482,
                "axAirtime": 2168736,
                "nonAxAirtime": 2710344,
                "nonAxATE": 20.652765848172777,
                "timestamp": "2020-05-04T19:20:00.000+0000"
            },
            {
                "modificationtime": 1588620300000,
                "axATE": 20.9207282936312,
                "nonAxBytes": 42393782,
                "axBytes": 40507802,
                "axAirtime": 1936252,
                "nonAxAirtime": 2052592,
                "nonAxATE": 20.653779221589094,
                "timestamp": "2020-05-04T19:25:00.000+0000"
            },
            {
                "modificationtime": 1588620600000,
                "axATE": 21.0550891713638,
                "nonAxBytes": 55189220,
                "axBytes": 49188394,
                "axAirtime": 2336176,
                "nonAxAirtime": 2695628,
                "nonAxATE": 20.47360392457713,
                "timestamp": "2020-05-04T19:30:00.000+0000"
            },
            {
                "modificationtime": 1588620900000,
                "axATE": 20.894910225925898,
                "nonAxBytes": 55867344,
                "axBytes": 45414168,
                "axAirtime": 2173456,
                "nonAxAirtime": 2704892,
                "nonAxATE": 20.65418656271674,
                "timestamp": "2020-05-04T19:35:00.000+0000"
            },
            {
                "modificationtime": 1588621200000,
                "axATE": 20.93137357050727,
                "nonAxBytes": 57260282,
                "axBytes": 46607136,
                "axAirtime": 2226664,
                "nonAxAirtime": 2772340,
                "nonAxATE": 20.654134052821803,
                "timestamp": "2020-05-04T19:40:00.000+0000"
            },
            {
                "modificationtime": 1588621500000,
                "axATE": 21.09195672878607,
                "nonAxBytes": 48419844,
                "axBytes": 39065088,
                "axAirtime": 1852132,
                "nonAxAirtime": 2344536,
                "nonAxATE": 20.65220751568754,
                "timestamp": "2020-05-04T19:45:00.000+0000"
            },
            {
                "modificationtime": 1588621800000,
                "axATE": 20.897720947492115,
                "nonAxBytes": 49624050,
                "axBytes": 43250592,
                "axAirtime": 2069632,
                "nonAxAirtime": 2402912,
                "nonAxATE": 20.65163018870437,
                "timestamp": "2020-05-04T19:50:00.000+0000"
            },
            {
                "modificationtime": 1588622100000,
                "axATE": 20.976903826079663,
                "nonAxBytes": 47527798,
                "axBytes": 38020806,
                "axAirtime": 1812508,
                "nonAxAirtime": 2301260,
                "nonAxATE": 20.65294577753057,
                "timestamp": "2020-05-04T19:55:00.000+0000"
            },
            {
                "modificationtime": 1588622400000,
                "axATE": 20.862085545160227,
                "nonAxBytes": 39537774,
                "axBytes": 40699342,
                "axAirtime": 1950876,
                "nonAxAirtime": 1914180,
                "nonAxATE": 20.655201705168793,
                "timestamp": "2020-05-04T20:00:00.000+0000"
            },
            {
                "modificationtime": 1588622700000,
                "axATE": 21.038715214950496,
                "nonAxBytes": 696,
                "axBytes": 45346426,
                "axAirtime": 2155380,
                "nonAxAirtime": 316,
                "nonAxATE": 2.2025316455696204,
                "timestamp": "2020-05-04T20:05:00.000+0000"
            },
            {
                "modificationtime": 1588623000000,
                "axATE": 21.141615904887598,
                "nonAxBytes": 464,
                "axBytes": 37997488,
                "axAirtime": 1797284,
                "nonAxAirtime": 204,
                "nonAxATE": 2.2745098039215685,
                "timestamp": "2020-05-04T20:10:00.000+0000"
            },
            {
                "modificationtime": 1588623300000,
                "axATE": 20.93953606729968,
                "nonAxBytes": 34256294,
                "axBytes": 33573112,
                "axAirtime": 1603336,
                "nonAxAirtime": 1658564,
                "nonAxATE": 20.65418880429094,
                "timestamp": "2020-05-04T20:15:00.000+0000"
            },
            {
                "modificationtime": 1588623600000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 100231066,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 4852884,
                "nonAxATE": 20.653917546761885,
                "timestamp": "2020-05-04T20:20:00.000+0000"
            },
            {
                "modificationtime": 1588623900000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 131144746,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 6349276,
                "nonAxATE": 20.65507090887213,
                "timestamp": "2020-05-04T20:25:00.000+0000"
            },
            {
                "modificationtime": 1588624200000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 106468676,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 5203680,
                "nonAxATE": 20.460265811886973,
                "timestamp": "2020-05-04T20:30:00.000+0000"
            },
            {
                "modificationtime": 1588624500000,
                "axATE": 20.764186382585933,
                "nonAxBytes": 55610538,
                "axBytes": 43910108,
                "axAirtime": 2114704,
                "nonAxAirtime": 2693056,
                "nonAxATE": 20.649603275980894,
                "timestamp": "2020-05-04T20:35:00.000+0000"
            },
            {
                "modificationtime": 1588624800000,
                "axATE": 21.03732096723633,
                "nonAxBytes": 44153690,
                "axBytes": 38923672,
                "axAirtime": 1850220,
                "nonAxAirtime": 2145812,
                "nonAxATE": 20.576681461376857,
                "timestamp": "2020-05-04T20:40:00.000+0000"
            },
            {
                "modificationtime": 1588625100000,
                "axATE": 20.98691891188761,
                "nonAxBytes": 37349310,
                "axBytes": 36140062,
                "axAirtime": 1722028,
                "nonAxAirtime": 1808356,
                "nonAxATE": 20.653737427807357,
                "timestamp": "2020-05-04T20:45:00.000+0000"
            },
            {
                "modificationtime": 1588625400000,
                "axATE": 20.935487109231985,
                "nonAxBytes": 47617458,
                "axBytes": 42544092,
                "axAirtime": 2032152,
                "nonAxAirtime": 2306184,
                "nonAxATE": 20.64772715446816,
                "timestamp": "2020-05-04T20:50:00.000+0000"
            },
            {
                "modificationtime": 1588625700000,
                "axATE": 20.860079917376257,
                "nonAxBytes": 50907830,
                "axBytes": 41930346,
                "axAirtime": 2010076,
                "nonAxAirtime": 2464772,
                "nonAxATE": 20.654174098050447,
                "timestamp": "2020-05-04T20:55:00.000+0000"
            },
            {
                "modificationtime": 1588626000000,
                "axATE": 20.865797990669613,
                "nonAxBytes": 52875156,
                "axBytes": 44046698,
                "axAirtime": 2110952,
                "nonAxAirtime": 2565248,
                "nonAxATE": 20.612103001347236,
                "timestamp": "2020-05-04T21:00:00.000+0000"
            },
            {
                "modificationtime": 1588626300000,
                "axATE": 17.416630669546436,
                "nonAxBytes": 131110060,
                "axBytes": 1128946,
                "axAirtime": 64820,
                "nonAxAirtime": 6372964,
                "nonAxATE": 10.486155902169108,
                "timestamp": "2020-05-04T21:05:00.000+0000"
            },
            {
                "modificationtime": 1588626600000,
                "axATE": 20.884545376920947,
                "nonAxBytes": 53797873,
                "axBytes": 40112278,
                "axAirtime": 1920668,
                "nonAxAirtime": 2630134,
                "nonAxATE": 10.491150831910621,
                "timestamp": "2020-05-04T21:10:00.000+0000"
            },
            {
                "modificationtime": 1588626900000,
                "axATE": 20.87319513264367,
                "nonAxBytes": 62432056,
                "axBytes": 47803708,
                "axAirtime": 2290196,
                "nonAxAirtime": 3023032,
                "nonAxATE": 20.6521320316821,
                "timestamp": "2020-05-04T21:15:00.000+0000"
            },
            {
                "modificationtime": 1588627200000,
                "axATE": 20.75495451124161,
                "nonAxBytes": 53596629,
                "axBytes": 41803052,
                "axAirtime": 2014124,
                "nonAxAirtime": 2597832,
                "nonAxATE": 10.509484978360833,
                "timestamp": "2020-05-04T21:20:00.000+0000"
            },
            {
                "modificationtime": 1588627500000,
                "axATE": 20.882361750686332,
                "nonAxBytes": 56481910,
                "axBytes": 45456558,
                "axAirtime": 2176792,
                "nonAxAirtime": 2734912,
                "nonAxATE": 20.65218551821777,
                "timestamp": "2020-05-04T21:25:00.000+0000"
            },
            {
                "modificationtime": 1588627800000,
                "axATE": 20.821825156470826,
                "nonAxBytes": 59542830,
                "axBytes": 46614986,
                "axAirtime": 2238756,
                "nonAxAirtime": 2884116,
                "nonAxATE": 20.645088477717263,
                "timestamp": "2020-05-04T21:30:00.000+0000"
            },
            {
                "modificationtime": 1588628100000,
                "axATE": 20.565909207840985,
                "nonAxBytes": 75441068,
                "axBytes": 23983552,
                "axAirtime": 1166180,
                "nonAxAirtime": 3652708,
                "nonAxATE": 20.65346258173388,
                "timestamp": "2020-05-04T21:35:00.000+0000"
            },
            {
                "modificationtime": 1588628400000,
                "axATE": 20.89942658580487,
                "nonAxBytes": 55846934,
                "axBytes": 44771922,
                "axAirtime": 2142256,
                "nonAxAirtime": 2714952,
                "nonAxATE": 20.57013678326541,
                "timestamp": "2020-05-04T21:40:00.000+0000"
            },
            {
                "modificationtime": 1588628700000,
                "axATE": 20.888950174865275,
                "nonAxBytes": 45927032,
                "axBytes": 38250258,
                "axAirtime": 1831124,
                "nonAxAirtime": 2224736,
                "nonAxATE": 20.64381211973016,
                "timestamp": "2020-05-04T21:45:00.000+0000"
            },
            {
                "modificationtime": 1588629000000,
                "axATE": 20.958748223490467,
                "nonAxBytes": 47814930,
                "axBytes": 37634702,
                "axAirtime": 1795656,
                "nonAxAirtime": 2315060,
                "nonAxATE": 20.65386210292606,
                "timestamp": "2020-05-04T21:50:00.000+0000"
            },
            {
                "modificationtime": 1588629300000,
                "axATE": 20.702808284220982,
                "nonAxBytes": 59612374,
                "axBytes": 57118634,
                "axAirtime": 2758980,
                "nonAxAirtime": 2913056,
                "nonAxATE": 20.46386131952149,
                "timestamp": "2020-05-04T21:55:00.000+0000"
            },
            {
                "modificationtime": 1588629600000,
                "axATE": 20.91345006006248,
                "nonAxBytes": 48009610,
                "axBytes": 44638588,
                "axAirtime": 2134444,
                "nonAxAirtime": 2331132,
                "nonAxATE": 20.594977032617628,
                "timestamp": "2020-05-04T22:00:00.000+0000"
            },
            {
                "modificationtime": 1588629900000,
                "axATE": 21.017395069625547,
                "nonAxBytes": 44188486,
                "axBytes": 42713652,
                "axAirtime": 2032300,
                "nonAxAirtime": 2140428,
                "nonAxATE": 20.644696294385984,
                "timestamp": "2020-05-04T22:05:00.000+0000"
            },
            {
                "modificationtime": 1588630200000,
                "axATE": 20.926661878390384,
                "nonAxBytes": 45426984,
                "axBytes": 44286676,
                "axAirtime": 2116280,
                "nonAxAirtime": 2199620,
                "nonAxATE": 20.652196288449822,
                "timestamp": "2020-05-04T22:10:00.000+0000"
            },
            {
                "modificationtime": 1588630500000,
                "axATE": 20.752005363468133,
                "nonAxBytes": 47467202,
                "axBytes": 45439172,
                "axAirtime": 2189628,
                "nonAxAirtime": 2298532,
                "nonAxATE": 20.651094698703346,
                "timestamp": "2020-05-04T22:15:00.000+0000"
            },
            {
                "modificationtime": 1588630800000,
                "axATE": 20.88481035527169,
                "nonAxBytes": 40241598,
                "axBytes": 39303960,
                "axAirtime": 1881940,
                "nonAxAirtime": 1948572,
                "nonAxATE": 20.651840424680227,
                "timestamp": "2020-05-04T22:20:00.000+0000"
            },
            {
                "modificationtime": 1588631100000,
                "axATE": 20.905744111359642,
                "nonAxBytes": 45479118,
                "axBytes": 42208112,
                "axAirtime": 2018972,
                "nonAxAirtime": 2203624,
                "nonAxATE": 20.638329406468618,
                "timestamp": "2020-05-04T22:25:00.000+0000"
            },
            {
                "modificationtime": 1588631400000,
                "axATE": 20.79214709068224,
                "nonAxBytes": 52335370,
                "axBytes": 47527272,
                "axAirtime": 2285828,
                "nonAxAirtime": 2534068,
                "nonAxATE": 20.652709398484966,
                "timestamp": "2020-05-04T22:30:00.000+0000"
            },
            {
                "modificationtime": 1588631700000,
                "axATE": 19.237576211263033,
                "nonAxBytes": 54635622,
                "axBytes": 51191652,
                "axAirtime": 2661024,
                "nonAxAirtime": 2653772,
                "nonAxATE": 20.5879110940955,
                "timestamp": "2020-05-04T22:35:00.000+0000"
            },
            {
                "modificationtime": 1588632000000,
                "axATE": 20.687213361626394,
                "nonAxBytes": 69353600,
                "axBytes": 70114924,
                "axAirtime": 3389288,
                "nonAxAirtime": 3387304,
                "nonAxATE": 20.474572108083596,
                "timestamp": "2020-05-04T22:40:00.000+0000"
            },
            {
                "modificationtime": 1588632300000,
                "axATE": 20.911261609652744,
                "nonAxBytes": 39774612,
                "axBytes": 38176352,
                "axAirtime": 1825636,
                "nonAxAirtime": 1925592,
                "nonAxATE": 20.6557837797415,
                "timestamp": "2020-05-04T22:45:00.000+0000"
            },
            {
                "modificationtime": 1588632600000,
                "axATE": 21.06433749344203,
                "nonAxBytes": 33860886,
                "axBytes": 33405006,
                "axAirtime": 1585856,
                "nonAxAirtime": 1639500,
                "nonAxATE": 20.653178408051236,
                "timestamp": "2020-05-04T22:50:00.000+0000"
            },
            {
                "modificationtime": 1588632900000,
                "axATE": 20.949048940443827,
                "nonAxBytes": 36472144,
                "axBytes": 41551852,
                "axAirtime": 1983472,
                "nonAxAirtime": 1766068,
                "nonAxATE": 20.65160797885472,
                "timestamp": "2020-05-04T22:55:00.000+0000"
            },
            {
                "modificationtime": 1588633200000,
                "axATE": 20.91157850716821,
                "nonAxBytes": 39889802,
                "axBytes": 39003858,
                "axAirtime": 1865180,
                "nonAxAirtime": 1931428,
                "nonAxATE": 20.65301010444086,
                "timestamp": "2020-05-04T23:00:00.000+0000"
            },
            {
                "modificationtime": 1588633500000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 62748770,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 3037892,
                "nonAxATE": 20.655365628534522,
                "timestamp": "2020-05-04T23:05:00.000+0000"
            },
            {
                "modificationtime": 1588633800000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 64866414,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 3140288,
                "nonAxATE": 20.656199049259175,
                "timestamp": "2020-05-04T23:10:00.000+0000"
            },
            {
                "modificationtime": 1588634100000,
                "axATE": 20.67027726322312,
                "nonAxBytes": 64602400,
                "axBytes": 63782936,
                "axAirtime": 3085732,
                "nonAxAirtime": 3138284,
                "nonAxATE": 20.58526251926212,
                "timestamp": "2020-05-04T23:15:00.000+0000"
            },
            {
                "modificationtime": 1588634400000,
                "axATE": 20.822218341198496,
                "nonAxBytes": 59224236,
                "axBytes": 56989662,
                "axAirtime": 2736964,
                "nonAxAirtime": 2881636,
                "nonAxATE": 20.55229598741826,
                "timestamp": "2020-05-04T23:20:00.000+0000"
            },
            {
                "modificationtime": 1588634700000,
                "axATE": 20.777330240120733,
                "nonAxBytes": 75006572,
                "axBytes": 74013172,
                "axAirtime": 3562208,
                "nonAxAirtime": 3646580,
                "nonAxATE": 20.56901864212495,
                "timestamp": "2020-05-04T23:25:00.000+0000"
            },
            {
                "modificationtime": 1588635000000,
                "axATE": 20.817704573442608,
                "nonAxBytes": 50786940,
                "axBytes": 47062668,
                "axAirtime": 2260704,
                "nonAxAirtime": 2461116,
                "nonAxATE": 20.635735983188116,
                "timestamp": "2020-05-04T23:30:00.000+0000"
            },
            {
                "modificationtime": 1588635300000,
                "axATE": 20.864843128573053,
                "nonAxBytes": 46649766,
                "axBytes": 42599166,
                "axAirtime": 2041672,
                "nonAxAirtime": 2263308,
                "nonAxATE": 20.611320244527036,
                "timestamp": "2020-05-04T23:35:00.000+0000"
            },
            {
                "modificationtime": 1588635600000,
                "axATE": 20.959769513800868,
                "nonAxBytes": 43057830,
                "axBytes": 40878090,
                "axAirtime": 1950312,
                "nonAxAirtime": 2087548,
                "nonAxATE": 20.626031114015102,
                "timestamp": "2020-05-04T23:40:00.000+0000"
            },
            {
                "modificationtime": 1588635900000,
                "axATE": 20.989497552349782,
                "nonAxBytes": 44665890,
                "axBytes": 42636722,
                "axAirtime": 2031336,
                "nonAxAirtime": 2165312,
                "nonAxATE": 20.62792336624006,
                "timestamp": "2020-05-04T23:45:00.000+0000"
            },
            {
                "modificationtime": 1588636200000,
                "axATE": 20.885689908576836,
                "nonAxBytes": 49770262,
                "axBytes": 46951198,
                "axAirtime": 2248008,
                "nonAxAirtime": 2410276,
                "nonAxATE": 20.649196191639465,
                "timestamp": "2020-05-04T23:50:00.000+0000"
            },
            {
                "modificationtime": 1588636500000,
                "axATE": 20.94635118617701,
                "nonAxBytes": 46108688,
                "axBytes": 42225498,
                "axAirtime": 2015888,
                "nonAxAirtime": 2232448,
                "nonAxATE": 20.65386875752537,
                "timestamp": "2020-05-04T23:55:00.000+0000"
            },
            {
                "modificationtime": 1588636800000,
                "axATE": 20.937115375505474,
                "nonAxBytes": 52608372,
                "axBytes": 38893958,
                "axAirtime": 1857656,
                "nonAxAirtime": 2551872,
                "nonAxATE": 20.615599841998346,
                "timestamp": "2020-05-05T00:00:00.000+0000"
            },
            {
                "modificationtime": 1588637100000,
                "axATE": 20.959094354762488,
                "nonAxBytes": 47017370,
                "axBytes": 43463880,
                "axAirtime": 2073748,
                "nonAxAirtime": 2276472,
                "nonAxATE": 20.653612256157775,
                "timestamp": "2020-05-05T00:05:00.000+0000"
            },
            {
                "modificationtime": 1588637400000,
                "axATE": 20.836560198925756,
                "nonAxBytes": 48446766,
                "axBytes": 45015388,
                "axAirtime": 2160404,
                "nonAxAirtime": 2350116,
                "nonAxATE": 20.614627533279208,
                "timestamp": "2020-05-05T00:10:00.000+0000"
            },
            {
                "modificationtime": 1588637700000,
                "axATE": 20.954937462499387,
                "nonAxBytes": 75910870,
                "axBytes": 32549472,
                "axAirtime": 1553308,
                "nonAxAirtime": 3676204,
                "nonAxATE": 20.649253958703053,
                "timestamp": "2020-05-05T00:15:00.000+0000"
            },
            {
                "modificationtime": 1588638000000,
                "axATE": 20.93528688687101,
                "nonAxBytes": 66740252,
                "axBytes": 33789888,
                "axAirtime": 1614016,
                "nonAxAirtime": 3231740,
                "nonAxATE": 20.651491766045535,
                "timestamp": "2020-05-05T00:20:00.000+0000"
            },
            {
                "modificationtime": 1588638300000,
                "axATE": 20.92777123970253,
                "nonAxBytes": 73410308,
                "axBytes": 33431696,
                "axAirtime": 1597480,
                "nonAxAirtime": 3559580,
                "nonAxATE": 20.62330612038499,
                "timestamp": "2020-05-05T00:25:00.000+0000"
            },
            {
                "modificationtime": 1588638600000,
                "axATE": 21.024966142613202,
                "nonAxBytes": 59225018,
                "axBytes": 29993628,
                "axAirtime": 1426572,
                "nonAxAirtime": 2867824,
                "nonAxATE": 20.65155253599942,
                "timestamp": "2020-05-05T00:30:00.000+0000"
            },
            {
                "modificationtime": 1588638900000,
                "axATE": 21.068579526654556,
                "nonAxBytes": 68781260,
                "axBytes": 26997952,
                "axAirtime": 1281432,
                "nonAxAirtime": 3333468,
                "nonAxATE": 20.633544404806045,
                "timestamp": "2020-05-05T00:35:00.000+0000"
            },
            {
                "modificationtime": 1588639200000,
                "axATE": 20.834929490994814,
                "nonAxBytes": 68008170,
                "axBytes": 35565558,
                "axAirtime": 1707016,
                "nonAxAirtime": 3292556,
                "nonAxATE": 20.65512932809647,
                "timestamp": "2020-05-05T00:40:00.000+0000"
            },
            {
                "modificationtime": 1588639500000,
                "axATE": 20.572446501186818,
                "nonAxBytes": 82813494,
                "axBytes": 17888812,
                "axAirtime": 869552,
                "nonAxAirtime": 4009312,
                "nonAxATE": 20.655287989560303,
                "timestamp": "2020-05-05T00:45:00.000+0000"
            },
            {
                "modificationtime": 1588639800000,
                "axATE": 20.91779827069639,
                "nonAxBytes": 47714946,
                "axBytes": 44068446,
                "axAirtime": 2106744,
                "nonAxAirtime": 2312084,
                "nonAxATE": 20.637202627586195,
                "timestamp": "2020-05-05T00:50:00.000+0000"
            },
            {
                "modificationtime": 1588640100000,
                "axATE": 20.666679307887158,
                "nonAxBytes": 71827090,
                "axBytes": 68664298,
                "axAirtime": 3322464,
                "nonAxAirtime": 3505808,
                "nonAxATE": 20.48802729641783,
                "timestamp": "2020-05-05T00:55:00.000+0000"
            },
            {
                "modificationtime": 1588640400000,
                "axATE": 20.94029012791519,
                "nonAxBytes": 44098392,
                "axBytes": 42445298,
                "axAirtime": 2026968,
                "nonAxAirtime": 2135184,
                "nonAxATE": 20.653204595013825,
                "timestamp": "2020-05-05T01:00:00.000+0000"
            },
            {
                "modificationtime": 1588640700000,
                "axATE": 20.86572024956093,
                "nonAxBytes": 49297010,
                "axBytes": 47142506,
                "axAirtime": 2259328,
                "nonAxAirtime": 2389672,
                "nonAxATE": 20.629195136403656,
                "timestamp": "2020-05-05T01:05:00.000+0000"
            },
            {
                "modificationtime": 1588641000000,
                "axATE": 20.83483093666291,
                "nonAxBytes": 51598630,
                "axBytes": 49499308,
                "axAirtime": 2375796,
                "nonAxAirtime": 2504920,
                "nonAxATE": 20.598913338549735,
                "timestamp": "2020-05-05T01:10:00.000+0000"
            },
            {
                "modificationtime": 1588641300000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 98326718,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 4760564,
                "nonAxATE": 20.654426240252207,
                "timestamp": "2020-05-05T01:15:00.000+0000"
            },
            {
                "modificationtime": 1588641600000,
                "axATE": 20.84483625660372,
                "nonAxBytes": 47490862,
                "axBytes": 46258944,
                "axAirtime": 2219204,
                "nonAxAirtime": 2308188,
                "nonAxATE": 20.574954033207,
                "timestamp": "2020-05-05T01:20:00.000+0000"
            },
            {
                "modificationtime": 1588641900000,
                "axATE": 20.822858329804827,
                "nonAxBytes": 42806994,
                "axBytes": 41702688,
                "axAirtime": 2002736,
                "nonAxAirtime": 2072848,
                "nonAxATE": 20.651294257948486,
                "timestamp": "2020-05-05T01:25:00.000+0000"
            },
            {
                "modificationtime": 1588642200000,
                "axATE": 20.860398464439452,
                "nonAxBytes": 48072642,
                "axBytes": 44427976,
                "axAirtime": 2129776,
                "nonAxAirtime": 2330124,
                "nonAxATE": 20.630937237674903,
                "timestamp": "2020-05-05T01:30:00.000+0000"
            },
            {
                "modificationtime": 1588642500000,
                "axATE": 20.830966808775432,
                "nonAxBytes": 47301656,
                "axBytes": 44991722,
                "axAirtime": 2159848,
                "nonAxAirtime": 2294004,
                "nonAxATE": 20.619692031923222,
                "timestamp": "2020-05-05T01:35:00.000+0000"
            },
            {
                "modificationtime": 1588642800000,
                "axATE": 20.71945363897118,
                "nonAxBytes": 59057584,
                "axBytes": 56286302,
                "axAirtime": 2716592,
                "nonAxAirtime": 2860020,
                "nonAxATE": 20.64936049398256,
                "timestamp": "2020-05-05T01:40:00.000+0000"
            },
            {
                "modificationtime": 1588643100000,
                "axATE": 20.337111864912217,
                "nonAxBytes": 51272148,
                "axBytes": 21823348,
                "axAirtime": 1073080,
                "nonAxAirtime": 2482464,
                "nonAxATE": 20.653732742952165,
                "timestamp": "2020-05-05T01:45:00.000+0000"
            },
            {
                "modificationtime": 1588643400000,
                "axATE": 20.859445259222007,
                "nonAxBytes": 40029486,
                "axBytes": 39653722,
                "axAirtime": 1900996,
                "nonAxAirtime": 1938228,
                "nonAxATE": 20.652619815625407,
                "timestamp": "2020-05-05T01:50:00.000+0000"
            },
            {
                "modificationtime": 1588643700000,
                "axATE": 20.032050206652535,
                "nonAxBytes": 39328510,
                "axBytes": 36777482,
                "axAirtime": 1835932,
                "nonAxAirtime": 1904932,
                "nonAxATE": 20.645624095768248,
                "timestamp": "2020-05-05T01:55:00.000+0000"
            },
            {
                "modificationtime": 1588644000000,
                "axATE": 20.881951960485242,
                "nonAxBytes": 40134754,
                "axBytes": 39976910,
                "axAirtime": 1914424,
                "nonAxAirtime": 1943408,
                "nonAxATE": 20.651738595292393,
                "timestamp": "2020-05-05T02:00:00.000+0000"
            },
            {
                "modificationtime": 1588644300000,
                "axATE": 20.869201371358795,
                "nonAxBytes": 56630596,
                "axBytes": 42658818,
                "axAirtime": 2044104,
                "nonAxAirtime": 2742868,
                "nonAxATE": 20.64648973264481,
                "timestamp": "2020-05-05T02:05:00.000+0000"
            },
            {
                "modificationtime": 1588644600000,
                "axATE": 20.873411767007003,
                "nonAxBytes": 56267578,
                "axBytes": 42686962,
                "axAirtime": 2045040,
                "nonAxAirtime": 2724564,
                "nonAxATE": 20.651956790150646,
                "timestamp": "2020-05-05T02:10:00.000+0000"
            },
            {
                "modificationtime": 1588644900000,
                "axATE": 20.660418162522134,
                "nonAxBytes": 59715878,
                "axBytes": 57779256,
                "axAirtime": 2796616,
                "nonAxAirtime": 2897956,
                "nonAxATE": 20.60620589132478,
                "timestamp": "2020-05-05T02:15:00.000+0000"
            },
            {
                "modificationtime": 1588645200000,
                "axATE": 20.945357286009045,
                "nonAxBytes": 82246794,
                "axBytes": 31144322,
                "axAirtime": 1486932,
                "nonAxAirtime": 3982004,
                "nonAxATE": 20.65462365180949,
                "timestamp": "2020-05-05T02:20:00.000+0000"
            },
            {
                "modificationtime": 1588645500000,
                "axATE": 20.71282863411165,
                "nonAxBytes": 69589608,
                "axBytes": 54984772,
                "axAirtime": 2654624,
                "nonAxAirtime": 3369276,
                "nonAxATE": 20.654172587820053,
                "timestamp": "2020-05-05T02:25:00.000+0000"
            },
            {
                "modificationtime": 1588645800000,
                "axATE": 20.620770835123718,
                "nonAxBytes": 52592556,
                "axBytes": 49909194,
                "axAirtime": 2420336,
                "nonAxAirtime": 2559056,
                "nonAxATE": 20.551545569928912,
                "timestamp": "2020-05-05T02:30:00.000+0000"
            },
            {
                "modificationtime": 1588646100000,
                "axATE": 20.83640557888133,
                "nonAxBytes": 44773956,
                "axBytes": 43175116,
                "axAirtime": 2072100,
                "nonAxAirtime": 2167776,
                "nonAxATE": 20.65432775342102,
                "timestamp": "2020-05-05T02:35:00.000+0000"
            },
            {
                "modificationtime": 1588646400000,
                "axATE": 20.814244732782484,
                "nonAxBytes": 49711374,
                "axBytes": 47277642,
                "axAirtime": 2271408,
                "nonAxAirtime": 2406772,
                "nonAxATE": 20.65479156313934,
                "timestamp": "2020-05-05T02:40:00.000+0000"
            },
            {
                "modificationtime": 1588646700000,
                "axATE": 20.770047098786996,
                "nonAxBytes": 58379808,
                "axBytes": 41382408,
                "axAirtime": 1992408,
                "nonAxAirtime": 2829696,
                "nonAxATE": 20.631123626000814,
                "timestamp": "2020-05-05T02:45:00.000+0000"
            },
            {
                "modificationtime": 1588647000000,
                "axATE": 20.82317598742585,
                "nonAxBytes": 95560876,
                "axBytes": 4928346,
                "axAirtime": 236676,
                "nonAxAirtime": 4630568,
                "nonAxATE": 20.636966350564336,
                "timestamp": "2020-05-05T02:50:00.000+0000"
            },
            {
                "modificationtime": 1588647300000,
                "axATE": 20.902492694239438,
                "nonAxBytes": 46864246,
                "axBytes": 45462838,
                "axAirtime": 2174996,
                "nonAxAirtime": 2269576,
                "nonAxATE": 20.648899177643752,
                "timestamp": "2020-05-05T02:55:00.000+0000"
            },
            {
                "modificationtime": 1588647600000,
                "axATE": 20.62172778581355,
                "nonAxBytes": 66812798,
                "axBytes": 66893338,
                "axAirtime": 3243828,
                "nonAxAirtime": 3238696,
                "nonAxATE": 20.629536702425913,
                "timestamp": "2020-05-05T03:00:00.000+0000"
            },
            {
                "modificationtime": 1588647900000,
                "axATE": 20.853228164417093,
                "nonAxBytes": 51966010,
                "axBytes": 61335600,
                "axAirtime": 2941300,
                "nonAxAirtime": 2524568,
                "nonAxATE": 20.584119738505755,
                "timestamp": "2020-05-05T03:05:00.000+0000"
            },
            {
                "modificationtime": 1588648200000,
                "axATE": 20.68488101022358,
                "nonAxBytes": 69947220,
                "axBytes": 65731422,
                "axAirtime": 3177752,
                "nonAxAirtime": 3386336,
                "nonAxATE": 20.655723472213037,
                "timestamp": "2020-05-05T03:10:00.000+0000"
            },
            {
                "modificationtime": 1588648500000,
                "axATE": 20.891001846512438,
                "nonAxBytes": 48176610,
                "axBytes": 44168926,
                "axAirtime": 2114256,
                "nonAxAirtime": 2332804,
                "nonAxATE": 20.65180358058371,
                "timestamp": "2020-05-05T03:15:00.000+0000"
            },
            {
                "modificationtime": 1588648800000,
                "axATE": 20.759921236799936,
                "nonAxBytes": 49788768,
                "axBytes": 46536602,
                "axAirtime": 2241656,
                "nonAxAirtime": 2410756,
                "nonAxATE": 20.652761208517163,
                "timestamp": "2020-05-05T03:20:00.000+0000"
            },
            {
                "modificationtime": 1588649100000,
                "axATE": 20.946088193732724,
                "nonAxBytes": 46987788,
                "axBytes": 43164358,
                "axAirtime": 2060736,
                "nonAxAirtime": 2275220,
                "nonAxATE": 20.651975633125588,
                "timestamp": "2020-05-05T03:25:00.000+0000"
            },
            {
                "modificationtime": 1588649400000,
                "axATE": 20.78866622576567,
                "nonAxBytes": 47770994,
                "axBytes": 45515870,
                "axAirtime": 2189456,
                "nonAxAirtime": 2313008,
                "nonAxATE": 20.65319013163811,
                "timestamp": "2020-05-05T03:30:00.000+0000"
            },
            {
                "modificationtime": 1588649700000,
                "axATE": 20.877037006713607,
                "nonAxBytes": 53514224,
                "axBytes": 50998426,
                "axAirtime": 2442800,
                "nonAxAirtime": 2593448,
                "nonAxATE": 20.634392515292383,
                "timestamp": "2020-05-05T03:35:00.000+0000"
            },
            {
                "modificationtime": 1588650000000,
                "axATE": 20.849073601283827,
                "nonAxBytes": 47497852,
                "axBytes": 45731192,
                "axAirtime": 2193440,
                "nonAxAirtime": 2303228,
                "nonAxATE": 20.622297054394963,
                "timestamp": "2020-05-05T03:40:00.000+0000"
            },
            {
                "modificationtime": 1588650300000,
                "axATE": 20.823559973802737,
                "nonAxBytes": 49498938,
                "axBytes": 45530464,
                "axAirtime": 2186488,
                "nonAxAirtime": 2399864,
                "nonAxATE": 20.6257262911565,
                "timestamp": "2020-05-05T03:45:00.000+0000"
            },
            {
                "modificationtime": 1588650600000,
                "axATE": 20.869285369133348,
                "nonAxBytes": 48617750,
                "axBytes": 46125378,
                "axAirtime": 2210204,
                "nonAxAirtime": 2354288,
                "nonAxATE": 20.65072327599682,
                "timestamp": "2020-05-05T03:50:00.000+0000"
            },
            {
                "modificationtime": 1588650900000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 131769350,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 6379272,
                "nonAxATE": 20.65586010441317,
                "timestamp": "2020-05-05T03:55:00.000+0000"
            },
            {
                "modificationtime": 1588651200000,
                "axATE": 20.946524164828297,
                "nonAxBytes": 45795254,
                "axBytes": 44512872,
                "axAirtime": 2125072,
                "nonAxAirtime": 2217924,
                "nonAxATE": 20.647801277230418,
                "timestamp": "2020-05-05T04:00:00.000+0000"
            },
            {
                "modificationtime": 1588651500000,
                "axATE": 20.83834616292798,
                "nonAxBytes": 47599956,
                "axBytes": 44125198,
                "axAirtime": 2117500,
                "nonAxAirtime": 2304688,
                "nonAxATE": 20.653535749741398,
                "timestamp": "2020-05-05T04:05:00.000+0000"
            },
            {
                "modificationtime": 1588651800000,
                "axATE": 20.950363248957878,
                "nonAxBytes": 45883946,
                "axBytes": 44167472,
                "axAirtime": 2108196,
                "nonAxAirtime": 2221640,
                "nonAxATE": 20.653186834950755,
                "timestamp": "2020-05-05T04:10:00.000+0000"
            },
            {
                "modificationtime": 1588652100000,
                "axATE": 20.950033688995262,
                "nonAxBytes": 43445620,
                "axBytes": 41665008,
                "axAirtime": 1988780,
                "nonAxAirtime": 2103504,
                "nonAxATE": 20.653927922171768,
                "timestamp": "2020-05-05T04:15:00.000+0000"
            },
            {
                "modificationtime": 1588652400000,
                "axATE": 20.88227781547737,
                "nonAxBytes": 48521778,
                "axBytes": 45913196,
                "axAirtime": 2198668,
                "nonAxAirtime": 2352096,
                "nonAxATE": 20.62916564630015,
                "timestamp": "2020-05-05T04:20:00.000+0000"
            },
            {
                "modificationtime": 1588652700000,
                "axATE": 20.80852946927518,
                "nonAxBytes": 58450110,
                "axBytes": 56614432,
                "axAirtime": 2720732,
                "nonAxAirtime": 2830052,
                "nonAxATE": 20.65336962006352,
                "timestamp": "2020-05-05T04:25:00.000+0000"
            },
            {
                "modificationtime": 1588653000000,
                "axATE": 20.961434467055643,
                "nonAxBytes": 41902822,
                "axBytes": 40653696,
                "axAirtime": 1939452,
                "nonAxAirtime": 2029396,
                "nonAxATE": 20.647927757815626,
                "timestamp": "2020-05-05T04:30:00.000+0000"
            },
            {
                "modificationtime": 1588653300000,
                "axATE": 20.919793193414023,
                "nonAxBytes": 54631754,
                "axBytes": 33227828,
                "axAirtime": 1588344,
                "nonAxAirtime": 2644952,
                "nonAxATE": 20.655102247602226,
                "timestamp": "2020-05-05T04:35:00.000+0000"
            },
            {
                "modificationtime": 1588653600000,
                "axATE": 20.947569528547596,
                "nonAxBytes": 70981966,
                "axBytes": 30787816,
                "axAirtime": 1469756,
                "nonAxAirtime": 3436584,
                "nonAxATE": 20.654803141724457,
                "timestamp": "2020-05-05T04:40:00.000+0000"
            },
            {
                "modificationtime": 1588653900000,
                "axATE": 20.856617539203807,
                "nonAxBytes": 68868862,
                "axBytes": 36969022,
                "axAirtime": 1772532,
                "nonAxAirtime": 3334272,
                "nonAxATE": 20.65484219643748,
                "timestamp": "2020-05-05T04:45:00.000+0000"
            },
            {
                "modificationtime": 1588654200000,
                "axATE": 20.89624034656447,
                "nonAxBytes": 59034846,
                "axBytes": 37469968,
                "axAirtime": 1793144,
                "nonAxAirtime": 2858412,
                "nonAxATE": 20.653022027615332,
                "timestamp": "2020-05-05T04:50:00.000+0000"
            },
            {
                "modificationtime": 1588654500000,
                "axATE": 20.44210947792617,
                "nonAxBytes": 87043182,
                "axBytes": 50645408,
                "axAirtime": 2477504,
                "nonAxAirtime": 4214120,
                "nonAxATE": 20.655126574468692,
                "timestamp": "2020-05-05T04:55:00.000+0000"
            },
            {
                "modificationtime": 1588654800000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 121165508,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 5866384,
                "nonAxATE": 20.65420674814332,
                "timestamp": "2020-05-05T05:00:00.000+0000"
            },
            {
                "modificationtime": 1588655100000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 65309440,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 3161772,
                "nonAxATE": 20.655961277410263,
                "timestamp": "2020-05-05T05:05:00.000+0000"
            },
            {
                "modificationtime": 1588655400000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 62576302,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 3030648,
                "nonAxATE": 20.647829111133987,
                "timestamp": "2020-05-05T05:10:00.000+0000"
            },
            {
                "modificationtime": 1588655700000,
                "axATE": 21.078834190027024,
                "nonAxBytes": 40880720,
                "axBytes": 39777868,
                "axAirtime": 1887100,
                "nonAxAirtime": 1979800,
                "nonAxATE": 20.648914031720377,
                "timestamp": "2020-05-05T05:15:00.000+0000"
            },
            {
                "modificationtime": 1588656000000,
                "axATE": 20.75661986002136,
                "nonAxBytes": 49475078,
                "axBytes": 47035746,
                "axAirtime": 2266060,
                "nonAxAirtime": 2395812,
                "nonAxATE": 20.65065121971173,
                "timestamp": "2020-05-05T05:20:00.000+0000"
            },
            {
                "modificationtime": 1588656300000,
                "axATE": 20.88229261065039,
                "nonAxBytes": 43540470,
                "axBytes": 42675972,
                "axAirtime": 2043644,
                "nonAxAirtime": 2108412,
                "nonAxATE": 20.650835794901564,
                "timestamp": "2020-05-05T05:25:00.000+0000"
            },
            {
                "modificationtime": 1588656600000,
                "axATE": 20.928537854687935,
                "nonAxBytes": 47987646,
                "axBytes": 44525548,
                "axAirtime": 2127504,
                "nonAxAirtime": 2323448,
                "nonAxATE": 20.653634598235037,
                "timestamp": "2020-05-05T05:30:00.000+0000"
            },
            {
                "modificationtime": 1588656900000,
                "axATE": 20.93255182913178,
                "nonAxBytes": 49965830,
                "axBytes": 46748668,
                "axAirtime": 2233300,
                "nonAxAirtime": 2419136,
                "nonAxATE": 20.654411327019233,
                "timestamp": "2020-05-05T05:35:00.000+0000"
            },
            {
                "modificationtime": 1588657200000,
                "axATE": 20.92515390658191,
                "nonAxBytes": 45104994,
                "axBytes": 41766942,
                "axAirtime": 1996016,
                "nonAxAirtime": 2183848,
                "nonAxATE": 20.653907231638833,
                "timestamp": "2020-05-05T05:40:00.000+0000"
            },
            {
                "modificationtime": 1588657500000,
                "axATE": 20.938554165062012,
                "nonAxBytes": 46171512,
                "axBytes": 44800298,
                "axAirtime": 2139608,
                "nonAxAirtime": 2235808,
                "nonAxATE": 20.650928881191945,
                "timestamp": "2020-05-05T05:45:00.000+0000"
            },
            {
                "modificationtime": 1588657800000,
                "axATE": 20.9172726026842,
                "nonAxBytes": 44961414,
                "axBytes": 43346362,
                "axAirtime": 2072276,
                "nonAxAirtime": 2177680,
                "nonAxATE": 20.6464742294552,
                "timestamp": "2020-05-05T05:50:00.000+0000"
            },
            {
                "modificationtime": 1588658100000,
                "axATE": 20.9694056917006,
                "nonAxBytes": 42496180,
                "axBytes": 39939756,
                "axAirtime": 1904668,
                "nonAxAirtime": 2058076,
                "nonAxATE": 20.648498889253847,
                "timestamp": "2020-05-05T05:55:00.000+0000"
            },
            {
                "modificationtime": 1588658400000,
                "axATE": 20.926315189175604,
                "nonAxBytes": 56641702,
                "axBytes": 39630172,
                "axAirtime": 1893796,
                "nonAxAirtime": 2742600,
                "nonAxATE": 20.652556698023773,
                "timestamp": "2020-05-05T06:00:00.000+0000"
            },
            {
                "modificationtime": 1588658700000,
                "axATE": 20.848307393174544,
                "nonAxBytes": 48345474,
                "axBytes": 45463070,
                "axAirtime": 2180660,
                "nonAxAirtime": 2340692,
                "nonAxATE": 20.6543509355353,
                "timestamp": "2020-05-05T06:05:00.000+0000"
            },
            {
                "modificationtime": 1588659000000,
                "axATE": 20.880637625528284,
                "nonAxBytes": 46233940,
                "axBytes": 43082602,
                "axAirtime": 2063280,
                "nonAxAirtime": 2240304,
                "nonAxATE": 20.637351002363964,
                "timestamp": "2020-05-05T06:10:00.000+0000"
            },
            {
                "modificationtime": 1588659300000,
                "axATE": 20.784875079270748,
                "nonAxBytes": 67081276,
                "axBytes": 66730174,
                "axAirtime": 3210516,
                "nonAxAirtime": 3253548,
                "nonAxATE": 20.617884229770084,
                "timestamp": "2020-05-05T06:15:00.000+0000"
            },
            {
                "modificationtime": 1588659600000,
                "axATE": 21.001347182168637,
                "nonAxBytes": 44386956,
                "axBytes": 42558222,
                "axAirtime": 2026452,
                "nonAxAirtime": 2149632,
                "nonAxATE": 20.648630091103964,
                "timestamp": "2020-05-05T06:20:00.000+0000"
            },
            {
                "modificationtime": 1588659900000,
                "axATE": 20.945021847392624,
                "nonAxBytes": 46496742,
                "axBytes": 43908306,
                "axAirtime": 2096360,
                "nonAxAirtime": 2251704,
                "nonAxATE": 20.649580051374425,
                "timestamp": "2020-05-05T06:25:00.000+0000"
            },
            {
                "modificationtime": 1588660200000,
                "axATE": 20.892309919717174,
                "nonAxBytes": 46712674,
                "axBytes": 44156482,
                "axAirtime": 2113528,
                "nonAxAirtime": 2261668,
                "nonAxATE": 20.654080970328096,
                "timestamp": "2020-05-05T06:30:00.000+0000"
            },
            {
                "modificationtime": 1588660500000,
                "axATE": 20.888383487435476,
                "nonAxBytes": 49019004,
                "axBytes": 47134888,
                "axAirtime": 2256512,
                "nonAxAirtime": 2374744,
                "nonAxATE": 20.641805600940565,
                "timestamp": "2020-05-05T06:35:00.000+0000"
            },
            {
                "modificationtime": 1588660800000,
                "axATE": 20.92769262978415,
                "nonAxBytes": 48457408,
                "axBytes": 44382446,
                "axAirtime": 2120752,
                "nonAxAirtime": 2347272,
                "nonAxATE": 20.64413838702971,
                "timestamp": "2020-05-05T06:40:00.000+0000"
            },
            {
                "modificationtime": 1588661100000,
                "axATE": 20.781296928327645,
                "nonAxBytes": 41709704,
                "axBytes": 42013548,
                "axAirtime": 2021700,
                "nonAxAirtime": 2020044,
                "nonAxATE": 20.64791856018978,
                "timestamp": "2020-05-05T06:45:00.000+0000"
            },
            {
                "modificationtime": 1588661400000,
                "axATE": 20.87839874692325,
                "nonAxBytes": 49734166,
                "axBytes": 46652782,
                "axAirtime": 2234500,
                "nonAxAirtime": 2408768,
                "nonAxATE": 20.647138288120733,
                "timestamp": "2020-05-05T06:50:00.000+0000"
            },
            {
                "modificationtime": 1588661700000,
                "axATE": 21.04042470000093,
                "nonAxBytes": 46670284,
                "axBytes": 45201986,
                "axAirtime": 2148340,
                "nonAxAirtime": 2259592,
                "nonAxATE": 20.654296881914966,
                "timestamp": "2020-05-05T06:55:00.000+0000"
            },
            {
                "modificationtime": 1588662000000,
                "axATE": 20.747854839864107,
                "nonAxBytes": 53565724,
                "axBytes": 49858838,
                "axAirtime": 2403084,
                "nonAxAirtime": 2593384,
                "nonAxATE": 20.654759958417266,
                "timestamp": "2020-05-05T07:00:00.000+0000"
            },
            {
                "modificationtime": 1588662300000,
                "axATE": 21.002503268262437,
                "nonAxBytes": 48363324,
                "axBytes": 43762412,
                "axAirtime": 2083676,
                "nonAxAirtime": 2341784,
                "nonAxATE": 20.652341975177897,
                "timestamp": "2020-05-05T07:05:00.000+0000"
            },
            {
                "modificationtime": 1588662600000,
                "axATE": 20.8944608475222,
                "nonAxBytes": 50566150,
                "axBytes": 45630828,
                "axAirtime": 2183872,
                "nonAxAirtime": 2448488,
                "nonAxATE": 20.651990126151322,
                "timestamp": "2020-05-05T07:10:00.000+0000"
            },
            {
                "modificationtime": 1588662900000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 100612460,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 4870916,
                "nonAxATE": 20.655757561822046,
                "timestamp": "2020-05-05T07:15:00.000+0000"
            },
            {
                "modificationtime": 1588663200000,
                "axATE": 21.033592642846376,
                "nonAxBytes": 43869954,
                "axBytes": 41854746,
                "axAirtime": 1989900,
                "nonAxAirtime": 2124416,
                "nonAxATE": 20.65035943995903,
                "timestamp": "2020-05-05T07:20:00.000+0000"
            },
            {
                "modificationtime": 1588663500000,
                "axATE": 21.088258727581742,
                "nonAxBytes": 43249378,
                "axBytes": 42137462,
                "axAirtime": 1998148,
                "nonAxAirtime": 2094088,
                "nonAxATE": 20.65308525716207,
                "timestamp": "2020-05-05T07:25:00.000+0000"
            },
            {
                "modificationtime": 1588663800000,
                "axATE": 20.933743208306765,
                "nonAxBytes": 48178180,
                "axBytes": 45571168,
                "axAirtime": 2176924,
                "nonAxAirtime": 2332868,
                "nonAxATE": 20.65191000948189,
                "timestamp": "2020-05-05T07:30:00.000+0000"
            },
            {
                "modificationtime": 1588664100000,
                "axATE": 20.967898496428393,
                "nonAxBytes": 44142700,
                "axBytes": 41975636,
                "axAirtime": 2001900,
                "nonAxAirtime": 2137288,
                "nonAxATE": 20.653604006572817,
                "timestamp": "2020-05-05T07:35:00.000+0000"
            },
            {
                "modificationtime": 1588664400000,
                "axATE": 21.02887041221508,
                "nonAxBytes": 44393552,
                "axBytes": 42176596,
                "axAirtime": 2005652,
                "nonAxAirtime": 2149280,
                "nonAxATE": 20.655080771235017,
                "timestamp": "2020-05-05T07:40:00.000+0000"
            },
            {
                "modificationtime": 1588664700000,
                "axATE": 20.957469031858263,
                "nonAxBytes": 36876740,
                "axBytes": 38283344,
                "axAirtime": 1826716,
                "nonAxAirtime": 1785140,
                "nonAxATE": 20.657617889913396,
                "timestamp": "2020-05-05T07:45:00.000+0000"
            },
            {
                "modificationtime": 1588665000000,
                "axATE": 20.97900662385395,
                "nonAxBytes": 42549460,
                "axBytes": 41553538,
                "axAirtime": 1980720,
                "nonAxAirtime": 2060400,
                "nonAxATE": 20.65106775383421,
                "timestamp": "2020-05-05T07:50:00.000+0000"
            },
            {
                "modificationtime": 1588665300000,
                "axATE": 20.864792980058205,
                "nonAxBytes": 44651868,
                "axBytes": 43475218,
                "axAirtime": 2083664,
                "nonAxAirtime": 2162268,
                "nonAxATE": 20.65047810909656,
                "timestamp": "2020-05-05T07:55:00.000+0000"
            },
            {
                "modificationtime": 1588665600000,
                "axATE": 21.020963544120388,
                "nonAxBytes": 42766916,
                "axBytes": 40157692,
                "axAirtime": 1910364,
                "nonAxAirtime": 2070524,
                "nonAxATE": 20.655117255342127,
                "timestamp": "2020-05-05T08:00:00.000+0000"
            },
            {
                "modificationtime": 1588665900000,
                "axATE": 20.944099300501083,
                "nonAxBytes": 45860512,
                "axBytes": 43151798,
                "axAirtime": 2060332,
                "nonAxAirtime": 2220528,
                "nonAxATE": 20.652976229077048,
                "timestamp": "2020-05-05T08:05:00.000+0000"
            },
            {
                "modificationtime": 1588666200000,
                "axATE": 21.0914241213433,
                "nonAxBytes": 43730084,
                "axBytes": 41003922,
                "axAirtime": 1944104,
                "nonAxAirtime": 2118936,
                "nonAxATE": 20.637755930334848,
                "timestamp": "2020-05-05T08:10:00.000+0000"
            },
            {
                "modificationtime": 1588666500000,
                "axATE": 21.04012600252834,
                "nonAxBytes": 42413898,
                "axBytes": 40609968,
                "axAirtime": 1930120,
                "nonAxAirtime": 2054548,
                "nonAxATE": 20.64390707834521,
                "timestamp": "2020-05-05T08:15:00.000+0000"
            },
            {
                "modificationtime": 1588666800000,
                "axATE": 20.941324947553085,
                "nonAxBytes": 39429584,
                "axBytes": 39010254,
                "axAirtime": 1862836,
                "nonAxAirtime": 1909180,
                "nonAxATE": 20.652627829748898,
                "timestamp": "2020-05-05T08:20:00.000+0000"
            },
            {
                "modificationtime": 1588667100000,
                "axATE": 21.040723588740164,
                "nonAxBytes": 37533418,
                "axBytes": 37292326,
                "axAirtime": 1772388,
                "nonAxAirtime": 1817480,
                "nonAxATE": 20.651351321610143,
                "timestamp": "2020-05-05T08:25:00.000+0000"
            },
            {
                "modificationtime": 1588667400000,
                "axATE": 21.02871597616318,
                "nonAxBytes": 35601236,
                "axBytes": 35513632,
                "axAirtime": 1688816,
                "nonAxAirtime": 1724288,
                "nonAxATE": 20.646919772103036,
                "timestamp": "2020-05-05T08:30:00.000+0000"
            },
            {
                "modificationtime": 1588667700000,
                "axATE": 21.065594359865926,
                "nonAxBytes": 44577706,
                "axBytes": 43038526,
                "axAirtime": 2043072,
                "nonAxAirtime": 2158336,
                "nonAxATE": 20.653737879551656,
                "timestamp": "2020-05-05T08:35:00.000+0000"
            },
            {
                "modificationtime": 1588668000000,
                "axATE": 20.951989732958193,
                "nonAxBytes": 45299906,
                "axBytes": 42446636,
                "axAirtime": 2025900,
                "nonAxAirtime": 2193416,
                "nonAxATE": 20.652674184924336,
                "timestamp": "2020-05-05T08:40:00.000+0000"
            },
            {
                "modificationtime": 1588668300000,
                "axATE": 21.09320977533173,
                "nonAxBytes": 44607304,
                "axBytes": 42710628,
                "axAirtime": 2024852,
                "nonAxAirtime": 2159748,
                "nonAxATE": 20.65393925587615,
                "timestamp": "2020-05-05T08:45:00.000+0000"
            },
            {
                "modificationtime": 1588668600000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 145490678,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 7043180,
                "nonAxATE": 20.656958646520465,
                "timestamp": "2020-05-05T08:50:00.000+0000"
            },
            {
                "modificationtime": 1588668900000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 126023668,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 6101328,
                "nonAxATE": 20.655120983497362,
                "timestamp": "2020-05-05T08:55:00.000+0000"
            },
            {
                "modificationtime": 1588669200000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 117068880,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 5669576,
                "nonAxATE": 20.648612876871216,
                "timestamp": "2020-05-05T09:00:00.000+0000"
            },
            {
                "modificationtime": 1588669500000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 56512986,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 2736208,
                "nonAxATE": 20.65376097138814,
                "timestamp": "2020-05-05T09:05:00.000+0000"
            },
            {
                "modificationtime": 1588669800000,
                "axATE": 20.99740137506494,
                "nonAxBytes": 38753502,
                "axBytes": 38639502,
                "axAirtime": 1840204,
                "nonAxAirtime": 1876728,
                "nonAxATE": 20.649503817281992,
                "timestamp": "2020-05-05T09:10:00.000+0000"
            },
            {
                "modificationtime": 1588670100000,
                "axATE": 21.06698248345301,
                "nonAxBytes": 38038308,
                "axBytes": 36590652,
                "axAirtime": 1736872,
                "nonAxAirtime": 1841584,
                "nonAxATE": 20.655212034856948,
                "timestamp": "2020-05-05T09:15:00.000+0000"
            },
            {
                "modificationtime": 1588670400000,
                "axATE": 21.042405251389738,
                "nonAxBytes": 45793234,
                "axBytes": 42198692,
                "axAirtime": 2005412,
                "nonAxAirtime": 2217396,
                "nonAxATE": 20.65180689421285,
                "timestamp": "2020-05-05T09:20:00.000+0000"
            },
            {
                "modificationtime": 1588670700000,
                "axATE": 20.91910242907048,
                "nonAxBytes": 47559832,
                "axBytes": 43139122,
                "axAirtime": 2062188,
                "nonAxAirtime": 2308756,
                "nonAxATE": 20.599765414794806,
                "timestamp": "2020-05-05T09:25:00.000+0000"
            },
            {
                "modificationtime": 1588671000000,
                "axATE": 21.011031959099146,
                "nonAxBytes": 47548092,
                "axBytes": 43545868,
                "axAirtime": 2072524,
                "nonAxAirtime": 2302212,
                "nonAxATE": 20.65322046796733,
                "timestamp": "2020-05-05T09:30:00.000+0000"
            },
            {
                "modificationtime": 1588671300000,
                "axATE": 20.94314092312644,
                "nonAxBytes": 58214842,
                "axBytes": 41644598,
                "axAirtime": 1988460,
                "nonAxAirtime": 2821240,
                "nonAxATE": 20.634487672087452,
                "timestamp": "2020-05-05T09:35:00.000+0000"
            },
            {
                "modificationtime": 1588671600000,
                "axATE": 20.949569197303997,
                "nonAxBytes": 42743800,
                "axBytes": 41451488,
                "axAirtime": 1978632,
                "nonAxAirtime": 2069732,
                "nonAxATE": 20.651852510373324,
                "timestamp": "2020-05-05T09:40:00.000+0000"
            },
            {
                "modificationtime": 1588671900000,
                "axATE": 20.912426222181328,
                "nonAxBytes": 47670490,
                "axBytes": 44544388,
                "axAirtime": 2130044,
                "nonAxAirtime": 2308968,
                "nonAxATE": 20.64579933546069,
                "timestamp": "2020-05-05T09:45:00.000+0000"
            },
            {
                "modificationtime": 1588672200000,
                "axATE": 20.933205231700985,
                "nonAxBytes": 47964080,
                "axBytes": 44186312,
                "axAirtime": 2110824,
                "nonAxAirtime": 2322288,
                "nonAxATE": 20.653803490350896,
                "timestamp": "2020-05-05T09:50:00.000+0000"
            },
            {
                "modificationtime": 1588672500000,
                "axATE": 20.951557053486923,
                "nonAxBytes": 46794778,
                "axBytes": 43564824,
                "axAirtime": 2079312,
                "nonAxAirtime": 2265844,
                "nonAxATE": 20.65225055211215,
                "timestamp": "2020-05-05T09:55:00.000+0000"
            },
            {
                "modificationtime": 1588672800000,
                "axATE": 20.863901821199285,
                "nonAxBytes": 63725140,
                "axBytes": 47772076,
                "axAirtime": 2289700,
                "nonAxAirtime": 3085208,
                "nonAxATE": 20.655054699715546,
                "timestamp": "2020-05-05T10:00:00.000+0000"
            },
            {
                "modificationtime": 1588673100000,
                "axATE": 20.946878212298607,
                "nonAxBytes": 48408622,
                "axBytes": 46216438,
                "axAirtime": 2206364,
                "nonAxAirtime": 2345380,
                "nonAxATE": 20.63999096095302,
                "timestamp": "2020-05-05T10:05:00.000+0000"
            },
            {
                "modificationtime": 1588673400000,
                "axATE": 20.95819076741199,
                "nonAxBytes": 46403826,
                "axBytes": 44249112,
                "axAirtime": 2111304,
                "nonAxAirtime": 2247072,
                "nonAxATE": 20.650796236168667,
                "timestamp": "2020-05-05T10:10:00.000+0000"
            },
            {
                "modificationtime": 1588673700000,
                "axATE": 20.814830410716866,
                "nonAxBytes": 48005094,
                "axBytes": 44388958,
                "axAirtime": 2132564,
                "nonAxAirtime": 2324344,
                "nonAxATE": 20.6531795637823,
                "timestamp": "2020-05-05T10:15:00.000+0000"
            },
            {
                "modificationtime": 1588674000000,
                "axATE": 21.076232791309398,
                "nonAxBytes": 41036104,
                "axBytes": 39485732,
                "axAirtime": 1873472,
                "nonAxAirtime": 1992888,
                "nonAxATE": 20.59127457237938,
                "timestamp": "2020-05-05T10:20:00.000+0000"
            },
            {
                "modificationtime": 1588674300000,
                "axATE": 20.939608559205283,
                "nonAxBytes": 49666076,
                "axBytes": 46145788,
                "axAirtime": 2203756,
                "nonAxAirtime": 2404748,
                "nonAxATE": 20.653339144060002,
                "timestamp": "2020-05-05T10:25:00.000+0000"
            },
            {
                "modificationtime": 1588674600000,
                "axATE": 20.834940080735542,
                "nonAxBytes": 51656952,
                "axBytes": 47442492,
                "axAirtime": 2277064,
                "nonAxAirtime": 2501292,
                "nonAxATE": 20.65210779069377,
                "timestamp": "2020-05-05T10:30:00.000+0000"
            },
            {
                "modificationtime": 1588674900000,
                "axATE": 20.85756307808469,
                "nonAxBytes": 54907084,
                "axBytes": 50386366,
                "axAirtime": 2415736,
                "nonAxAirtime": 2662292,
                "nonAxATE": 20.623990155850674,
                "timestamp": "2020-05-05T10:35:00.000+0000"
            },
            {
                "modificationtime": 1588675200000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 130805362,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 6332544,
                "nonAxATE": 20.656052606977543,
                "timestamp": "2020-05-05T10:40:00.000+0000"
            },
            {
                "modificationtime": 1588675500000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 138761890,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 6717576,
                "nonAxATE": 20.65654188356038,
                "timestamp": "2020-05-05T10:45:00.000+0000"
            },
            {
                "modificationtime": 1588675800000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 139039896,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 6731116,
                "nonAxATE": 20.656291764991124,
                "timestamp": "2020-05-05T10:50:00.000+0000"
            },
            {
                "modificationtime": 1588676100000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 119404316,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 5780796,
                "nonAxATE": 20.65534158271629,
                "timestamp": "2020-05-05T10:55:00.000+0000"
            },
            {
                "modificationtime": 1588676400000,
                "axATE": 20.96131051640925,
                "nonAxBytes": 45945920,
                "axBytes": 43743572,
                "axAirtime": 2086872,
                "nonAxAirtime": 2225160,
                "nonAxATE": 20.648366859012384,
                "timestamp": "2020-05-05T11:00:00.000+0000"
            },
            {
                "modificationtime": 1588676700000,
                "axATE": 21.048737036510868,
                "nonAxBytes": 45921774,
                "axBytes": 44448618,
                "axAirtime": 2111700,
                "nonAxAirtime": 2232776,
                "nonAxATE": 20.567120929282652,
                "timestamp": "2020-05-05T11:05:00.000+0000"
            },
            {
                "modificationtime": 1588677000000,
                "axATE": 20.896084913836688,
                "nonAxBytes": 48000848,
                "axBytes": 46291682,
                "axAirtime": 2215328,
                "nonAxAirtime": 2324444,
                "nonAxATE": 20.650464369113646,
                "timestamp": "2020-05-05T11:10:00.000+0000"
            },
            {
                "modificationtime": 1588677300000,
                "axATE": 20.901141655505803,
                "nonAxBytes": 50097710,
                "axBytes": 47629438,
                "axAirtime": 2278796,
                "nonAxAirtime": 2431360,
                "nonAxATE": 20.60480965385628,
                "timestamp": "2020-05-05T11:15:00.000+0000"
            },
            {
                "modificationtime": 1588677600000,
                "axATE": 20.61051197397308,
                "nonAxBytes": 76415620,
                "axBytes": 73639628,
                "axAirtime": 3572916,
                "nonAxAirtime": 3721272,
                "nonAxATE": 20.53481175254053,
                "timestamp": "2020-05-05T11:20:00.000+0000"
            },
            {
                "modificationtime": 1588677900000,
                "axATE": 20.693287544634117,
                "nonAxBytes": 64169620,
                "axBytes": 54544940,
                "axAirtime": 2635876,
                "nonAxAirtime": 3106752,
                "nonAxATE": 20.654889736934265,
                "timestamp": "2020-05-05T11:25:00.000+0000"
            },
            {
                "modificationtime": 1588678200000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 124482988,
                "axBytes": 232,
                "axAirtime": 104,
                "nonAxAirtime": 6026612,
                "nonAxATE": 20.655550415390938,
                "timestamp": "2020-05-05T11:30:00.000+0000"
            },
            {
                "modificationtime": 1588678500000,
                "axATE": 20.940533125862174,
                "nonAxBytes": 45004514,
                "axBytes": 43172092,
                "axAirtime": 2061652,
                "nonAxAirtime": 2178964,
                "nonAxATE": 20.654087905995695,
                "timestamp": "2020-05-05T11:35:00.000+0000"
            },
            {
                "modificationtime": 1588678800000,
                "axATE": 20.894736253336404,
                "nonAxBytes": 55098624,
                "axBytes": 37356696,
                "axAirtime": 1787852,
                "nonAxAirtime": 2667788,
                "nonAxATE": 20.653299287649542,
                "timestamp": "2020-05-05T11:40:00.000+0000"
            },
            {
                "modificationtime": 1588679100000,
                "axATE": 20.97792548073897,
                "nonAxBytes": 50039736,
                "axBytes": 46660864,
                "axAirtime": 2224284,
                "nonAxAirtime": 2422820,
                "nonAxATE": 20.653509546726543,
                "timestamp": "2020-05-05T11:45:00.000+0000"
            },
            {
                "modificationtime": 1588679400000,
                "axATE": 20.84766020844819,
                "nonAxBytes": 65741190,
                "axBytes": 63528658,
                "axAirtime": 3047280,
                "nonAxAirtime": 3183320,
                "nonAxATE": 20.65176922207004,
                "timestamp": "2020-05-05T11:50:00.000+0000"
            },
            {
                "modificationtime": 1588679700000,
                "axATE": 20.82594797369215,
                "nonAxBytes": 74068354,
                "axBytes": 72082188,
                "axAirtime": 3461172,
                "nonAxAirtime": 3588052,
                "nonAxATE": 20.643054782929568,
                "timestamp": "2020-05-05T11:55:00.000+0000"
            },
            {
                "modificationtime": 1588680000000,
                "axATE": 20.842889103351464,
                "nonAxBytes": 68815018,
                "axBytes": 64618292,
                "axAirtime": 3100256,
                "nonAxAirtime": 3334228,
                "nonAxATE": 20.638965901552023,
                "timestamp": "2020-05-05T12:00:00.000+0000"
            },
            {
                "modificationtime": 1588680300000,
                "axATE": 20.78258618419035,
                "nonAxBytes": 47304750,
                "axBytes": 46079732,
                "axAirtime": 2217228,
                "nonAxAirtime": 2298808,
                "nonAxATE": 20.57794735358499,
                "timestamp": "2020-05-05T12:05:00.000+0000"
            },
            {
                "modificationtime": 1588680600000,
                "axATE": 20.895681808552705,
                "nonAxBytes": 49148828,
                "axBytes": 47346606,
                "axAirtime": 2265856,
                "nonAxAirtime": 2380260,
                "nonAxATE": 20.64851234739062,
                "timestamp": "2020-05-05T12:10:00.000+0000"
            },
            {
                "modificationtime": 1588680900000,
                "axATE": 20.90021962223593,
                "nonAxBytes": 52701428,
                "axBytes": 48381584,
                "axAirtime": 2314884,
                "nonAxAirtime": 2552000,
                "nonAxATE": 20.651029780564265,
                "timestamp": "2020-05-05T12:15:00.000+0000"
            },
            {
                "modificationtime": 1588681200000,
                "axATE": 21.026317192996853,
                "nonAxBytes": 44901242,
                "axBytes": 41000898,
                "axAirtime": 1949980,
                "nonAxAirtime": 2174044,
                "nonAxATE": 20.653327163571667,
                "timestamp": "2020-05-05T12:20:00.000+0000"
            },
            {
                "modificationtime": 1588681500000,
                "axATE": 21.05242086544161,
                "nonAxBytes": 38165942,
                "axBytes": 35515434,
                "axAirtime": 1687000,
                "nonAxAirtime": 1847964,
                "nonAxATE": 20.65296834786825,
                "timestamp": "2020-05-05T12:25:00.000+0000"
            },
            {
                "modificationtime": 1588681800000,
                "axATE": 21.00922228251731,
                "nonAxBytes": 46914330,
                "axBytes": 43980874,
                "axAirtime": 2093408,
                "nonAxAirtime": 2277572,
                "nonAxATE": 20.598396011190864,
                "timestamp": "2020-05-05T12:30:00.000+0000"
            },
            {
                "modificationtime": 1588682100000,
                "axATE": 20.91281505906747,
                "nonAxBytes": 42464618,
                "axBytes": 40666140,
                "axAirtime": 1944556,
                "nonAxAirtime": 2062524,
                "nonAxATE": 20.58866611976394,
                "timestamp": "2020-05-05T12:35:00.000+0000"
            },
            {
                "modificationtime": 1588682400000,
                "axATE": 21.029327658749484,
                "nonAxBytes": 37167848,
                "axBytes": 36446096,
                "axAirtime": 1733108,
                "nonAxAirtime": 1799988,
                "nonAxATE": 20.64894210405847,
                "timestamp": "2020-05-05T12:40:00.000+0000"
            },
            {
                "modificationtime": 1588682700000,
                "axATE": 21.006914403312194,
                "nonAxBytes": 39340736,
                "axBytes": 38013188,
                "axAirtime": 1809556,
                "nonAxAirtime": 1905216,
                "nonAxATE": 20.648963687057005,
                "timestamp": "2020-05-05T12:45:00.000+0000"
            },
            {
                "modificationtime": 1588683000000,
                "axATE": 20.98722470688029,
                "nonAxBytes": 47322066,
                "axBytes": 40675676,
                "axAirtime": 1938116,
                "nonAxAirtime": 2292300,
                "nonAxATE": 20.643923570213325,
                "timestamp": "2020-05-05T12:50:00.000+0000"
            },
            {
                "modificationtime": 1588683300000,
                "axATE": 20.84378264289413,
                "nonAxBytes": 48983010,
                "axBytes": 46533578,
                "axAirtime": 2232492,
                "nonAxAirtime": 2376744,
                "nonAxATE": 20.6092915349739,
                "timestamp": "2020-05-05T12:55:00.000+0000"
            },
            {
                "modificationtime": 1588683600000,
                "axATE": 20.988764783180027,
                "nonAxBytes": 44464318,
                "axBytes": 42806166,
                "axAirtime": 2039480,
                "nonAxAirtime": 2155504,
                "nonAxATE": 20.628269768926433,
                "timestamp": "2020-05-05T13:00:00.000+0000"
            },
            {
                "modificationtime": 1588683900000,
                "axATE": 20.856090443087748,
                "nonAxBytes": 54661524,
                "axBytes": 52827476,
                "axAirtime": 2532952,
                "nonAxAirtime": 2647908,
                "nonAxATE": 20.643286700293213,
                "timestamp": "2020-05-05T13:05:00.000+0000"
            },
            {
                "modificationtime": 1588684200000,
                "axATE": 20.971537746546232,
                "nonAxBytes": 44812302,
                "axBytes": 43500338,
                "axAirtime": 2074256,
                "nonAxAirtime": 2170100,
                "nonAxATE": 20.64987880742823,
                "timestamp": "2020-05-05T13:10:00.000+0000"
            },
            {
                "modificationtime": 1588684500000,
                "axATE": 20.895579127917685,
                "nonAxBytes": 45650156,
                "axBytes": 44928922,
                "axAirtime": 2150164,
                "nonAxAirtime": 2210488,
                "nonAxATE": 20.651619009015203,
                "timestamp": "2020-05-05T13:15:00.000+0000"
            },
            {
                "modificationtime": 1588684800000,
                "axATE": 20.914930259171364,
                "nonAxBytes": 46005178,
                "axBytes": 44594396,
                "axAirtime": 2132180,
                "nonAxAirtime": 2227904,
                "nonAxATE": 20.64953337307173,
                "timestamp": "2020-05-05T13:20:00.000+0000"
            },
            {
                "modificationtime": 1588685100000,
                "axATE": 20.92893926513649,
                "nonAxBytes": 46903952,
                "axBytes": 44314936,
                "axAirtime": 2117400,
                "nonAxAirtime": 2271616,
                "nonAxATE": 20.64783484532597,
                "timestamp": "2020-05-05T13:25:00.000+0000"
            },
            {
                "modificationtime": 1588685400000,
                "axATE": 20.959476886112665,
                "nonAxBytes": 37265428,
                "axBytes": 37056942,
                "axAirtime": 1768028,
                "nonAxAirtime": 1804812,
                "nonAxATE": 20.647817057953958,
                "timestamp": "2020-05-05T13:30:00.000+0000"
            },
            {
                "modificationtime": 1588685700000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 58987468,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 2855992,
                "nonAxATE": 20.653933204294688,
                "timestamp": "2020-05-05T13:35:00.000+0000"
            },
            {
                "modificationtime": 1588686000000,
                "axATE": 20.933868248759143,
                "nonAxBytes": 46265108,
                "axBytes": 41029042,
                "axAirtime": 1959936,
                "nonAxAirtime": 2239952,
                "nonAxATE": 20.654508668042887,
                "timestamp": "2020-05-05T13:40:00.000+0000"
            },
            {
                "modificationtime": 1588686300000,
                "axATE": 20.82962417277925,
                "nonAxBytes": 49656540,
                "axBytes": 47389228,
                "axAirtime": 2275088,
                "nonAxAirtime": 2405048,
                "nonAxATE": 20.64679790174666,
                "timestamp": "2020-05-05T13:45:00.000+0000"
            },
            {
                "modificationtime": 1588686600000,
                "axATE": 21.046166442387197,
                "nonAxBytes": 45182156,
                "axBytes": 41539408,
                "axAirtime": 1973728,
                "nonAxAirtime": 2187648,
                "nonAxATE": 20.65330254227371,
                "timestamp": "2020-05-05T13:50:00.000+0000"
            },
            {
                "modificationtime": 1588686900000,
                "axATE": 21.051264362789578,
                "nonAxBytes": 54495512,
                "axBytes": 38510762,
                "axAirtime": 1829380,
                "nonAxAirtime": 2639320,
                "nonAxATE": 20.647557704257157,
                "timestamp": "2020-05-05T13:55:00.000+0000"
            },
            {
                "modificationtime": 1588687200000,
                "axATE": 20.949855561642995,
                "nonAxBytes": 48739776,
                "axBytes": 43745142,
                "axAirtime": 2088088,
                "nonAxAirtime": 2359880,
                "nonAxATE": 20.65349763547299,
                "timestamp": "2020-05-05T14:00:00.000+0000"
            },
            {
                "modificationtime": 1588687500000,
                "axATE": 20.834221641843357,
                "nonAxBytes": 65078882,
                "axBytes": 32100452,
                "axAirtime": 1540756,
                "nonAxAirtime": 3150876,
                "nonAxATE": 20.654218699815544,
                "timestamp": "2020-05-05T14:05:00.000+0000"
            },
            {
                "modificationtime": 1588687800000,
                "axATE": 20.617340869238326,
                "nonAxBytes": 71227212,
                "axBytes": 69323582,
                "axAirtime": 3362392,
                "nonAxAirtime": 3451744,
                "nonAxATE": 20.635137484123966,
                "timestamp": "2020-05-05T14:10:00.000+0000"
            },
            {
                "modificationtime": 1588688100000,
                "axATE": 20.780853655568396,
                "nonAxBytes": 73707834,
                "axBytes": 71719464,
                "axAirtime": 3451228,
                "nonAxAirtime": 3582676,
                "nonAxATE": 20.573402116183544,
                "timestamp": "2020-05-05T14:15:00.000+0000"
            },
            {
                "modificationtime": 1588688400000,
                "axATE": 20.833535745347703,
                "nonAxBytes": 67875168,
                "axBytes": 63334032,
                "axAirtime": 3040004,
                "nonAxAirtime": 3307796,
                "nonAxATE": 20.519756357405353,
                "timestamp": "2020-05-05T14:20:00.000+0000"
            },
            {
                "modificationtime": 1588688700000,
                "axATE": 20.87404237491022,
                "nonAxBytes": 47457318,
                "axBytes": 44641728,
                "axAirtime": 2138624,
                "nonAxAirtime": 2297800,
                "nonAxATE": 20.65337192096788,
                "timestamp": "2020-05-05T14:25:00.000+0000"
            },
            {
                "modificationtime": 1588689000000,
                "axATE": 20.92363733579914,
                "nonAxBytes": 54119934,
                "axBytes": 47428362,
                "axAirtime": 2266736,
                "nonAxAirtime": 2620192,
                "nonAxATE": 20.654949713608772,
                "timestamp": "2020-05-05T14:30:00.000+0000"
            },
            {
                "modificationtime": 1588689300000,
                "axATE": 20.967888120543417,
                "nonAxBytes": 46668830,
                "axBytes": 42826576,
                "axAirtime": 2042484,
                "nonAxAirtime": 2260048,
                "nonAxATE": 20.649486205602713,
                "timestamp": "2020-05-05T14:35:00.000+0000"
            },
            {
                "modificationtime": 1588689600000,
                "axATE": 20.960022488157332,
                "nonAxBytes": 35955352,
                "axBytes": 35716046,
                "axAirtime": 1704008,
                "nonAxAirtime": 1741100,
                "nonAxATE": 20.650940210211935,
                "timestamp": "2020-05-05T14:40:00.000+0000"
            },
            {
                "modificationtime": 1588689900000,
                "axATE": 20.951000176984273,
                "nonAxBytes": 39078260,
                "axBytes": 38827902,
                "axAirtime": 1853272,
                "nonAxAirtime": 1892388,
                "nonAxATE": 20.65023663223398,
                "timestamp": "2020-05-05T14:45:00.000+0000"
            },
            {
                "modificationtime": 1588690200000,
                "axATE": 20.986265193110892,
                "nonAxBytes": 36002924,
                "axBytes": 35775706,
                "axAirtime": 1704720,
                "nonAxAirtime": 1743628,
                "nonAxATE": 20.64828277591321,
                "timestamp": "2020-05-05T14:50:00.000+0000"
            },
            {
                "modificationtime": 1588690500000,
                "axATE": 21.003876948657584,
                "nonAxBytes": 41799720,
                "axBytes": 40220492,
                "axAirtime": 1914908,
                "nonAxAirtime": 2025616,
                "nonAxATE": 20.63555975071287,
                "timestamp": "2020-05-05T14:55:00.000+0000"
            },
            {
                "modificationtime": 1588690800000,
                "axATE": 20.71722199357251,
                "nonAxBytes": 48146626,
                "axBytes": 47316892,
                "axAirtime": 2283940,
                "nonAxAirtime": 2331784,
                "nonAxATE": 20.647978543467147,
                "timestamp": "2020-05-05T15:00:00.000+0000"
            },
            {
                "modificationtime": 1588691100000,
                "axATE": 20.80149962918048,
                "nonAxBytes": 44480482,
                "axBytes": 42745168,
                "axAirtime": 2054908,
                "nonAxAirtime": 2153740,
                "nonAxATE": 20.652670238747483,
                "timestamp": "2020-05-05T15:05:00.000+0000"
            },
            {
                "modificationtime": 1588691400000,
                "axATE": 2.230769230769231,
                "nonAxBytes": 107398194,
                "axBytes": 116,
                "axAirtime": 52,
                "nonAxAirtime": 5199572,
                "nonAxATE": 20.65519892791176,
                "timestamp": "2020-05-05T15:10:00.000+0000"
            },
            {
                "modificationtime": 1588691700000,
                "axATE": 20.932055613564266,
                "nonAxBytes": 48604640,
                "axBytes": 46268132,
                "axAirtime": 2210396,
                "nonAxAirtime": 2353216,
                "nonAxATE": 20.65455954744486,
                "timestamp": "2020-05-05T15:15:00.000+0000"
            },
            {
                "modificationtime": 1588692000000,
                "axATE": 20.984572317513216,
                "nonAxBytes": 45068126,
                "axBytes": 41708852,
                "axAirtime": 1987596,
                "nonAxAirtime": 2182380,
                "nonAxATE": 20.650906808163565,
                "timestamp": "2020-05-05T15:20:00.000+0000"
            },
            {
                "modificationtime": 1588692300000,
                "axATE": 21.010469751248802,
                "nonAxBytes": 36237866,
                "axBytes": 34978146,
                "axAirtime": 1664796,
                "nonAxAirtime": 1754572,
                "nonAxATE": 20.65339353414964,
                "timestamp": "2020-05-05T15:25:00.000+0000"
            },
            {
                "modificationtime": 1588692600000,
                "axATE": 20.954582381464146,
                "nonAxBytes": 44001516,
                "axBytes": 42817504,
                "axAirtime": 2043348,
                "nonAxAirtime": 2130500,
                "nonAxATE": 20.653140577329264,
                "timestamp": "2020-05-05T15:30:00.000+0000"
            },
            {
                "modificationtime": 1588692900000,
                "axATE": 20.919153561079977,
                "nonAxBytes": 43228960,
                "axBytes": 41612966,
                "axAirtime": 1989228,
                "nonAxAirtime": 2093004,
                "nonAxATE": 20.65402646148789,
                "timestamp": "2020-05-05T15:35:00.000+0000"
            },
            {
                "modificationtime": 1588693200000,
                "axATE": 20.975624372084493,
                "nonAxBytes": 41557142,
                "axBytes": 40253230,
                "axAirtime": 1919048,
                "nonAxAirtime": 2012108,
                "nonAxATE": 20.653534502124142,
                "timestamp": "2020-05-05T15:40:00.000+0000"
            },
            {
                "modificationtime": 1588693500000,
                "axATE": 20.8966795269525,
                "nonAxBytes": 53248994,
                "axBytes": 51758484,
                "axAirtime": 2476876,
                "nonAxAirtime": 2578248,
                "nonAxATE": 20.65316990452431,
                "timestamp": "2020-05-05T15:45:00.000+0000"
            },
            {
                "modificationtime": 1588693800000,
                "axATE": 20.814961448947486,
                "nonAxBytes": 46125262,
                "axBytes": 44825186,
                "axAirtime": 2153508,
                "nonAxAirtime": 2233132,
                "nonAxATE": 20.654964417687804,
                "timestamp": "2020-05-05T15:50:00.000+0000"
            },
            {
                "modificationtime": 1588694100000,
                "axATE": 20.935503834360436,
                "nonAxBytes": 42897574,
                "axBytes": 41812472,
                "axAirtime": 1997204,
                "nonAxAirtime": 2076964,
                "nonAxATE": 20.653980521568982,
                "timestamp": "2020-05-05T15:55:00.000+0000"
            },
            {
                "modificationtime": 1588694400000,
                "axATE": 21.020626770950138,
                "nonAxBytes": 37964402,
                "axBytes": 36143202,
                "axAirtime": 1719416,
                "nonAxAirtime": 1837980,
                "nonAxATE": 20.65550332430168,
                "timestamp": "2020-05-05T16:00:00.000+0000"
            },
            {
                "modificationtime": 1588694700000,
                "axATE": 20.881224076406607,
                "nonAxBytes": 40297770,
                "axBytes": 38925358,
                "axAirtime": 1864132,
                "nonAxAirtime": 1951024,
                "nonAxATE": 20.654676723607704,
                "timestamp": "2020-05-05T16:05:00.000+0000"
            },
            {
                "modificationtime": 1588695000000,
                "axATE": 20.069378104596428,
                "nonAxBytes": 58877854,
                "axBytes": 18504368,
                "axAirtime": 922020,
                "nonAxAirtime": 2850828,
                "nonAxATE": 20.65289593058578,
                "timestamp": "2020-05-05T16:10:00.000+0000"
            },
            {
                "modificationtime": 1588695300000,
                "axATE": 21.062099664674943,
                "nonAxBytes": 37558454,
                "axBytes": 37209232,
                "axAirtime": 1766644,
                "nonAxAirtime": 1818848,
                "nonAxATE": 20.649583692535057,
                "timestamp": "2020-05-05T16:15:00.000+0000"
            },
            {
                "modificationtime": 1588695600000,
                "axATE": 21.045818869221517,
                "nonAxBytes": 37215150,
                "axBytes": 36739686,
                "axAirtime": 1745700,
                "nonAxAirtime": 1802472,
                "nonAxATE": 20.64672849286979,
                "timestamp": "2020-05-05T16:20:00.000+0000"
            },
            {
                "modificationtime": 1588695900000,
                "axATE": 21.033053826469626,
                "nonAxBytes": 38274272,
                "axBytes": 38006560,
                "axAirtime": 1806992,
                "nonAxAirtime": 1853508,
                "nonAxATE": 20.64963949440736,
                "timestamp": "2020-05-05T16:25:00.000+0000"
            },
            {
                "modificationtime": 1588696200000,
                "axATE": 21.053579688481406,
                "nonAxBytes": 35237312,
                "axBytes": 34592042,
                "axAirtime": 1643048,
                "nonAxAirtime": 1705964,
                "nonAxATE": 20.655366701759238,
                "timestamp": "2020-05-05T16:30:00.000+0000"
            },
            {
                "modificationtime": 1588696500000,
                "axATE": 21.035152453873728,
                "nonAxBytes": 37955678,
                "axBytes": 36450806,
                "axAirtime": 1732852,
                "nonAxAirtime": 1837808,
                "nonAxATE": 20.65268950837084,
                "timestamp": "2020-05-05T16:35:00.000+0000"
            },
            {
                "modificationtime": 1588696800000,
                "axATE": 21.016382955765764,
                "nonAxBytes": 40172194,
                "axBytes": 32722172,
                "axAirtime": 1556984,
                "nonAxAirtime": 1945120,
                "nonAxATE": 20.65281010940199,
                "timestamp": "2020-05-05T16:40:00.000+0000"
            },
            {
                "modificationtime": 1588697100000,
                "axATE": 20.954175063340887,
                "nonAxBytes": 55024680,
                "axBytes": 40094892,
                "axAirtime": 1913456,
                "nonAxAirtime": 2664696,
                "nonAxATE": 20.649514991578776,
                "timestamp": "2020-05-05T16:45:00.000+0000"
            },
            {
                "modificationtime": 1588697400000,
                "axATE": 21.041159602327262,
                "nonAxBytes": 41228664,
                "axBytes": 42616428,
                "axAirtime": 2025384,
                "nonAxAirtime": 1996108,
                "nonAxATE": 20.654525707025872,
                "timestamp": "2020-05-05T16:50:00.000+0000"
            },
            {
                "modificationtime": 1588697700000,
                "axATE": 21.06690814499317,
                "nonAxBytes": 45562212,
                "axBytes": 41271000,
                "axAirtime": 1959044,
                "nonAxAirtime": 2207420,
                "nonAxATE": 20.640481648259055,
                "timestamp": "2020-05-05T16:55:00.000+0000"
            },
            {
                "modificationtime": 1588698000000,
                "axATE": 20.974185010769176,
                "nonAxBytes": 49099654,
                "axBytes": 45963668,
                "axAirtime": 2191440,
                "nonAxAirtime": 2381560,
                "nonAxATE": 20.616593325383363,
                "timestamp": "2020-05-05T17:00:00.000+0000"
            },
            {
                "modificationtime": 1588698300000,
                "axATE": 21.000077184316147,
                "nonAxBytes": 47323868,
                "axBytes": 44076474,
                "axAirtime": 2098872,
                "nonAxAirtime": 2291452,
                "nonAxATE": 20.652349689192704,
                "timestamp": "2020-05-05T17:05:00.000+0000"
            },
            {
                "modificationtime": 1588698600000,
                "axATE": 21.096064348612824,
                "nonAxBytes": 42392382,
                "axBytes": 40851516,
                "axAirtime": 1936452,
                "nonAxAirtime": 2056640,
                "nonAxATE": 20.612446514703596,
                "timestamp": "2020-05-05T17:10:00.000+0000"
            },
            {
                "modificationtime": 1588698900000,
                "axATE": 20.893260076242804,
                "nonAxBytes": 39506824,
                "axBytes": 39000780,
                "axAirtime": 1866668,
                "nonAxAirtime": 1913184,
                "nonAxATE": 20.64977754361316,
                "timestamp": "2020-05-05T17:15:00.000+0000"
            },
            {
                "modificationtime": 1588699200000,
                "axATE": 20.899322584470475,
                "nonAxBytes": 39990368,
                "axBytes": 39477650,
                "axAirtime": 1888944,
                "nonAxAirtime": 1936484,
                "nonAxATE": 20.651019063415966,
                "timestamp": "2020-05-05T17:20:00.000+0000"
            },
            {
                "modificationtime": 1588699500000,
                "axATE": 21.077459757887297,
                "nonAxBytes": 37919894,
                "axBytes": 32573022,
                "axAirtime": 1545396,
                "nonAxAirtime": 1836460,
                "nonAxATE": 20.648363699726648,
                "timestamp": "2020-05-05T17:25:00.000+0000"
            },
            {
                "modificationtime": 1588699800000,
                "axATE": 21.0314491208243,
                "nonAxBytes": 36156420,
                "axBytes": 35581258,
                "axAirtime": 1691812,
                "nonAxAirtime": 1750840,
                "nonAxATE": 20.650898997052842,
                "timestamp": "2020-05-05T17:30:00.000+0000"
            },
            {
                "modificationtime": 1588700100000,
                "axATE": 21.066336028727342,
                "nonAxBytes": 38397050,
                "axBytes": 38203042,
                "axAirtime": 1813464,
                "nonAxAirtime": 1859484,
                "nonAxATE": 20.649303785351204,
                "timestamp": "2020-05-05T17:35:00.000+0000"
            },
            {
                "modificationtime": 1588700400000,
                "axATE": 20.96705818082491,
                "nonAxBytes": 37701858,
                "axBytes": 36844992,
                "axAirtime": 1757280,
                "nonAxAirtime": 1826040,
                "nonAxATE": 20.646786488795428,
                "timestamp": "2020-05-05T17:40:00.000+0000"
            },
            {
                "modificationtime": 1588700700000,
                "axATE": 21.007862555133407,
                "nonAxBytes": 41043404,
                "axBytes": 35188758,
                "axAirtime": 1675028,
                "nonAxAirtime": 1987100,
                "nonAxATE": 20.654926274470334,
                "timestamp": "2020-05-05T17:45:00.000+0000"
            },
            {
                "modificationtime": 1588701000000,
                "axATE": 20.841909100822722,
                "nonAxBytes": 38909774,
                "axBytes": 34402072,
                "axAirtime": 1650620,
                "nonAxAirtime": 1883828,
                "nonAxATE": 20.654631951536977,
                "timestamp": "2020-05-05T17:50:00.000+0000"
            },
            {
                "modificationtime": 1588701300000,
                "axATE": 21.014436170749747,
                "nonAxBytes": 38944314,
                "axBytes": 38301836,
                "axAirtime": 1822644,
                "nonAxAirtime": 1885444,
                "nonAxATE": 20.655248312864238,
                "timestamp": "2020-05-05T17:55:00.000+0000"
            },
            {
                "modificationtime": 1588701600000,
                "axATE": 21.012294511352223,
                "nonAxBytes": 34840334,
                "axBytes": 33805588,
                "axAirtime": 1608848,
                "nonAxAirtime": 1686820,
                "nonAxATE": 20.654446828944405,
                "timestamp": "2020-05-05T18:00:00.000+0000"
            },
            {
                "modificationtime": 1588701900000,
                "axATE": 21.037055033916676,
                "nonAxBytes": 36697760,
                "axBytes": 34548314,
                "axAirtime": 1642260,
                "nonAxAirtime": 1776792,
                "nonAxATE": 20.653942611177897,
                "timestamp": "2020-05-05T18:05:00.000+0000"
            },
            {
                "modificationtime": 1588702200000,
                "axATE": 21.00530552374642,
                "nonAxBytes": 35762776,
                "axBytes": 34998788,
                "axAirtime": 1666188,
                "nonAxAirtime": 1732060,
                "nonAxATE": 20.64753876886482,
                "timestamp": "2020-05-05T18:10:00.000+0000"
            },
            {
                "modificationtime": 1588702500000,
                "axATE": 20.95866734764876,
                "nonAxBytes": 34513658,
                "axBytes": 33977940,
                "axAirtime": 1621188,
                "nonAxAirtime": 1670916,
                "nonAxATE": 20.655531456997238,
                "timestamp": "2020-05-05T18:15:00.000+0000"
            },
            {
                "modificationtime": 1588702800000,
                "axATE": 21.070491406741986,
                "nonAxBytes": 43036430,
                "axBytes": 40418428,
                "axAirtime": 1918248,
                "nonAxAirtime": 2083964,
                "nonAxATE": 20.65123485818373,
                "timestamp": "2020-05-05T18:20:00.000+0000"
            },
            {
                "modificationtime": 1588703100000,
                "axATE": 20.945722165524614,
                "nonAxBytes": 44252662,
                "axBytes": 41376012,
                "axAirtime": 1975392,
                "nonAxAirtime": 2142680,
                "nonAxATE": 20.652949577165046,
                "timestamp": "2020-05-05T18:25:00.000+0000"
            }
        ],
        "insights": 1.42
    }
})