define({
"Template":
{
  "version": "1.0",
  "response": {
    "id": "0ea803b2-52d9-4d11-b16e-b0169741ddea",
    "name": "radio_5_util_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "macAddr",
    "entity": "70:69:5A:51:58:60",
    "groupBy": "Unknown",
    "category": "Utilization",
    "severity": "HIGH",
    "summary": "The 5 GHz radio 1 on AP \"DeviceNamePlaceHolder\" is experiencing high utilization.",
    "groupId": "radio_util_high_grouping",
    "groupName": "2.4/5 GHz radio on AP(s) are experiencing high utilization.",
    "scope": "GLOBAL",
    "priority": "P3",
    "rootCause": "AP_RADIO_HIGH_UTIL",
    "timestamp": 1563870300000,
    "description": "The 5 GHz radio on the AP \"DeviceNamePlaceHolder\" has exceeded the radioChannelUtilizationAP% threshold and is currently experiencing currVal% utilization. This is impacting 2 client(s).",
    "suggestions": [
      {
        "message": "Consider adding more APs with 5 GHz radios to this location."
      },
      {
        "message": "Check the neighbor APs on the same channel. High traffic on their clients will cause higher interference."
      },
      {
        "message": "Disable 2.4 GHz on some access points to reduce co-channel interferences."
      },
      {
        "message": "Check for the presence of rogue APs (show rogue ap summary) and rogue clients (show rogue client summary) on these APs channels. "
      },
      {
        "message": "Use the show 802.11a cleanair device ap DeviceNamePlaceHolder command to check the CleanAir Interference Severity Index (ISI) on this AP, and the RSSI from other APs.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "show 802.11a cleanair device ap DeviceNamePlaceHolder",
            "command": "show 802.11a cleanair device ap DeviceNamePlaceHolder",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Consider enabling optimized roaming on the WLC using -  https://www.cisco.com/c/en/us/td/docs/wireless/controller/8-5/config-guide/b_cg85/client_roaming.html"
      }
    ],
    "additionalParams": [
      {
        "key": "_name",
        "value": "radio_5_util_trigger"
      },
      {
        "key": "sourceDeviceType",
        "value": "1"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "channel",
        "value": "36.0"
      },
      {
        "key": "threshold",
        "value": "70"
      },
      {
        "key": "type",
        "value": "Issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:70:69:5A:51:58:60:1"
      },
      {
        "key": "floorId",
        "value": "9243058d-6f11-4bdb-8fa8-1d9451bfcd7c"
      },
      {
        "key": "_count",
        "value": "1"
      },
      {
        "key": "areaName",
        "value": "Global/San Jose"
      },
      {
        "key": "entityName",
        "value": "macAddr"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "AP_RADIO_HIGH_UTIL"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:70:69:5A:51:58:60"
      },
      {
        "key": "apname",
        "value": "Icap-row11-rack16"
      },
      {
        "key": "floorName",
        "value": "Global/San Jose/SJC04/Lab252"
      },
      {
        "key": "clientCount",
        "value": "3"
      },
      {
        "key": "siteType",
        "value": "floor"
      },
      {
        "key": "deviceType",
        "value": "Cisco 4800 Series Unified Access Points"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "8bea589a-8752-4a6c-9962-12a4a197c531"
      },
      {
        "key": "deviceRole",
        "value": "Access Point"
      },
      {
        "key": "_suppression",
        "value": "7200"
      },
      {
        "key": "deviceOS",
        "value": "8.9.120.0"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "nwDeviceName",
        "value": "Icap-row11-rack16"
      },
      {
        "key": "_eventTime",
        "value": "1563870300000"
      },
      {
        "key": "_mostRecent",
        "value": "1563870300000"
      },
      {
        "key": "utilization",
        "value": "92.0"
      },
      {
        "key": "entityId",
        "value": "70:69:5A:51:58:60"
      },
      {
        "key": "eventUniqueId",
        "value": "Icap-row11-rack16"
      },
      {
        "key": "deviceFamily",
        "value": "Unified AP"
      },
      {
        "key": "priority",
        "value": "P3"
      },
      {
        "key": "buildingId",
        "value": "e6c34e3d-f90f-455f-a38b-b9e90128ef29"
      },
      {
        "key": "buildingName",
        "value": "Global/San Jose/SJC04"
      },
      {
        "key": "macAddress",
        "value": "70:69:5A:51:58:60"
      },
      {
        "key": "radioSlot",
        "value": "1"
      },
      {
        "key": "areaId",
        "value": "b5e09ea7-0376-45cb-85fd-cb2a716a0920"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/San Jose/SJC04/Lab252"
      },
      {
        "key": "_source",
        "value": "internal"
      },
      {
        "key": "deviceModel",
        "value": "FOC21461SDG"
      },
      {
        "key": "category",
        "value": "Utilization"
      },
      {
        "key": "timestring",
        "value": "2019-07-23T08:25:00.000+0000"
      },
      {
        "key": "status",
        "value": "active"
      },
      {
        "key": "slot",
        "value": "1"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}
})