
define({
    "Template":
{
    "request": {
      "key": "binary-over-http",
      "type": "Application",
      "label": "binary-over-http",
      "collection": false,
      "componentContent": [
        {
          "component": "fusion",
          "additional": "27c5676f-e694-4d07-b8f9-059aefc32dfb"
        }
      ]
    },
    "data": {
      "response": {
        "id": "27c5676f-e694-4d07-b8f9-059aefc32dfb",
        "instanceId": 4180,
        "authEntityId": 4180,
        "displayName": "4180",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1528265510350,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1528265510350,
        "name": "binary-over-http",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "binary-over-http",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "805f4349-8bc4-484b-83f4-aababdebdd01",
            "instanceId": 11138,
            "authEntityId": 11138,
            "displayName": "11138",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp",
            "applicationGroup": "other",
            "applicationType": "DEFAULT",
            "categoryId": "329c21cd-9dcc-49e5-b448-75a45098775e",
            "createTime": 1528265510350,
            "encrypted": "false",
            "engineId": "13",
            "helpString": "Binary traffic over HTTP",
            "ignoreConflict": false,
            "lastUpdateTime": 1528265510350,
            "longDescription": "Binary over HTTP represents the transfer of binary data (for example, executive and compressed files such as .exe, .zip , and .rar) over HTTP protocol.",
            "name": "binary-over-http",
            "nbarId": "121",
            "p2pTechnology": "false",
            "popularity": 10,
            "rank": 65535,
            "references": "N/A",
            "selectorId": "431",
            "subCategory": "53a21609-b9e3-4a8d-b46f-4675f0641c21",
            "trafficClass": "BULK_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "48703019-b48e-411c-8463-a5e793a39158",
            "instanceId": 12388,
            "authEntityId": 12388,
            "displayName": "12388",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "80",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "089a9f63-608e-4bc9-9423-24a6a5f6d64e"
        },
        "user": [],
        "userGroup": []
      }
    },
    "result": [
      {
        "bookID": {
          "namespace": "fusion",
          "name": "core",
          "version": "1.0.1"
        },
        "response": {
          "id": "27c5676f-e694-4d07-b8f9-059aefc32dfb",
          "instanceId": 4180,
          "authEntityId": 4180,
          "displayName": "4180",
          "authEntityClass": -1909073334,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "createTime": 1528265510350,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1528265510350,
          "name": "binary-over-http",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "scalableGroupExternalHandle": "binary-over-http",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "identitySource": {
            "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
            "instanceId": 10010,
            "authEntityId": 10010,
            "displayName": "10010",
            "authEntityClass": 1738953278,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR"
          },
          "indicativeNetworkIdentity": [],
          "networkApplications": [
            {
              "id": "805f4349-8bc4-484b-83f4-aababdebdd01",
              "instanceId": 11138,
              "authEntityId": 11138,
              "displayName": "11138",
              "authEntityClass": -217092956,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "appProtocol": "tcp",
              "applicationGroup": "other",
              "applicationType": "DEFAULT",
              "categoryId": "329c21cd-9dcc-49e5-b448-75a45098775e",
              "createTime": 1528265510350,
              "encrypted": "false",
              "engineId": "13",
              "helpString": "Binary traffic over HTTP",
              "ignoreConflict": false,
              "lastUpdateTime": 1528265510350,
              "longDescription": "Binary over HTTP represents the transfer of binary data (for example, executive and compressed files such as .exe, .zip , and .rar) over HTTP protocol.",
              "name": "binary-over-http",
              "nbarId": "121",
              "p2pTechnology": "false",
              "popularity": 10,
              "rank": 65535,
              "references": "N/A",
              "selectorId": "431",
              "subCategory": "53a21609-b9e3-4a8d-b46f-4675f0641c21",
              "trafficClass": "BULK_DATA",
              "tunnel": "false"
            }
          ],
          "networkIdentity": [
            {
              "id": "48703019-b48e-411c-8463-a5e793a39158",
              "instanceId": 12388,
              "authEntityId": 12388,
              "displayName": "12388",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "80",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "parentScalableGroup": {
            "idRef": "089a9f63-608e-4bc9-9423-24a6a5f6d64e"
          },
          "user": [],
          "userGroup": []
        }
      }
    ]
  }
});