//similar to ApplicationTypeTemplate.js. But some format has changed
define({
    VIRTUAL_IMAGE: [
      {
        deviceFamily: "Unassigned",
        deviceTypeOrdinal: "-100",
        softwareType: null,
       
        productType: null,
        productTypeOrdinal: -1,
        supervisorType: null,
        supervisorTypeOrdinal: -1,
        productFamily: null,
        productFamilyOrdinal: -1,
        productSeries: null,
        productSeriesOrdinal: -1,
        baseImages: {
          "c6848x-adventerprisek9-mz.SPA.155-1.SY6.bin": "15.5(1)SY6",
          "cat9k_iosxe.17.06.01.SPA.bin": "17.06.01.0.250",
          "AIR-CT5520-K9-8-10-151-0.aes": "8.10.151.0",
          "C9800-L-universalk9_wlc.17.04.01.SPA.bin": "17.04.01.0.173",
          "C9800-40-universalk9_wlc.17.02.01.SPA.bin": "17.02.01.0.306",
          "cat9k_iosxe.17.04.01.SPA.bin": "17.04.01.0.173",
          "cat9k_lite_iosxe.17.03.01.SPA.bin": "17.03.01.0.351",
          "cat9k_iosxe.17.03.02a.SPA.bin": "17.03.02a.0.3793",
          "C9800-L-universalk9_wlc.17.06.01.SPA.bin": "17.06.01.0.250",
          "C9800-40-universalk9_wlc.17.03.02a.SPA.bin": "17.03.02a.0.3793",
          "cat9k_iosxe.17.03.03.SPA.bin": "17.03.03.0.4762",
          "C9800-L-universalk9_wlc.17.03.01.SPA.bin": "17.03.01.0.351",
          "C9800-40-universalk9_wlc.17.06.01.SPA.bin": "17.06.01.0.250",
          "C9800-40-universalk9_wlc.17.08.01.SPA.bin": "17.08.01.0.1490",
          "cat9k_iosxe.17.03.01.SPA.bin": "17.03.01.0.351",
          "C9800-L-universalk9_wlc.17.03.03.SPA.bin": "17.03.03.0.4762",
          "AIR-CT5520-K9-8-8-130-0.aes": "8.8.130.0",
          "C9800-L-universalk9_wlc.17.05.01.SPA.bin": "17.05.01.0.144",
          "C9800-40-universalk9_wlc.17.03.03.SPA.bin": "17.03.03.0.4762",
        },
        kickstartImages: {},
        smuImages: {},
        rommonImages: {},
        apspImages: {},
        apdpImages: {},
        deviceUuids: [],
        goldenImageUuids: [],
        virtualVendors: [],
        deviceAvailable: false,
        runningImageList: 
          [
            {
              name: "cat9k_lite_iosxe.17.03.01.SPA.bin",
              imageUuid: "0c2251f7-a50a-4ae2-aa88-2a8922782cbd",
              version: "17.03.01.0.351",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "746d7b5074b8ece7979e80a4b5cc491f",
              shaCheckSum:
                "1d1482059d99ad0f119d9d5ef460db5f8c9c9f8da0394fcc5ce6e4b91ea6752abbffd86d27dcd6580bad6cef8c3e3a3d82f8be79b14224cdd4349a0f694571fa",
              createdTime: "2020-10-13 05:23:28.0",
              applicationType: null,
              feature: "",
              fileServiceId: "29457ec6-ffa9-48f9-9035-1cd98b2f4f6b",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.260.62555//localdisk/Staging/cat9k_lite_iosxe.17.03.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "CAT9K_LITE",
              vendor: "CISCO",
              filesize: 483549665,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286320865",
                  productName: "Cisco Catalyst 9200 Switch Stack",
                  productId: [],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320849",
                  productName: "Cisco Catalyst 9200L Switch Stack",
                  productId: ["C9200L-STACKKIT-RF", "C9200L-STACKKIT-WS"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: false,
            },
            {
              name: "C9800-40-universalk9_wlc.17.08.01.SPA.bin",
              imageUuid: "14190467-0002-4ff8-99f3-e95746e2f89f",
              version: "17.08.01.0.1490",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "c3d96d739ba96b5d11210cc96839261d",
              shaCheckSum:
                "d94ffb6163468aaa40eb6a96be6d7645fe497333421e28c8966d7dc323b537ed61ae6b811c615379880079afceeeb8f38a38435b5bbc5f0d3d5082332e4afd4e",
              createdTime: "2022-07-29 16:29:15.0",
              applicationType: null,
              feature: "",
              fileServiceId: "607e5975-fd84-45b8-a87c-e56d41037099",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.512.62187//localdisk/Staging/C9800-40-universalk9_wlc.17.08.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "C9800",
              vendor: "CISCO",
              filesize: 1369470372,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: null,
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: false,
              ccorecommended: false,
              ccolatest: false,
            },
            {
              name: "c6848x-adventerprisek9-mz.SPA.155-1.SY6.bin",
              imageUuid: "2188d5f0-ec0c-448b-9dcb-e8dd8b40a06d",
              version: "15.5(1)SY6",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "872f6704b8570719b8cbf6cd8bfc1f81",
              shaCheckSum:
                "a57a3be0839bf73fd46e3776e65af45d20bd31024c622c9d6e308f7c540311f7458fdf5e190291a948280cabcc215bcac571b25f6315b46fd4412282b445b09a",
              createdTime: "2021-05-24 09:51:26.594",
              applicationType: null,
              feature: null,
              fileServiceId: "70420124-c978-4f8f-9417-7223e1d065c4",
              imageSeries: [
                "Switches and Hubs/Cisco Catalyst 6800 Series Switches",
              ],
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "c6848x",
              vendor: "CISCO",
              filesize: 118483092,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 1,
              imageAttributes: [],
              isSMUApplicable: true,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286320638",
                  productName: "Cisco Catalyst C6824-X-L Switch",
                  productId: [
                    "C6807-XL=",
                    "C6824-X-LE-40G-WS",
                    "C6824-X-LE-40G",
                    "C1-C6824-X-LE-40G",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286287226",
                  productName: "Cisco Catalyst 6840-X Switch",
                  productId: [
                    "C6800-8P10G-XL++=",
                    "C1-C6840-X-LE-40G",
                    "C6832-X-LE-WS",
                    "C6800-16P10G-XL++=",
                    "C6816-X-LE",
                    "C6800-16P10G=",
                    "C6880-DNA-E=",
                    "C6800-32P10G=",
                    "C6824-X-LE-40G=",
                    "C6800-32P10G-XL-RF",
                    "C6800-48P-TX",
                    "C1A1ATCAT68402",
                    "C6840-X-1100W-AC",
                    "C1A1ATCAT68401",
                    "C6840-X-750W-AC",
                    "C6800-16P10G",
                    "C6800-8P10G-RF",
                    "C1-C6840H-DNAA-T",
                    "C1-C6840L-DNAA-T",
                    "C6840-DNA-E=",
                    "C6800-48P-TX-RF",
                    "C6840-X-750W-DC-RF",
                    "C6800-32P10G-XL=",
                    "C6800-16P10G-RF",
                    "C6840-X-1100W-DC=",
                    "C6807-DNA-E",
                    "C6807-XL=",
                    "C6816-X-LE-WS",
                    "C6840-DNA-E",
                    "C6807-DNA-A",
                    "C1AA1TCAT6840",
                    "C6824-X-LE-40G",
                    "C6840-X-1100W-DC",
                    "C6800-48P10G",
                    "C6800-8P10G-XL-RF",
                    "C6800-48P-SFP=",
                    "C6824-X-LE-40G-WS",
                    "C6840-DNA-L-A=",
                    "C6800-4P100G",
                    "C6840-X-1100WAC-RF",
                    "C6800-48P-SFP",
                    "C6800-32P10G",
                    "C6800-16P10G-WS",
                    "C6840-X-LE-40G-WS",
                    "C6800-48P-TX-XL=",
                    "C6800-48P-TX-XL-WS",
                    "C6800-48P-SFP-RF",
                    "C6800-8P10G++=",
                    "C6800-8P10G-XL",
                    "C1FBACAT6800S=",
                    "C6880-DNA-A=",
                    "C6832-X-LE",
                    "C6840-X-750W-DC-WS",
                    "C6800-8P10G-WS",
                    "C6880-DNA-A",
                    "C6800-48P10G=",
                    "C6800-8P10G=",
                    "C6880-DNA-E",
                    "C6832-X-LE-RF",
                    "C6800-48P-SFPXL-WS",
                    "C6840-DNA-A",
                    "C6800-16P10G-XL",
                    "C6800-16P10G-XL-WS",
                    "C6800-16P10G++=",
                    "C6807-DNA-A=",
                    "C6800-DNA-L-E",
                    "C6800-12P40G=",
                    "C6840-X-750W-AC=",
                    "C6800-DNA-L-A",
                    "C6800-32P10G-RF",
                    "C6840-X-750W-DC",
                    "C1-C6832-X-LE",
                    "C6800-8P10G",
                    "C6800-48P-TX-WS",
                    "C6840-X-750W-AC-RF",
                    "C6800-48P-SFPXL-RF",
                    "C1FBACAT6800S",
                    "C6800-48P-SFP-XL",
                    "C6800-4P100G=",
                    "C6840-DNA-A=",
                    "C6816-X-LE-RF",
                    "C6800-12P40G",
                    "C6800-48P-TX=",
                    "C6800-48P-SFP-XL=",
                    "C6800-16P10G-XL=",
                    "C6840-X-750W-DC=",
                    "C6840-X-750W-AC-WS",
                    "C6800-32P10G-XL-WS",
                    "C6800-32P10G-XL",
                    "C6840-DNA-L-E=",
                    "C6840-DNA-L-A",
                    "C6800-16P10G-XL-RF",
                    "C6800-32P10G-XL++=",
                    "C6840-X-LE-40G-RF",
                    "C6800-48P-TX-XL-RF",
                    "C6800-48P-SFP-WS",
                    "C6800-32P10G-WS",
                    "C6840-X-LE-40G=",
                    "C6800-8P10G-XL=",
                    "C6807-DNA-E=",
                    "C6840-X-1100WAC-WS",
                    "C6840-X-LE-40G",
                    "C6840-DNA-L-E",
                    "C6824-X-LE-40G-RF",
                    "C6800-48P-TX-XL",
                    "C1-C6816-X-LE",
                    "C6800-32P10G++=",
                    "C6800-8P10G-XL-WS",
                    "C6840-X-1100W-AC=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320645",
                  productName: "Cisco Catalyst C6832-X-LE Switch",
                  productId: [
                    "C6807-XL=",
                    "C6832-X-LE-RF",
                    "C6832-X-LE=",
                    "C6832-X-LE",
                    "C6832-X-LE-WS",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320635",
                  productName: "Cisco Catalyst C6816-X-LE Switch",
                  productId: ["C6816-X-LE", "C6807-XL=", "C6816-X-LE-WS"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: true,
              ccolatest: false,
            },
            {
              name: "C9800-L-universalk9_wlc.17.03.01.SPA.bin",
              imageUuid: "374fccdf-f7a0-4022-81e8-5ec56e5e6294",
              version: "17.03.01.0.351",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "3e1b7528581e6f9e13e19afec3759831",
              shaCheckSum:
                "e9a4417c0c9a6d4ad984a0591bb887fe9710e76ba1a01bf3309dccfa6e10c5c37bf9ac6a5d836089d6aa6aecfb387ea4b1316edf6e0fe1ac8715f7bbffef7336",
              createdTime: "2021-04-30 22:29:16.0",
              applicationType: null,
              feature: "",
              fileServiceId: "6e8dc396-3b7f-4233-96c0-4a54b000ec45",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.313.62478//localdisk/Staging/C9800-L-universalk9_wlc.17.03.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "C9800-L",
              vendor: "CISCO",
              filesize: 1131990842,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286321399",
                  productName: "Cisco Catalyst 9800-L-F Wireless Controller",
                  productId: ["EDU-C9800-L-F-K9", "C9800-L-F-K9"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286323158",
                  productName: "Cisco Catalyst 9800-L-C Wireless Controller",
                  productId: [
                    "C9800-L-C-K9-RF",
                    "EDU-C9800-L-C-K9",
                    "C9800-L-F-K9",
                    "C9800-L-C-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: false,
            },
            {
              name: "C9800-L-universalk9_wlc.17.05.01.SPA.bin",
              imageUuid: "4e3818a7-04e2-44cf-ba51-f29070ea857c",
              version: "17.05.01.0.144",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "81b294577b5a5f0ed08c32901dc1016c",
              shaCheckSum:
                "ba2642a4bae715ff7548e13a93e816b7bc0fdaf10a23520815fe6c32a3c4cadcbd988b046458b116901e686144ac04295cf42bd5348dd50cb5182481c6ffa527",
              createdTime: "2021-12-08 01:12:18.777",
              applicationType: null,
              feature: "",
              fileServiceId: "0ed46c8b-30df-4dae-8c8b-f114b70e8ea9",
              imageSeries: null,
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "C9800-L",
              vendor: "CISCO",
              filesize: 1110657159,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286321399",
                  productName: "Cisco Catalyst 9800-L-F Wireless Controller",
                  productId: ["EDU-C9800-L-F-K9", "C9800-L-F-K9"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286323158",
                  productName: "Cisco Catalyst 9800-L-C Wireless Controller",
                  productId: [
                    "C9800-L-C-K9-RF",
                    "EDU-C9800-L-C-K9",
                    "C9800-L-F-K9",
                    "C9800-L-C-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "cat9k_iosxe.17.03.01.SPA.bin",
              imageUuid: "6a66fbb7-b5c9-43ef-a97b-dff0dc2b5776",
              version: "17.03.01.0.351",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "b90c6ef495af2ed6f9dcfe98455622eb",
              shaCheckSum:
                "25c2fd958e35fb0b5e62d2617610548bd361df234bda52803ce6027bce26b7d9404df558e85195767b893fbf4d90315914a2d8c5e68eebec30371eef01168533",
              createdTime: "2020-11-05 01:12:18.703",
              applicationType: null,
              feature: "",
              fileServiceId: "77c05d8c-8ed3-400b-853c-f5bfd6f6be64",
              imageSeries: null,
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "CAT9K",
              vendor: "CISCO",
              filesize: 892195673,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286320244",
                  productName: "Cisco Catalyst 9404R Switch",
                  productId: [
                    "C9404R",
                    "C9404R-WS",
                    "C9404R-48UBNDLA-RF",
                    "C9404R-RF",
                    "C9404R-UL",
                    "C9404R=",
                    "C9404R-1A",
                    "C9404R-10A",
                    "C9404R-P",
                    "C9404R-48U-BNDL-A",
                    "C9404R-48U-BDL-EDU",
                    "C9404R-48U-BNDL-1E",
                    "C9404R-48U-BNDL-E",
                    "C9404R-48U-BNDL-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315703",
                  productName: "Cisco Catalyst 9410R Switch",
                  productId: [
                    "C9400-LC-24XS-WS",
                    "C9410R-96UBNDLA-RF",
                    "C9400-LC-48T++=",
                    "C9400-SUP-1XL=",
                    "C9410R-WS",
                    "C9410R-96U-BNDL-A",
                    "C9400-SUP-1XL++=",
                    "C9410-96U-BNDL-CX",
                    "C9410R-96U-BNDL-E",
                    "C9400-LC-48S=",
                    "C9410R-96U-BNDL-1A",
                    "C9410R-P",
                    "C9410R-96U-BDL-EDU",
                    "C9400-LC-48UX++=",
                    "C9410R-96U-STK-EDU",
                    "C9400-LC-48P",
                    "C9400-LC-48P-WS",
                    "C9400-LC-48S",
                    "C9410R-UL",
                    "C9400-LC-24S",
                    "C9400-LC-24XS++=",
                    "C9400-SUP-1XL-B",
                    "C9400-SUP-1++=",
                    "C9400-LC-24XS",
                    "C9400-LC-24XS=",
                    "C9400-LC-48P=",
                    "C9400-LC-48U-B1",
                    "C9410R",
                    "C9400-LC-48UX-WS",
                    "C9400-LC-48U++=",
                    "C9410R-96UBNDLA-WS",
                    "C9400-PWR-2400AC",
                    "C9410R-10A",
                    "C9400-LC-48UX",
                    "C9400-SUP-1XL-WS",
                    "C9400-SR-BLANK=",
                    "C9400-PWR-2400AC=",
                    "C9410R-96U-BNDL-1E",
                    "C9400-LC-48UX=",
                    "C9410R-RF",
                    "C9400-SUP-1XL",
                    "C9410R-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286323141",
                  productName: "Cisco Catalyst 9600 Series Supervisor Engine 1",
                  productId: [
                    "C9600X-SUP-2",
                    "C9600-SUP-1-WS",
                    "C9600X-SUP-2=",
                    "C9600-SUP-1/2",
                    "C9600-SUP-1",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319589",
                  productName: "Cisco Catalyst C9500-48Y4C Switch",
                  productId: [
                    "C9500-48Y4C",
                    "C9500-48Y4C=",
                    "L-C9500-48Y4C-EA-3",
                    "C9500H-ACCKIT-19I=",
                    "C9500-48Y4C-CX",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322029",
                  productName: "Cisco Catalyst 9300L Switch Stack",
                  productId: [
                    "S9300LNPE-174",
                    "S9300LUK9-174",
                    "C9300L-STACKBLANK=",
                  ],
                  sites: ["00d38152-9d4f-41f9-a8e9-af3f811d4920"],
                  show: true,
                  userDefined: false,
                },
                {
                  mdfId: "286316172",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1",
                  productId: [
                    "C9404-FAN=",
                    "C9400-SUP-1",
                    "C9404-FAN",
                    "C9400-SSD-960GB=",
                    "C9410R",
                    "C9400-SSD-980GB=",
                    "C9400-SUP-1/2",
                    "C9400-SSD-480GB",
                    "C9400-SSD-240GB",
                    "C9400-SUP-1-B",
                    "C9400-SSD-960GB",
                    "C9400-SUP-1=",
                    "C9400-SSD-480GB=",
                    "C9400-SUP-1-RF",
                    "C9400-SSD-240GB=",
                    "C9407R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319595",
                  productName: "Cisco Catalyst C9500-24Y4C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "L-C9500-24Y4C-EA-3",
                    "C9500-24Y4C-EDU-RF",
                    "L-C9500-24Y4C-EA-7",
                    "C9500-24Y4C=",
                    "C9500-60C12D-A",
                    "C9500H-ACCKIT-19I=",
                    "C9500-24Y4C-CX",
                    "C9500-24Y4C",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315863",
                  productName: "Cisco Catalyst 9500 Switch",
                  productId: [
                    "C9500-DNA-L-P",
                    "C9500X-ACCKIT-19I",
                    "C9500-ACCKIT-23I",
                    "C9500X-FAN-1U-R=",
                    "C9K-PWR-1500WAC-R",
                    "NAL-C9500-48X-E",
                    "NAL-C9500-48X-A",
                    "C9K-PWR-1600WDCR/2",
                    "C9500-ACCKIT-19I",
                    "PWR-C4-950WAC-R",
                    "C9500X-ACCKIT-19I=",
                    "C9K-PWR-C5-BLANK",
                    "C9500X-FAN-1U-R",
                    "HX-9500-8E",
                    "C9K-PWR-1600WDC-R=",
                    "C9500X-FAN-1U-F",
                    "C9K-PWR-1500WDC-F",
                    "C9500X-4PTH-KIT",
                    "PWR-C4-BLANK",
                    "C9K-PWR-1600WAC-R=",
                    "C9500X-FAN-1U-F=",
                    "C9K-PWR-1600WDC-R",
                    "C9K-F1-SSD-240G=",
                    "C9K-PWR-1500WDC-R",
                    "C9500-ACCKITH-19I=",
                    "C9500-ACCKIT-19I=",
                    "C9K-F1-SSD-240G",
                    "C9500-4PTH-KIT=",
                    "C9K-PWR-C4-BLANK",
                    "C9K-PWR-650WAC-R/2",
                    "C9500-DNA-P=",
                    "C9K-F1-SSD-480G=",
                    "C9K-F1-SSD-240G-WS",
                    "C9K-PWR-1600WACR/2",
                    "C9500-16X-EDU-RF",
                    "C9K-F1-SSD-BLANK",
                    "HX-9500-8E=",
                    "C9K-PWR-C5-BLANK=",
                    "C9K-PWR-1500WAC-F",
                    "C9K-F1-SSD-960G=",
                    "C9K-PWR-1500WDC-F=",
                    "C9500X-60L4D",
                    "C9500-ACCKIT-23I=",
                    "C9K-F1-SSD-960G",
                    "C9K-F3-SSD-240GB=",
                    "C9K-F3-SSD-240GB",
                    "C9K-F3-SSD-BLANK=",
                    "C9K-PWR-1500WDC-R=",
                    "C9500-ACCKITH-23I",
                    "C9K-PWR-1500WDCR/2",
                    "C9500X-4PTH-KIT=",
                    "C9500X-ACCKIT-23I=",
                    "C9K-PWR-1500WAC-R=",
                    "C9K-PWR-1500WDCF/2",
                    "C9500-SPS-NONE",
                    "C9K-PWR-930WDC-R/2",
                    "C9K-F1-SSD-480G",
                    "PWR-C4-BLANK=",
                    "C9500-4PTH-KIT",
                    "C9K-F3-SSD-480GB=",
                    "C9K-F3-SSD-960GB",
                    "C9500-ACCKITH-19I",
                    "C9K-PWR-1500WACF/2",
                    "C9K-PWR-1500WAC-F=",
                    "C9500-4PT-KIT",
                    "C9K-PWR-C4-BLANK=",
                    "PWR-C4-950WAC-R-RF",
                    "C9K-F3-SSD-480GB",
                    "C9K-PWR-1600WAC-R",
                    "C9K-F3-SSD-960GB=",
                    "C9500X-ACCKIT-23I",
                    "C9500-DNA-P",
                    "C9500-ACCKITH-23I=",
                    "C9K-F3-SSD-BLANK",
                    "C9500-DNA-L-P=",
                    "C9K-PWR-1500WACR/2",
                  ],
                  sites: ["00d38152-9d4f-41f9-a8e9-af3f811d4920"],
                  show: true,
                  userDefined: false,
                },
                {
                  mdfId: "286320497",
                  productName: "Cisco Catalyst C9500 SVL Switch",
                  productId: ["C9500-24Y4C-E-RF", "C9500-24Y4C-10E"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316710",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL",
                  productId: [
                    "C9400-SUP-1XL-WS",
                    "C9400-SUP-1XL-RF",
                    "C9400-SUP-1XL0-RF",
                    "C9400-SUP-1XL/2",
                    "C9400-SUP-1XL",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315874",
                  productName: "Cisco Catalyst 9300 Switch",
                  productId: [
                    "C9300-NM-4M-WS",
                    "C9300-NM-4G",
                    "C9300-NM-8X=",
                    "SC9300UK9-174",
                    "C9300-NM-4M",
                    "C9300-NM-2X=",
                    "C9300-NM-4X=",
                    "C9300X-NM-8M",
                    "S9300NPE-174",
                    "C9300X-NM-8Y",
                    "C9300-24-E-A",
                    "C9300-NM-8X",
                    "C9300-NM-2Q",
                    "C9300-NM-4G=",
                    "C9200-48PXG-EDU",
                    "C9300X-NM-NONE",
                    "C9300X-NM-BLANK",
                    "C9300-NM-4G-WS",
                    "C9300-48-E-A",
                    "C9300-NM-BLANK=",
                    "C9300X-NM-2C",
                    "C9300-NM-2Q=",
                    "C9300X-NM-4C",
                    "C9200-24PXG-EDU",
                    "C9300X-NM-8M=",
                    "C9300-NM-BLANK",
                    "C9300-NM-2Y=",
                    "C9300-SSD-PROMO",
                    "C9300-NM-8X-WS",
                    "C9300-NM-8X-UL",
                    "C9300X-NM-8Y=",
                    "C9300-NM-4X",
                    "C9300X-NM-BLANK=",
                    "C9300-NM-2Y",
                    "C9300-NM-2X",
                    "C9300-RFID=",
                    "SSD-PROMO",
                    "C9300-NM-2Q-WS",
                    "C9300X-NM-4C=",
                    "C9300-NM-2Y-WS",
                    "C9300X-NM-2C=",
                  ],
                  sites: ["00d38152-9d4f-41f9-a8e9-af3f811d4920"],
                  show: true,
                  userDefined: false,
                },
                {
                  mdfId: "286319599",
                  productName: "Cisco Catalyst C9500-32C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32C-P",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32C=",
                    "C9500-32C",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322137",
                  productName: "Cisco Catalyst 9606R Switch",
                  productId: [
                    "C9600-SSD-NONE",
                    "C9606R-48Y24C-BN-A",
                    "C9606R-48Y24C-BNCX",
                    "QSFP-40G-CSR-S-RF",
                    "C9606R=",
                    "C9606-FILTER=",
                    "C9606=",
                    "C9606R-48Y24C-EDU",
                    "BN-A1-SBA-K-K9",
                    "C9606R-EDU",
                    "C9606",
                    "C9606-RACK-KIT=",
                    "C9606R-RF",
                    "BN-A1-SBA-C-K9",
                    "C9606R-1A",
                    "BN-A1-SBA-I-K9",
                    "C9606R-P",
                    "HWOA-TETR-PLT",
                    "C9606R",
                    "C9606R-48S-BN-A",
                    "C9606R-10A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320394",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL-Y",
                  productId: [
                    "C9400-SUP-1XL-Y-WS",
                    "C9400-SUP-1XL-Y",
                    "C9400-SUP-1XL-Y/2",
                    "C9400-DNX-A-10Y",
                    "C9400-SUP-1XL-Y=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315691",
                  productName: "Cisco Catalyst 9407R Switch",
                  productId: [
                    "C9400-PWR-2100AC",
                    "C9407R-96U-BNDL-E",
                    "C9407-RACK19KIT-WS",
                    "C9407R-RF",
                    "S9400NPE-174",
                    "C9407R-10A",
                    "S9400UK9-174",
                    "C9407R-1A",
                    "C9407R-96U-STK-EDU",
                    "C9407R",
                    "C9407R-96UBNDLA-WS",
                    "C9407R-96U-BDL-EDU",
                    "C9407R-96UBNDLE-RF",
                    "C9407R-96UBNDLA-RF",
                    "C9407-RACK19KIT-RF",
                    "C9400-PWR-2400AC",
                    "C9407R-UL",
                    "C9400-SR-BLANK=",
                    "C9407R-96U-BNDL-1E",
                    "C9407-96U-BNDL-CX",
                    "C9407R-96U-BNDL-A",
                    "C9400-PWR-2400AC=",
                    "C9407R-96U-BNDL-1A",
                    "C9407R-WS",
                    "C9407R-P",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319592",
                  productName: "Cisco Catalyst C9500-32QC Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32QC-P",
                    "C9500-32QC",
                    "C9500-32QC=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32QC-EDU-RF",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 3,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "C9800-40-universalk9_wlc.17.03.02a.SPA.bin",
              imageUuid: "6f0c963f-8125-463b-ba34-29ac213e4a10",
              version: "17.03.02a.0.3793",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "fdf0ec03c53b7b84b7b87bf96d89560d",
              shaCheckSum:
                "39c97a9d3509cbb5a1afbc48409d6b283313488b5fd34cf198c85932fa3630b6593f57d88158ef343b98b0ee37a18bef326a3b5119fc32d087908e48bbdbb915",
              createdTime: "2021-02-27 01:12:19.851",
              applicationType: null,
              feature: "",
              fileServiceId: "bc988646-7730-434b-a64d-d80716ad4e90",
              imageSeries: null,
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "C9800",
              vendor: "CISCO",
              filesize: 1218729935,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286321396",
                  productName: "Cisco Catalyst 9800-80 Wireless Controller",
                  productId: [
                    "LIC-C9800-DTLS-K9",
                    "C9800-1X40GE",
                    "SC980080K9-1610",
                    "C9800-2X40GE",
                    "C9800-1X100GE",
                    "EDU-C9800-80-K9",
                    "C9800-18X1GE",
                    "C9800-AC-1100W",
                    "EDU-C9800-80-S5-K9",
                    "C9800-80-SLRCA-K9",
                    "C9800-DC-950W",
                    "C9800-40-K9",
                    "C9800-80-CAP-K9",
                    "C9800-80-CA-K9",
                    "C9800-80-K9-RF",
                    "C9800-10X10GE",
                    "C9800-80-SLR-K9",
                    "C9800-80-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316412",
                  productName: "Cisco Catalyst 9800-40 Wireless Controller",
                  productId: [
                    "C9800-40-2K-SLR-K9",
                    "LIC-C9800-DTLS-K9",
                    "AIR-CT9540-CA-K9",
                    "CAB-C13-C14-JMPR",
                    "C9800-40-SLR-K9",
                    "AIR-DNA-A",
                    "C9800-L-F-K9",
                    "C9800-AC-750W-R-RF",
                    "EDU-C9800-40-K9",
                    "C9800-40-SLRCA-K9",
                    "C9800-AC-110W",
                    "AIR-CT9540-K9++",
                    "C9800-AC-110W=",
                    "AIR-DNA-EDU",
                    "C9800-40-CA-K9",
                    "C9800-40-K9",
                    "C9800-AC-750W-RED",
                    "C9800-40-K9-RF",
                    "SC980040K9-1610",
                    "C9800-AC-750W-R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "C9800-40-universalk9_wlc.17.03.03.SPA.bin",
              imageUuid: "891bf016-98d1-4c07-a147-5a16c371a42c",
              version: "17.03.03.0.4762",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "18128d6d922317cf6bc60a6f9cd9a50f",
              shaCheckSum:
                "2f3126ef138a6ab6beb3e19a7a19d96415533ca1eae042059d95d8db85e8d147d8594f5b18910c18dd54b92d86469ceae224ef7e3ac814ea9f694974a07018a4",
              createdTime: "2021-12-06 01:12:18.686",
              applicationType: null,
              feature: "",
              fileServiceId: "428182d1-d3b7-4d50-afb7-03412a970c22",
              imageSeries: null,
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "C9800",
              vendor: "CISCO",
              filesize: 1216469331,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286316412",
                  productName: "Cisco Catalyst 9800-40 Wireless Controller",
                  productId: [
                    "C9800-40-2K-SLR-K9",
                    "LIC-C9800-DTLS-K9",
                    "AIR-CT9540-CA-K9",
                    "CAB-C13-C14-JMPR",
                    "C9800-40-SLR-K9",
                    "AIR-DNA-A",
                    "C9800-L-F-K9",
                    "C9800-AC-750W-R-RF",
                    "EDU-C9800-40-K9",
                    "C9800-40-SLRCA-K9",
                    "C9800-AC-110W",
                    "AIR-CT9540-K9++",
                    "C9800-AC-110W=",
                    "AIR-DNA-EDU",
                    "C9800-40-CA-K9",
                    "C9800-40-K9",
                    "C9800-AC-750W-RED",
                    "C9800-40-K9-RF",
                    "SC980040K9-1610",
                    "C9800-AC-750W-R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286321396",
                  productName: "Cisco Catalyst 9800-80 Wireless Controller",
                  productId: [
                    "LIC-C9800-DTLS-K9",
                    "C9800-1X40GE",
                    "SC980080K9-1610",
                    "C9800-2X40GE",
                    "C9800-1X100GE",
                    "EDU-C9800-80-K9",
                    "C9800-18X1GE",
                    "C9800-AC-1100W",
                    "EDU-C9800-80-S5-K9",
                    "C9800-80-SLRCA-K9",
                    "C9800-DC-950W",
                    "C9800-40-K9",
                    "C9800-80-CAP-K9",
                    "C9800-80-CA-K9",
                    "C9800-80-K9-RF",
                    "C9800-10X10GE",
                    "C9800-80-SLR-K9",
                    "C9800-80-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: true,
              ccolatest: false,
            },
            {
              name: "AIR-CT5520-K9-8-8-130-0.aes",
              imageUuid: "8c34b7cf-654a-4751-82f5-7c6c8efffa1e",
              version: "8.8.130.0",
              softwareType: null,
              imageType: "WLC_SW",
              isTaggedGolden: "false",
              md5Checksum: "e5c5acadcf82da10a56c3ef9a7e48c9b",
              shaCheckSum:
                "e0349ad3e01662841b19ad8b8f886cc091d84a79c9dba1af6515ea6e75e3de01f8a0f86fdc806ee11d2b316cdafc02f8e2b9149ddbc33b5e777a3f2cadea260e",
              createdTime: "2021-12-08 01:12:18.777",
              applicationType: null,
              feature: null,
              fileServiceId: "d30ca71b-6657-440d-b44f-98ce3480b4cc",
              imageSeries: [
                "Wireless Controllers/Cisco 5500 Series Wireless LAN Controllers",
              ],
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "AIR-CT5520",
              vendor: "CISCO",
              filesize: 286326006,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286284599",
                  productName: "Cisco 8540 Series Wireless Controllers",
                  productId: [
                    "C1F1UAIRK9=",
                    "PROMOCT8540-300-K9",
                    "C1F2UAIRK9=",
                    "AIR-CT8510100K9-RF",
                    "AIR-CT8540-1K-K9++",
                    "AIRSD240G0KS2EV-RF",
                    "AIR-CT8540-K9-WS",
                    "LIC-CT8540-UPG",
                    "AIR-BZL-C240M4",
                    "C1F1UAIRK9",
                    "LIC-CT8540-1AP-INT",
                    "AIR-CT8540-K9",
                    "PROMOCT8540-3-K9",
                    "AIR-CT8540-CA-K9",
                    "AIR-CT8540-SW-8.3",
                    "AIR-PSU-930WDC-RF",
                    "AIR-PSU2V21200W-WS",
                    "AIR-CT8540-SW-8.2",
                    "LIC-CT8500-SP-1A",
                    "AIR-CT8540-SW-8.1",
                    "C1F1PAIRSPC1K9=",
                    "AIR-CT8540-1K-K9",
                    "AIR-CT8540-1KK9-RF",
                    "C1-AIR-CT8540-K9++",
                    "AIR-PSU2V2-1200W=",
                    "AIR-SD240G0KS2-EV=",
                    "AIR-SD240G0KS2-EV",
                    "C1-AIR-CT8540-K9",
                    "C1F2UAIRK9",
                    "AIR-CT8540-K9-PM",
                    "EDU-CT8540-1K-K9",
                    "AIR-CT85DC-SP-K9",
                    "AIR-CT9540-K9",
                    "C1-AIR-CT8540K9-RF",
                    "AIR-CT8540-DC-K9Z",
                    "AIR-CT8540-K9-RF",
                    "AIR-PSU-930WDC=",
                    "AIR-PSU2V21200W-RF",
                    "AIR-PSU-930WDC",
                    "AIR-PSU2V2-1200W",
                    "AIR-BZL-C240M4=",
                    "AIR-CT8540-K9++",
                    "AIR-CT8510-100-K9",
                    "AIR-CT8540-SW-8.10",
                    "AIR-CT8540-K9Z",
                    "AIR-CT8540-DC-K9",
                    "EDU-CT8540-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286284588",
                  productName: "Cisco 5520 Series Wireless Controllers",
                  productId: [
                    "C1F1UAIRK9=",
                    "AIR-CT5520-50-K9",
                    "C1F2UAIRK9=",
                    "AIR-CT5520-SW-8.10",
                    "EDU-CT5520-K9",
                    "AIR-FAN-C220M4=",
                    "AIRCT5508-100K9-WS",
                    "EDU-CT5520-50-K9",
                    "LIC-CT5520-1AP-INT",
                    "AIR-PSU1-770W-WS",
                    "AIR-BZL-C220M4=",
                    "CAB-AC-WS",
                    "C1-AIR-CT5520-K9",
                    "AIR-CT5508500K9-WS",
                    "AIR-CT5520-K9Z",
                    "AIR-CT5520-K9-RF",
                    "AIR-CT6880-NIC-K9",
                    "AIR-SD240G0KS2-EV=",
                    "AIR-SD240G0KS2-EV",
                    "AIR-PSU1-770W=",
                    "AIR-SD240GBKS4-EV=",
                    "AIR-CT5520-50K9-RF",
                    "CAB-ACI=",
                    "C1F2UAIRK9",
                    "PROMOCT5520-100-K9",
                    "AIR-CT5520-K9++",
                    "CAB-ACU",
                    "CAB-ACU=",
                    "EDU-LIC-CT5520-UPG",
                    "AIR-CT5520-CA-K9",
                    "CAB-AC-RF",
                    "AIR-CT5508-HA-K9",
                    "AIRCT5508-100K9-RF",
                    "LIC-CT5520-UPG",
                    "AIR-SD240GBKS4-EV",
                    "AIR-CT5520-K9-WS",
                    "AIR-CT5520-50-K9++",
                    "AIRSD240G0KS2EV-RF",
                    "C1F1UAIRK9",
                    "CAB-ACA",
                    "AIR-BZL-C220M4",
                    "AIR-CT6870-NIC-K9",
                    "CAB-AC=",
                    "C1-AIR-CT5520K9-RF",
                    "C1F1PAIRSPC1K9=",
                    "PROMOCT5520100K9RF",
                    "WLC-AP-T",
                    "CAB-ACI",
                    "CAB-ACA=",
                    "AIR-CT5520-SW-8.1",
                    "AIR-CT5520-SW-8.2",
                    "AIR-CT5520-SW-8.3",
                    "AIR-CT5520-K9-PM",
                    "AIR-CT5520-CAK9-RF",
                    "AIR-PSU1-770W-RF",
                    "AIR-PSU1-770W",
                    "CAB-AC",
                    "AIRSD240GBKS4EV-RF",
                    "CAB-ACU-RF",
                    "AIR-CT5520-50K9-WS",
                    "AIR-CT5508-100-K9",
                    "C1-AIR-CT5520-K9++",
                    "AIR-CT5520-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "C9800-40-universalk9_wlc.17.02.01.SPA.bin",
              imageUuid: "b30d5e14-9688-4ff1-a97a-97e0f056048c",
              version: "17.02.01.0.306",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "0720da4c6aa68707a07b2a17ec1734cd",
              shaCheckSum:
                "77f7d1a23587ef76c1010b7f860a297f1731df80f1438655a2b3611f3c24d2f82ecb242c008df448415b1882761ebcfa384649f5569493ff3cd62c07af0bda0c",
              createdTime: "2021-04-27 17:19:41.0",
              applicationType: null,
              feature: "",
              fileServiceId: "596f1a33-8a55-460f-9e36-87d3f69220f1",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.313.62478//localdisk/Staging/C9800-40-universalk9_wlc.17.02.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "C9800",
              vendor: "CISCO",
              filesize: 1155335584,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286321396",
                  productName: "Cisco Catalyst 9800-80 Wireless Controller",
                  productId: [
                    "LIC-C9800-DTLS-K9",
                    "C9800-1X40GE",
                    "SC980080K9-1610",
                    "C9800-2X40GE",
                    "C9800-1X100GE",
                    "EDU-C9800-80-K9",
                    "C9800-18X1GE",
                    "C9800-AC-1100W",
                    "EDU-C9800-80-S5-K9",
                    "C9800-80-SLRCA-K9",
                    "C9800-DC-950W",
                    "C9800-40-K9",
                    "C9800-80-CAP-K9",
                    "C9800-80-CA-K9",
                    "C9800-80-K9-RF",
                    "C9800-10X10GE",
                    "C9800-80-SLR-K9",
                    "C9800-80-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316412",
                  productName: "Cisco Catalyst 9800-40 Wireless Controller",
                  productId: [
                    "C9800-40-2K-SLR-K9",
                    "LIC-C9800-DTLS-K9",
                    "AIR-CT9540-CA-K9",
                    "CAB-C13-C14-JMPR",
                    "C9800-40-SLR-K9",
                    "AIR-DNA-A",
                    "C9800-L-F-K9",
                    "C9800-AC-750W-R-RF",
                    "EDU-C9800-40-K9",
                    "C9800-40-SLRCA-K9",
                    "C9800-AC-110W",
                    "AIR-CT9540-K9++",
                    "C9800-AC-110W=",
                    "AIR-DNA-EDU",
                    "C9800-40-CA-K9",
                    "C9800-40-K9",
                    "C9800-AC-750W-RED",
                    "C9800-40-K9-RF",
                    "SC980040K9-1610",
                    "C9800-AC-750W-R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: false,
            },
            {
              name: "cat9k_iosxe.17.06.01.SPA.bin",
              imageUuid: "b8dfc9a9-4000-4ecc-8c33-8885079db981",
              version: "17.06.01.0.250",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "fdb9c92bae37f9130d0ee6761afe2919",
              shaCheckSum:
                "504f982dadbbecbddb3d48fff152b5ec6ea6697ce8534f57e13d6fa04c2028e91804eac4a2e18f48cf46b878919059f02c58c664e48b71bd751519f5c1a2c122",
              createdTime: "2021-11-26 01:12:18.711",
              applicationType: null,
              feature: "",
              fileServiceId: "0ee03838-9ce4-47f8-a1dc-9044c402b61e",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.385.60720//localdisk/Staging/cat9k_iosxe.17.06.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "CAT9K",
              vendor: "CISCO",
              filesize: 1018097396,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 1,
              imageAttributes: [],
              isSMUApplicable: true,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286319592",
                  productName: "Cisco Catalyst C9500-32QC Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32QC-P",
                    "C9500-32QC",
                    "C9500-32QC=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32QC-EDU-RF",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316172",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1",
                  productId: [
                    "C9404-FAN=",
                    "C9400-SUP-1",
                    "C9404-FAN",
                    "C9400-SSD-960GB=",
                    "C9410R",
                    "C9400-SSD-980GB=",
                    "C9400-SUP-1/2",
                    "C9400-SSD-480GB",
                    "C9400-SSD-240GB",
                    "C9400-SUP-1-B",
                    "C9400-SSD-960GB",
                    "C9400-SUP-1=",
                    "C9400-SSD-480GB=",
                    "C9400-SUP-1-RF",
                    "C9400-SSD-240GB=",
                    "C9407R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315703",
                  productName: "Cisco Catalyst 9410R Switch",
                  productId: [
                    "C9400-LC-24XS-WS",
                    "C9400-LC-48T++=",
                    "C9410R-96UBNDLA-RF",
                    "C9400-SUP-1XL=",
                    "C9410R-WS",
                    "C9410R-96U-BNDL-A",
                    "C9400-SUP-1XL++=",
                    "C9410-96U-BNDL-CX",
                    "C9410R-96U-BNDL-E",
                    "C9400-LC-48S=",
                    "C9410R-96U-BNDL-1A",
                    "C9410R-P",
                    "C9410R-96U-BDL-EDU",
                    "C9400-LC-48UX++=",
                    "C9410R-96U-STK-EDU",
                    "C9400-LC-48P",
                    "C9400-LC-48P-WS",
                    "C9400-LC-48S",
                    "C9410R-UL",
                    "C9400-LC-24S",
                    "C9400-LC-24XS++=",
                    "C9400-SUP-1XL-B",
                    "C9400-SUP-1++=",
                    "C9400-LC-24XS",
                    "C9400-LC-24XS=",
                    "C9400-LC-48P=",
                    "C9400-LC-48U-B1",
                    "C9410R",
                    "C9400-LC-48U++=",
                    "C9400-LC-48UX-WS",
                    "C9410R-96UBNDLA-WS",
                    "C9400-PWR-2400AC",
                    "C9410R-10A",
                    "C9400-LC-48UX",
                    "C9400-SUP-1XL-WS",
                    "C9400-SR-BLANK=",
                    "C9400-PWR-2400AC=",
                    "C9410R-96U-BNDL-1E",
                    "C9400-LC-48UX=",
                    "C9410R-RF",
                    "C9400-SUP-1XL",
                    "C9410R-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320244",
                  productName: "Cisco Catalyst 9404R Switch",
                  productId: [
                    "C9404R",
                    "C9404R-WS",
                    "C9404R-48UBNDLA-RF",
                    "C9404R-RF",
                    "C9404R-UL",
                    "C9404R=",
                    "C9404R-1A",
                    "C9404R-10A",
                    "C9404R-P",
                    "C9404R-48U-BNDL-A",
                    "C9404R-48U-BDL-EDU",
                    "C9404R-48U-BNDL-1E",
                    "C9404R-48U-BNDL-E",
                    "C9404R-48U-BNDL-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286323141",
                  productName: "Cisco Catalyst 9600 Series Supervisor Engine 1",
                  productId: [
                    "C9600X-SUP-2",
                    "C9600-SUP-1-WS",
                    "C9600X-SUP-2=",
                    "C9600-SUP-1/2",
                    "C9600-SUP-1",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320497",
                  productName: "Cisco Catalyst C9500 SVL Switch",
                  productId: ["C9500-24Y4C-E-RF", "C9500-24Y4C-10E"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315863",
                  productName: "Cisco Catalyst 9500 Switch",
                  productId: [
                    "C9500-DNA-L-P",
                    "C9500X-ACCKIT-19I",
                    "C9500X-FAN-1U-R=",
                    "C9500-ACCKIT-23I",
                    "C9K-PWR-1500WAC-R",
                    "NAL-C9500-48X-E",
                    "NAL-C9500-48X-A",
                    "PWR-C4-950WAC-R",
                    "C9K-PWR-1600WDCR/2",
                    "C9500-ACCKIT-19I",
                    "C9500X-ACCKIT-19I=",
                    "C9500X-FAN-1U-R",
                    "C9K-PWR-C5-BLANK",
                    "HX-9500-8E",
                    "C9K-PWR-1600WDC-R=",
                    "C9500X-FAN-1U-F",
                    "C9K-PWR-1500WDC-F",
                    "C9500X-4PTH-KIT",
                    "PWR-C4-BLANK",
                    "C9K-PWR-1600WAC-R=",
                    "C9500X-FAN-1U-F=",
                    "C9K-PWR-1600WDC-R",
                    "C9K-PWR-1500WDC-R",
                    "C9K-F1-SSD-240G=",
                    "C9500-ACCKITH-19I=",
                    "C9500-ACCKIT-19I=",
                    "C9K-F1-SSD-240G",
                    "C9K-PWR-C4-BLANK",
                    "C9500-4PTH-KIT=",
                    "C9K-PWR-650WAC-R/2",
                    "C9500-DNA-P=",
                    "C9K-F1-SSD-480G=",
                    "C9K-F1-SSD-240G-WS",
                    "C9K-PWR-1600WACR/2",
                    "HX-9500-8E=",
                    "C9500-16X-EDU-RF",
                    "C9K-F1-SSD-BLANK",
                    "C9K-PWR-C5-BLANK=",
                    "C9K-PWR-1500WAC-F",
                    "C9K-F1-SSD-960G=",
                    "C9K-PWR-1500WDC-F=",
                    "C9500X-60L4D",
                    "C9500-ACCKIT-23I=",
                    "C9K-F3-SSD-240GB=",
                    "C9K-F1-SSD-960G",
                    "C9K-F3-SSD-240GB",
                    "C9K-F3-SSD-BLANK=",
                    "C9K-PWR-1500WDC-R=",
                    "C9500-ACCKITH-23I",
                    "C9K-PWR-1500WDCR/2",
                    "C9500X-4PTH-KIT=",
                    "C9500X-ACCKIT-23I=",
                    "C9K-PWR-1500WAC-R=",
                    "C9K-PWR-1500WDCF/2",
                    "C9500-SPS-NONE",
                    "C9K-PWR-930WDC-R/2",
                    "C9K-F1-SSD-480G",
                    "PWR-C4-BLANK=",
                    "C9500-4PTH-KIT",
                    "C9K-F3-SSD-480GB=",
                    "C9K-F3-SSD-960GB",
                    "C9500-ACCKITH-19I",
                    "C9K-PWR-1500WACF/2",
                    "C9K-PWR-1500WAC-F=",
                    "C9500-4PT-KIT",
                    "C9K-PWR-C4-BLANK=",
                    "PWR-C4-950WAC-R-RF",
                    "C9K-F3-SSD-480GB",
                    "C9K-F3-SSD-960GB=",
                    "C9500X-ACCKIT-23I",
                    "C9K-PWR-1600WAC-R",
                    "C9500-DNA-P",
                    "C9K-F3-SSD-BLANK",
                    "C9500-ACCKITH-23I=",
                    "C9500-DNA-L-P=",
                    "C9K-PWR-1500WACR/2",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316710",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL",
                  productId: [
                    "C9400-SUP-1XL-WS",
                    "C9400-SUP-1XL-RF",
                    "C9400-SUP-1XL0-RF",
                    "C9400-SUP-1XL/2",
                    "C9400-SUP-1XL",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319595",
                  productName: "Cisco Catalyst C9500-24Y4C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "L-C9500-24Y4C-EA-3",
                    "C9500-24Y4C-EDU-RF",
                    "L-C9500-24Y4C-EA-7",
                    "C9500-24Y4C=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-60C12D-A",
                    "C9500-24Y4C-CX",
                    "C9500-24Y4C",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320394",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL-Y",
                  productId: [
                    "C9400-SUP-1XL-Y-WS",
                    "C9400-SUP-1XL-Y",
                    "C9400-SUP-1XL-Y/2",
                    "C9400-DNX-A-10Y",
                    "C9400-SUP-1XL-Y=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322137",
                  productName: "Cisco Catalyst 9606R Switch",
                  productId: [
                    "C9606R-48Y24C-BN-A",
                    "C9600-SSD-NONE",
                    "C9606R-48Y24C-BNCX",
                    "QSFP-40G-CSR-S-RF",
                    "C9606R=",
                    "C9606-FILTER=",
                    "C9606=",
                    "C9606R-48Y24C-EDU",
                    "BN-A1-SBA-K-K9",
                    "C9606R-EDU",
                    "C9606",
                    "C9606-RACK-KIT=",
                    "C9606R-RF",
                    "BN-A1-SBA-C-K9",
                    "C9606R-1A",
                    "BN-A1-SBA-I-K9",
                    "C9606R-P",
                    "HWOA-TETR-PLT",
                    "C9606R",
                    "C9606R-48S-BN-A",
                    "C9606R-10A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319589",
                  productName: "Cisco Catalyst C9500-48Y4C Switch",
                  productId: [
                    "C9500-48Y4C",
                    "C9500-48Y4C=",
                    "L-C9500-48Y4C-EA-3",
                    "C9500H-ACCKIT-19I=",
                    "C9500-48Y4C-CX",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322029",
                  productName: "Cisco Catalyst 9300L Switch Stack",
                  productId: [
                    "S9300LNPE-174",
                    "S9300LUK9-174",
                    "C9300L-STACKBLANK=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315691",
                  productName: "Cisco Catalyst 9407R Switch",
                  productId: [
                    "C9400-PWR-2100AC",
                    "C9407R-96U-BNDL-E",
                    "C9407-RACK19KIT-WS",
                    "C9407R-RF",
                    "S9400NPE-174",
                    "C9407R-10A",
                    "S9400UK9-174",
                    "C9407R-1A",
                    "C9407R-96U-STK-EDU",
                    "C9407R",
                    "C9407R-96UBNDLA-WS",
                    "C9407R-96U-BDL-EDU",
                    "C9407R-96UBNDLE-RF",
                    "C9407R-96UBNDLA-RF",
                    "C9407-RACK19KIT-RF",
                    "C9400-PWR-2400AC",
                    "C9407R-UL",
                    "C9400-SR-BLANK=",
                    "C9407R-96U-BNDL-1E",
                    "C9407-96U-BNDL-CX",
                    "C9407R-96U-BNDL-A",
                    "C9400-PWR-2400AC=",
                    "C9407R-96U-BNDL-1A",
                    "C9407R-WS",
                    "C9407R-P",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315874",
                  productName: "Cisco Catalyst 9300 Switch",
                  productId: [
                    "C9300-NM-4G",
                    "C9300-NM-4M-WS",
                    "C9300-NM-8X=",
                    "SC9300UK9-174",
                    "C9300-NM-4M",
                    "C9300-NM-2X=",
                    "C9300-NM-4X=",
                    "S9300NPE-174",
                    "C9300-24-E-A",
                    "C9300-NM-8X",
                    "C9300-NM-2Q",
                    "C9200-48PXG-EDU",
                    "C9300-NM-4G=",
                    "C9300-NM-4G-WS",
                    "C9300-48-E-A",
                    "C9300-NM-BLANK=",
                    "C9300-NM-2Q=",
                    "C9200-24PXG-EDU",
                    "C9300-NM-BLANK",
                    "C9300-NM-2Y=",
                    "C9300-SSD-PROMO",
                    "C9300-NM-8X-WS",
                    "C9300-NM-8X-UL",
                    "C9300-NM-4X",
                    "C9300-NM-2Y",
                    "C9300-RFID=",
                    "C9300-NM-2X",
                    "SSD-PROMO",
                    "C9300-NM-2Q-WS",
                    "C9300-NM-2Y-WS",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319599",
                  productName: "Cisco Catalyst C9500-32C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32C-P",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32C",
                    "C9500-32C=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "C9800-L-universalk9_wlc.17.06.01.SPA.bin",
              imageUuid: "befb90e1-ef01-49d1-872e-e5f8624ac650",
              version: "17.06.01.0.250",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "119f4d37e35d0a8d9140c201ac359cfd",
              shaCheckSum:
                "e3290c29e42cbc5c6ab6ce3f97e3d18ec1d5fc2b0900f217cbeca4d32638305ddb1a6c0ca261fedcd2571adb76e4a8ac4deb740cc87226c3966536ba3bf0bf8b",
              createdTime: "2021-11-28 01:12:18.705",
              applicationType: null,
              feature: "",
              fileServiceId: "74f84001-5fd6-478d-937b-6c696cb9e7aa",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.388.60445//localdisk/Staging/C9800-L-universalk9_wlc.17.06.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "C9800-L",
              vendor: "CISCO",
              filesize: 1146960149,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286323158",
                  productName: "Cisco Catalyst 9800-L-C Wireless Controller",
                  productId: [
                    "C9800-L-C-K9-RF",
                    "EDU-C9800-L-C-K9",
                    "C9800-L-F-K9",
                    "C9800-L-C-K9-WS",
                    "C9800-L-C-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286321399",
                  productName: "Cisco Catalyst 9800-L-F Wireless Controller",
                  productId: ["EDU-C9800-L-F-K9", "C9800-L-F-K9", "C9800-L-C-K9"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "cat9k_iosxe.17.03.03.SPA.bin",
              imageUuid: "c7c3adac-e446-416f-9e8b-21704458702b",
              version: "17.03.03.0.4762",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "89c98b1ed44cf6cb1190eca977edb9a5",
              shaCheckSum:
                "a91e2c868b8fd64b12e44c18b54e7e00947ecaf73fc5a809739908d57bafde08c62da4b175b26e9f4c60e4e59b36aad9b4cc30a9ad3714aad708f37c03fe62c0",
              createdTime: "2021-07-23 01:12:18.684",
              applicationType: null,
              feature: "",
              fileServiceId: "59fbd410-539c-4a6b-a3a0-fa8071f39733",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.310.61012//localdisk/Staging/cat9k_iosxe.17.03.03.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "CAT9K",
              vendor: "CISCO",
              filesize: 901741775,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286315874",
                  productName: "Cisco Catalyst 9300 Switch",
                  productId: [
                    "C9300-NM-4G",
                    "C9300-NM-4M-WS",
                    "C9300-NM-8X=",
                    "SC9300UK9-174",
                    "C9300-NM-4M",
                    "C9300-NM-2X=",
                    "C9300-NM-4X=",
                    "S9300NPE-174",
                    "C9300-24-E-A",
                    "C9300-NM-8X",
                    "C9300-NM-2Q",
                    "C9200-48PXG-EDU",
                    "C9300-NM-4G=",
                    "C9300-NM-4G-WS",
                    "C9300-48-E-A",
                    "C9300-NM-BLANK=",
                    "C9300-NM-2Q=",
                    "C9200-24PXG-EDU",
                    "C9300-NM-BLANK",
                    "C9300-NM-2Y=",
                    "C9300-SSD-PROMO",
                    "C9300-NM-8X-WS",
                    "C9300-NM-8X-UL",
                    "C9300-NM-4X",
                    "C9300-NM-2Y",
                    "C9300-RFID=",
                    "C9300-NM-2X",
                    "SSD-PROMO",
                    "C9300-NM-2Q-WS",
                    "C9300-NM-2Y-WS",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286323141",
                  productName: "Cisco Catalyst 9600 Series Supervisor Engine 1",
                  productId: [
                    "C9600X-SUP-2",
                    "C9600-SUP-1-WS",
                    "C9600X-SUP-2=",
                    "C9600-SUP-1/2",
                    "C9600-SUP-1",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320394",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL-Y",
                  productId: [
                    "C9400-SUP-1XL-Y-WS",
                    "C9400-SUP-1XL-Y",
                    "C9400-SUP-1XL-Y/2",
                    "C9400-DNX-A-10Y",
                    "C9400-SUP-1XL-Y=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320244",
                  productName: "Cisco Catalyst 9404R Switch",
                  productId: [
                    "C9404R",
                    "C9404R-WS",
                    "C9404R-48UBNDLA-RF",
                    "C9404R-RF",
                    "C9404R-UL",
                    "C9404R=",
                    "C9404R-1A",
                    "C9404R-10A",
                    "C9404R-P",
                    "C9404R-48U-BNDL-A",
                    "C9404R-48U-BDL-EDU",
                    "C9404R-48U-BNDL-1E",
                    "C9404R-48U-BNDL-E",
                    "C9404R-48U-BNDL-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315691",
                  productName: "Cisco Catalyst 9407R Switch",
                  productId: [
                    "C9400-PWR-2100AC",
                    "C9407R-96U-BNDL-E",
                    "C9407-RACK19KIT-WS",
                    "C9407R-RF",
                    "S9400NPE-174",
                    "C9407R-10A",
                    "S9400UK9-174",
                    "C9407R-1A",
                    "C9407R-96U-STK-EDU",
                    "C9407R",
                    "C9407R-96UBNDLA-WS",
                    "C9407R-96U-BDL-EDU",
                    "C9407R-96UBNDLE-RF",
                    "C9407R-96UBNDLA-RF",
                    "C9407-RACK19KIT-RF",
                    "C9400-PWR-2400AC",
                    "C9407R-UL",
                    "C9400-SR-BLANK=",
                    "C9407R-96U-BNDL-1E",
                    "C9407-96U-BNDL-CX",
                    "C9407R-96U-BNDL-A",
                    "C9400-PWR-2400AC=",
                    "C9407R-96U-BNDL-1A",
                    "C9407R-WS",
                    "C9407R-P",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319589",
                  productName: "Cisco Catalyst C9500-48Y4C Switch",
                  productId: [
                    "C9500-48Y4C",
                    "C9500-48Y4C=",
                    "L-C9500-48Y4C-EA-3",
                    "C9500H-ACCKIT-19I=",
                    "C9500-48Y4C-CX",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319599",
                  productName: "Cisco Catalyst C9500-32C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32C-P",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32C",
                    "C9500-32C=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316710",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL",
                  productId: [
                    "C9400-SUP-1XL-WS",
                    "C9400-SUP-1XL-RF",
                    "C9400-SUP-1XL0-RF",
                    "C9400-SUP-1XL/2",
                    "C9400-SUP-1XL",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322029",
                  productName: "Cisco Catalyst 9300L Switch Stack",
                  productId: [
                    "S9300LNPE-174",
                    "S9300LUK9-174",
                    "C9300L-STACKBLANK=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319592",
                  productName: "Cisco Catalyst C9500-32QC Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32QC-P",
                    "C9500-32QC",
                    "C9500-32QC=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32QC-EDU-RF",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315703",
                  productName: "Cisco Catalyst 9410R Switch",
                  productId: [
                    "C9400-LC-24XS-WS",
                    "C9400-LC-48T++=",
                    "C9410R-96UBNDLA-RF",
                    "C9400-SUP-1XL=",
                    "C9410R-WS",
                    "C9410R-96U-BNDL-A",
                    "C9400-SUP-1XL++=",
                    "C9410-96U-BNDL-CX",
                    "C9410R-96U-BNDL-E",
                    "C9400-LC-48S=",
                    "C9410R-96U-BNDL-1A",
                    "C9410R-P",
                    "C9410R-96U-BDL-EDU",
                    "C9400-LC-48UX++=",
                    "C9410R-96U-STK-EDU",
                    "C9400-LC-48P",
                    "C9400-LC-48P-WS",
                    "C9400-LC-48S",
                    "C9410R-UL",
                    "C9400-LC-24S",
                    "C9400-LC-24XS++=",
                    "C9400-SUP-1XL-B",
                    "C9400-SUP-1++=",
                    "C9400-LC-24XS",
                    "C9400-LC-24XS=",
                    "C9400-LC-48P=",
                    "C9400-LC-48U-B1",
                    "C9410R",
                    "C9400-LC-48U++=",
                    "C9400-LC-48UX-WS",
                    "C9410R-96UBNDLA-WS",
                    "C9400-PWR-2400AC",
                    "C9410R-10A",
                    "C9400-LC-48UX",
                    "C9400-SUP-1XL-WS",
                    "C9400-SR-BLANK=",
                    "C9400-PWR-2400AC=",
                    "C9410R-96U-BNDL-1E",
                    "C9400-LC-48UX=",
                    "C9410R-RF",
                    "C9400-SUP-1XL",
                    "C9410R-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319595",
                  productName: "Cisco Catalyst C9500-24Y4C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "L-C9500-24Y4C-EA-3",
                    "C9500-24Y4C-EDU-RF",
                    "L-C9500-24Y4C-EA-7",
                    "C9500-24Y4C=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-60C12D-A",
                    "C9500-24Y4C-CX",
                    "C9500-24Y4C",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320497",
                  productName: "Cisco Catalyst C9500 SVL Switch",
                  productId: ["C9500-24Y4C-E-RF", "C9500-24Y4C-10E"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322137",
                  productName: "Cisco Catalyst 9606R Switch",
                  productId: [
                    "C9606R-48Y24C-BN-A",
                    "C9600-SSD-NONE",
                    "C9606R-48Y24C-BNCX",
                    "QSFP-40G-CSR-S-RF",
                    "C9606R=",
                    "C9606-FILTER=",
                    "C9606=",
                    "C9606R-48Y24C-EDU",
                    "BN-A1-SBA-K-K9",
                    "C9606R-EDU",
                    "C9606",
                    "C9606-RACK-KIT=",
                    "C9606R-RF",
                    "BN-A1-SBA-C-K9",
                    "C9606R-1A",
                    "BN-A1-SBA-I-K9",
                    "C9606R-P",
                    "HWOA-TETR-PLT",
                    "C9606R",
                    "C9606R-48S-BN-A",
                    "C9606R-10A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316172",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1",
                  productId: [
                    "C9404-FAN=",
                    "C9400-SUP-1",
                    "C9404-FAN",
                    "C9400-SSD-960GB=",
                    "C9410R",
                    "C9400-SSD-980GB=",
                    "C9400-SUP-1/2",
                    "C9400-SSD-480GB",
                    "C9400-SSD-240GB",
                    "C9400-SUP-1-B",
                    "C9400-SSD-960GB",
                    "C9400-SUP-1=",
                    "C9400-SSD-480GB=",
                    "C9400-SUP-1-RF",
                    "C9400-SSD-240GB=",
                    "C9407R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315863",
                  productName: "Cisco Catalyst 9500 Switch",
                  productId: [
                    "C9500-DNA-L-P",
                    "C9500X-ACCKIT-19I",
                    "C9500-ACCKIT-23I",
                    "C9500X-FAN-1U-R=",
                    "C9K-PWR-1500WAC-R",
                    "NAL-C9500-48X-E",
                    "NAL-C9500-48X-A",
                    "PWR-C4-950WAC-R",
                    "C9K-PWR-1600WDCR/2",
                    "C9500-ACCKIT-19I",
                    "C9500X-ACCKIT-19I=",
                    "C9K-PWR-C5-BLANK",
                    "C9500X-FAN-1U-R",
                    "HX-9500-8E",
                    "C9K-PWR-1600WDC-R=",
                    "C9500X-FAN-1U-F",
                    "C9K-PWR-1500WDC-F",
                    "C9500X-4PTH-KIT",
                    "PWR-C4-BLANK",
                    "C9K-PWR-1600WAC-R=",
                    "C9500X-FAN-1U-F=",
                    "C9K-PWR-1600WDC-R",
                    "C9K-F1-SSD-240G=",
                    "C9K-PWR-1500WDC-R",
                    "C9500-ACCKITH-19I=",
                    "C9500-ACCKIT-19I=",
                    "C9K-F1-SSD-240G",
                    "C9K-PWR-C4-BLANK",
                    "C9500-4PTH-KIT=",
                    "C9K-PWR-650WAC-R/2",
                    "C9500-DNA-P=",
                    "C9K-F1-SSD-480G=",
                    "C9K-F1-SSD-240G-WS",
                    "C9K-PWR-1600WACR/2",
                    "C9500-16X-EDU-RF",
                    "C9K-F1-SSD-BLANK",
                    "HX-9500-8E=",
                    "C9K-PWR-C5-BLANK=",
                    "C9K-PWR-1500WAC-F",
                    "C9K-F1-SSD-960G=",
                    "C9K-PWR-1500WDC-F=",
                    "C9500X-60L4D",
                    "C9500-ACCKIT-23I=",
                    "C9K-F1-SSD-960G",
                    "C9K-F3-SSD-240GB=",
                    "C9K-F3-SSD-240GB",
                    "C9K-F3-SSD-BLANK=",
                    "C9K-PWR-1500WDC-R=",
                    "C9500-ACCKITH-23I",
                    "C9K-PWR-1500WDCR/2",
                    "C9500X-4PTH-KIT=",
                    "C9500X-ACCKIT-23I=",
                    "C9K-PWR-1500WAC-R=",
                    "C9K-PWR-1500WDCF/2",
                    "C9500-SPS-NONE",
                    "C9K-PWR-930WDC-R/2",
                    "C9K-F1-SSD-480G",
                    "PWR-C4-BLANK=",
                    "C9500-4PTH-KIT",
                    "C9K-F3-SSD-480GB=",
                    "C9K-F3-SSD-960GB",
                    "C9500-ACCKITH-19I",
                    "C9K-PWR-1500WACF/2",
                    "C9K-PWR-1500WAC-F=",
                    "C9500-4PT-KIT",
                    "C9K-PWR-C4-BLANK=",
                    "PWR-C4-950WAC-R-RF",
                    "C9K-F3-SSD-480GB",
                    "C9K-PWR-1600WAC-R",
                    "C9K-F3-SSD-960GB=",
                    "C9500X-ACCKIT-23I",
                    "C9500-DNA-P",
                    "C9500-ACCKITH-23I=",
                    "C9K-F3-SSD-BLANK",
                    "C9500-DNA-L-P=",
                    "C9K-PWR-1500WACR/2",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: true,
              ccolatest: false,
            },
            {
              name: "C9800-L-universalk9_wlc.17.04.01.SPA.bin",
              imageUuid: "d1f434ba-1c44-4a07-93f4-cb87f8c7f557",
              version: "17.04.01.0.173",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "398db90773a8e6095a918d22c2e59958",
              shaCheckSum:
                "1f031e0766b8e0a2e387d07b995f8c2860b814cced03ea00a48e53296b5cf5af6037ee001952878e3fbab32c15eeb5ca2339f066614dfd1199286e8376aa8c57",
              createdTime: "2021-05-01 04:38:37.0",
              applicationType: null,
              feature: "",
              fileServiceId: "f16de39e-410a-4216-941a-8d8e464394c2",
              imageSeries: null,
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "C9800-L",
              vendor: "CISCO",
              filesize: 1093786963,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286321399",
                  productName: "Cisco Catalyst 9800-L-F Wireless Controller",
                  productId: ["EDU-C9800-L-F-K9", "C9800-L-F-K9"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286323158",
                  productName: "Cisco Catalyst 9800-L-C Wireless Controller",
                  productId: [
                    "C9800-L-C-K9-RF",
                    "EDU-C9800-L-C-K9",
                    "C9800-L-F-K9",
                    "C9800-L-C-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "AIR-CT5520-K9-8-10-151-0.aes",
              imageUuid: "e4980bb1-cd20-47d1-8dc0-f5e4d689f21a",
              version: "8.10.151.0",
              softwareType: null,
              imageType: "WLC_SW",
              isTaggedGolden: "false",
              md5Checksum: "0ff5158d79916f32f5363f8425c6686b",
              shaCheckSum:
                "1a556b7736323e5b3b3a97d67bd8ecafd71c2b44ce72bc9db01b4940c572e6e0d68cc514812393a921239d3b4e4534472224736a5e0604f9cdd1a6cb6724378b",
              createdTime: "2021-10-25 01:12:18.696",
              applicationType: null,
              feature: null,
              fileServiceId: "b1465247-f16a-4753-815b-194e8733cbe8",
              imageSeries: [
                "Wireless Controllers/Cisco 5500 Series Wireless LAN Controllers",
              ],
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "AIR-CT5520",
              vendor: "CISCO",
              filesize: 518731902,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286284599",
                  productName: "Cisco 8540 Series Wireless Controllers",
                  productId: [
                    "C1F1UAIRK9=",
                    "PROMOCT8540-300-K9",
                    "C1F2UAIRK9=",
                    "AIR-CT8510100K9-RF",
                    "AIR-CT8540-1K-K9++",
                    "AIRSD240G0KS2EV-RF",
                    "AIR-CT8540-K9-WS",
                    "LIC-CT8540-UPG",
                    "AIR-BZL-C240M4",
                    "C1F1UAIRK9",
                    "LIC-CT8540-1AP-INT",
                    "AIR-CT8540-K9",
                    "PROMOCT8540-3-K9",
                    "AIR-CT8540-CA-K9",
                    "AIR-CT8540-SW-8.3",
                    "AIR-PSU-930WDC-RF",
                    "AIR-PSU2V21200W-WS",
                    "AIR-CT8540-SW-8.2",
                    "LIC-CT8500-SP-1A",
                    "AIR-CT8540-SW-8.1",
                    "C1F1PAIRSPC1K9=",
                    "AIR-CT8540-1K-K9",
                    "AIR-CT8540-1KK9-RF",
                    "C1-AIR-CT8540-K9++",
                    "AIR-PSU2V2-1200W=",
                    "AIR-SD240G0KS2-EV=",
                    "AIR-SD240G0KS2-EV",
                    "C1-AIR-CT8540-K9",
                    "C1F2UAIRK9",
                    "AIR-CT8540-K9-PM",
                    "EDU-CT8540-1K-K9",
                    "AIR-CT85DC-SP-K9",
                    "AIR-CT9540-K9",
                    "C1-AIR-CT8540K9-RF",
                    "AIR-CT8540-DC-K9Z",
                    "AIR-CT8540-K9-RF",
                    "AIR-PSU-930WDC=",
                    "AIR-PSU2V21200W-RF",
                    "AIR-PSU-930WDC",
                    "AIR-PSU2V2-1200W",
                    "AIR-BZL-C240M4=",
                    "AIR-CT8540-K9++",
                    "AIR-CT8510-100-K9",
                    "AIR-CT8540-SW-8.10",
                    "AIR-CT8540-K9Z",
                    "AIR-CT8540-DC-K9",
                    "EDU-CT8540-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286284588",
                  productName: "Cisco 5520 Series Wireless Controllers",
                  productId: [
                    "C1F1UAIRK9=",
                    "AIR-CT5520-50-K9",
                    "C1F2UAIRK9=",
                    "AIR-CT5520-SW-8.10",
                    "EDU-CT5520-K9",
                    "AIR-FAN-C220M4=",
                    "AIRCT5508-100K9-WS",
                    "EDU-CT5520-50-K9",
                    "LIC-CT5520-1AP-INT",
                    "AIR-PSU1-770W-WS",
                    "AIR-BZL-C220M4=",
                    "CAB-AC-WS",
                    "C1-AIR-CT5520-K9",
                    "AIR-CT5508500K9-WS",
                    "AIR-CT5520-K9Z",
                    "AIR-CT5520-K9-RF",
                    "AIR-CT6880-NIC-K9",
                    "AIR-SD240G0KS2-EV=",
                    "AIR-SD240G0KS2-EV",
                    "AIR-PSU1-770W=",
                    "AIR-SD240GBKS4-EV=",
                    "AIR-CT5520-50K9-RF",
                    "CAB-ACI=",
                    "C1F2UAIRK9",
                    "PROMOCT5520-100-K9",
                    "AIR-CT5520-K9++",
                    "CAB-ACU",
                    "CAB-ACU=",
                    "EDU-LIC-CT5520-UPG",
                    "AIR-CT5520-CA-K9",
                    "CAB-AC-RF",
                    "AIR-CT5508-HA-K9",
                    "AIRCT5508-100K9-RF",
                    "LIC-CT5520-UPG",
                    "AIR-SD240GBKS4-EV",
                    "AIR-CT5520-K9-WS",
                    "AIR-CT5520-50-K9++",
                    "AIRSD240G0KS2EV-RF",
                    "C1F1UAIRK9",
                    "CAB-ACA",
                    "AIR-BZL-C220M4",
                    "AIR-CT6870-NIC-K9",
                    "CAB-AC=",
                    "C1-AIR-CT5520K9-RF",
                    "C1F1PAIRSPC1K9=",
                    "PROMOCT5520100K9RF",
                    "WLC-AP-T",
                    "CAB-ACI",
                    "CAB-ACA=",
                    "AIR-CT5520-SW-8.1",
                    "AIR-CT5520-SW-8.2",
                    "AIR-CT5520-SW-8.3",
                    "AIR-CT5520-K9-PM",
                    "AIR-CT5520-CAK9-RF",
                    "AIR-PSU1-770W-RF",
                    "AIR-PSU1-770W",
                    "CAB-AC",
                    "AIRSD240GBKS4EV-RF",
                    "CAB-ACU-RF",
                    "AIR-CT5520-50K9-WS",
                    "AIR-CT5508-100-K9",
                    "C1-AIR-CT5520-K9++",
                    "AIR-CT5520-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: true,
              ccolatest: false,
            },
            {
              name: "C9800-40-universalk9_wlc.17.06.01.SPA.bin",
              imageUuid: "e559ba4c-4384-40ca-8605-cdf6001a1cf6",
              version: "17.06.01.0.250",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "3be6ae9ef1e2be1ccfa19800b3475473",
              shaCheckSum:
                "e718e05880bb6518664e724e86696e19067cd755eef3dc9ab40510e0d47f78f41e48e2ce881e9a80a060441726c01d938f31ed271335dffe2c49386a721f69e1",
              createdTime: "2021-11-28 01:12:18.705",
              applicationType: null,
              feature: "",
              fileServiceId: "b2548eaa-68b0-4edf-a800-30a682b198c9",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.385.60720//localdisk/Staging/C9800-40-universalk9_wlc.17.06.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "C9800",
              vendor: "CISCO",
              filesize: 1233172048,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286321396",
                  productName: "Cisco Catalyst 9800-80 Wireless Controller",
                  productId: [
                    "LIC-C9800-DTLS-K9",
                    "C9800-1X40GE",
                    "SC980080K9-1610",
                    "C9800-2X40GE",
                    "C9800-1X100GE",
                    "EDU-C9800-80-K9",
                    "C9800-18X1GE",
                    "C9800-AC-1100W",
                    "EDU-C9800-80-S5-K9",
                    "C9800-80-SLRCA-K9",
                    "C9800-DC-950W",
                    "C9800-40-K9",
                    "C9800-80-CAP-K9",
                    "C9800-80-CA-K9",
                    "C9800-80-K9-RF",
                    "C9800-10X10GE",
                    "C9800-80-SLR-K9",
                    "C9800-80-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316412",
                  productName: "Cisco Catalyst 9800-40 Wireless Controller",
                  productId: [
                    "C9800-40-2K-SLR-K9",
                    "LIC-C9800-DTLS-K9",
                    "AIR-CT9540-CA-K9",
                    "CAB-C13-C14-JMPR",
                    "C9800-40-SLR-K9",
                    "AIR-DNA-A",
                    "C9800-L-F-K9",
                    "C9800-AC-750W-R-RF",
                    "EDU-C9800-40-K9",
                    "C9800-40-SLRCA-K9",
                    "C9800-AC-110W",
                    "AIR-CT9540-K9++",
                    "C9800-AC-110W=",
                    "AIR-DNA-EDU",
                    "C9800-40-CA-K9",
                    "C9800-40-K9",
                    "C9800-AC-750W-RED",
                    "C9800-40-K9-RF",
                    "SC980040K9-1610",
                    "C9800-AC-750W-R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "cat9k_iosxe.17.04.01.SPA.bin",
              imageUuid: "f0a2f249-a4b2-4e2a-b803-1520411fe301",
              version: "17.04.01.0.173",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "d2deac5602813c0d8e655291f8c1405e",
              shaCheckSum:
                "766b91d177c240a7e2325596793c842c774858158feab955b877f1a59dd35ea9ff32cf6d4bc16205cf5e1fec873770a0b779dd189524e8492f6631555c840da0",
              createdTime: "2021-05-25 01:12:19.608",
              applicationType: null,
              feature: "",
              fileServiceId: "6b812ed7-6583-4a45-a0e1-f5ae4a5f945f",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.313.62478//localdisk/Staging/cat9k_iosxe.17.04.01.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "CAT9K",
              vendor: "CISCO",
              filesize: 938738554,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286323141",
                  productName: "Cisco Catalyst 9600 Series Supervisor Engine 1",
                  productId: [
                    "C9600X-SUP-2",
                    "C9600-SUP-1-WS",
                    "C9600X-SUP-2=",
                    "C9600-SUP-1/2",
                    "C9600-SUP-1",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315691",
                  productName: "Cisco Catalyst 9407R Switch",
                  productId: [
                    "C9400-PWR-2100AC",
                    "C9407R-96U-BNDL-E",
                    "C9407-RACK19KIT-WS",
                    "C9407R-RF",
                    "S9400NPE-174",
                    "C9407R-10A",
                    "S9400UK9-174",
                    "C9407R-1A",
                    "C9407R-96U-STK-EDU",
                    "C9407R",
                    "C9407R-96UBNDLA-WS",
                    "C9407R-96U-BDL-EDU",
                    "C9407R-96UBNDLE-RF",
                    "C9407R-96UBNDLA-RF",
                    "C9407-RACK19KIT-RF",
                    "C9400-PWR-2400AC",
                    "C9407R-UL",
                    "C9400-SR-BLANK=",
                    "C9407R-96U-BNDL-1E",
                    "C9407-96U-BNDL-CX",
                    "C9407R-96U-BNDL-A",
                    "C9400-PWR-2400AC=",
                    "C9407R-96U-BNDL-1A",
                    "C9407R-WS",
                    "C9407R-P",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322029",
                  productName: "Cisco Catalyst 9300L Switch Stack",
                  productId: [
                    "S9300LNPE-174",
                    "S9300LUK9-174",
                    "C9300L-STACKBLANK=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322137",
                  productName: "Cisco Catalyst 9606R Switch",
                  productId: [
                    "C9606R-48Y24C-BN-A",
                    "C9600-SSD-NONE",
                    "C9606R-48Y24C-BNCX",
                    "QSFP-40G-CSR-S-RF",
                    "C9606R=",
                    "C9606-FILTER=",
                    "C9606=",
                    "C9606R-48Y24C-EDU",
                    "BN-A1-SBA-K-K9",
                    "C9606R-EDU",
                    "C9606",
                    "C9606-RACK-KIT=",
                    "C9606R-RF",
                    "BN-A1-SBA-C-K9",
                    "C9606R-1A",
                    "BN-A1-SBA-I-K9",
                    "C9606R-P",
                    "HWOA-TETR-PLT",
                    "C9606R",
                    "C9606R-48S-BN-A",
                    "C9606R-10A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320394",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL-Y",
                  productId: [
                    "C9400-SUP-1XL-Y-WS",
                    "C9400-SUP-1XL-Y",
                    "C9400-SUP-1XL-Y/2",
                    "C9400-DNX-A-10Y",
                    "C9400-SUP-1XL-Y=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319595",
                  productName: "Cisco Catalyst C9500-24Y4C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "L-C9500-24Y4C-EA-3",
                    "C9500-24Y4C-EDU-RF",
                    "L-C9500-24Y4C-EA-7",
                    "C9500-24Y4C=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-60C12D-A",
                    "C9500-24Y4C-CX",
                    "C9500-24Y4C",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319589",
                  productName: "Cisco Catalyst C9500-48Y4C Switch",
                  productId: [
                    "C9500-48Y4C",
                    "C9500-48Y4C=",
                    "L-C9500-48Y4C-EA-3",
                    "C9500H-ACCKIT-19I=",
                    "C9500-48Y4C-CX",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315703",
                  productName: "Cisco Catalyst 9410R Switch",
                  productId: [
                    "C9400-LC-24XS-WS",
                    "C9400-LC-48T++=",
                    "C9410R-96UBNDLA-RF",
                    "C9400-SUP-1XL=",
                    "C9410R-WS",
                    "C9410R-96U-BNDL-A",
                    "C9400-SUP-1XL++=",
                    "C9410-96U-BNDL-CX",
                    "C9410R-96U-BNDL-E",
                    "C9400-LC-48S=",
                    "C9410R-96U-BNDL-1A",
                    "C9410R-P",
                    "C9410R-96U-BDL-EDU",
                    "C9400-LC-48UX++=",
                    "C9410R-96U-STK-EDU",
                    "C9400-LC-48P",
                    "C9400-LC-48P-WS",
                    "C9400-LC-48S",
                    "C9410R-UL",
                    "C9400-LC-24S",
                    "C9400-LC-24XS++=",
                    "C9400-SUP-1XL-B",
                    "C9400-SUP-1++=",
                    "C9400-LC-24XS",
                    "C9400-LC-24XS=",
                    "C9400-LC-48P=",
                    "C9400-LC-48U-B1",
                    "C9410R",
                    "C9400-LC-48U++=",
                    "C9400-LC-48UX-WS",
                    "C9410R-96UBNDLA-WS",
                    "C9400-PWR-2400AC",
                    "C9410R-10A",
                    "C9400-LC-48UX",
                    "C9400-SUP-1XL-WS",
                    "C9400-SR-BLANK=",
                    "C9400-PWR-2400AC=",
                    "C9410R-96U-BNDL-1E",
                    "C9400-LC-48UX=",
                    "C9410R-RF",
                    "C9400-SUP-1XL",
                    "C9410R-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319592",
                  productName: "Cisco Catalyst C9500-32QC Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32QC-P",
                    "C9500-32QC",
                    "C9500-32QC=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32QC-EDU-RF",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316172",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1",
                  productId: [
                    "C9404-FAN=",
                    "C9400-SUP-1",
                    "C9404-FAN",
                    "C9400-SSD-960GB=",
                    "C9410R",
                    "C9400-SSD-980GB=",
                    "C9400-SUP-1/2",
                    "C9400-SSD-480GB",
                    "C9400-SSD-240GB",
                    "C9400-SUP-1-B",
                    "C9400-SSD-960GB",
                    "C9400-SUP-1=",
                    "C9400-SSD-480GB=",
                    "C9400-SUP-1-RF",
                    "C9400-SSD-240GB=",
                    "C9407R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316710",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL",
                  productId: [
                    "C9400-SUP-1XL-WS",
                    "C9400-SUP-1XL-RF",
                    "C9400-SUP-1XL0-RF",
                    "C9400-SUP-1XL/2",
                    "C9400-SUP-1XL",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315874",
                  productName: "Cisco Catalyst 9300 Switch",
                  productId: [
                    "C9300-NM-4G",
                    "C9300-NM-4M-WS",
                    "C9300-NM-8X=",
                    "SC9300UK9-174",
                    "C9300-NM-4M",
                    "C9300-NM-2X=",
                    "C9300-NM-4X=",
                    "S9300NPE-174",
                    "C9300-24-E-A",
                    "C9300-NM-8X",
                    "C9300-NM-2Q",
                    "C9200-48PXG-EDU",
                    "C9300-NM-4G=",
                    "C9300-NM-4G-WS",
                    "C9300-48-E-A",
                    "C9300-NM-BLANK=",
                    "C9300-NM-2Q=",
                    "C9200-24PXG-EDU",
                    "C9300-NM-BLANK",
                    "C9300-NM-2Y=",
                    "C9300-SSD-PROMO",
                    "C9300-NM-8X-WS",
                    "C9300-NM-8X-UL",
                    "C9300-NM-4X",
                    "C9300-NM-2Y",
                    "C9300-RFID=",
                    "C9300-NM-2X",
                    "SSD-PROMO",
                    "C9300-NM-2Q-WS",
                    "C9300-NM-2Y-WS",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320497",
                  productName: "Cisco Catalyst C9500 SVL Switch",
                  productId: ["C9500-24Y4C-E-RF", "C9500-24Y4C-10E"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320244",
                  productName: "Cisco Catalyst 9404R Switch",
                  productId: [
                    "C9404R",
                    "C9404R-WS",
                    "C9404R-48UBNDLA-RF",
                    "C9404R-RF",
                    "C9404R-UL",
                    "C9404R=",
                    "C9404R-1A",
                    "C9404R-10A",
                    "C9404R-P",
                    "C9404R-48U-BNDL-A",
                    "C9404R-48U-BDL-EDU",
                    "C9404R-48U-BNDL-1E",
                    "C9404R-48U-BNDL-E",
                    "C9404R-48U-BNDL-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315863",
                  productName: "Cisco Catalyst 9500 Switch",
                  productId: [
                    "C9500-DNA-L-P",
                    "C9500X-ACCKIT-19I",
                    "C9500-ACCKIT-23I",
                    "C9500X-FAN-1U-R=",
                    "C9K-PWR-1500WAC-R",
                    "NAL-C9500-48X-E",
                    "NAL-C9500-48X-A",
                    "PWR-C4-950WAC-R",
                    "C9K-PWR-1600WDCR/2",
                    "C9500-ACCKIT-19I",
                    "C9500X-ACCKIT-19I=",
                    "C9K-PWR-C5-BLANK",
                    "C9500X-FAN-1U-R",
                    "HX-9500-8E",
                    "C9K-PWR-1600WDC-R=",
                    "C9500X-FAN-1U-F",
                    "C9K-PWR-1500WDC-F",
                    "C9500X-4PTH-KIT",
                    "PWR-C4-BLANK",
                    "C9K-PWR-1600WAC-R=",
                    "C9500X-FAN-1U-F=",
                    "C9K-PWR-1600WDC-R",
                    "C9K-F1-SSD-240G=",
                    "C9K-PWR-1500WDC-R",
                    "C9500-ACCKITH-19I=",
                    "C9500-ACCKIT-19I=",
                    "C9K-F1-SSD-240G",
                    "C9K-PWR-C4-BLANK",
                    "C9500-4PTH-KIT=",
                    "C9K-PWR-650WAC-R/2",
                    "C9500-DNA-P=",
                    "C9K-F1-SSD-480G=",
                    "C9K-F1-SSD-240G-WS",
                    "C9K-PWR-1600WACR/2",
                    "C9500-16X-EDU-RF",
                    "C9K-F1-SSD-BLANK",
                    "HX-9500-8E=",
                    "C9K-PWR-C5-BLANK=",
                    "C9K-PWR-1500WAC-F",
                    "C9K-F1-SSD-960G=",
                    "C9K-PWR-1500WDC-F=",
                    "C9500X-60L4D",
                    "C9500-ACCKIT-23I=",
                    "C9K-F1-SSD-960G",
                    "C9K-F3-SSD-240GB=",
                    "C9K-F3-SSD-240GB",
                    "C9K-F3-SSD-BLANK=",
                    "C9K-PWR-1500WDC-R=",
                    "C9500-ACCKITH-23I",
                    "C9K-PWR-1500WDCR/2",
                    "C9500X-4PTH-KIT=",
                    "C9500X-ACCKIT-23I=",
                    "C9K-PWR-1500WAC-R=",
                    "C9K-PWR-1500WDCF/2",
                    "C9500-SPS-NONE",
                    "C9K-PWR-930WDC-R/2",
                    "C9K-F1-SSD-480G",
                    "PWR-C4-BLANK=",
                    "C9500-4PTH-KIT",
                    "C9K-F3-SSD-480GB=",
                    "C9K-F3-SSD-960GB",
                    "C9500-ACCKITH-19I",
                    "C9K-PWR-1500WACF/2",
                    "C9K-PWR-1500WAC-F=",
                    "C9500-4PT-KIT",
                    "C9K-PWR-C4-BLANK=",
                    "PWR-C4-950WAC-R-RF",
                    "C9K-F3-SSD-480GB",
                    "C9K-PWR-1600WAC-R",
                    "C9K-F3-SSD-960GB=",
                    "C9500X-ACCKIT-23I",
                    "C9500-DNA-P",
                    "C9500-ACCKITH-23I=",
                    "C9K-F3-SSD-BLANK",
                    "C9500-DNA-L-P=",
                    "C9K-PWR-1500WACR/2",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319599",
                  productName: "Cisco Catalyst C9500-32C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32C-P",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32C",
                    "C9500-32C=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
            {
              name: "C9800-L-universalk9_wlc.17.03.03.SPA.bin",
              imageUuid: "f37cd933-4e5b-4135-aed7-01ed3a760225",
              version: "17.03.03.0.4762",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "fe0992e16e2a5b24aecb2b60f626c65f",
              shaCheckSum:
                "709f852c978263ad0aae14de12461c9152c302b7a0445ef08ad4abf51593e9fe5196b3dcf7b8bc010527c2aa629e00201e1fb042e42f253eaf34a6eb5ab375a4",
              createdTime: "2021-12-06 01:12:18.686",
              applicationType: null,
              feature: "",
              fileServiceId: "b83234f1-4304-42bc-ab87-8edc616157c0",
              imageSeries: null,
              imageSource: "CCO",
              imageSourceType: "Imported from CCO",
              imageFamily: "C9800-L",
              vendor: "CISCO",
              filesize: 1131196133,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 0,
              imageAttributes: [],
              isSMUApplicable: false,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286321399",
                  productName: "Cisco Catalyst 9800-L-F Wireless Controller",
                  productId: ["EDU-C9800-L-F-K9", "C9800-L-F-K9"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286323158",
                  productName: "Cisco Catalyst 9800-L-C Wireless Controller",
                  productId: [
                    "C9800-L-C-K9-RF",
                    "EDU-C9800-L-C-K9",
                    "C9800-L-F-K9",
                    "C9800-L-C-K9",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 0,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: true,
              ccolatest: false,
            },
            {
              name: "cat9k_iosxe.17.03.02a.SPA.bin",
              imageUuid: "f4d6b424-229e-4ed7-9a68-9755af870719",
              version: "17.03.02a.0.3793",
              softwareType: null,
              imageType: "SYSTEM_SW",
              isTaggedGolden: "false",
              md5Checksum: "3cc37b28a564064485e767ebc3a1f2f8",
              shaCheckSum:
                "6cec116c6f283460dd8e900073c751aa0e0361237bdd7f1613c790dd75ebd97c13f12476cae77a871bbac312677d9e3cc145d00df1634449d5d2e0e70690d82e",
              createdTime: "2021-02-27 01:12:19.851",
              applicationType: null,
              feature: "",
              fileServiceId: "4e10cb15-0e11-48cd-85e9-00e53617b8d7",
              imageSeries: null,
              imageSource:
                "file:/opt/maglev/services/swim-service/7.1.265.62735//localdisk/Staging/cat9k_iosxe.17.03.02a.SPA.bin",
              imageSourceType: "Imported from File System",
              imageFamily: "CAT9K",
              vendor: "CISCO",
              filesize: 898018097,
              deviceCount: 0,
              deviceUuidList: [],
              addOnCount: 1,
              imageAttributes: [],
              isSMUApplicable: true,
              goldenTagInheritedFromSiteUuid: null,
              goldenTagInheritedFromSiteName: null,
              importSupported: true,
              reasonForNotSupportingImport: null,
              tagList: [],
              imageCategory: "PHYSICAL",
              imageIntegrityStatus: "VERIFIED",
              imageImported: true,
              applicableDevicesForImage: [
                {
                  mdfId: "286323141",
                  productName: "Cisco Catalyst 9600 Series Supervisor Engine 1",
                  productId: [
                    "C9600X-SUP-2",
                    "C9600-SUP-1-WS",
                    "C9600X-SUP-2=",
                    "C9600-SUP-1/2",
                    "C9600-SUP-1",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320497",
                  productName: "Cisco Catalyst C9500 SVL Switch",
                  productId: ["C9500-24Y4C-E-RF", "C9500-24Y4C-10E"],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319589",
                  productName: "Cisco Catalyst C9500-48Y4C Switch",
                  productId: [
                    "C9500-48Y4C",
                    "C9500-48Y4C=",
                    "L-C9500-48Y4C-EA-3",
                    "C9500H-ACCKIT-19I=",
                    "C9500-48Y4C-CX",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315874",
                  productName: "Cisco Catalyst 9300 Switch",
                  productId: [
                    "C9300-NM-4G",
                    "C9300-NM-4M-WS",
                    "C9300-NM-8X=",
                    "SC9300UK9-174",
                    "C9300-NM-4M",
                    "C9300-NM-2X=",
                    "C9300-NM-4X=",
                    "S9300NPE-174",
                    "C9300-24-E-A",
                    "C9300-NM-8X",
                    "C9300-NM-2Q",
                    "C9200-48PXG-EDU",
                    "C9300-NM-4G=",
                    "C9300-NM-4G-WS",
                    "C9300-48-E-A",
                    "C9300-NM-BLANK=",
                    "C9300-NM-2Q=",
                    "C9200-24PXG-EDU",
                    "C9300-NM-BLANK",
                    "C9300-NM-2Y=",
                    "C9300-SSD-PROMO",
                    "C9300-NM-8X-WS",
                    "C9300-NM-8X-UL",
                    "C9300-NM-4X",
                    "C9300-NM-2Y",
                    "C9300-RFID=",
                    "C9300-NM-2X",
                    "SSD-PROMO",
                    "C9300-NM-2Q-WS",
                    "C9300-NM-2Y-WS",
                  ],
                  sites: ["00d38152-9d4f-41f9-a8e9-af3f811d4920"],
                  show: true,
                  userDefined: false,
                },
                {
                  mdfId: "286315691",
                  productName: "Cisco Catalyst 9407R Switch",
                  productId: [
                    "C9400-PWR-2100AC",
                    "C9407R-96U-BNDL-E",
                    "C9407-RACK19KIT-WS",
                    "C9407R-RF",
                    "S9400NPE-174",
                    "C9407R-10A",
                    "S9400UK9-174",
                    "C9407R-1A",
                    "C9407R-96U-STK-EDU",
                    "C9407R",
                    "C9407R-96UBNDLA-WS",
                    "C9407R-96U-BDL-EDU",
                    "C9407R-96UBNDLE-RF",
                    "C9407R-96UBNDLA-RF",
                    "C9407-RACK19KIT-RF",
                    "C9400-PWR-2400AC",
                    "C9407R-UL",
                    "C9400-SR-BLANK=",
                    "C9407R-96U-BNDL-1E",
                    "C9407-96U-BNDL-CX",
                    "C9407R-96U-BNDL-A",
                    "C9400-PWR-2400AC=",
                    "C9407R-96U-BNDL-1A",
                    "C9407R-WS",
                    "C9407R-P",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315703",
                  productName: "Cisco Catalyst 9410R Switch",
                  productId: [
                    "C9400-LC-24XS-WS",
                    "C9400-LC-48T++=",
                    "C9410R-96UBNDLA-RF",
                    "C9400-SUP-1XL=",
                    "C9410R-WS",
                    "C9410R-96U-BNDL-A",
                    "C9400-SUP-1XL++=",
                    "C9410-96U-BNDL-CX",
                    "C9410R-96U-BNDL-E",
                    "C9400-LC-48S=",
                    "C9410R-96U-BNDL-1A",
                    "C9410R-P",
                    "C9410R-96U-BDL-EDU",
                    "C9400-LC-48UX++=",
                    "C9410R-96U-STK-EDU",
                    "C9400-LC-48P",
                    "C9400-LC-48P-WS",
                    "C9400-LC-48S",
                    "C9410R-UL",
                    "C9400-LC-24S",
                    "C9400-LC-24XS++=",
                    "C9400-SUP-1XL-B",
                    "C9400-SUP-1++=",
                    "C9400-LC-24XS",
                    "C9400-LC-24XS=",
                    "C9400-LC-48P=",
                    "C9400-LC-48U-B1",
                    "C9410R",
                    "C9400-LC-48U++=",
                    "C9400-LC-48UX-WS",
                    "C9410R-96UBNDLA-WS",
                    "C9400-PWR-2400AC",
                    "C9410R-10A",
                    "C9400-LC-48UX",
                    "C9400-SUP-1XL-WS",
                    "C9400-SR-BLANK=",
                    "C9400-PWR-2400AC=",
                    "C9410R-96U-BNDL-1E",
                    "C9400-LC-48UX=",
                    "C9410R-RF",
                    "C9400-SUP-1XL",
                    "C9410R-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322137",
                  productName: "Cisco Catalyst 9606R Switch",
                  productId: [
                    "C9606R-48Y24C-BN-A",
                    "C9600-SSD-NONE",
                    "C9606R-48Y24C-BNCX",
                    "QSFP-40G-CSR-S-RF",
                    "C9606R=",
                    "C9606-FILTER=",
                    "C9606=",
                    "C9606R-48Y24C-EDU",
                    "BN-A1-SBA-K-K9",
                    "C9606R-EDU",
                    "C9606",
                    "C9606-RACK-KIT=",
                    "C9606R-RF",
                    "BN-A1-SBA-C-K9",
                    "C9606R-1A",
                    "BN-A1-SBA-I-K9",
                    "C9606R-P",
                    "HWOA-TETR-PLT",
                    "C9606R",
                    "C9606R-48S-BN-A",
                    "C9606R-10A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316710",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL",
                  productId: [
                    "C9400-SUP-1XL-WS",
                    "C9400-SUP-1XL-RF",
                    "C9400-SUP-1XL0-RF",
                    "C9400-SUP-1XL/2",
                    "C9400-SUP-1XL",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319599",
                  productName: "Cisco Catalyst C9500-32C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32C-P",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32C",
                    "C9500-32C=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286320244",
                  productName: "Cisco Catalyst 9404R Switch",
                  productId: [
                    "C9404R",
                    "C9404R-WS",
                    "C9404R-48UBNDLA-RF",
                    "C9404R-RF",
                    "C9404R-UL",
                    "C9404R=",
                    "C9404R-1A",
                    "C9404R-10A",
                    "C9404R-P",
                    "C9404R-48U-BNDL-A",
                    "C9404R-48U-BDL-EDU",
                    "C9404R-48U-BNDL-1E",
                    "C9404R-48U-BNDL-E",
                    "C9404R-48U-BNDL-1A",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319592",
                  productName: "Cisco Catalyst C9500-32QC Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "C9500-32QC-P",
                    "C9500-32QC",
                    "C9500-32QC=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-32QC-EDU-RF",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286315863",
                  productName: "Cisco Catalyst 9500 Switch",
                  productId: [
                    "C9500-DNA-L-P",
                    "C9500X-ACCKIT-19I",
                    "C9500-ACCKIT-23I",
                    "C9500X-FAN-1U-R=",
                    "C9K-PWR-1500WAC-R",
                    "NAL-C9500-48X-E",
                    "NAL-C9500-48X-A",
                    "PWR-C4-950WAC-R",
                    "C9K-PWR-1600WDCR/2",
                    "C9500-ACCKIT-19I",
                    "C9500X-ACCKIT-19I=",
                    "C9K-PWR-C5-BLANK",
                    "C9500X-FAN-1U-R",
                    "HX-9500-8E",
                    "C9K-PWR-1600WDC-R=",
                    "C9500X-FAN-1U-F",
                    "C9K-PWR-1500WDC-F",
                    "C9500X-4PTH-KIT",
                    "PWR-C4-BLANK",
                    "C9K-PWR-1600WAC-R=",
                    "C9500X-FAN-1U-F=",
                    "C9K-PWR-1600WDC-R",
                    "C9K-F1-SSD-240G=",
                    "C9K-PWR-1500WDC-R",
                    "C9500-ACCKITH-19I=",
                    "C9500-ACCKIT-19I=",
                    "C9K-F1-SSD-240G",
                    "C9K-PWR-C4-BLANK",
                    "C9500-4PTH-KIT=",
                    "C9K-PWR-650WAC-R/2",
                    "C9500-DNA-P=",
                    "C9K-F1-SSD-480G=",
                    "C9K-F1-SSD-240G-WS",
                    "C9K-PWR-1600WACR/2",
                    "C9500-16X-EDU-RF",
                    "C9K-F1-SSD-BLANK",
                    "HX-9500-8E=",
                    "C9K-PWR-C5-BLANK=",
                    "C9K-PWR-1500WAC-F",
                    "C9K-F1-SSD-960G=",
                    "C9K-PWR-1500WDC-F=",
                    "C9500X-60L4D",
                    "C9500-ACCKIT-23I=",
                    "C9K-F1-SSD-960G",
                    "C9K-F3-SSD-240GB=",
                    "C9K-F3-SSD-240GB",
                    "C9K-F3-SSD-BLANK=",
                    "C9K-PWR-1500WDC-R=",
                    "C9500-ACCKITH-23I",
                    "C9K-PWR-1500WDCR/2",
                    "C9500X-4PTH-KIT=",
                    "C9500X-ACCKIT-23I=",
                    "C9K-PWR-1500WAC-R=",
                    "C9K-PWR-1500WDCF/2",
                    "C9500-SPS-NONE",
                    "C9K-PWR-930WDC-R/2",
                    "C9K-F1-SSD-480G",
                    "PWR-C4-BLANK=",
                    "C9500-4PTH-KIT",
                    "C9K-F3-SSD-480GB=",
                    "C9K-F3-SSD-960GB",
                    "C9500-ACCKITH-19I",
                    "C9K-PWR-1500WACF/2",
                    "C9K-PWR-1500WAC-F=",
                    "C9500-4PT-KIT",
                    "C9K-PWR-C4-BLANK=",
                    "PWR-C4-950WAC-R-RF",
                    "C9K-F3-SSD-480GB",
                    "C9K-PWR-1600WAC-R",
                    "C9K-F3-SSD-960GB=",
                    "C9500X-ACCKIT-23I",
                    "C9500-DNA-P",
                    "C9500-ACCKITH-23I=",
                    "C9K-F3-SSD-BLANK",
                    "C9500-DNA-L-P=",
                    "C9K-PWR-1500WACR/2",
                  ],
                  sites: ["00d38152-9d4f-41f9-a8e9-af3f811d4920"],
                  show: true,
                  userDefined: false,
                },
                {
                  mdfId: "286320394",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1XL-Y",
                  productId: [
                    "C9400-SUP-1XL-Y-WS",
                    "C9400-SUP-1XL-Y",
                    "C9400-SUP-1XL-Y/2",
                    "C9400-DNX-A-10Y",
                    "C9400-SUP-1XL-Y=",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286319595",
                  productName: "Cisco Catalyst C9500-24Y4C Switch",
                  productId: [
                    "C9500H-4PT-KIT=",
                    "L-C9500-24Y4C-EA-3",
                    "C9500-24Y4C-EDU-RF",
                    "L-C9500-24Y4C-EA-7",
                    "C9500-24Y4C=",
                    "C9500H-ACCKIT-19I=",
                    "C9500-60C12D-A",
                    "C9500-24Y4C-CX",
                    "C9500-24Y4C",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286316172",
                  productName: "Cisco Catalyst 9400 Supervisor Engine-1",
                  productId: [
                    "C9404-FAN=",
                    "C9400-SUP-1",
                    "C9404-FAN",
                    "C9400-SSD-960GB=",
                    "C9410R",
                    "C9400-SSD-980GB=",
                    "C9400-SUP-1/2",
                    "C9400-SSD-480GB",
                    "C9400-SSD-240GB",
                    "C9400-SUP-1-B",
                    "C9400-SSD-960GB",
                    "C9400-SUP-1=",
                    "C9400-SSD-480GB=",
                    "C9400-SUP-1-RF",
                    "C9400-SSD-240GB=",
                    "C9407R",
                  ],
                  sites: [],
                  show: false,
                  userDefined: false,
                },
                {
                  mdfId: "286322029",
                  productName: "Cisco Catalyst 9300L Switch Stack",
                  productId: [
                    "S9300LNPE-174",
                    "S9300LUK9-174",
                    "C9300L-STACKBLANK=",
                  ],
                  sites: ["00d38152-9d4f-41f9-a8e9-af3f811d4920"],
                  show: true,
                  userDefined: false,
                },
              ],
              profileInfo: null,
              physicallyAvailable: true,
              assignCount: 3,
              showUnassign: true,
              disableUnassign: false,
              ccoreverseSync: true,
              ccorecommended: false,
              ccolatest: true,
            },
          ]
           },
        ],
  });
  