define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ScheduledJobTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimStealthWatchData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimDeviceReplacementData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/license-manage/SimLicenseManagerData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/swim/models/SimSwimModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimScheduledJobData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimConnectivityDomain',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/design/models/SimSiteProfileModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/discovery/models/SimDiscoveryModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/cfs/models/SimCfsModelsv1',    
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimEnhancedRRMData'
  ],  function (SimSwimData, UtilityFunctions, ScheduledJobTemplate, SimLokiDatabaseActions, SimStealthWatchData, 
    SimDeviceReplacementData, SimLicenseManagerData, SimSwimModels, SimScheduledJobData, SimConnectivityDomain, SimTaskDataGenerator,
    SimSiteProfileModels, SimDiscoveryModels,SimCfsModelsv1, SimEnhancedRRMData) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;


                default:
                    break;
            }

            return data;
        }
    };


    function processGetRequest(urlAction){
        // for Stealth Watch
        if(urlAction.action.count) {
            if(["COMPLETED", "FAILED", "RUNNING"].indexOf(urlAction.filter.status) > -1) {
                var dataObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-job',{'scheduleState': urlAction.filter["status"]});
                return  dataObj.length
            } else if(urlAction.filter.scheduleState){//urlAction.filter.scheduleState == "ACTIVE"
                var dataObj = SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-job',{'scheduleState': urlAction.filter["scheduleState"]});
                return  dataObj.length
            } else if(urlAction.filter.source){//urlAction.filter.source == "EXTERNAL"
                var dataObj =  SimLokiDatabaseActions.getFilteredRecordHandler('scheduled-job',{'source': urlAction.filter["source"]});
                return  dataObj.length
            } else if(urlAction.filter.module ==".*SSA.*" ) {
                return 1
            } else if(( ["DISCOVERY", ".*DISCOVERY.*"].indexOf(urlAction.filter.module) > -1) ) {
                return 0;
            } else if(urlAction.filter['module'] == 'IP_ACCESS_POLICY') {
                return 0
            } else if(urlAction.filter.scheduleState == "ACTIVE" && urlAction.service.indexOf('triggered-job') >= 0) {
                return 1 
            } else {
                var time = UtilityFunctions.getTimeStamp();
                return {"pending":0,"upcoming":0,"running":0,"completed":0,"failed":0,
                "filterStartTime":urlAction.filter.filterStartTime,"filterEndTime":urlAction.filter.filterEndTime,"completionTime":time};
            }
        }
        if(urlAction.service.indexOf("module")>-1) {
            //called from Stealth Watch
            var dataObj = JSON.parse(JSON.stringify(ScheduledJobTemplate.Schedule_Job_Template_2));
            return dataObj;
        /* } else if(urlAction.service.indexOf("scheduled-job")>-1) {
            var dataObj = JSON.parse(JSON.stringify(ScheduledJobTemplate.Schedule_Job_Template_3));
            return dataObj; */ // this section to be taken by developer again
        } else {
            var result = SimScheduledJobData.scheduledJobStatus(urlAction);
            return result
        }
        

    }

    function processPostRequest(urlAction){
        let resultObj
        //commenting the below section as it is blocking all task calls
        //Schedule-Job call for Security-advisory
        /*  if((urlAction.service.indexOf('/api/v1/scheduled-job')>-1 ) || (urlAction.service.indexOf('scheduled-job')>-1 ) ) {
           return {"response":{"taskId":"9eddcf77-6c8e-4e36-9e09-aecb2d1a2721","url":"/api/v1/task/9eddcf77-6c8e-4e36-9e09-aecb2d1a2721"},"version":"1.0"};       } 
           */
        
        if(urlAction.service.indexOf('security-advisory')>-1 ) {
            resObj = {"response":{"taskId":"7fd2c1e6-ac5d-45e0-941f-64896667021c","url":"/api/v1/task/7fd2c1e6-ac5d-45e0-941f-64896667021c"},"version":"1.0"};
            return resObj;
        }
        var moduleName = "default";
        let notificationUrl = "", taskResponse = {}, updatedUrlAction = {}, updatedUrlActionArr = [];
        if(urlAction.restPayload.length && urlAction.restPayload[0].externalSchedule &&
          urlAction.restPayload[0].externalSchedule.module) {
            moduleName = urlAction.restPayload[0].externalSchedule.module;
            notificationUrl = urlAction.restPayload[0].externalSchedule.notificationURL;
        }
        urlAction.restPayload.forEach(payload => {
            updatedUrlAction = {
                "url":payload.externalSchedule.notificationURL,
                "restPayload":payload.externalSchedule.notificationBody,
                "method":payload.externalSchedule.notificationMethod,
                "service":(payload.externalSchedule.notificationURL.split("?"))[0].split("/")
            }
            updatedUrlActionArr.push(updatedUrlAction);
        });
        var paramNamesAndValues = urlAction.restPayload[0].paramNamesAndValues;
        switch(moduleName) {
            case "OS_UPDATE" :
                //if(urlAction.restPayload[0].startTime == -1) {
                    //Now option
                    //action of upgrading OS
                    taskResponse = SimSwimModels.doProcessRestRequest(updatedUrlAction);
                    //log into tasks table
                    SimScheduledJobData.insertScheduledJobTask(urlAction.restPayload, taskResponse.taskId, taskResponse.childTaskId);
                    delete taskResponse.childTaskId;
                    return taskResponse;
                /* } else {
                    //Later option (mostly)
                    if(notificationUrl.indexOf('distribution') > -1) {
                        return SimSwimData.scheduledDistribution(urlAction);
                    } else {
                        return SimSwimData.scheduledActivation(urlAction);
                    }
                } */
                break;
            case "DEVICE_REPLACEMENT" :
                //if actions is done on multiple devices, we need ref cluster to understand the response to send back
                return SimDeviceReplacementData.replaceDevice(urlAction.restPayload[0].externalSchedule.notificationBody)
                break;
            case "SSA":
                // For Stealth Watch
                return SimStealthWatchData.scheduledSSA(urlAction);
            case "LICENSE" :
                 //for Licence Manager
                return SimLicenseManagerData.registerDeregister(urlAction.restPayload[0].externalSchedule.notificationBody)
                break;
             case "PROVISION" :
                if(updatedUrlAction.url.indexOf('customer-facing-service') > -1){
                    //for fabric connectivity domain 
                    if(paramNamesAndValues.deviceDetail)
                        paramNamesAndValues.deviceDetail = typeof(paramNamesAndValues.deviceDetail)=='string' ? 
                            JSON.parse(paramNamesAndValues.deviceDetail) : paramNamesAndValues.deviceDetail;
                    taskResponse=SimCfsModelsv1.processFabricRequest(updatedUrlAction, paramNamesAndValues);//handles add, remove, updation of border configuration of fabric role
                    if(taskResponse==undefined) {
                        updatedUrlActionArr.forEach(urlActionIter => {
                           taskResponse=SimCfsModelsv1.doProcessRestRequest(urlActionIter, paramNamesAndValues); 
                        });
                    }
                    SimScheduledJobData.insertScheduledJobTask(urlAction.restPayload, taskResponse.taskId, taskResponse.childTaskId);
                    delete taskResponse.childTaskId;
                    return taskResponse;
                }
                break;
            case "NFV_PROVISIONING":
                taskResponse = SimSiteProfileModels.doProcessRestRequest(updatedUrlAction);
                //log into tasks table
                SimScheduledJobData.insertScheduledJobTask(urlAction.restPayload, taskResponse.taskId, taskResponse.childTaskId);
                delete taskResponse.childTaskId;
                return taskResponse;
                break;
            case "DISCOVERY":
                taskResponse = SimDiscoveryModels.doProcessRestRequest(updatedUrlAction);
                //log into tasks table
                SimScheduledJobData.insertScheduledJobTask(urlAction.restPayload, taskResponse.response.taskId, taskResponse.response.taskId);
                return taskResponse;
                break;
            case "SITE_ASSIGNMENT":
                let removedSites = Object.keys(urlAction.restPayload[0].externalSchedule.notificationBody.deleteMembersMap)
                for(let site of removedSites) {
                    let affectedDevices = urlAction.restPayload[0].externalSchedule.notificationBody.deleteMembersMap[site]
                    for(let id of affectedDevices) {
                        let deviceRecords = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {
                            'id': id
                        })
                        deviceRecords[0].siteId = "";//null
                        SimLokiDatabaseActions.update('network-device', deviceRecords[0])                                              
                    }
                }
                let addedSites = Object.keys(urlAction.restPayload[0].externalSchedule.notificationBody.addMembersMap)                
                for(let site of addedSites) {
                    let affectedDevices = urlAction.restPayload[0].externalSchedule.notificationBody.addMembersMap[site]
                    for(let id of affectedDevices) {
                        let deviceRecords = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {
                            'id': id
                          })
                        deviceRecords[0].siteId = site
                        SimLokiDatabaseActions.update('network-device', deviceRecords[0]);
                        let devInfoRec = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo',{'networkDeviceId':id});
                        if(devInfoRec && devInfoRec.length>0) {
                            devInfoRec[0].siteId = site;
                            SimLokiDatabaseActions.update('DeviceInfo', devInfoRec);
                        };
                    }
                }
                
                taskResponse = SimTaskDataGenerator.createTask('Inventory service')
                return taskResponse;
            case "APP_POLICY":
                // Need to review and update this code modified this in Furry release
                let resultObj = "Success"
                return resultObj
                break;
            case "SECURITY_ADVISORIES":
                var id =UtilityFunctions.generate_uuid();
                var resultObj1 = SimTaskDataGenerator.createTask("NCSS",{ id: id });
                SimTaskDataGenerator.updateTaskCompletion(resultObj1.taskId, {isError: false });
                resultObj1.statusCode= 202;                
                return resultObj1;            
                break;
            case "Ai_RRM":
                var resultObj2 = SimEnhancedRRMData.getScheduleJobRRMData(urlAction)
                return resultObj2;
                break;
            default:
                break;
        }
    }

    function processDeleteRequest(urlAction){

    }
});
