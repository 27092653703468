define({
    'VC_Camera2_eventsViewTemplate' : {
        "version": "1.0",
        "response": [
            {
                "name": "LINK_DOWN_StartTime",
                "id": "0fbb386a-4be9-4d16-b810-e6c2af7a5378",
                "eventName": "LINK_DOWN",
                "timestamp": 1631048280276,
                "index": null,
                "eventType": null,
                "reasonType": null,
                "subreasonType": null,
                "resultStatus": null,
                "failCategory": null,
                "details": [
                    {
                        "key": "Severity",
                        "value": "Error"
                    },
                    {
                        "key": "Mnemonic",
                        "value": "DOWN"
                    },
                    {
                        "key": "Facility",
                        "value": "LINK"
                    },
                    {
                        "key": "Message Text",
                        "value": "GigabitEthernet1/5 Link went Down"
                    },
                    {
                        "key": "additionalInfo",
                        "value": "TRAP | Connected Device: Connected_Device_Name | Connected Interface: GigabitEthernet1/5"
                    },
                    {
                        "key": "Message Type",
                        "value": "TRAP"
                    },
                    {
                        "key": "Color Level",
                        "value": "1"
                    },
                    {
                        "key": "Connected Device IP",
                        "value": "Connected_Device_IP"
                    },
                    {
                        "key": "Connected Device Name",
                        "value": "Connected_Device_Name"
                    },
                    {
                        "key": "Connected Interface",
                        "value": "GigabitEthernet1/5"
                    }
                ],
                "healthScore": null,
                "source": null,
                "eventSource": null,
                "hasPackets": null,
                "hasAnomalyPackets": null,
                "isPrivateMac": null
            }
        ],
        "totalCount": 1
    }
})