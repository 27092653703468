
//family nexus7000 Cisco Nexus 7000 10-Slot Switch
define({
"runningImageList":[  
    {  
       "name":"n7000-s1-dk9.6.1.3.bin",
       "imageUuid":"19882602-75df-408d-b414-8d6a070fe925",
       "version":"6.1(3)",
       "softwareType":null,
       "imageType":"system",
       "isTaggedGolden":"true",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"nexus7000",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[
       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    },
    {  
       "name":"n7000-s1-kickstart.6.1.3.bin",
       "imageUuid":"1a07dc94-e5a7-49b2-a09f-357ca0bc65ac",
       "version":"6.1(3)",
       "softwareType":null,
       "imageType":"kickstart",
       "isTaggedGolden":"false",
       "md5Checksum":null,
       "shaCheckSum":null,
       "createdTime":null,
       "applicationType":null,
       "feature":null,
       "fileServiceId":null,
       "imageSeries":null,
       "imageSource":null,
       "imageSourceType":null,
       "imageFamily":"nexus7000",
       "vendor":null,
       "filesize":0,
       "deviceCount":0,
       "deviceUuidList":[  
       ],
       "smuCount":0,
       "imageAttributes":[  

       ],
       "isSMUApplicable":false,
       "goldenTagInheritedFromSiteUuid":null,
       "goldenTagInheritedFromSiteName":null,
       "importSupported":true,
       "reasonForNotSupportingImport":null,
       "tagList":[  
          {  
             "taggedGolden":false,
             "role":"ALL",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"CORE",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"DISTRIBUTION",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"BORDER ROUTER",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"UNKNOWN",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          },
          {  
             "taggedGolden":false,
             "role":"ACCESS",
             "inheritedSiteName":null,
             "inheritedSiteUuid":null
          }
       ],
       "profileInfo": null,
        "physicallyAvailable": true,
        "assignCount": 0,
        "showUnassign": false,
        "disableUnassign": false,
       "imageCategory":"PHYSICAL",
       "imageIntegrityStatus":null,
       "ccorecommended":false,
       "ccolatest":false
    }
 ]
});