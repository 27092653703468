define({
    "Host_Template":  {
        "hostMac": "7C:DD:90:DD:CC:9B",
        "userId": "",
        "location": {
          "latitude": -999.0,
          "longitude": -999.0,
          "altitude": 0.0
        },
        "position": {
          "x": 19.687876,
          "y": 80.37615,
          "z": 4
        },
        "lradMacAddr": "70:70:8B:06:21:20",
        "ipAddress": null,
        "changedOn": 1533408611244,
        "status": "associated",
        "ssid": "PacketCapture",
        "reenterFloor": null,
        "healthScore": 10,
        "subType": null,
        "hostOs": null,
        "clientConnection": "70:70:8b:06:21:20",
        "band": "5",
        "protocol": "IEEE_802_11_A",
        "issueCount": null,
        "dataRate": null,
        "rssi": -49.0,
        "snr": 0.0,
        "id": null,
        "hostType": null,
        "hostName": null,
        "hostIp": null,
        "hostVersion": null,
        "lastUpdated": null,
        "locationSourceTime": "1533408611244",
        "networkStatus": null,
        "slot": 1,
        "antennaIndex": 0,
        "lastHeardInSeconds": 4,
        "detectingControllers": null,
        "guestUser": null,
        "manufacturer": "Shenzhen Ogemray Technology Co., Ltd.",
        "confidenceFactor": 24.0,
        "bytesSent": null,
        "bytesReceived": null,
        "smallIcon": null,
        "largeIcon": null,
        "notes": null,
        "reporterType": "cmx",
        "channel": null,
        "throughput": null,
        "pktDropRate": null,
        "dataRateStatus": null,
        "rssiStatus": null,
        "snrStatus": null,
        "throughputStatus": null,
        "pktDropRateStatus": null,
        "outstandingIssues": null,
        "apName": "4800-RED"
      }
  });