define({
    "sensorListTemplate":{
      "version": "1.0",
      "response": [
        {
          "name": "Sensor-0928",
          "status": "RUNNING",
          "radioMacAddress": "70:f3:5a:7c:8a:c0",
          "ethernetMacAddress": "70:f3:5a:7c:09:28",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": "Wired",
          "serialNumber": "KWC214106RW",
          "ipAddress": "10.13.5.116",
          "version": "8.8.263.0",
          "lastSeen": 1568367434887,
          "uptime": null,
          "uptimeLong": 1597889949000,
          "type": "FARALLON",
          "isLEDEnabled": true,
          "sshConfig": {
            "sshState": "Disabled",
            "sshUserName": "Cisco",
            "sshPassword": "yYwvYCnilnjas4OdamEak6EtO7BNytUfo/jZVQIHKVbapDI1oSE3QWyGErCG5hN/uXuidXVc5qpcsofWRiVlCiAZ0kdb0nTT",
            "enablePassword": "CSo3L31ulk7swRq2pid3Kubsbntt5sBhCOBp5bbsmlH4FzDfptM9D3snFqxua5XZMITePDNf7DRuVRMrWvNYg5+hRiHuaFLQ"
          },
          "location": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
          "compatibility": [
            {
                "capability": "TEMPLATE_COMPATIBLE",
                "info": {
                    "available": true,
                    "details": null
                }
            },
            {
                "capability": "SSH_EDIT_COMPATIBLE",
                "info": {
                    "available": true,
                    "details": null
                }
            },
            {
                "capability": "NAME_EDIT_COMPATIBLE",
                "info": {
                    "available": true,
                    "details": null
                }
            },
            {
                "capability": "LED_EDIT_COMPATIBLE",
                "info": {
                    "available": true,
                    "details": null
                }
            }
        ]
        },
        {
          "name": "Sensor-0860",
          "status": "RUNNING",
          "radioMacAddress": "70:f3:5a:7c:87:a0",
          "ethernetMacAddress": "70:f3:5a:7c:08:60",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": null,
          "serialNumber": "KWC214106R7",
          "ipAddress": "10.13.5.115",
          "version": "8.8.263.0",
          "lastSeen": 1568367421484,
          "uptime": null,
          "type": "FARALLON",
          "isLEDEnabled": true,
          "sshConfig": {
            "sshState": "Disabled",
            "sshUserName": "Cisco",
            "sshPassword": "Py42oy9l7N+nARGTVu5v27FkuBmcnjlQBmff+ivHksbkBAFCzLwCTkeuT9hhSpJA2MjQH1UnqpEU2imaEAUjf9uc6R/8kYTQ",
            "enablePassword": "DG3Jzd758ljQ0r3La2bWkLkZdEeJm7hlyMyLkzXH2v+W//9BFKyA0eM+bVAmwZlFGKqy3QG3h0VDd818M7uonEN0mqYcynxb"
          }
        },
        {
          "name": "Sensor-0140",
          "status": "RUNNING",
          "radioMacAddress": "70:f3:5a:78:6b:60",
          "ethernetMacAddress": "70:f3:5a:78:01:40",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": null,
          "serialNumber": "KWC212100QQ",
          "ipAddress": "10.13.5.107",
          "version": "8.8.263.0",
          "lastSeen": 1568367418291,
          "uptime": null,
          "type": "FARALLON",
          "isLEDEnabled": true,
          "sshConfig": {
            "sshState": "Disabled",
            "sshUserName": "Cisco",
            "sshPassword": "Y3Qydl2uRyVaa3A6xmpFZSOj4nOi8+8w293i8KPXW75XXfO/a4q4ziKZl/jxppW+iyk3P3Pr2A7Y8dD9gUHm8xOonvcHhf39",
            "enablePassword": "TFPyBCa90byjvx0rfEMoDkskjdoI420+wWZGVfutbjGw8VtQ1CfBVI4v4YOSQWFTj/GE0WYFElKKw22EG48HMmUagitKhVbo"
          }
        },
        {
          "name": "Sensor-0868",
          "status": "RUNNING",
          "radioMacAddress": "70:f3:5a:7c:87:c0",
          "ethernetMacAddress": "70:f3:5a:7c:08:68",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": null,
          "serialNumber": "KWC214106R8",
          "ipAddress": "10.13.5.118",
          "version": "8.8.263.0",
          "lastSeen": 1568367416688,
          "uptime": null,
          "type": "FARALLON",
          "isLEDEnabled": true,
          "sshConfig": {
            "sshState": "Disabled",
            "sshUserName": "Cisco",
            "sshPassword": "57ocUm0tjHk3xcErsrmqXABaSGem4UdOepaaI0ykzNaf44DhXQqfplY1Tu8JCjgbdcnNeDjRfp/zcgD/GIbsKWcS0lsdnYTu",
            "enablePassword": "OOx9Wwjxl2z36M7RAVoRBSvOhfXk8tLfCEXBdwxtkDFtz2cInuIRdBlpTWnJuJJ5JitnJssH0I/DZMPLH5YU3kimhGy5mBfO"
          }
        },
        {
          "name": "12345678901234567890123456789012",
          "status": "IDLE",
          "radioMacAddress": "dc:ce:c1:2e:bd:c0",
          "ethernetMacAddress": "dc:ce:c1:2e:14:d0",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": "WIRED",
          "serialNumber": "KWC193901RS",
          "ipAddress": "10.13.5.109",
          "version": "8.10.2.18",
          "lastSeen": 1568367414494,
          "uptime": null,
          "type": "AP_AS_SENSOR",
          "isLEDEnabled": true,
          "sshConfig": null
        },
        {
          "name": "Sensor-5C98",
          "status": "RUNNING",
          "radioMacAddress": "70:f3:5a:7b:d8:a0",
          "ethernetMacAddress": "70:f3:5a:7a:5c:98",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": null,
          "serialNumber": "KWC214106B2",
          "ipAddress": "10.13.5.105",
          "version": "8.8.263.0",
          "lastSeen": 1568367394892,
          "uptime": null,
          "type": "FARALLON",
          "isLEDEnabled": true,
          "sshConfig": {
            "sshState": "Disabled",
            "sshUserName": "Cisco",
            "sshPassword": "qdL/w7GB/2CgjZuY4iIJ0WNg6C47qSyjZFHCFXdvmBxX5NcUb9cr3hW3czgytpDOx7FEx2mu/UbhppwJeG9Q1jp4I77h1v/8",
            "enablePassword": "OOeuQW8MSJpQwQoxK0v3wOytRCpYpQ6XkIf/YhC77KrgCBMwDVXbL9RsgfD7fApWJMEE33180FIGneDuPnmuX7qi1O9t34Dn"
          }
        },
        {
          "name": "Sensor-0738",
          "status": "RUNNING",
          "radioMacAddress": "70:f3:5a:7c:83:00",
          "ethernetMacAddress": "70:f3:5a:7c:07:38",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": null,
          "serialNumber": "KWC214106Q6",
          "ipAddress": "10.13.5.104",
          "version": "8.8.263.0",
          "lastSeen": 1568367394041,
          "uptime": null,
          "type": "FARALLON",
          "isLEDEnabled": true,
          "sshConfig": {
            "sshState": "Disabled",
            "sshUserName": "Cisco",
            "sshPassword": "ElnpYTzYbu8LSuydW3CyiWzPH5mYS7bnVWlv0m27Z7tRX+Pkko6lYFTS1861DKSqcaRzA8dOKfQR04frdlzvRw5KLPNhErpA",
            "enablePassword": "ulpNjJxrUu0Q2R0gVLskYKAaJC9X9a94QYcMu2ZBZW82IDzlt5FHdEvcKoDDd+5svPHtXYVR3d67flKiWW3Q5kaKgx6s+NSs"
          }
        },
        {
          "name": "SSSS-02",
          "status": "UNREACHABLE",
          "radioMacAddress": "70:f3:5a:7b:4a:80",
          "ethernetMacAddress": "70:f3:5a:7a:39:10",
          "location": "Global/San Francisco/One Bush/#1300",
          "backhaulType": "WIRELESS",
          "serialNumber": "KWC21400EGH",
          "ipAddress": "10.13.4.250.dummy.com",
          "version": "8.8.400.44",
          "lastSeen": 1567937839386,
          "uptime": null,
          "type": "FARALLON",
          "isLEDEnabled": true,
          "sshConfig": {
            "sshState": "Disabled",
            "sshUserName": "Cisco",
            "sshPassword": "TnxxM1zHJposRVazatQd9ZiQ+DGZKYQ36hh0+vwYdbMA01rpo7KaloqWv+M7CY5KpqQNscFgCFlXj/+60o9vcHVm9mZRL8P+",
            "enablePassword": "AQ6c2LaTJoaEdr4VYu/JGuafOivyvFSaL+iOcUXwdKxi95K3fBYHyqSeP0RxUlqjGAzxJLBmdvIQ8f9wbThIFEdWDIb3CITR"
          }
        }
      ]
    }
});