define({
    "User_Template":
      {
        "id" : "hugh.jackman",
        "label" : "User",
        "timestamp" : "2017-12-14T06:52:17.165+0000",
        "isDeleted" : false,
        "type" : "vertex",
        "properties" : {
          "userId" : [ {
            "value" : "hugh.jackman"
          } ]
        }
      }
});
