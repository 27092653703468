define({
    "ApNameData":[
        {
          "version": "v2",
          "data": [
            {
              "values": {
                "rank": 1,
                "attemptCount": 66,
                "clientCount": 4,
                "clientAssociated": true
              },
              "apName": "SJC14-F1-9166-2"
            },
            {
              "values": {
                "rank": 2,
                "attemptCount": 32,
                "clientCount": 4,
                "clientAssociated": false
              },
              "apName": "SJC14-F1-9164-2"
            },
            {
              "values": {
                "rank": 3,
                "attemptCount": 31,
                "clientCount": 3,
                "clientAssociated": false
              },
              "apName": "SJC14-F1-9166-1"
            },
            {
              "values": {
                "rank": 4,
                "attemptCount": 25,
                "clientCount": 3,
                "clientAssociated": false
              },
              "apName": "SJC14-F1-9164-1"
            },
            {
              "values": {
                "rank": 5,
                "attemptCount": 4,
                "clientCount": 3,
                "clientAssociated": false
              },
              "apName": "SJC14-F1-9136-3"
            }
          ],
          "page": null,
          "scope": null,
          "instrumentation": null,
          "message": null
        },
        {
          "version": "v2",
          "data": [
            {
              "values": {
                "rank": 1,
                "attemptCount": 169,
                "clientCount": 6,
                "clientAssociated": true
              },
              "ssid": "@TME_live_secure"
            }
          ],
          "page": null,
          "scope": null,
          "instrumentation": null,
          "message": null
        },
        {
          "version": "v2",
          "data": [
            {
              "values": {
                "rank": 1,
                "attemptCount": 9,
                "clientCount": 4,
                "clientAssociated": false
              },
              "band": "2.4"
            },
            {
              "values": {
                "rank": 2,
                "attemptCount": 86,
                "clientCount": 3,
                "clientAssociated": true
              },
              "band": "6.0"
            },
            {
              "values": {
                "rank": 3,
                "attemptCount": 74,
                "clientCount": 3,
                "clientAssociated": false
              },
              "band": "5.0"
            }
          ],
          "page": null,
          "scope": null,
          "instrumentation": null,
          "message": null
        },
        {
          "version": "v2",
          "data": [
            {
              "hostOs": "Cisco-Wireless-Sensor 1800S",
              "values": {
                "rank": 1,
                "attemptCount": 12,
                "clientCount": 2,
                "clientAssociated": false
              }
            },
            {
              "hostOs": "Intel-Device",
              "values": {
                "rank": 2,
                "attemptCount": 45,
                "clientCount": 1,
                "clientAssociated": false
              }
            },
            {
              "hostOs": "Dell Inc. Latitude 5520",
              "values": {
                "rank": 3,
                "attemptCount": 40,
                "clientCount": 1,
                "clientAssociated": false
              }
            },
            {
              "hostOs": "Dell-Device Latitude 5520",
              "values": {
                "rank": 4,
                "attemptCount": 26,
                "clientCount": 1,
                "clientAssociated": false
              }
            },
            {
              "hostOs": "Dell Inc. XPS 13 7390",
              "values": {
                "rank": 5,
                "attemptCount": 22,
                "clientCount": 1,
                "clientAssociated": false
              }
            },
            {
              "hostOs": "Apple-Device",
              "values": {
                "rank": 6,
                "attemptCount": 18,
                "clientCount": 1,
                "clientAssociated": true
              }
            }
          ],
          "page": null,
          "scope": null,
          "instrumentation": null,
          "message": null
        },
        {
          "version": "v2",
          "data": [
            {
              "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/624b038c-e463-4365-836c-9581e844b059/d927416c-fc2f-4344-bb6c-cd07c658c665/",
              "values": {
                "siteHierarchy": "Global/San Jose/Building 14/Floor1",
                "rank": 1,
                "attemptCount": 169,
                "clientCount": 6,
                "clientAssociated": true
              }
            }
          ],
          "page": null,
          "scope": null,
          "instrumentation": null,
          "message": null
        }
      ],
      "ConnectivityTrendData":
      {
        "version": "v2",
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -54,
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 34,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 823,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684682520000
                },
                "time": 1684682400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 548,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 32,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684682340000
                },
                "time": 1684682100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 363,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 50,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684682040000
                },
                "time": 1684681800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 961,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 35,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 654,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 22,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684681620000
                },
                "time": 1684681500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 38,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 62,
                                "latencyBg": 21,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 17,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 386,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 11,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684681320000
                },
                "time": 1684681200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -42,
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 505,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684681020000
                },
                "time": 1684680900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 500,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 10,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684680840000
                },
                "time": 1684680600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 383,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 38,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 6,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684680420000
                },
                "time": 1684680300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -39,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 381,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 19,
                                "latencyBe": 27,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684680120000
                },
                "time": 1684680000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -38,
                                "dataRateThreshold": 12,
                                "dataRate": 144,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 10,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684679940000
                },
                "time": 1684679700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 493,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 40,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684679640000
                },
                "time": 1684679400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 644,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684679340000
                },
                "time": 1684679100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 625,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 45,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684679040000
                },
                "time": 1684678800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 644,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 27,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684678740000
                },
                "time": 1684678500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 6,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 519,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684678320000
                },
                "time": 1684678200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -39,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 469,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 17,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684678020000
                },
                "time": 1684677900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -66,
                                "dataRateThreshold": 12,
                                "dataRate": 404,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 20,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684677840000
                },
                "time": 1684677600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 411,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684677540000
                },
                "time": 1684677300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 577,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 35,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684677240000
                },
                "time": 1684677000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 433,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 7,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684676940000
                },
                "time": 1684676700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -38,
                                "latencyVoiceThreshold": 32,
                                "snr": 58,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 312,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 0,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 0,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684676520000
                },
                "time": 1684676400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 572,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684676340000
                },
                "time": 1684676100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 493,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684676040000
                },
                "time": 1684675800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 418,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684675740000
                },
                "time": 1684675500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 536,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 35,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684675440000
                },
                "time": 1684675200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 637,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 45,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684675140000
                },
                "time": 1684674900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 597,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684674840000
                },
                "time": 1684674600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -65,
                                "dataRateThreshold": 12,
                                "dataRate": 473,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 34,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684674540000
                },
                "time": 1684674300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 418,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684674240000
                },
                "time": 1684674000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 606,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684673640000
                },
                "time": 1684673400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 71,
                                "latencyBe": 15,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 555,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 44,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684673220000
                },
                "time": 1684673100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 197,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 6,
                                "latencyBg": 4,
                                "latencyBe": 116,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 52,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 577,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 30,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684672920000
                },
                "time": 1684672800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -46,
                                "latencyVoiceThreshold": 32,
                                "snr": 51,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 34,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 456,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684672620000
                },
                "time": 1684672500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 46,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684672440000
                },
                "time": 1684672200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -45,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 4,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684671960000
                },
                "time": 1684671900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -65,
                                "dataRateThreshold": 12,
                                "dataRate": 443,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 20,
                                "rxRetries": 39,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684671840000
                },
                "time": 1684671600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 606,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684671540000
                },
                "time": 1684671300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -39,
                                "dataRateThreshold": 12,
                                "dataRate": 541,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 4,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684671120000
                },
                "time": 1684671000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -40,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 34,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 12,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 340,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 3,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 41,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 6,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684670820000
                },
                "time": 1684670700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 503,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 5,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684670640000
                },
                "time": 1684670400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 476,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684670340000
                },
                "time": 1684670100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 468,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684670040000
                },
                "time": 1684669800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 266,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 5,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684669740000
                },
                "time": 1684669500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 343,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 3,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 9,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684669320000
                },
                "time": 1684669200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 7,
                                "latencyBe": 21,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 460,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684669020000
                },
                "time": 1684668900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -43,
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 33,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 460,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 20,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684668720000
                },
                "time": 1684668600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 569,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 9,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684668540000
                },
                "time": 1684668300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 15,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 477,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684668120000
                },
                "time": 1684668000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 12,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 4,
                                "latencyBe": 122,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 323,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 46,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 17,
                                "latencyBe": 31,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684667820000
                },
                "time": 1684667700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 308,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 55,
                                "latencyBg": 12,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 87,
                                "rssi": -44,
                                "dataRateThreshold": 12,
                                "dataRate": 392,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 48,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 12,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684667520000
                },
                "time": 1684667400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -40,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 314,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 30,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 6,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684667220000
                },
                "time": 1684667100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -42,
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 21,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 282,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 42,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684666920000
                },
                "time": 1684666800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -40,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 32,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 314,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684666620000
                },
                "time": 1684666500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 380,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 27,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684666440000
                },
                "time": 1684666200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -39,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 16,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 384,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 46,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684666020000
                },
                "time": 1684665900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 649,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 30,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684665840000
                },
                "time": 1684665600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 226,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 0,
                                "latencyBg": 52,
                                "latencyBe": 30,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 59,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 576,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 38,
                                "rxRetries": 43,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 18,
                                "latencyBe": 14,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684665420000
                },
                "time": 1684665300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -43,
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 551,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 49,
                                "rxRetries": 36,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 211,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684665120000
                },
                "time": 1684665000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBg": 9,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 11,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 602,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 25,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 5,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684664820000
                },
                "time": 1684664700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 41,
                                "latencyBe": 198,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 441,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 69,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684664520000
                },
                "time": 1684664400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 133,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 258,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 0,
                                "latencyBg": 9,
                                "latencyBe": 0,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 66,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 313,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 32,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 9,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684664220000
                },
                "time": 1684664100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -43,
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 258,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 539,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684663920000
                },
                "time": 1684663800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 466,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 43,
                                "rxRetries": 30,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684663740000
                },
                "time": 1684663500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 467,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 41,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684663440000
                },
                "time": 1684663200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 503,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 37,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684663140000
                },
                "time": 1684662900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 470,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 38,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684662840000
                },
                "time": 1684662600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -53,
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 3,
                                "dataRateThreshold": 12,
                                "dataRate": 721,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 519,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684662420000
                },
                "time": 1684662300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -55,
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 505,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 20,
                                "rxRetries": 20,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684662120000
                },
                "time": 1684662000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -43,
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 519,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 2,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684661820000
                },
                "time": 1684661700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -39,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -45,
                                "dataRateThreshold": 12,
                                "dataRate": 329,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 2,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684661520000
                },
                "time": 1684661400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 512,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 40,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684661340000
                },
                "time": 1684661100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 445,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 49,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684661040000
                },
                "time": 1684660800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 488,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 54,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684660740000
                },
                "time": 1684660500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 0,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684660440000
                },
                "time": 1684660200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 7,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 494,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 45,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684659720000
                },
                "time": 1684659600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 276,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBg": 116,
                                "latencyBe": 53,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 66,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 252,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 30,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 116,
                                "latencyBe": 13,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684659420000
                },
                "time": 1684659300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 448,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 23,
                                "latencyBe": 20,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 114,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 245,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 26,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 14,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684659120000
                },
                "time": 1684659000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "latencyBe": 360,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 11,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 252,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 35,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 95,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684658820000
                },
                "time": 1684658700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -40,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 286,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 26,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 6,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684658520000
                },
                "time": 1684658400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 27,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 208,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 20,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684658220000
                },
                "time": 1684658100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -39,
                                "dataRateThreshold": 12,
                                "dataRate": 961,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 34,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 615,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 25,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657920000
                },
                "time": 1684657800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 644,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 22,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657740000
                },
                "time": 1684657500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -66,
                                "dataRateThreshold": 12,
                                "dataRate": 399,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 20,
                                "rxRetries": 71,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657440000
                },
                "time": 1684657200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 572,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 436,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 203,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 416,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 146,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657020000
                },
                "time": 1684656900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 8,
                                "latencyBg": 99,
                                "latencyBe": 98,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 667,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 32,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 51,
                                "latencyBe": 26,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684656720000
                },
                "time": 1684656600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 4,
                                "latencyBg": 4,
                                "latencyBe": 27,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 394,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 22,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684656420000
                },
                "time": 1684656300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 354,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 42,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684656120000
                },
                "time": 1684656000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 669,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBe": 126,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 216,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 402,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 15,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 38,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684655820000
                },
                "time": 1684655700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 1,
                                "latencyBe": 41,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 398,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 15,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684655520000
                },
                "time": 1684655400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 31,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBe": 208,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 386,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 2,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 11,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 5,
                                "latencyBe": 55,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684655220000
                },
                "time": 1684655100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 436,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBg": 15,
                                "latencyBe": 94,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 219,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 42,
                                "rxRetries": 10,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 15,
                                "latencyBe": 53,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684654920000
                },
                "time": 1684654800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 109,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 12,
                                "latencyBe": 238,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 39,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 374,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 17,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 7,
                                "latencyBe": 69,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684654620000
                },
                "time": 1684654500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 35,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 325,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 38,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684654320000
                },
                "time": 1684654200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 29,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 608,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 2,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 7,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684654020000
                },
                "time": 1684653900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 10,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 721,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 27,
                                "latencyBe": 0,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 12,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 463,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 19,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684653720000
                },
                "time": 1684653600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 515,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 0,
                                "latencyBe": 49,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 260,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 698,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 25,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 30,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684653420000
                },
                "time": 1684653300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 17,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 0,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 22,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 403,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684653120000
                },
                "time": 1684653000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 170,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 6,
                                "latencyBg": 384,
                                "latencyBe": 106,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 70,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 381,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 46,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 206,
                                "latencyBe": 37,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684652820000
                },
                "time": 1684652700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 644,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 344,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 41,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 0,
                                "latencyBe": 165,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684652520000
                },
                "time": 1684652400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 448,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 43,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 167,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 554,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 2,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 19,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684652220000
                },
                "time": 1684652100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 22,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 108,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 16,
                                "rssi": -46,
                                "dataRateThreshold": 12,
                                "dataRate": 658,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 0,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 41,
                                "rxRetries": 2,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 55,
                                "latencyBe": 31,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684651920000
                },
                "time": 1684651800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 33,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -46,
                                "dataRateThreshold": 12,
                                "dataRate": 561,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 17,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684651620000
                },
                "time": 1684651500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 347,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBe": 117,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 118,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 585,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 47,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684651320000
                },
                "time": 1684651200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 326,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 81,
                                "latencyBg": 2,
                                "latencyBe": 99,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 110,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 588,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 30,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 38,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684651020000
                },
                "time": 1684650900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 639,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 13,
                                "latencyBe": 64,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 322,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 633,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 9,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 7,
                                "latencyBe": 34,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684650720000
                },
                "time": 1684650600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 2,
                                "latencyBe": 15,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 12,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 455,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 12,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684650420000
                },
                "time": 1684650300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 11,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 96,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 13,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684650120000
                },
                "time": 1684650000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 99,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 21,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 74,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 25,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 44,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684649820000
                },
                "time": 1684649700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 270,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "latencyBg": 3,
                                "latencyBe": 95437,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 101,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 349,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 6,
                                "latencyBe": 31815,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684649520000
                },
                "time": 1684649400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 37,
                                "latencyBg": 3,
                                "latencyBe": 22,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 397,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 25,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 30,
                                "latencyBe": 18,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684649220000
                },
                "time": 1684649100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 43,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 14,
                                "latencyBe": 36,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 12,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 347,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648920000
                },
                "time": 1684648800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 524,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 10,
                                "latencyBe": 89,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 175,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 344,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 30,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648620000
                },
                "time": 1684648500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 337,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 48,
                                "latencyBe": 19,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 114,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 344,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 37,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648320000
                },
                "time": 1684648200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 446,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 2,
                                "latencyBe": 350,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 152,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 392,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 0,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 118,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648020000
                },
                "time": 1684647900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -43,
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 35,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 14,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 480,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 33,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684647720000
                },
                "time": 1684647600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 53,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 20,
                                "latencyBg": 7,
                                "latencyBe": 151,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 24,
                                "rssi": -44,
                                "dataRateThreshold": 12,
                                "dataRate": 489,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 47,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 52,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684647420000
                },
                "time": 1684647300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 45,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 69,
                                "latencyBe": 18,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 18,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 138,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 39,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684647120000
                },
                "time": 1684647000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 64,
                                "latencyBe": 272,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -65,
                                "dataRateThreshold": 12,
                                "dataRate": 459,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 20,
                                "rxRetries": 49,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 272,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684646820000
                },
                "time": 1684646700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 674,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 64,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 229,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 411,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 33,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684646520000
                },
                "time": 1684646400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 21,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 14,
                                "latencyBg": 2,
                                "latencyBe": 105,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 338,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 35,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 5,
                                "latencyBe": 34,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684646220000
                },
                "time": 1684646100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 788,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 1,
                                "latencyBg": 17,
                                "latencyBe": 46,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 210,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 295,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 34,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 17,
                                "latencyBe": 13,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684645920000
                },
                "time": 1684645800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 7,
                                "latencyBg": 4,
                                "latencyBe": 61,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 11,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 338,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 35,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 8,
                                "latencyBe": 20,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684645620000
                },
                "time": 1684645500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -42,
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 10,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 310,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 38,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 3,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684645320000
                },
                "time": 1684645200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 360,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 46,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684645140000
                },
                "time": 1684644900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 334,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684644840000
                },
                "time": 1684644600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 477,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 44,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684644540000
                },
                "time": 1684644300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -65,
                                "dataRateThreshold": 12,
                                "dataRate": 537,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684644240000
                },
                "time": 1684644000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -45,
                                "dataRateThreshold": 12,
                                "dataRate": 347,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 11,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684643940000
                },
                "time": 1684643700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 90,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684643580000
                },
                "time": 1684643400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 151,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 207,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 32,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 86,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684643220000
                },
                "time": 1684643100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 517,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 2,
                                "latencyBe": 577,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 280,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 274,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 1,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 290,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684642920000
                },
                "time": 1684642800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 50,
                                "latencyBg": 23,
                                "latencyBe": 60,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 50,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 23,
                                "latencyBe": 60,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684642620000
                },
                "time": 1684642500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 11,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 69,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 10,
                                "rssi": -46,
                                "dataRateThreshold": 12,
                                "dataRate": 19,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 51,
                                "rxRetries": 1,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 35,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684642320000
                },
                "time": 1684642200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 16,
                                "latencyBe": 207,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 25,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 50,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 81,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684642020000
                },
                "time": 1684641900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 3,
                                "latencyBg": 8,
                                "latencyBe": 71,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -46,
                                "dataRateThreshold": 12,
                                "dataRate": 156,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 52,
                                "rxRetries": 2,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 6,
                                "latencyBe": 38,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684641720000
                },
                "time": 1684641600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 102,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 12,
                                "latencyBe": 141,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 37,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 249,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 49,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 11,
                                "latencyBe": 53,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684641420000
                },
                "time": 1684641300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 24,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 339,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 30,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 380,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 47,
                                "rxRetries": 27,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 118,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684641120000
                },
                "time": 1684641000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 334,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684640820000
                },
                "time": 1684640700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBg": 51,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 374,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 8,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 51,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684640520000
                },
                "time": 1684640400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 636,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 442,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 216,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684640220000
                },
                "time": 1684640100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 16,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 248,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 23,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 348,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 11,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 6,
                                "latencyBe": 54,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684639920000
                },
                "time": 1684639800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 2,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 31,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 429,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684639620000
                },
                "time": 1684639500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 310,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 53,
                                "latencyBe": 257,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 88,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 332,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 26,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 31,
                                "latencyBe": 70,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684639320000
                },
                "time": 1684639200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 2,
                                "latencyBg": 180,
                                "latencyBe": 409,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 196,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 180,
                                "latencyBe": 104,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684639020000
                },
                "time": 1684638900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 6,
                                "latencyBg": 7,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 370,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 36,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 7,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684638720000
                },
                "time": 1684638600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 204,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 540,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 82,
                                "latencyBg": 8,
                                "latencyBe": 128,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 103,
                                "rssi": -36,
                                "dataRateThreshold": 12,
                                "dataRate": 420,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 43,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 8,
                                "latencyBe": 65,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684638420000
                },
                "time": 1684638300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 961,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 93,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 24,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 691,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 65,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684638120000
                },
                "time": 1684638000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 400,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 25,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684637940000
                },
                "time": 1684637700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 16,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 455,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684637520000
                },
                "time": 1684637400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 354,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBg": 3,
                                "latencyBe": 614,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 103,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 466,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 156,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684637220000
                },
                "time": 1684637100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 2,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 414,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 26,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 8,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684636920000
                },
                "time": 1684636800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 12,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 382,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 15,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684636620000
                },
                "time": 1684636500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 1,
                                "latencyBe": 110,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 492,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 2,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 32,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 23,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684636320000
                },
                "time": 1684636200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 17,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 1,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 388,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 33,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684636020000
                },
                "time": 1684635900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 521,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 241,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 145,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684635720000
                },
                "time": 1684635600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 57,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 7,
                                "latencyBe": 676,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 34,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 182,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 5,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 7,
                                "latencyBe": 242,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684635420000
                },
                "time": 1684635300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 13,
                                "latencyBg": 9,
                                "latencyBe": 29,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 280,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 9,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 9,
                                "latencyBe": 13,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684635120000
                },
                "time": 1684635000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -39,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -36,
                                "dataRateThreshold": 12,
                                "dataRate": 46,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 60,
                                "rxRetries": 2,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684634820000
                },
                "time": 1684634700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -66,
                                "dataRateThreshold": 12,
                                "dataRate": 175,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 39,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684634640000
                },
                "time": 1684634400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 161,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684634280000
                },
                "time": 1684634100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 1,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684634040000
                },
                "time": 1684633800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 270,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684633740000
                },
                "time": 1684633500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 552,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684633440000
                },
                "time": 1684633200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -39,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -32,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 60,
                                "rxRetries": 0,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684633020000
                },
                "time": 1684632900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 1081,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 39,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684632360000
                },
                "time": 1684632300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -46,
                                "latencyVoiceThreshold": 32,
                                "snr": 52,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 553,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 7,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684632120000
                },
                "time": 1684632000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 505,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 9,
                                "latencyBg": 145,
                                "latencyBe": 55,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 257,
                                "rssi": -46,
                                "dataRateThreshold": 12,
                                "dataRate": 553,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 46,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 73,
                                "latencyBe": 28,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684631820000
                },
                "time": 1684631700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBg": 41,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 347,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 45,
                                "rxRetries": 22,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 41,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684631520000
                },
                "time": 1684631400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 465,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 2,
                                "latencyBg": 349,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 172,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 216,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 45,
                                "rxRetries": 15,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 175,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684631220000
                },
                "time": 1684631100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 481,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 8,
                                "latencyBg": 11,
                                "latencyBe": 188,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 132,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 389,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 42,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 11,
                                "latencyBe": 60,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684630920000
                },
                "time": 1684630800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 13,
                                "latencyBg": 5,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 527,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 3,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684630620000
                },
                "time": 1684630500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -39,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 10,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 563,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684630320000
                },
                "time": 1684630200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 23,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 179,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 23,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 499,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 46,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684630020000
                },
                "time": 1684629900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 266,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 2,
                                "latencyBe": 805,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 99,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 394,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 8,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 0,
                                "latencyBe": 202,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684629720000
                },
                "time": 1684629600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBe": 20,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 565,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 33,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684629420000
                },
                "time": 1684629300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 16,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBg": 101,
                                "latencyBe": 293,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 508,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 27,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 101,
                                "latencyBe": 99,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684629120000
                },
                "time": 1684629000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -43,
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 44,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 532,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 36,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684628820000
                },
                "time": 1684628700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 473,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 34,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684628520000
                },
                "time": 1684628400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 587,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 20,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684628340000
                },
                "time": 1684628100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 373,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 10,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684628040000
                },
                "time": 1684627800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 550,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 20,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684627740000
                },
                "time": 1684627500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 494,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684627440000
                },
                "time": 1684627200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 633,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 25,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684627140000
                },
                "time": 1684626900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 697,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684626840000
                },
                "time": 1684626600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 857,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 43,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684626540000
                },
                "time": 1684626300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 715,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 35,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684626240000
                },
                "time": 1684626000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 282,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 11,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684625940000
                },
                "time": 1684625700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -66,
                                "dataRateThreshold": 12,
                                "dataRate": 468,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684625640000
                },
                "time": 1684625400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 649,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 45,
                                "latencyBe": 39,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 338,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684625220000
                },
                "time": 1684625100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 27,
                                "latencyBe": 225,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 529,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 40,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 77,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684624920000
                },
                "time": 1684624800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 628,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 26,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 164,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 546,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 33,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684624620000
                },
                "time": 1684624500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1286,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 71,
                                "latencyBg": 3,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 437,
                                "rssi": -64,
                                "dataRateThreshold": 12,
                                "dataRate": 485,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 50,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 40,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684624320000
                },
                "time": 1684624200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 721,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 0,
                                "latencyBg": 1,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 56,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 414,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 17,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684624020000
                },
                "time": 1684623900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 405,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBe": 280,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 120,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 436,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 94,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684623720000
                },
                "time": 1684623600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBg": 4,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 11,
                                "rssi": -45,
                                "dataRateThreshold": 12,
                                "dataRate": 399,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684623420000
                },
                "time": 1684623300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 325,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 4,
                                "latencyBe": 215,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 83,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 450,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 55,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684623120000
                },
                "time": 1684623000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 598,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 634,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 122,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 479,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 130,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684622820000
                },
                "time": 1684622700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 43,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 72,
                                "latencyBg": 27,
                                "latencyBe": 997,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 27,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 554,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 44,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 27,
                                "latencyBe": 203,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684622520000
                },
                "time": 1684622400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 229,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 566,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684622220000
                },
                "time": 1684622100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 261,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 582,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 67,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684621920000
                },
                "time": 1684621800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 27,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "latencyBe": 30,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 434,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684621620000
                },
                "time": 1684621500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 1,
                                "latencyBe": 87,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 17,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 455,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 25,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684621320000
                },
                "time": 1684621200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 96,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 263,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 34,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 457,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 68,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684621020000
                },
                "time": 1684620900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 301,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 5,
                                "latencyBg": 81,
                                "latencyBe": 48,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 71,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 426,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 28,
                                "latencyBe": 16,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684620720000
                },
                "time": 1684620600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -38,
                                "latencyVoiceThreshold": 32,
                                "snr": 58,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 2,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 231,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 7,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 0,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684620420000
                },
                "time": 1684620300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "dataRateThreshold": 12,
                                "dataRate": 466,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 18,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684620240000
                },
                "time": 1684620000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 67,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 19,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 34,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 161,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 0,
                                "latencyBe": 18,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684619820000
                },
                "time": 1684619700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 13,
                                "latencyBe": 60,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 204,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 27,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684619520000
                },
                "time": 1684619400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -42,
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 9,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 62,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 204,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 45,
                                "rxRetries": 3,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684619220000
                },
                "time": 1684619100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 7,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 204,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 45,
                                "rxRetries": 9,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684618920000
                },
                "time": 1684618800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 548,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 41,
                                "rxRetries": 35,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684618740000
                },
                "time": 1684618500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 634,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 32,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684618440000
                },
                "time": 1684618200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 630,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684618140000
                },
                "time": 1684617900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 745,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684617840000
                },
                "time": 1684617600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 28,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 630,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 5,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 34,
                                "rxRetries": 20,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684617420000
                },
                "time": 1684617300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 24,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 14,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 563,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684617120000
                },
                "time": 1684617000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 920,
                                "rssi": -43,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 54,
                                "rxRetries": 1,
                                "latencyBg": 15,
                                "latencyBe": 35,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 920,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 563,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 15,
                                "latencyBe": 35,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684616820000
                },
                "time": 1684616700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 946,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 43,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 316,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 342,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 17,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684616520000
                },
                "time": 1684616400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 224,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 53,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684616220000
                },
                "time": 1684616100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 78,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "latencyBg": 4,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 27,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 436,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 3,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684615920000
                },
                "time": 1684615800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 217,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 38,
                                "latencyBg": 4,
                                "latencyBe": 24,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 55,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 440,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684615620000
                },
                "time": 1684615500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 5,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 280,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 6,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684615320000
                },
                "time": 1684615200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 428,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 7,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684615140000
                },
                "time": 1684614900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 698,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684614840000
                },
                "time": 1684614600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 697,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 32,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684614540000
                },
                "time": 1684614300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "latencyBe": 419,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 387,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 419,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684614120000
                },
                "time": 1684614000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 787,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBg": 82,
                                "latencyBe": 132,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 181,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 563,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 22,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 43,
                                "latencyBe": 31,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684613820000
                },
                "time": 1684613700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -40,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 10,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 42,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 604,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 39,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684613520000
                },
                "time": 1684613400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 737,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 37,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684613340000
                },
                "time": 1684613100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 659,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 44,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684613040000
                },
                "time": 1684612800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 602,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 33,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684612740000
                },
                "time": 1684612500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 510,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 42,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684612440000
                },
                "time": 1684612200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 515,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 67,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684612140000
                },
                "time": 1684611900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 14,
                                "latencyBe": 46,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 517,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 31,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 19,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684611720000
                },
                "time": 1684611600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -39,
                                "dataRateThreshold": 12,
                                "dataRate": 1081,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 460,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 42,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 14,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684611420000
                },
                "time": 1684611300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -48,
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 27,
                                "rxRetries": 0,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684611120000
                },
                "time": 1684611000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 1201,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684610760000
                },
                "time": 1684610700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 483,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684610640000
                },
                "time": 1684610400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 619,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684610340000
                },
                "time": 1684610100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 622,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 36,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684610040000
                },
                "time": 1684609800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 440,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 22,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684609740000
                },
                "time": 1684609500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 288,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 0,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684609440000
                },
                "time": 1684609200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 1081,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684608960000
                },
                "time": 1684608900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -44,
                                "dataRateThreshold": 12,
                                "dataRate": 192,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 43,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684608840000
                },
                "time": 1684608600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -40,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 475,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 4,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684608420000
                },
                "time": 1684608300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -38,
                                "latencyVoiceThreshold": 32,
                                "snr": 58,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 240,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 3,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684608120000
                },
                "time": 1684608000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 92,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 116,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 36,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 325,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 7,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 3,
                                "latencyBe": 39,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684607820000
                },
                "time": 1684607700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 70,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 1,
                                "latencyBg": 5,
                                "latencyBe": 95,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 103,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 276,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 43,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 5,
                                "latencyBe": 31,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684607520000
                },
                "time": 1684607400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 560,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 7,
                                "latencyBe": 287,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 200,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 176,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 14,
                                "latencyBe": 81,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684607220000
                },
                "time": 1684607100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 38,
                                "latencyBg": 4,
                                "latencyBe": 578,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 22,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 108,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 49,
                                "rxRetries": 15,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 150,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684606920000
                },
                "time": 1684606800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 4,
                                "latencyBg": 8,
                                "latencyBe": 96,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 20,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 108,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 48,
                                "rxRetries": 7,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 5,
                                "latencyBe": 35,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684606620000
                },
                "time": 1684606500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 16,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -48,
                                "dataRateThreshold": 12,
                                "dataRate": 120,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 49,
                                "rxRetries": 12,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 4,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684606320000
                },
                "time": 1684606200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -38,
                                "latencyVoiceThreshold": 32,
                                "snr": 58,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -47,
                                "dataRateThreshold": 12,
                                "dataRate": 224,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 50,
                                "rxRetries": 1,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684606020000
                },
                "time": 1684605900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 1,
                                "latencyBe": 137,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -26,
                                "dataRateThreshold": 12,
                                "dataRate": 15,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 71,
                                "rxRetries": 4,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 137,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684605720000
                },
                "time": 1684605600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 47,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 1,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 27,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 462,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 21,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684605420000
                },
                "time": 1684605300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 287,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 10,
                                "latencyBe": 115,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 550,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 1,
                                "latencyBe": 32,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684605120000
                },
                "time": 1684605000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 0,
                                "latencyBe": 276,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 19,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 512,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 1,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 11,
                                "latencyBe": 58,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684604820000
                },
                "time": 1684604700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 361,
                                "rssi": -40,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 1,
                                "latencyBg": 44,
                                "latencyBe": 64,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 182,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 482,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 13,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 44,
                                "latencyBe": 33,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684604520000
                },
                "time": 1684604400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -38,
                                "latencyVoiceThreshold": 32,
                                "snr": 58,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 36,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 476,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 5,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684604220000
                },
                "time": 1684604100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 706,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684604040000
                },
                "time": 1684603800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -39,
                                "latencyVoiceThreshold": 32,
                                "snr": 57,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 35,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 494,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 39,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684603620000
                },
                "time": 1684603500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 458,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 41,
                                "rxRetries": 19,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684603440000
                },
                "time": 1684603200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -52,
                                "dataRateThreshold": 12,
                                "dataRate": 63,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 40,
                                "rxRetries": 7,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684603140000
                },
                "time": 1684602900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 45,
                                "rxRetries": 0,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684602840000
                },
                "time": 1684602600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 15,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -41,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 56,
                                "rxRetries": 15,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684602420000
                },
                "time": 1684602300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -66,
                                "dataRateThreshold": 12,
                                "dataRate": 302,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 34,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684602240000
                },
                "time": 1684602000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 421,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 961,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 0,
                                "latencyBg": 0,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 151,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 599,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 15,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 0,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684601820000
                },
                "time": 1684601700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -52,
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 3,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 38,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 609,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 9,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 19,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684601520000
                },
                "time": 1684601400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 11,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 24,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 616,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "latencyVideo": 28,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 39,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 11,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684601220000
                },
                "time": 1684601100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 11,
                                "rssi": -53,
                                "dataRateThreshold": 12,
                                "dataRate": 961,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 33,
                                "rxRetries": 30,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 10,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 799,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 37,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684600920000
                },
                "time": 1684600800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -50,
                                "latencyVoiceThreshold": 32,
                                "snr": 36,
                                "rxRetries": 85,
                                "dataRateThreshold": 12,
                                "dataRate": 961,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 558,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 54,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684600620000
                },
                "time": 1684600500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 273,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 48,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684600440000
                },
                "time": 1684600200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -63,
                                "dataRateThreshold": 12,
                                "dataRate": 273,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 36,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684600140000
                },
                "time": 1684599900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 471,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 35,
                                "rxRetries": 3,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684599840000
                },
                "time": 1684599600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 591,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 14,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684599540000
                },
                "time": 1684599300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 356,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684599240000
                },
                "time": 1684599000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 497,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 30,
                                "rxRetries": 23,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684598940000
                },
                "time": 1684598700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 45,
                                "latencyBe": 57,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 12,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 702,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 24,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 17,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684598520000
                },
                "time": 1684598400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 721,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 70,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 656,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 26,
                                "rxRetries": 47,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684598220000
                },
                "time": 1684598100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -56,
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 43,
                                "dataRateThreshold": 12,
                                "dataRate": 721,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 18,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 604,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 25,
                                "rxRetries": 59,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 2,
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684597920000
                },
                "time": 1684597800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 459,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 0,
                                "latencyBe": 34,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 161,
                                "rssi": -58,
                                "dataRateThreshold": 12,
                                "dataRate": 745,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 29,
                                "rxRetries": 16,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684597620000
                },
                "time": 1684597500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 123,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 31,
                                "rxRetries": 45,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 44,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 775,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 36,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684597320000
                },
                "time": 1684597200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -56,
                                "dataRateThreshold": 12,
                                "dataRate": 865,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 54,
                                "latencyBe": 584,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 556,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 32,
                                "rxRetries": 29,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBe": 168,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684597020000
                },
                "time": 1684596900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 885,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 24,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 5,
                                "latencyBe": 112,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 194,
                                "rssi": -55,
                                "dataRateThreshold": 12,
                                "dataRate": 205,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 37,
                                "rxRetries": 10,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 12,
                                "latencyBe": 27,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684596720000
                },
                "time": 1684596600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -42,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 55,
                                "rxRetries": 21,
                                "latencyBe": 444,
                                "latencyBgThreshold": 32
                            }
                        },
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 16,
                                "rssi": -54,
                                "dataRateThreshold": 12,
                                "dataRate": 299,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 38,
                                "rxRetries": 37,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBg": 3,
                                "latencyBe": 153,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684596420000
                },
                "time": 1684596300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "site",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -62,
                                "dataRateThreshold": 12,
                                "dataRate": 460,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "frequency": "5.0",
                                "latencyBeThreshold": 32,
                                "siteHierarchyGraphId": "/e206d6b6-43d9-482c-b737-e213d4218afc/972ffb67-2cd3-48ab-94c5-6aba2ba90fd2/874c295f-9a68-4942-a5b9-a23421e3f185/b452d9e4-4bf4-4b1a-b6e5-bb8db8111c6c/",
                                "latencyVoiceThreshold": 32,
                                "snr": 28,
                                "rxRetries": 28,
                                "siteHierarchy": "Global/San Jose/Building 18/Floor3",
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684596240000
                },
                "time": 1684596000000
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    },
    "ConnectivityTrendFalseData":
    {
        "version": "v2",
        "data": [
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 22,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 48,
                                "rxRetries": 75,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684730280000
                },
                "time": 1684730100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 21,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 29,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684729980000
                },
                "time": 1684729800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 49,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684729680000
                },
                "time": 1684729500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 34,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684729380000
                },
                "time": 1684729200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 165,
                                "rssi": -73,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 90,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684729080000
                },
                "time": 1684728900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 6,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 85,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684728780000
                },
                "time": 1684728600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 26,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 99,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684728480000
                },
                "time": 1684728300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 30,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 0,
                                "latencyBe": 68,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684728180000
                },
                "time": 1684728000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 129,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 49,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684727880000
                },
                "time": 1684727700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 15,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 25,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684727580000
                },
                "time": 1684727400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684727280000
                },
                "time": 1684727100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -51,
                                "latencyVoiceThreshold": 32,
                                "snr": 46,
                                "rxRetries": 38,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684725180000
                },
                "time": 1684725000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -72,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 22,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684724880000
                },
                "time": 1684724700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 18,
                                "rssi": -70,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 80,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684724580000
                },
                "time": 1684724400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 34,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684724280000
                },
                "time": 1684724100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 252,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 30,
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684723980000
                },
                "time": 1684723800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 26,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684723680000
                },
                "time": 1684723500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "latencyVoiceThreshold": 32,
                                "snr": 16,
                                "rxRetries": 9,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684723380000
                },
                "time": 1684723200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 34,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 130,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 47,
                                "rxRetries": 15,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684723080000
                },
                "time": 1684722900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -69,
                                "latencyVoiceThreshold": 32,
                                "snr": 15,
                                "rxRetries": 93,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684722780000
                },
                "time": 1684722600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684722480000
                },
                "time": 1684722300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 10,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 0,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684722180000
                },
                "time": 1684722000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 20,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 0,
                                "latencyBe": 39,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684721880000
                },
                "time": 1684721700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684721580000
                },
                "time": 1684721400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 84,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684721280000
                },
                "time": 1684721100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 29,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 20,
                                "rxRetries": 86,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684720980000
                },
                "time": 1684720800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 62,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 86,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684720680000
                },
                "time": 1684720500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 46,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 82,
                                "latencyBe": 0,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684720380000
                },
                "time": 1684720200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 77,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 7,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 87,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684720080000
                },
                "time": 1684719900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 81,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684719780000
                },
                "time": 1684719600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 58,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684719480000
                },
                "time": 1684719300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 24,
                                "rssi": -68,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 74,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684719180000
                },
                "time": 1684719000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 57,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684718880000
                },
                "time": 1684718700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 324,
                                "rssi": -69,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 69,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684718580000
                },
                "time": 1684718400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 68,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 38,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684718280000
                },
                "time": 1684718100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 32,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684717980000
                },
                "time": 1684717800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -70,
                                "dataRateThreshold": 12,
                                "dataRate": 32,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 16,
                                "rxRetries": 1,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684717680000
                },
                "time": 1684717500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -52,
                                "latencyVoiceThreshold": 32,
                                "snr": 45,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684717380000
                },
                "time": 1684717200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -53,
                                "latencyVoiceThreshold": 32,
                                "snr": 44,
                                "rxRetries": 2,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684717080000
                },
                "time": 1684716900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 21,
                                "rssi": -49,
                                "dataRateThreshold": 12,
                                "dataRate": 144,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 47,
                                "rxRetries": 35,
                                "latencyBe": 20,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684716780000
                },
                "time": 1684716600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 54,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 41,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684716480000
                },
                "time": 1684716300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 46,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 47,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684716180000
                },
                "time": 1684716000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 34,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 24,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684715880000
                },
                "time": 1684715700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 60,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684715580000
                },
                "time": 1684715400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 59,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684715280000
                },
                "time": 1684715100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 16,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684714980000
                },
                "time": 1684714800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 45,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684714680000
                },
                "time": 1684714500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 39,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684714380000
                },
                "time": 1684714200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 14,
                                "rssi": -69,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 45,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684714080000
                },
                "time": 1684713900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -72,
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684713780000
                },
                "time": 1684713600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 25,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 41,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684713480000
                },
                "time": 1684713300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 17,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 20,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684713180000
                },
                "time": 1684713000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 86,
                                "rssi": -69,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 16,
                                "rxRetries": 77,
                                "latencyBe": 7,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684671780000
                },
                "time": 1684671600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 50,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 89,
                                "latencyBe": 65,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684671480000
                },
                "time": 1684671300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 10,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 0,
                                "latencyBe": 38,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684671180000
                },
                "time": 1684671000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 88,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684670880000
                },
                "time": 1684670700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 32,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 84,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684670580000
                },
                "time": 1684670400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 4,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 83,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684670280000
                },
                "time": 1684670100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 89,
                                "latencyBe": 10,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684669980000
                },
                "time": 1684669800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 28,
                                "rssi": -61,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 57,
                                "latencyBe": 8,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684669680000
                },
                "time": 1684669500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -73,
                                "latencyVoiceThreshold": 32,
                                "snr": 11,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684669380000
                },
                "time": 1684669200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -49,
                                "latencyVoiceThreshold": 32,
                                "snr": 47,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684669080000
                },
                "time": 1684668900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 35,
                                "rssi": -73,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 12,
                                "rxRetries": 0,
                                "latencyBe": 0,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684668780000
                },
                "time": 1684668600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 29,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684668480000
                },
                "time": 1684668300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -69,
                                "latencyVoiceThreshold": 32,
                                "snr": 16,
                                "rxRetries": 48,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684668180000
                },
                "time": 1684668000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -72,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 58,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684667880000
                },
                "time": 1684667700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 33,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 39,
                                "latencyBe": 14,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684667580000
                },
                "time": 1684667400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 66,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684667280000
                },
                "time": 1684667100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 57,
                                "rssi": -69,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 16,
                                "rxRetries": 68,
                                "latencyBe": 13,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684666980000
                },
                "time": 1684666800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 68,
                                "rssi": -72,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 14,
                                "rxRetries": 0,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684666680000
                },
                "time": 1684666500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -71,
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 43,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684666380000
                },
                "time": 1684666200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 19,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 85,
                                "latencyBe": 16,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684666080000
                },
                "time": 1684665900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684665780000
                },
                "time": 1684665600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 90,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 15,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684665480000
                },
                "time": 1684665300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 43,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 87,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684665180000
                },
                "time": 1684665000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 40,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 83,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684664880000
                },
                "time": 1684664700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -59,
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 71,
                                "dataRateThreshold": 12,
                                "dataRate": 7,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684664580000
                },
                "time": 1684664400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 26,
                                "rssi": -72,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 14,
                                "rxRetries": 73,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684664280000
                },
                "time": 1684664100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 55,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684663980000
                },
                "time": 1684663800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 51,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684663680000
                },
                "time": 1684663500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 47,
                                "latencyBe": 14,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684663380000
                },
                "time": 1684663200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 19,
                                "rssi": -68,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 70,
                                "latencyBe": 16,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684663080000
                },
                "time": 1684662900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 13,
                                "rssi": -69,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 55,
                                "latencyBe": 2,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684662780000
                },
                "time": 1684662600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 30,
                                "rssi": -72,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 16,
                                "rxRetries": 79,
                                "latencyBe": 43,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684662480000
                },
                "time": 1684662300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 0,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684660380000
                },
                "time": 1684660200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -69,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 47,
                                "latencyBe": 13,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684659780000
                },
                "time": 1684659600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 48,
                                "rssi": -72,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 32,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684659480000
                },
                "time": 1684659300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -51,
                                "latencyVoiceThreshold": 32,
                                "snr": 46,
                                "rxRetries": 0,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684659180000
                },
                "time": 1684659000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 30,
                                "rssi": -51,
                                "dataRateThreshold": 12,
                                "dataRate": 18,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 46,
                                "rxRetries": 51,
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684658880000
                },
                "time": 1684658700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -50,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 46,
                                "rxRetries": 46,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684658580000
                },
                "time": 1684658400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -73,
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 27,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684658280000
                },
                "time": 1684658100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 8,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 90,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657980000
                },
                "time": 1684657800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 23,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657680000
                },
                "time": 1684657500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 20,
                                "rssi": -73,
                                "dataRateThreshold": 12,
                                "dataRate": 43,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 78,
                                "latencyBe": 31,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657380000
                },
                "time": 1684657200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 32,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 38,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684657080000
                },
                "time": 1684656900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 139,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 20,
                                "rxRetries": 92,
                                "latencyBe": 15,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684656780000
                },
                "time": 1684656600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 1,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 12,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684656480000
                },
                "time": 1684656300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 44,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 74,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684656180000
                },
                "time": 1684656000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -57,
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 78,
                                "dataRateThreshold": 12,
                                "dataRate": 7,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684655880000
                },
                "time": 1684655700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 64,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 16,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684655580000
                },
                "time": 1684655400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 56,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 88,
                                "latencyBe": 3,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684655280000
                },
                "time": 1684655100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 39,
                                "rssi": -59,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 21,
                                "rxRetries": 83,
                                "latencyBe": 6,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684654980000
                },
                "time": 1684654800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -58,
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 68,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684654680000
                },
                "time": 1684654500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 29,
                                "rssi": -73,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 14,
                                "rxRetries": 22,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684650480000
                },
                "time": 1684650300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 5,
                                "rssi": -71,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 15,
                                "rxRetries": 43,
                                "latencyBe": 28,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684650180000
                },
                "time": 1684650000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 3,
                                "rssi": -69,
                                "dataRateThreshold": 12,
                                "dataRate": 32,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 59,
                                "latencyBe": 25,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684649880000
                },
                "time": 1684649700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -72,
                                "latencyVoiceThreshold": 32,
                                "snr": 14,
                                "rxRetries": 47,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684649580000
                },
                "time": 1684649400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 44,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684649280000
                },
                "time": 1684649100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 9,
                                "rssi": -73,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 12,
                                "rxRetries": 84,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648980000
                },
                "time": 1684648800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 24,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648680000
                },
                "time": 1684648500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 64,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 95,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648380000
                },
                "time": 1684648200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 98,
                                "rssi": -57,
                                "dataRateThreshold": 12,
                                "dataRate": 97,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 24,
                                "rxRetries": 82,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684648080000
                },
                "time": 1684647900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 64,
                                "rssi": -60,
                                "dataRateThreshold": 12,
                                "dataRate": 195,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 22,
                                "rxRetries": 0,
                                "latencyBe": 43,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684647780000
                },
                "time": 1684647600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 44,
                                "dataRateThreshold": 12,
                                "dataRate": 7,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684647480000
                },
                "time": 1684647300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -73,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 13,
                                "rxRetries": 48,
                                "latencyBe": 1,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684647180000
                },
                "time": 1684647000000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -61,
                                "latencyVoiceThreshold": 32,
                                "snr": 23,
                                "rxRetries": 76,
                                "dataRateThreshold": 12,
                                "dataRate": 260,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684646880000
                },
                "time": 1684646700000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -69,
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 57,
                                "dataRateThreshold": 12,
                                "dataRate": 7,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684646580000
                },
                "time": 1684646400000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 19,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 70,
                                "latencyBe": 11,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684646280000
                },
                "time": 1684646100000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 46,
                                "rssi": -68,
                                "dataRateThreshold": 12,
                                "dataRate": 7,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 71,
                                "latencyBe": 0,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684645980000
                },
                "time": 1684645800000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -68,
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 47,
                                "dataRateThreshold": 12,
                                "dataRate": 7,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684645680000
                },
                "time": 1684645500000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 29,
                                "rssi": -68,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 17,
                                "rxRetries": 56,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684645380000
                },
                "time": 1684645200000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 7,
                                "rssi": -67,
                                "dataRateThreshold": 12,
                                "dataRate": 520,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 18,
                                "rxRetries": 61,
                                "latencyBe": 4,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684645080000
                },
                "time": 1684644900000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "rssi": -67,
                                "latencyVoiceThreshold": 32,
                                "snr": 19,
                                "rxRetries": 66,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "latencyBgThreshold": 32,
                                "frequency": "5.0"
                            }
                        }
                    ],
                    "__timestamp": 1684644780000
                },
                "time": 1684644600000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 22,
                                "rssi": -70,
                                "dataRateThreshold": 12,
                                "dataRate": 390,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 16,
                                "rxRetries": 66,
                                "latencyBe": 9,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684644480000
                },
                "time": 1684644300000
            },
            {
                "values": {
                    "nestedData": [
                        {
                            "entityType": "client",
                            "values": {
                                "rssiThreshold": -72,
                                "latencyVoice": 14,
                                "rssi": -73,
                                "dataRateThreshold": 12,
                                "dataRate": 65,
                                "latencyVideoThreshold": 32,
                                "snrThreshold": 9,
                                "latencyBeThreshold": 32,
                                "frequency": "5.0",
                                "latencyVoiceThreshold": 32,
                                "snr": 12,
                                "rxRetries": 65,
                                "latencyBe": 5,
                                "latencyBgThreshold": 32
                            }
                        }
                    ],
                    "__timestamp": 1684644180000
                },
                "time": 1684644000000
            }
        ],
        "page": null,
        "scope": null,
        "instrumentation": null,
        "message": null
    }
}
)