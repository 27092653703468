define({
"Template":[
  {
    "id": "cba22c35-7e32-487c-a463-f6ade447284b",
    "instanceId": 3984351463,
    "instanceCreatedOn": 1669921032021,
    "instanceUpdatedOn": 1669921032021,
    "instanceVersion": 0,
    "createTime": 1669921031983,
    "deployed": false,
    "isSeeded": false,
    "isStale": false,
    "lastUpdateTime": 1669921031983,
    "name": "TesterPolicy1",
    "namespace": "cba22c35-7e32-487c-a463-f6ade447284b",
    "provisioningState": "DEFINED",
    "resourceVersion": 0,
    "targetIdList": [],
    "type": "ExtranetPolicy",
    "cfsChangeInfo": [],
    "customProvisions": [],
    "fabricUuids": [
      "1b93dd31-ca5c-4626-8ba8-997589672bff"
    ],
    "globalPolicyName": "TesterPolicy1",
    "policyType": "GLOBAL",
    "providers": [
      {
        "id": "673a3714-6cdf-4bd8-bd85-0bd7775b327a",
        "instanceId": 9435788362,
        "instanceCreatedOn": 1669921032021,
        "instanceUpdatedOn": 1669921032021,
        "instanceVersion": 0,
        "name": "TestNetwork2",
        "displayName": "TestNetwork2"
      }
    ],
    "subscribers": [
      {
        "id": "147b6428-623b-415f-a8d1-ca3eeb7ed6df",
        "instanceId": 9435789363,
        "instanceCreatedOn": 1669921032021,
        "instanceUpdatedOn": 1669921032021,
        "instanceVersion": 0,
        "name": "TestNetwork3",
        "displayName": "TestNetwork3"
      }
    ],
    "displayName": "2469f543[TesterPolicy1]"
  }
]

})