define({
    "Results_Detail_Template":{
     "mostRecent":{"lastRun":0,"testResults":[
     {"id": 1, "testType": "dhcp", "status": "pass"},
     {"id": 2, "testType": "webserver", "status": "pass"},
     {"id": 3, "testType": "onboarding", "status": "pass"}]
     },
     "detailResults":{"dhcp": {"detailList":{"interval1": "none", "interval2": "pass", "interval3": "pass", "interval4": "pass", "interval5": "pass","interval6":"slow","interval7":"pass","interval8":"pass","interval9":"pass","interval10":"pass","interval11":"pass","interval12":"pass","interval13":"pass","interval14":"slow","interval15":"slow","interval16":"slow","interval17":"pass","interval18":"pass","interval19":"pass","interval20":"pass","interval21":"pass","interval22":"pass","interval23":"pass","interval24":"slow","interval25":"pass","interval26":"pass","interval27":"pass","interval28":"pass","interval29":"pass","interval30":"pass","interval31":"pass","interval32":"pass","interval33":"pass","interval34":"pass","interval35":"pass","interval36":"pass","interval37":"pass","interval38":"pass","interval39":"slow","interval40":"pass","interval41":"pass","interval42":"pass","interval43":"pass","interval44":"pass","interval45":"pass","interval46":"pass","interval47":"pass","interval48":"pass"}},
            "onboarding": {"detailList":{"interval1": "none", "interval2": "pass", "interval3": "pass", "interval4": "pass", "interval5": "pass","interval6":"slow","interval7":"pass","interval8":"pass","interval9":"pass","interval10":"pass","interval11":"slow","interval12":"pass","interval13":"pass","interval14":"slow","interval15":"slow","interval16":"slow","interval17":"pass","interval18":"pass","interval19":"pass","interval20":"pass","interval21":"pass","interval22":"pass","interval23":"pass","interval24":"slow","interval25":"slow","interval26":"pass","interval27":"pass","interval28":"pass","interval29":"pass","interval30":"pass","interval31":"pass","interval32":"pass","interval33":"pass","interval34":"pass","interval35":"pass","interval36":"pass","interval37":"pass","interval38":"slow","interval39":"slow","interval40":"pass","interval41":"pass","interval42":"pass","interval43":"pass","interval44":"pass","interval45":"pass","interval46":"pass","interval47":"pass","interval48":"pass"}},
            "webserver": {"detailList":{"interval1": "none", "interval2": "pass", "interval3": "pass", "interval4": "pass", "interval5": "pass","interval6":"slow","interval7":"pass","interval8":"pass","interval9":"slow","interval10":"pass","interval11":"pass","interval12":"pass","interval13":"pass","interval14":"slow","interval15":"slow","interval16":"slow","interval17":"pass","interval18":"pass","interval19":"pass","interval20":"pass","interval21":"pass","interval22":"pass","interval23":"pass","interval24":"slow","interval25":"pass","interval26":"pass","interval27":"slow","interval28":"pass","interval29":"pass","interval30":"pass","interval31":"pass","interval32":"pass","interval33":"pass","interval34":"pass","interval35":"pass","interval36":"slow","interval37":"pass","interval38":"pass","interval39":"slow","interval40":"pass","interval41":"pass","interval42":"pass","interval43":"pass","interval44":"pass","interval45":"pass","interval46":"pass","interval47":"pass","interval48":"pass"}}
     }
     }});