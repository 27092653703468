define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions'
], function (SimLokiDatabaseActions, UtilityFunctions) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            console.log(urlAction.url);
            console.log(urlAction);
            var data;
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    break;

                case 'DELETE':
                    break;

                default:
                    break;
            }

            return data;
        }
    };

    function processGetRequest(urlAction){
        if(urlAction.service.indexOf('health')>=0 && urlAction.service.indexOf('cisco-ise')>=0){
           var data = [{
                "fqdn": "ise-30-70.ibng.local",
                "ip": "dcloud-ise-ctf-inst-xxx.cisco.com",
                "role": "PRIMARY",
                "status": "AVAILABLE",
                "lastStatusUpdateTime": (UtilityFunctions.getTimeStamp())-6000
            },{
                "fqdn": "ise-30-71.ibng.local",
                "ip": "dcloud-ise2-sim-inst-xxx.cisco.com",
                "role": "SECONDARY",
                "status": "AVAILABLE",
                "lastStatusUpdateTime": (UtilityFunctions.getTimeStamp())-6000
            },{
                "fqdn": "ise-30-70.ibng.local",
                "ip": "dcloud-ise-ctf-inst-xxx.cisco.com",
                "role": "PXGRID-ACTIVE",
                "status": "AVAILABLE",
                "lastStatusUpdateTime": (UtilityFunctions.getTimeStamp())-6000
            },{
                "fqdn": "ise-30-71.ibng.local",
                "ip": "dcloud-ise2-sim-inst-xxx.cisco.com",
                "role": "PXGRID-ACTIVE",
                "status": "AVAILABLE",
                "lastStatusUpdateTime": (UtilityFunctions.getTimeStamp())-6000
            }]
            return data
        }
        if(urlAction.service.indexOf('health')>=0) {
            if(urlAction.service.indexOf('vmanage') > -1) {
                return [{"status":"AVAILABLE","ipOrHostname":"172.29.58.90","username":"dcnadmin","vmanageUrl":"https://172.29.58.90:8443"}]
            }
            return [{"ipAddress":"10.16.81.75","state":"ACTIVE"}];
         }else if(urlAction.service.indexOf('flow_collectors')>=0) {
            data = [{"name":"FCNF-81-76","id":"151","ipAddress":"10.16.81.76","port":2055,"model":"FCNFVE","version":"7.0.0","build":"2018.12.12.1643-0"}]
            return data;
         }else if(urlAction.service.indexOf('netflowdestination')>=0) {
            data = [{"name":"FCNF-81-76","id":"271","ipAddress":"10.16.81.76","port":2055,"model":"FCNFVE","version":"7.1.2","build":"2018.12.12.1643-0","type":"FLOW_COLLECTOR"}]
            return data;
         }else if(urlAction.service.indexOf('stealthwatch')>=0) {
            return [{"ipAddress":"10.16.81.75","instanceUuid":"d47dea16-632b-4707-8378-1916b1e1e58b","username":"admin",
            "password":"UX6nTdubgUlCRCWJT7C6TbHU3evVQJ/MOHezbiCeQV9J1ZXx/zvTdLnjI6HIMJYc1oHkQIwg5xwQ1Wi4kU5h9MAUY7CtPGJ1"}]
         }else if(urlAction.service.indexOf('umbrella')>=0) {
            return {"response":[]}
         }
        }
    

});
