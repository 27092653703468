define([
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/PnpDeviceInfoTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/LearnDeviceConfigTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTopologyData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/provision/DeviceConfigStatusTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/PnpSettingsTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/swim/SimSwimData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimConnectivityDomainData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/topology/TopologyHostLinkTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimAssuranceDataGenerator',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimInterfaceData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/interface/DeviceInterfaceTemplate',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimMdfData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTemplateProgrammerData',
  'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/assurance/SimPoeAnalyticsData'
], function (SimLokiDatabaseActions, PnpDeviceInfoTemplate, UtilityFunctions, SimTaskDataGenerator,
  LearnDeviceConfigTemplate, SimNetworkDeviceData, SimTopologyData, DeviceConfigStatusTemplate, PnpSettingsTemplate, DefaultConfig,
  SimSwimData, SimConnectivityDomainData, TopologyHostLinkTemplate, SimAssuranceDataGenerator, SimInterfaceData, DeviceInterfaceTemplate,
  SimMdfData, SimTemplateProgrammerData, SimPoeAnalyticsData) {

    var SF12_DEMO = 'SF12Demo', SENSOR_DEMO = '1800SensorDemo', STACKED_DEMO = 'StackedPnP', MOBEXP_DEMO = 'MobilityExpress', 
    DEV_REPLACE = 'DevReplacement', DEMO_9800 = 'DEMO_9800';
    var validDemoNames = [STACKED_DEMO, SF12_DEMO, SENSOR_DEMO, MOBEXP_DEMO, DEV_REPLACE, DEMO_9800];
    var sfo12DevTotal = 0, sfo10DevTotal = 0;

    var demoLocation = 'Original';//This should be the key of deviceRelocationMap and/or defLocationMap
    var deviceRelocationMap = {'Original':{'SFO12':'SFO12', 'SFO10':'SFO10','SJC04':'SJC04','SJC01':'SJC01'},
                              'Melbourne':{'SFO12':'MEL03', 'SFO10':'MEL04','SJC04':'SYD04','SJC01':'SYD01'}
                              };
    var defLocationMap = {'Original':'Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1',
                          'Melbourne':'Global/Australia/New South Wales/Sydney/SYD01/Flr-SYD1-1'};

    var fabricName = {'Original': 'Global/North_America/USA/California/San_Francisco/SFO12_SanJose_Fabric',
                      'Melbourne': 'Global/Australia/Victoria/Melbourne/MEL03_Australia_Fabric'};
    
    //For info: SF12_DEMO: SFO12, SENSOR_DEMO: floor of SJC01, STACKED_DEMO: SJC04, MOBEXP_DEMO: SFO10

    function updatedDeviceName(deviceName) {
      var newName = deviceName, firstName = (deviceName.split('-'))[0], locBasedData = deviceRelocationMap[demoLocation];
      if(Object.keys(locBasedData).indexOf(firstName) > -1) {
        Object.keys(locBasedData).forEach(function(key) {
          if(key == firstName) {
            newName = deviceName.replace(firstName, locBasedData[key]);
          }
        });
      }
      return newName;
    }

    function updatedLocationName(siteName) {
      var locBasedData = deviceRelocationMap[demoLocation], newLoc = siteName;
      Object.keys(locBasedData).forEach(function(key) {
        if(key == siteName) {
          newLoc = locBasedData[key];
        }
      });
      return newLoc;
    }

    function updateDefLocation() {
      return defLocationMap[demoLocation];
    }

    function updatedFabricName() {
      return fabricName[demoLocation];
    }

    function createPnPNetworkDevice(demoName) {
      var pnpDevices = DefaultConfig.PNP_DEVICE_DETAILS[demoName], workflowNames = [], macAddress, ipAddress, serialno;
      var timestamp = UtilityFunctions.getTimeStamp(), deviceType;
      if (pnpDevices.network) {
        Object.keys(pnpDevices.network).forEach(function (key) {
          var ntwkObjs = pnpDevices.network[key];
          for (var j = 0; j < ntwkObjs.length; j++) {
            macAddress = ntwkObjs[j].deviceInfo.macAddr;
            ipAddress = ntwkObjs[j].deviceInfo.ipAddress;
            serialno = ntwkObjs[j].deviceInfo.serialno;
            for (var i = 0; i < ntwkObjs[j].hostnames.length; i++) {
              var tJson, isPreClaimNameExist = false;
              if(ntwkObjs[j].preClaimDeviceName && ntwkObjs[j].preClaimDeviceName.length == ntwkObjs[j].hostnames.length) {
                isPreClaimNameExist = true;
              }
              var name = updatedDeviceName(ntwkObjs[j].hostnames[i]);
              switch(key) {
                case "StackedSwitch":
                  deviceType = "Stacked"; break;
                case "WLC":
                  deviceType = "WLC"; break;
                case "Sensor":
                  deviceType = "Sensor"; break;
                case "Switch":
                case "Router":
                default:
                  deviceType = "Network Device"; break;
              }
              tJson = getTemplate(deviceType, false, ntwkObjs[j].deviceInfo.platformid, name);
              tJson.deviceInfo.postClaimDeviceName = name;//used during provisioning/topology creation
              ntwkObjs[j].hostnames[i] = (isPreClaimNameExist==true ? ntwkObjs[j].preClaimDeviceName[i] : name);
              var fields = name.split('-');//Ex:SFO12-C9200-02. <siteId>-<type/series>-<number>
              tJson.deviceInfo.hostname = ntwkObjs[j].hostnames[i];
              serialno = (isPreClaimNameExist==true ? ntwkObjs[j].preClaimDeviceName[i] : UtilityFunctions.incrementSerialNo(serialno, 1));
              tJson.deviceInfo.serialNumber = serialno;
              tJson.deviceInfo.name = ntwkObjs[j].hostnames[i];
              tJson.deviceInfo.pid = ntwkObjs[j].deviceInfo.platformid;
              tJson.deviceInfo.deviceType = key;
              tJson.id = UtilityFunctions.generateId(24);
              delete tJson.deviceInfo.projectId;
              delete tJson.deviceInfo.workflowId;
              macAddress = UtilityFunctions.incrementMacAddrres(macAddress, 1);
              tJson.deviceInfo.macAddress = macAddress;
              ipAddress = UtilityFunctions.incrementIpAddrres(ipAddress, 1);
              tJson.deviceInfo.ipAddress = ipAddress;//adding this field so that it is easy during claiming
              tJson.deviceInfo.httpHeaders[0].value = ipAddress;//for key clientAddress
              if (demoName == STACKED_DEMO) {
                tJson.deviceInfo.deviceSudiSerialNos[0] = ntwkObjs[j].deviceInfo.serialno;
                tJson.deviceInfo.authenticatedSudiSerialNo = ntwkObjs[j].deviceInfo.serialno;
              } 
              tJson.dayNCmdQueue = [];
              tJson.version = 2;
              tJson.id = UtilityFunctions.generateId(24);
              tJson.runSummaryList = [
                { timestamp: 1669770638197, details: "User Added Device", errorFlag: false, tenantId : "614699e69546d628e2ece475" ,version: 2}
              ]
              tJson.progress = {message: "Device has not yet contacted the server. Device is ready to be claimed.", inProgress: false, progressPercent: 0}
              tJson.deviceInfo.aaaCredentials = {username: "", password: ""}
              tJson.deviceInfo.lastUpdateOn = timestamp;
              tJson.deviceInfo.addedOn = timestamp;
              tJson.deviceInfo.firstContact = timestamp;
              tJson.deviceInfo.lastContact = timestamp;
              tJson.deviceInfo.tlsState = "NO_OP";
              tJson.deviceInfo.pnpaasSupportBundleState = "UPPORT_BUNDLE_NONE";
              tJson.deviceInfo.poeSupported = false;
              tJson.deviceInfo.lastContactDuration = 0;
              tJson.deviceInfo.provisionedOn = 0;
              tJson.deviceInfo.dnacDeviceType = "NETWORK";
              tJson.deviceInfo.apProvisionStatus = "DAY0";
              tJson.deviceInfo.authOperation = "AUTHORIZATION_NOT_REQUIRED";
              tJson.deviceInfo.capwapBackOff = false;
              tJson.deviceInfo.dayN = false;
              tJson.deviceInfo.dayNClaimOperation = "NO_OP";
              tJson.deviceInfo.capwapBackOff = false;
              tJson.deviceInfo.hseclicense = false;
              tJson.deviceInfo.provisioningServerType = "DNAC";
              tJson.deviceInfo.redirectionState = "NONE";
              tJson.deviceInfo.reProvision = false;

              if ([SF12_DEMO, MOBEXP_DEMO].indexOf(demoName) > -1) {
                if (demoName == SF12_DEMO) {
                  sfo12DevTotal++;
                } else if (demoName == MOBEXP_DEMO) {
                  sfo10DevTotal++;
                }
              }

              if (demoName == STACKED_DEMO) {
                for (var k = 0; k < ntwkObjs[j].stackedDtls[i].length; k++) {
                  //each device will have stacks
                  var tempObj1 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACKED_FILESYSTEM_CRASH_TEMPLATE));
                  var tempObj2 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACKED_FILESYSTEM_FLASH_TEMPLATE));
                  var tempObj3 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACKED_INFO_TEMPLATE));
                  if (k > 0) {
                    tempObj1.name = tempObj1.name + '-' + (k + 1);
                    tempObj2.name = tempObj2.name + '-' + (k + 1);
                  }
                  tempObj3.stackNumber = k + 1;
                  tempObj3.serialNumber = ntwkObjs[j].stackedDtls[i][k].serialNo;
                  tempObj3.role = ntwkObjs[j].stackedDtls[i][k].role;
                  tempObj3.priority = ntwkObjs[j].stackedDtls[i][k].priority;
                  tempObj3.macAddress = ntwkObjs[j].stackedDtls[i][k].macAddr;

                  tJson.deviceInfo.fileSystemList.push(tempObj1);
                  tJson.deviceInfo.fileSystemList.push(tempObj2);
                  tJson.deviceInfo.stackInfo.stackMemberList.push(tempObj3);
                }
              }
              delete tJson.deviceInfo.siteId;
              delete tJson.deviceInfo.siteName;
              SimLokiDatabaseActions.insert('onboarding-pnp-device', tJson);
              var workflowName = 'WorkFlow_' + UtilityFunctions.getRandomIntForRangeValues(1000, 1500);//some random name
              if (ntwkObjs[j].isNamingConventioneFollowed) {
                workflowName = fields[1] + "_" + fields[0];
              }
              if (workflowNames.indexOf(workflowName) == -1) {
                workflowNames.push(workflowName);
              }
            }
          }
        });
      }

      for (var i = 0; i < workflowNames.length; i++) {
        createWorkflow(workflowNames[i], UtilityFunctions.generateId(24));
      }
      if (demoName == SF12_DEMO) {
        var obj = { 'key': 'SFO12_TotalDevices', 'value': sfo12DevTotal };
        SimLokiDatabaseActions.insert('dnacConstants', obj);
      } else if (demoName == MOBEXP_DEMO) {
        var obj2 = { 'key': 'SFO10_TotalDevices', 'value': sfo10DevTotal };
        SimLokiDatabaseActions.insert('dnacConstants', obj2);
      }
    }

    function getWorkflowDetail(workflowName) {
      var records = SimLokiDatabaseActions.getFilteredRecordHandler('pnp-workflow', { 'name': workflowName });
      if (records && records.length) {
        return records[0];
      }
      return undefined;
    }

    function createWorkflow(workflowName, workflowId) {
      var tJson = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.PNP_NETWORK_WORKFLOW_TEMPLATE));
      tJson.name = workflowName;
      tJson.id = workflowId;
      tJson.addedOn = UtilityFunctions.getTimeStamp();
      tJson.lastupdateOn = UtilityFunctions.getTimeStamp();
      SimLokiDatabaseActions.insert('pnp-workflow', tJson);
    }

    function updateTimestampHistory(data, startTime) {
      var currTime = startTime, diffTime = 0;
      for (var i = data.length - 1; i >= 0; i--) {
        if (i != 0) {
          diffTime = data[i - 1].timestamp - data[i].timestamp;
        }
        if (i == data.length - 1) {
          data[i].timestamp = currTime;
        } else {
          data[i].timestamp = currTime + diffTime;
          currTime = data[i].timestamp;
        }
      }
      return data;
    }

    function createTopology(data) {
      //This is used only for SFO12
      for (var i = 0; i < data.length; i++) {
        var core1 = updatedDeviceName("SFO12-C9500-01"), router1 = updatedDeviceName("SFO12-ISR4461-01"),
        core2 = updatedDeviceName("SFO12-C9500-02"), router2 = updatedDeviceName("SFO12-ISR4461-02"),
        splEdge = updatedDeviceName("SFO12-C9300-01"), ieDev = updatedDeviceName("SFO12-IE3300-01");
        var devName = data[i].deviceInfo.postClaimDeviceName!= undefined ? data[i].deviceInfo.postClaimDeviceName : data[i].deviceInfo.hostname;
        var coreDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': core1});
        var routerDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': router1});
        var coreDevice2 = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': core2});
        var routerDevice2 = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': router2});
        var splEdgeDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': splEdge});
        var ieDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': ieDev});
        var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': devName});
        if (devName == router1) {
          SimTopologyData.addDevice(DefaultConfig.DEFAULT_UUID.TopologyCloudNode, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, coreDevice[0].siteId);
        } else if (devName == router2) {
          SimTopologyData.addDevice(DefaultConfig.DEFAULT_UUID.TopologyCloudNode, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, coreDevice2[0].siteId);
        } else if (devName == core1) {
          SimTopologyData.addDevice(routerDevice[0].id, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, coreDevice[0].siteId);
        } else if (devName == core2) {
          SimTopologyData.addDevice(routerDevice2[0].id, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, coreDevice2[0].siteId);
        } else if (devName == ieDev) {
          SimTopologyData.addDevice(splEdgeDevice[0].id, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, splEdgeDevice[0].siteId);
        } else {
          SimTopologyData.addDevice(coreDevice[0].id, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, coreDevice[0].siteId);
        }
      }
      if(data.length>0) {
        var topologySite = [
          { "source": [router1], "target": [core2] },
          { "source": [router2], "target": [core1] },
          { "source": [core2], "target": [updatedDeviceName('SFO12-C9200-01')] },
          { "source": [core2], "target": [updatedDeviceName('SFO12-C9200-02')] },
          { "source": [core2], "target": [updatedDeviceName('SFO12-C9200-03')] },
          { "source": [core2], "target": [splEdge] },
          { "source": [splEdge], "target": [ieDev] },
          { "source": [core2], "target": [updatedDeviceName('SFO12-C9800-01')] }];
        SimTopologyData.updateTopology(topologySite);
      }
    }

    function createTopologyMobility(data) {
      //This is used only for SFO10
      var routerNewName, devNewName;
      for (var i = 0; i < data.length; i++) {
        if (data[i].deviceInfo.oldName == updatedDeviceName("SFO10-ISR4321-01")) {
          routerNewName = data[i].deviceInfo.hostname;
        } else {
          devNewName = data[i].deviceInfo.hostname;
        }
      }
      for (var i = 0; i < data.length; i++) {
        var devName = data[i].deviceInfo.hostname;
        var routerDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'hostname': routerNewName });
        var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'hostname': devName });
        if (data[i].deviceInfo.hostname == routerNewName) {
          SimTopologyData.addDevice(DefaultConfig.DEFAULT_UUID.TopologyCloudNode, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, deviceDetails[0].siteId);
        } else {
          SimTopologyData.addDevice(routerDevice[0].id, deviceDetails);
          SimTopologyData.updateSiteDetails(deviceDetails[0].id, routerDevice[0].siteId);
          //add clients under this switch device
          var record = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostName': "SEP189C5D21F515" });
          var accessDevice = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'hostname': devNewName });
          record[0].connectedNetworkDeviceId = accessDevice[0].id;
          record[0].location = getGroupNameHierarchy(accessDevice[0].siteId);
          record[0].clientConnection = accessDevice[0].hostname;
          record[0].vlanId = 120;
          SimLokiDatabaseActions.update('host', record);
          var t_linkJson = JSON.parse(JSON.stringify(TopologyHostLinkTemplate.Topology_Host_Link_Template));
          t_linkJson.source = accessDevice[0].id;
          t_linkJson.target = record[0].id;
          SimLokiDatabaseActions.insert('physical-topology', t_linkJson);
        }
      }
      var topologySite = [{ "source": [routerNewName], "target": [devNewName] },
      { "source": [devNewName], "target": [updatedDeviceName('SFO10-AP3800-01')] },
      { "source": [devNewName], "target": [updatedDeviceName('SFO10-AP3800-02')] }];
      SimTopologyData.updateTopology(topologySite);
    }

    function getGroupNameHierarchy(siteId) {
      var groupNameHierarchy = '';
      recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': siteId });
      if (recordObj && recordObj.length) {
        groupNameHierarchy = recordObj[0].groupNameHierarchy;
      }
      return groupNameHierarchy;
    }

    function addDevice(deviceType, inputData, devDtls) {
      var tJson;
      var timestamp = UtilityFunctions.getTimeStamp();
      if (deviceType == 'Sensor') {
        tJson = getTemplate("Sensor", true, inputData.deviceInfo.pid, inputData.deviceInfo.name);
      } else if (deviceType == 'Stacked') {
        tJson = getTemplate("Stacked", true, inputData.deviceInfo.pid, inputData.deviceInfo.name);
        for (var k = 0; k < inputData.deviceInfo.userSudiSerialNos.length; k++) {
          //each device will have stacks
          var tempObj1 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACKED_FILESYSTEM_CRASH_TEMPLATE));
          var tempObj2 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACKED_FILESYSTEM_FLASH_TEMPLATE));
          var tempObj3 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACKED_INFO_TEMPLATE));
          if (k > 0) {
            tempObj1.name = tempObj1.name + '-' + (k + 1);
            tempObj2.name = tempObj2.name + '-' + (k + 1);
          }
          tempObj3.stackNumber = k + 1;
          tempObj3.serialNumber = inputData.deviceInfo.userSudiSerialNos[k];
          tempObj3.role = 'Member';
          tempObj3.priority = k == 0 ? 1 : 10;
          tempObj3.macAddress = k > 9 ? ('501c.b066.ea' + k) : ('501c.b066.ea0' + k);

          tJson.deviceInfo.fileSystemList.push(tempObj1);
          tJson.deviceInfo.fileSystemList.push(tempObj2);
          tJson.deviceInfo.stackInfo.stackMemberList.push(tempObj3);
        }
      } else {
        tJson = getTemplate(deviceType, true, inputData.deviceInfo.pid, inputData.deviceInfo.name);
      }
      tJson.deviceInfo.addedOn = timestamp;
      tJson.deviceInfo.firstContact = timestamp;
      tJson.deviceInfo.lastContact = timestamp;
      tJson.deviceInfo.lastUpdateOn = timestamp;
      tJson.deviceInfo.hostname = inputData.deviceInfo.hostname;
      tJson.deviceInfo.name = inputData.deviceInfo.name;
      tJson.deviceInfo.pid = inputData.deviceInfo.pid;
      tJson.deviceInfo.serialNumber = inputData.deviceInfo.serialNumber;
      tJson.id = UtilityFunctions.generateId(24);
      tJson.version = 2;
      if (inputData.site) {
        var tSite = {"key": "location", "value": "SJC-4k-212"};
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'groupNameHierarchy': inputData.site });
        tSite.value = siteRecord[0].groupNameHierarchy;
        tJson.workflowParameters.configList[0].configParameters.push(tSite);
        tJson.deviceInfo.siteName = siteRecord[0].groupNameHierarchy; //for easy referencing
        tJson.deviceInfo.siteid = siteRecord[0].id; //this is used only in few templates though
      } else {
        delete tJson.deviceInfo.siteId;
        delete tJson.deviceInfo.siteName;
      }
      if (inputData.deviceInfo.deviceSudiSerialNos && inputData.deviceInfo.deviceSudiSerialNos.length > 0) {
        tJson.deviceInfo.deviceSudiSerialNos = inputData.deviceInfo.deviceSudiSerialNos;
      }
      tJson.deviceInfo.authenticatedSudiSerialNo = inputData.deviceInfo.serialno;
      tJson.deviceInfo.state = "Unclaimed";
      tJson.deviceInfo.onbState = "Not Contacted";
      SimLokiDatabaseActions.insert('onboarding-pnp-device', tJson);
    }

    function getFilteredData (data, filter) {
      /* /api/v1/onboarding/pnp-device?sort=source&sortOrder=des&name=JPG2227023C&hostname=Test&
        serialNumber=JPG2227023C&pid=C9200&source=User&state=Planned&onbState=Initialized&siteName=SJC01
        &smartAccountId=abc&virtualAccountId=asd&offset=0&limit=50 */
      if(filter['name']) {
        data = data.filter(function (v) { return v.deviceInfo.name == filter['name']; });
      }
      if(filter['hostname']) {
        data = data.filter(function (v) { return v.deviceInfo.hostname == filter['hostname']; });
      }
      if(filter['serialNumber']) {
        data = data.filter(function (v) { return v.deviceInfo.serialNumber == filter['serialNumber']; });
      }
      if(filter['pid']) {
        data = data.filter(function (v) { return v.deviceInfo.pid == filter['pid']; });
      }
      if(filter['source']) {
        data = data.filter(function (v) { return v.deviceInfo.source == filter['source']; });
      }
      if(filter['state']) {
        var multiple = filter['state'].split(','); //used in DNAC dashboard call
        data = data.filter(function (v) { return multiple.indexOf(v.deviceInfo.state) > -1; });
      }
      if(filter['onbState']) {
        data = data.filter(function (v) { return v.deviceInfo.onbState == filter['onbState']; });
      }
      if(filter['siteName']) {
        data = data.filter(function (v) {
          if(v.deviceInfo.siteName != undefined) {
            return v.deviceInfo.siteName == filter['siteName']; 
          } 
          return false;
        });
      }
      if(filter['smartAccountId']) {
        data = data.filter(function (v) { return v.deviceInfo.smartAccountId == filter['smartAccountId']; });
      }
      if(filter['virtualAccountId']) {
        data = data.filter(function (v) { return v.deviceInfo.virtualAccountId == filter['virtualAccountId']; });
      }
      return data;    
    }

    function addMoreDeviceDetails (deviceId) {
      var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'id':deviceId});
      if(record && record.length) {
        SimInterfaceData.associateInterfacesToSpecificDevice(record, true);
      }
    }

    function updateValidActions(pnpObj) {
      pnpObj.deviceInfo.validActions.claim = false;
      pnpObj.deviceInfo.validActions.editSUDI = false;
      pnpObj.deviceInfo.validActions.editWfParams = false;
      pnpObj.deviceInfo.validActions.claimMsg = "This device has completed provisioning. This device may be deleted. No other actions are applicable.";
      pnpObj.deviceInfo.validActions.editSUDIMsg = "This device has completed provisioning. This device may be deleted. No other actions are applicable.";
      pnpObj.deviceInfo.validActions.editWfParamsMsg = "This device has completed provisioning. This device may be deleted. No other actions are applicable.";
      pnpObj.deviceInfo.validActions.resetMsg = "This device has completed provisioning. This device may be deleted. No other actions are applicable.";
    }

    function getTemplate(deviceType, isAddedFromUi, pid, name) {
      var tJson = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.PNP_DEVICE_TEMPLATE_STD));
      if (deviceType != undefined || deviceType != "") { 
        tJson.deviceInfo.deviceType = deviceType;
        if (deviceType == 'Sensor') {
          tJson.deviceInfo.siteClaimType = 'Sensor';
          delete tJson.deviceInfo.authenticatedSudiSerialNo;
        } else if (deviceType == 'Stacked') {
          tJson.deviceInfo.stack = true;
          tJson.deviceInfo.siteClaimType = 'StackSwitch';
          tJson.deviceInfo.filesystem = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACKED_DEVICE_FILESYSTEM));
          tJson.deviceInfo.stackInfo = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.STACK_INFO));
          tJson.deviceInfo.cmState = "Authenticated";
          tJson.deviceInfo.featuresSupported.push("STACK_RENUMBERING");
        } else if (deviceType == 'WLC') {
          tJson.deviceInfo.siteClaimType = 'CatalystWLC';
        } else {
          delete tJson.deviceInfo.authenticatedSudiSerialNo;
        }
        tJson.workflowParameters = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.WORKFLOW_TEMPLATE));
        tJson.workflowParameters.configList[0].configParameters[0].value = name;
        tJson.workflowParameters.configList[0].configParameters.splice(1,1);//removing location
      }
      if(!isAddedFromUi) {
        tJson.runSummaryList = [];
      } else {
        tJson.deviceInfo.source = "User";
        tJson.deviceInfo.pnpProfileList[0].createdBy = "User";
      }
      //get family from PID
      var pidDtls = SimMdfData.findDevDetailsByPid(pid);
      tJson.deviceInfo.ipInterfaces = updateInterfaces(pidDtls.family, pid);
      return tJson;
    }

    function updateInterfaces(devFamily, pid) {
      //logic to maintain uniformity in interface names and status is copied from SimInterfaceData.associateInterfacesToDevice
      var interfaces = [], ipInterfaces = [];
      var randInt = UtilityFunctions.getRandomIntForRangeValues(1, 50);
      var macAddress = UtilityFunctions.incrementMacAddrres("00:6b:f1:25:08:00", randInt);
      var ipv6 = UtilityFunctions.incrementMacAddrres("fe80:0000:0000:0000:26b0:f1ff:fe25:8000", randInt);
      if(devFamily == "Switches and Hubs") {
        interfaces = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.SwitchesAndHubs))
        if(pid.startsWith("IE")) {
          interfaces  = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.IotDevices));
        } else if(pid.indexOf('-48') > -1) {
          let data = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.SwitchesAndHubsExtraPorts))
          interfaces = [...interfaces,...data]
      }
      } else if (devFamily == "Routers") {
          interfaces = JSON.parse(JSON.stringify(DeviceInterfaceTemplate.Interfaces.Routers))
      } else if(devFamily == "Wireless Controller") {
          interfaces = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.INTERFACES_WLC));//specifically as only one port needs to be up here and is shown in UI
          return interfaces;
      }
      for(var i=0; i<interfaces.length;) {
        var tJson = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.IP_INTERFACE_TEMPLATE));
        tJson.name = interfaces[i];
        i++;
        tJson.status = (i%7 == 0) ? "down":"up";
        tJson.macAddress = UtilityFunctions.incrementMacAddrres(macAddress, i);
        if(tJson.status == "up") {
          if(tJson.name.indexOf("Loopback")>-1) {
            tJson.ipv4Address = "127.0.0.1";
            tJson.ipv6AddressList = ["0:0:0:0:0:0:0:1"];
          } else {
            var x = UtilityFunctions.getRandomIntForRangeValues(0, 1);
            randInt = UtilityFunctions.getRandomIntForRangeValues(1, 200);
            tJson.ipv4Address = ( x==0 ? null : UtilityFunctions.incrementIpAddrres("10.150.138.10", randInt) );
            x = UtilityFunctions.getRandomIntForRangeValues(0, 1);
            tJson.ipv6AddressList = ( x==0 ? [] : [UtilityFunctions.incrementIpv6Addrres(ipv6, i)] );
          }
        } else {
          tJson.ipv4Address = null;
          tJson.ipv6AddressList = [];
        }
        ipInterfaces.push(tJson);
      }
      return ipInterfaces;
    }

    function updatePoeData(records) {
      var validRecords = [], validTypes= ['Cisco Catalyst 9300 Switch','Cisco Catalyst WS-C3850-12X48U-S Switch',
      'Cisco Catalyst 9404R Switch','Cisco Catalyst 9300L Switch Stack'];
      records.forEach(record => {
        if(validTypes.indexOf(record.type) > -1) {
          validRecords.push(record);
        }
      });
      if(validRecords.length>0) SimPoeAnalyticsData.createPoeDetails(validRecords);
    }

    return {

      init: function () {
        //to enable single demo
        /* var demoToEnable = 'MobilityExpress';//update this field based on need. let it be 'NA' if no devices need to be added
        if(validDemoNames.indexOf(demoToEnable) > -1) {
          createPnPNetworkDevice(demoToEnable);
        } */
        //to enable all demos
        for (var i = 0; i < validDemoNames.length; i++) {
          createPnPNetworkDevice(validDemoNames[i]);
        }
        var obj = { 'key': 'SFO12_DevicesClaimed', 'value': 0 };
        var obj2 = { 'key': 'SFO10_DevicesClaimed', 'value': 0 };
        SimLokiDatabaseActions.insert('dnacConstants', obj);
        SimLokiDatabaseActions.insert('dnacConstants', obj2);
        console.log('No of records inserted for PnP during init:: ' + (SimLokiDatabaseActions.getAll('onboarding-pnp-device')).length);
      },

      processPost: function (urlAction) {
        if (urlAction.service.indexOf('pnp-device') >= 0 && urlAction.service.indexOf('import') >= 0) {
          this.addPnpDevice(urlAction);
          var deviceNames = [];
          for (var i = 0; i < urlAction.restPayload.length; i++) {
            deviceNames.push(urlAction.restPayload[i].deviceInfo.name);
          }
          var data = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'deviceInfo.hostname': { '$in': deviceNames } });
          return { "successList": data, failureList: [] };
        }

        if (urlAction.service.indexOf('learndeviceconfig') >= 0) {
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('learn-device-device', { 'id': urlAction.action.id });
          if (recordObj.length == 0) {
            var data = JSON.parse(JSON.stringify(LearnDeviceConfigTemplate.Default_Device_Config_Template));
            var specificSite = SimLokiDatabaseActions.getFilteredRecordHandler('site',{'name':'SJC04'});
            if(specificSite.length>0) {
              data.SITEHIERARCHY = {}, siteId = specificSite[0].id;
              data.SITEHIERARCHY[siteId] = specificSite[0].groupNameHierarchy;
            }
            return { "response": data };
          }
          return { "response": recordObj[0].value };
        }

        if (urlAction.service.indexOf('pnp-device-claim') >= 0) {
          //Claim from ACME portal
          var site = updatedLocationName("SFO12");
          var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': site});
          var devices = [];
          var pnpDevices = DefaultConfig.PNP_DEVICE_DETAILS[SF12_DEMO];
          if (pnpDevices.network) {
            Object.keys(pnpDevices.network).forEach(function (key) {
              var ntwkObjs = pnpDevices.network[key];
              for (var j = 0; j < ntwkObjs.length; j++) {
                if(ntwkObjs[j].preClaimDeviceName && ntwkObjs[j].preClaimDeviceName.length == ntwkObjs[j].hostnames.length) {
                  devices = devices.concat(ntwkObjs[j].preClaimDeviceName);
                } else {
                  devices = devices.concat(ntwkObjs[j].hostnames);
                }
              }
            });
          }
          var data = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device',
            { '$and': [{ 'deviceInfo.state': "Unclaimed" }, { 'deviceInfo.hostname': { '$in': devices } }] });
          return this.claimDeviceDemo(data, siteRecord, SF12_DEMO);
        }

        if (urlAction.service.indexOf('pnp-device-provision') >= 0) {
          //Provision from ACME portal
          this.provisionDevices(SF12_DEMO);
          return this.addingDeviceToFabric();
        }

        //not used now
        /* if (urlAction.service.indexOf('device-claim') >= 0) {
          var input = urlAction.restPayload;
          for (var i = 0; i < input.length; i++) {
            var data = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': input[0].unclaimedDeviceId });
            var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name': input[0].siteName });
            if (data.length) {
              this.claimDevice(data, siteRecord);
            }
          }
          return [];
        } */

        if (urlAction.service.indexOf('dna') >= 0) {
          return this.addPnpDevice(urlAction);
        }

        if (urlAction.service.indexOf('site-claim') > -1) {
          //Claim from UI 
          var restPayload = urlAction.restPayload;
          var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': restPayload.siteId });
          var pnpRecord = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': restPayload.deviceId });
          var additionalInfo = {};
          if (pnpRecord.length > 0) {
            /* pnpRecord[0].deviceInfo.state = 'Claimed';//actually "Planned"
            pnpRecord[0].deviceInfo.onbState = 'Executed Workflow'; */ //to be taken care in claimDevice method
            pnpRecord[0].deviceInfo.siteId = restPayload.siteId;
            pnpRecord[0].deviceInfo.siteName = siteRecord[0].groupNameHierarchy;

            if (restPayload.imageInfo && restPayload.imageInfo.imageId &&
              restPayload.imageInfo.imageId != "" && (restPayload.imageInfo.skip == false || restPayload.imageInfo.skip == 'false')) {
              var imageDtl = SimSwimData.getImageDtlsFromUuid(restPayload.imageInfo.imageId);
              pnpRecord[0].deviceInfo.swversion = imageDtl.version;//adding for easy reference
            }
            if(restPayload.sensorProfile) {
              pnpRecord[0].dayZeroConfigPreview.sensorProfile = restPayload.sensorProfile;
            }
            if(restPayload.hostname && restPayload.hostname!=pnpRecord[0].deviceInfo.serialNumber) 
              additionalInfo.name = restPayload.hostname;
            if(restPayload.staticIP) additionalInfo.staticIP = restPayload.staticIP;
            if (pnpRecord[0].deviceInfo.siteClaimType == 'StackSwitch') {
              //the configured values should reflect in Inventory and not in PnP. Hence introducing additionalInfo.
              var stackedList = pnpRecord[0].deviceInfo.stackInfo.stackMemberList;
              var overallPid = '', overallSerNumber = '';
              for (var i = 0; i < stackedList.length; i++) {
                overallPid = overallPid + stackedList[i].pid + (i == stackedList.length - 1 ? '' : ',');
                overallSerNumber = overallSerNumber + stackedList[i].serialNumber + (i == stackedList.length - 1 ? '' : ',');
              }
              additionalInfo.pid = overallPid;
              additionalInfo.serialNumber = overallSerNumber;
              if (restPayload.configInfo && restPayload.configInfo.configParameters && restPayload.configInfo.configParameters.length &&
                restPayload.configInfo.configParameters[0].key == 'hostname' && restPayload.configInfo.configParameters[0].value != '') {
                additionalInfo.name = restPayload.configInfo.configParameters[0].value;
              }              
            }

            SimLokiDatabaseActions.update('onboarding-pnp-device', pnpRecord);
            pnpRecord = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': restPayload.deviceId });
            let isClaimRepeated = this.claimDevice(pnpRecord, siteRecord, additionalInfo);
            if(isClaimRepeated) 
              return { "response": "Device Claimed Repeated, hence skipping", "version": "1.0" };//call repeatition, so exit.
            pnpRecord = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': restPayload.deviceId });
            var site1 = updatedLocationName("SFO12"), site2 = updatedLocationName("SFO10");
            var sfo12Site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': site1});
            var sfo10Site = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': site2});
            var sfo12DevClaimed = 0, sfo10DevClaimed = 0;
            if (pnpRecord[0].deviceInfo.siteId == sfo12Site[0].id) {
              var records = SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', { 'key': 'SFO12_DevicesClaimed' });
              sfo12DevClaimed = records[0].value + 1;
              records[0].value = sfo12DevClaimed;
              SimLokiDatabaseActions.update('dnacConstants', records);
              var total = SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', { 'key': 'SFO12_TotalDevices' });
              if (total.length > 0 && sfo12DevClaimed == total[0].value) {
                //if all the devices under SFO12 are claimed, then provision and create topology
                this.provisionDevices(SF12_DEMO);
                this.addingDeviceToFabric();
              }
            }
            if (pnpRecord[0].deviceInfo.siteId == sfo10Site[0].id) {
              var records = SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', { 'key': 'SFO10_DevicesClaimed' });
              sfo10DevClaimed = records[0].value + 1;
              records[0].value = sfo10DevClaimed;
              SimLokiDatabaseActions.update('dnacConstants', records);
              var total = SimLokiDatabaseActions.getFilteredRecordHandler('dnacConstants', { 'key': 'SFO10_TotalDevices' });
              if (total.length > 0 && sfo10DevClaimed == total[0].value) {
                //if all the devices under SFO10 are claimed, then provision and create topology
                this.provisionDevices(MOBEXP_DEMO);
              }
            }
          }
          return { "response": "Device Claimed", "version": "1.0" };
        }

        if (urlAction.service.indexOf('site-config-preview') > -1 || urlAction.service.indexOf('site-config-cli-preview') > -1) {
          var restPayload = urlAction.restPayload;
          var resultObj = SimTaskDataGenerator.createTask('Onboarding Service');
          var stTime = UtilityFunctions.getTimeStamp();
          var result = {
            "response": {
              "taskId": resultObj.taskId, "siteId": restPayload.siteId, "startTime": stTime,
              "expiredTime": stTime, "complete": false, "error": false
            }, "version": "1.0"
          };
          SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });

          //temporarily commenting the code below..
          var pnpRecord = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': restPayload.deviceId});
          pnpRecord[0].dayZeroConfigPreview = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.DAYZERO_TEMPLATE));
          pnpRecord[0].dayZeroConfigPreview.startTime = stTime;
          pnpRecord[0].dayZeroConfigPreview.expiredTime = stTime;
          pnpRecord[0].dayZeroConfigPreview.taskId = resultObj.taskId;
          pnpRecord[0].dayZeroConfigPreview.siteId = restPayload.siteId;
          SimLokiDatabaseActions.update('onboarding-pnp-device', pnpRecord);
          result.statusCode = 202;
          result.response.statusCode = 202;
          return result;
        }
        return {};
      },

      processGet: function (urlAction) {

        if (urlAction.service.indexOf('pnp-device') >= 0 && urlAction.action.count == true) {
          if (urlAction.service.indexOf('dashboard') >= 0) {
            var filter = urlAction.filter['category'];
            if (urlAction.filter['category'] == 'errorState') {
              filter = 'onbState';//As this field doesnt exist in records
            }
            var output = { 'All': 0 };
            var response = [];//[{"categoryValue":"All","count":0}];
            var records = SimLokiDatabaseActions.getAll('onboarding-pnp-device');
            for (var i = 0; i < records.length; i++) {
              var field = records[i].deviceInfo[filter];
              if (output[field]) {
                output[field] = output[field] + 1;
              } else {
                output[field] = 1;
              }
              output['All']++;
            }
            Object.keys(output).forEach(function (key) {
              response.push({ "categoryValue": key, "count": output[key] });
            });
            return response;
          } else {
            var data = [];
            if (urlAction.filter['workflowId']) {
              data = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'deviceInfo.workflowId': urlAction.filter['workflowId'] });
            } else {
              data = SimLokiDatabaseActions.getAll('onboarding-pnp-device');
            }
            data = getFilteredData(data, urlAction.filter);
            return { "response": data.length };
          }
        }

        if (urlAction.service.indexOf('pnp-device') >= 0 && urlAction.service.indexOf('history') >= 0) {
          var data = [];
          if (urlAction.filter['serialNumber']) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device',
              { 'deviceInfo.serialNumber': urlAction.filter['serialNumber'] });
            if (records && records.length) {
              if (records[0].source == 'SmartAccount') {
                data = JSON.parse(JSON.stringify(records[0].runSummaryList));
              } else {
                if (records[0].deviceInfo.state != "Unclaimed") {
                  //we will not get Error state anyway..
                  var arr1 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.PNP_NETWORK_HISTORY_CLAIM));
                  var time = records[0].deviceInfo.lastUpdateOn - (arr1[0].timestamp - arr1[arr1.length - 1].timestamp);
                  arr1 = updateTimestampHistory(arr1, time);
                  var arr2 = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.PNP_NETWORK_HISTORY_BASIC));
                  arr2 = updateTimestampHistory(arr2, records[0].deviceInfo.addedOn);
                  data = arr1.concat(arr2);
                } else {
                  data = JSON.parse(JSON.stringify(PnpDeviceInfoTemplate.PNP_NETWORK_HISTORY_BASIC));
                  data = updateTimestampHistory(data, records[0].deviceInfo.addedOn);
                }
                for (var i = 0; i < data.length; i++) {
                  if (data[i].details.indexOf('Executing User Workflow:') > -1) {
                    if (records[0].deviceInfo.workflowName) {
                      data[i].details = "Executing User Workflow: " + records[0].deviceInfo.workflowName;
                    } else {
                      data[i].details = "Executing User Workflow: " + "";
                    }
                  }
                }
              }
              urlAction.filter['sort'] = 'timestamp';//sometimes the url is formed without the key. hence hardcoding
              if (Array.isArray(data) && urlAction.filter['sort']) {
                data = UtilityFunctions.sortData(urlAction.filter['sort'], urlAction.filter['sortOrder'], data);
              }
              data = { "response": data, "statusCode": 200 };
              return data;
            }
          }
        }

        if (urlAction.service.indexOf('pnp-device') >= 0) {
          if (urlAction.filter['workflowId']) {
            var records = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'deviceInfo.workflowId': urlAction.filter['workflowId'] });
            return records;
          } else if(urlAction.service.indexOf('cli-config-device')>-1) {
            var devId = urlAction.service[urlAction.service.length-1];
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': devId });
            var tJson = PnpDeviceInfoTemplate.DEF_CLI_CONFIG;//not sure how the data has to change based on scenarios.
            tJson = tJson.replace('TestDummy',record[0].deviceInfo.hostname);
            return tJson;
          }
          var data;
          var urlFields = (urlAction.url.split('?'))[0].split('/');
          var id = urlFields.length > 5 ? urlFields[5] : undefined;
          if (id) {
            data = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': id });
            data = data[0];
            return data;
          }
          if (urlAction.filter['state']) {
            data = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'deviceInfo.state': { '$containsAny': urlAction.filter['state'].split(",") } });
            return data;
          } else {
            data = SimLokiDatabaseActions.getAll('onboarding-pnp-device');
            var isUpdated = false;
            for(var i=0; i<data.length; i++) {
              if(data[i].deviceInfo.onbState == "Not Contacted") {
                data[i].deviceInfo.onbState = "Initialized";
                isUpdated = true;
              }
            }
            if(isUpdated) {
              SimLokiDatabaseActions.update('onboarding-pnp-device', data);
            }
          }
          data = getFilteredData(data, urlAction.filter);
          //temporarily commenting the code as claiming is creating problem when sorted..
          /* if (Array.isArray(data) && (urlAction.filter['sort'] || urlAction.filter['sortOrder'])) {
            var sortBy = 'deviceInfo.' + (urlAction.filter['sort']==undefined?urlAction.filter['sortOrder']:urlAction.filter['sort']);
            if (["name", "serialNumber", "pid", "source", "state"].indexOf(urlAction.filter['sort']) > -1) {
              data = UtilityFunctions.sortSecondLevelData(sortBy, urlAction.filter['sortOrder'], data);
            } else {
              data = UtilityFunctions.sortData(urlAction.filter['sort'], urlAction.filter['sortOrder'], data);
            }
          } */
          return data;
        }

        if (urlAction.service.indexOf('pnp-settings') > - 1) {
          var tJson = JSON.parse(JSON.stringify(PnpSettingsTemplate.PNP_SETTINGS_TEMPLATE));
          return tJson;
        }

        if (urlAction.service.indexOf('pnp-workflow') > - 1) {
          if (urlAction.action.count == true) {
            var records = SimLokiDatabaseActions.getAll('pnp-workflow');
            return { "response": records.length };
          } else {
            var urlFields = (urlAction.url.split('?'))[0].split('/');
            var id = urlFields.length > 5 ? urlFields[5] : undefined;
            if (id) {
              var records = SimLokiDatabaseActions.getFilteredRecordHandler('pnp-workflow', { 'id': id });
              if (records && records.length) { return records[0]; }
            }
            var records = SimLokiDatabaseActions.getAll('pnp-workflow');
            return records;
          }
        }

        if(urlAction.service.indexOf('pnp-template') && urlAction.action.id != undefined) {
          //api/v1/template-programmer/template/36cbc01c-0fba-44ed-aaa0-df3cafaaadba - old
          //api/v1/onboarding/pnp-template/36cbc01c-0fba-44ed-aaa0-df3cafaaadba - new
          return SimTemplateProgrammerData.getTemplateDetails(urlAction);
        }

      },

      processPutRequest: function(urlAction) {
        if(urlAction.service.indexOf('pnp-template') > -1 && urlAction.service.indexOf('preview') > -1) {
          return SimTemplateProgrammerData.processPutRequest(urlAction);
        } else {
          return this.updatePnpDevice(urlAction);
        }
      },

      addPnpDevice: function (urlAction) {
        var input = urlAction.restPayload;
        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        for (var i = 0; i < input.length; i++) {
          var name = input[i].deviceInfo.hostname != undefined ? input[i].deviceInfo.hostname : input[i].deviceInfo.name;
          if (name == undefined || name == "") {
            //hostname is not mandatory
            name = input[i].deviceInfo.serialNumber;
          }
          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'deviceInfo.hostname': name });
          if (recordObj.length == 0) {
            var devDtls = SimMdfData.findDevDetailsByPid(input[i].deviceInfo.pid);
            var deviceType = (devDtls && devDtls.family) ? devDtls.family : "Switches and Hubs";
            /* if (name.indexOf('Sensor') > -1) {
              deviceType = "Sensor";
            } else */ if (deviceType == "Wireless Sensor") {
              deviceType = "Sensor";
            }
            if (input[i].deviceInfo.stack == true) {
              deviceType = "Stacked";
            }
            input[i].deviceInfo.hostname = name;
            input[i].deviceInfo.name = name;
            addDevice(deviceType, input[i], devDtls);
          }
        }
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        return { "response": resultObj };
      },

      updatePnpDevice: function (urlAction) {
        var timestamp = UtilityFunctions.getTimeStamp();
        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        var id = urlAction.action.id;
        if (id == undefined || id == "") {
          id = (urlAction.url.split('/'))[5];
        }
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': id });
        if (recordObj && recordObj.length > 0) {
          var name = urlAction.restPayload.deviceInfo.hostname != undefined ? urlAction.restPayload.deviceInfo.hostname :
            urlAction.restPayload.deviceInfo.name;
          recordObj[0].deviceInfo.hostname = name;
          recordObj[0].deviceInfo.name = name;
          recordObj[0].deviceInfo.pid = urlAction.restPayload.deviceInfo.pid;
          recordObj[0].deviceInfo.serialNumber = urlAction.restPayload.deviceInfo.serialNumber;
          recordObj[0].deviceInfo.lastUpdateOn = timestamp;
          SimLokiDatabaseActions.update('onboarding-pnp-device', recordObj);
          recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': id });
          return recordObj[0];
        }
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        return { "response": resultObj };
      },

      deletePnpDevice: function (urlAction) {
        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        var id = urlAction.action.id;
        if (id == undefined || id == "") {
          id = (urlAction.url.split('/'))[5];
        }
        var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': id });
        if (recordObj && recordObj.length > 0) {
          SimLokiDatabaseActions.delete('onboarding-pnp-device', recordObj);
        }
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        return recordObj[0];
      },

      updateWorkflow: function (pnpId, workflowType) {
        var records = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', { 'id': pnpId });
        if (records && records.length) {
          var fields = records[0].deviceInfo.postClaimDeviceName!=undefined?records[0].deviceInfo.postClaimDeviceName.split('-'):
            records[0].deviceInfo.name.split('-');
          var workflowName, workflowId, workflowName;
          if (workflowType == "default") {
            workflowName = fields[1] + "_" + fields[0];
            var workFlowRecord = getWorkflowDetail(workflowName);
            if (workFlowRecord) {
              workflowId = workFlowRecord.id;
              workflowName = workFlowRecord.name;
            } else {
              workflowId = UtilityFunctions.generateId(24);
              workflowName = pnpId + "_" + UtilityFunctions.getRandomIntForRangeValues(4500, 4900);
              workflowType = "other";//since this doesnt match the default workflow
            }
          } else {
            workflowId = UtilityFunctions.generateId(24);
            workflowName = pnpId + "_" + UtilityFunctions.getRandomIntForRangeValues(4500, 4900);
            workflowType = "other";//since this doesnt match the default workflow
          }
          //SimLokiDatabaseActions.update('onboarding-pnp-device', records);
          if (workflowType != "default") {
            createWorkflow(workflowName, workflowId);
          }
          var record = SimLokiDatabaseActions.getFilteredRecordHandler('pnp-workflow', { 'name': workflowName });
          record[0].useState = "InUse";//this is updated when device is mapped to workflow
          SimLokiDatabaseActions.update('pnp-workflow', record);
          return {'workflowId':workflowId, 'workflowName':workflowName};
        }
      },


      claimDevice: function (data, siteRecord, additionalInfo) {
        var deviceRecord = { type: "Cisco Catalyst 9300 Switch", series: "Cisco Catalyst 9300 Series Switches", macAddr: "3c:aa:d4:ef:bc:60", platformid: "c9300-24P", swversion: "17.9.1", family: "Switches and Hubs", serialno: "JPG2227015A", ipAddress: "10.10.20.100", role: "ACCESS" };
        var sensorDevRecord = { type: "Cisco Aironet 1800S Active Sensor", series: "Cisco Aironet 1800S Active Sensor", platformid: "AIR-AP1800S-B-K9", swversion: "1.3.3.0", family: "Wireless Sensor", serialno: "KWC212100QQ", ipAddress: "10.10.30.100", macAddr: "39:fe:5a:78:6b:00", role: "ACCESS" };
        var timestamp = UtilityFunctions.getTimeStamp();
        var isClaimRepeated = false;
        //data will have only one record ideally
        for (var i = 0; i < data.length; i++) {
          if(data[i].deviceInfo.onbState != 'Initialized') { isClaimRepeated = true; continue; }
          deviceRecord.hostname = data[i].deviceInfo.hostname;
          var deviceDetails = [], isPreClaimNameExist = false;
          var devDtls = SimMdfData.findDevDetailsByPid(data[i].deviceInfo.pid);
          var ipaddr = data[i].deviceInfo.ipAddress;
          if (data[i].deviceInfo.hostname.indexOf('Sensor') > -1) {
            deviceRecord = sensorDevRecord;
            data[i].deviceInfo.deviceType="Sensor";
          }          

          // this code is to get the details from configuration
          var getSpecificDetailsDemo = [SF12_DEMO, MOBEXP_DEMO,  SENSOR_DEMO, STACKED_DEMO, DEMO_9800, DEV_REPLACE], isFound = false, isPreClaimNameExist = false, demoName;
          for (var k = 0; k < getSpecificDetailsDemo.length && !isFound; k++) {
            var pnpDevices = DefaultConfig.PNP_DEVICE_DETAILS[getSpecificDetailsDemo[k]];
            Object.keys(pnpDevices.network).forEach(function (key) {
              if(!isFound) {
                var ntwkObjs = pnpDevices.network[key];
                for (var j = 0; j < ntwkObjs.length; j++) {
                  if (ntwkObjs[j].preClaimDeviceName && ntwkObjs[j].preClaimDeviceName.length == ntwkObjs[j].hostnames.length && 
                    ntwkObjs[j].preClaimDeviceName.indexOf(data[i].deviceInfo.hostname) > -1) {
                    deviceRecord = ntwkObjs[j].deviceInfo;
                    isPreClaimNameExist = true;
                    isFound = true;
                    demoName = getSpecificDetailsDemo[k];
                    break;
                  } else {
                    var updatedDevices = [];
                    for(var m=0; m<ntwkObjs[j].hostnames.length; m++) {
                      updatedDevices.push(updatedDeviceName(ntwkObjs[j].hostnames[m]));
                    }                    
                    if (updatedDevices.indexOf(data[i].deviceInfo.hostname) > -1) {
                      deviceRecord = ntwkObjs[j].deviceInfo;
                      isFound = true;
                      demoName = getSpecificDetailsDemo[k];
                      break;
                    }
                  }
                }
              }
            });
          }

          deviceRecord.hostname = data[i].deviceInfo.postClaimDeviceName!=undefined ? data[i].deviceInfo.postClaimDeviceName : data[i].deviceInfo.hostname;

          if (!data[i].deviceInfo.ipAddress || data[i].deviceInfo.ipAddress == "1.2.3.4") {
            //Device was added from UI
            ipaddr = UtilityFunctions.incrementIpAddrres(deviceRecord.ipAddress, UtilityFunctions.getRandomIntForRangeValues(1, 20));
            deviceRecord.macAddr = UtilityFunctions.incrementMacAddrres(deviceRecord.macAddr, UtilityFunctions.getRandomIntForRangeValues(1, 20));
            data[i].deviceInfo.macAddress = deviceRecord.macAddr;
            if (devDtls && devDtls.family) {
              deviceRecord.type = devDtls.type;
              deviceRecord.family = devDtls.family;
              deviceRecord.role = "ACCESS";
              deviceRecord.series = devDtls.series
              deviceRecord.platformid = data[i].deviceInfo.pid;
            } else {/*keep it as 9300*/ }
          }

          var id1 = UtilityFunctions.generate_uuid();
          var id2 = UtilityFunctions.generate_uuid();
          data[i].deviceInfo.oldName = deviceRecord.hostname;//used for referencing in topology creation section..
          if (additionalInfo) {
            if(additionalInfo.name) {
              data[i].deviceInfo.hostname = additionalInfo.name;
              deviceRecord.hostname = additionalInfo.name;
              data[i].deviceInfo.postClaimDeviceName = additionalInfo.name;
            }
            if(additionalInfo.staticIP) {
              ipaddr = additionalInfo.staticIP;
              data[i].deviceInfo.ipAddress = ipaddr;
            }            
          }
          var obj = {
            id: id1, isDefaultFD: false,deviceId:id1,
            deviceInfoId: id2, hostname: deviceRecord.hostname, type: deviceRecord.type,
            platformid: deviceRecord.platformid, macAddress: data[i].deviceInfo.macAddress, managementIpAddress: ipaddr,
            serialnumber: data[i].deviceInfo.serialNumber, swversion: deviceRecord.swversion, family: deviceRecord.family,
            role: deviceRecord.role, healthScore: [{ healthType: "OVERALL", reason: "", score: 10 }], series: deviceRecord.series
          };
          if (data[i].deviceInfo.swversion) {
            obj.swversion = data[i].deviceInfo.swversion;
          }
          if (data[i].deviceInfo.siteClaimType == 'StackSwitch' && additionalInfo) {
            obj.hostname = additionalInfo.name
            obj.platformid = additionalInfo.pid;
            obj.macAddress = data[i].deviceInfo.macAddress;
            obj.serialnumber = additionalInfo.serialNumber;
          }
          deviceDetails.push(obj);

          SimNetworkDeviceData.addDevice(deviceDetails);
          addMoreDeviceDetails(id1);
          updatePoeData(deviceDetails);
          if(data[i].deviceInfo.siteClaimType == 'Sensor') {
            //data[i].deviceInfo.state = "Claimed";//actually "Planned" or "Provisioned"
            data[i].deviceInfo.state = "Provisioned";
            data[i].deviceInfo.onbState = "Provisioned";
          //} else if (data[i].deviceInfo.siteClaimType == 'StackSwitch' || demoName==DEMO_9800 || demoName == undefined) {
          } else if (data[i].deviceInfo.siteClaimType == 'StackSwitch' || [SF12_DEMO, MOBEXP_DEMO].indexOf(demoName)==-1) {
            data[i].deviceInfo.state = "Provisioned";
            data[i].deviceInfo.onbState = "Provisioned";
          } else {
            //Intentionally not keeping state/onbState as Provisioned/Provisioned - to handle usecase of SFO10, SFO12 also from UI. 
            //Only once all the devices in that site are completed, they will be marked Provisioned.
            data[i].deviceInfo.state = "Claimed";//actually "Planned"
            data[i].deviceInfo.onbState = "Executed Workflow";
          }
          updateValidActions(data[i]);

          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': id1 });
          recordObj[0].siteId = siteRecord[0].id;
          SimLokiDatabaseActions.update('network-device', recordObj[0]);
          SimNetworkDeviceData.updateMemberInfoForAllSites();

          var deviceConfigObj = {};
          deviceConfigObj.deviceId = deviceDetails[0].id;
          deviceConfigObj.status = 'Success';
          deviceConfigObj.type = deviceDetails[0].type;
          deviceConfigObj.role = deviceDetails[0].role;
          deviceConfigObj.family = deviceDetails[0].family;
          deviceConfigObj.hostname = deviceDetails[0].hostname;
          //  Creating the device config
          this.createDeviceConfigStatus(deviceConfigObj);
          if (data[i].deviceInfo.deviceType == 'Sensor' || data[i].deviceInfo.deviceType == 'sensor') {
            SimNetworkDeviceData.createSensor(deviceDetails[0].id, siteRecord[0].id);
          }

          var workflowDetail = {};
          if (demoName == SF12_DEMO) {
            workflowDetail = this.updateWorkflow(data[i].id, "default");
          } else {
            workflowDetail = this.updateWorkflow(data[i].id, "other");
          }
          data[i].deviceInfo.workflowId = workflowDetail.workflowId;
          data[i].deviceInfo.workflowName = workflowDetail.workflowName;
          data[i].deviceInfo.projectId = UtilityFunctions.generateId(24);
          data[i].deviceInfo.projectName = 'Proj';
          var wfRecord = SimLokiDatabaseActions.getFilteredRecordHandler('pnp-workflow', {'id': workflowDetail.workflowId});
          if(wfRecord && wfRecord.length) {
            var wfRecord = SimLokiDatabaseActions.getFilteredRecordHandler('pnp-workflow', {'id': workflowDetail.workflowId});
            if(data[i].deviceInfo.deviceType == 'sensor') {
              wfRecord[0].tasks[0].sensorProfile = data[i].dayZeroConfigPreview.sensorProfile;
            }
            SimLokiDatabaseActions.update('pnp-workflow', wfRecord);
          }
          
          var deviceRecord = { type: deviceDetails[0].type, id: deviceDetails[0].id, softwareVersion: deviceDetails[0].swversion, platformId: deviceDetails[0].platformid, name: deviceDetails[0].hostname };
          //if (deviceDetails[0].hostname != "SFO12-ISR4461-01" && deviceDetails[0].hostname != "SFO12-ISR4461-02") {
          if (["Cisco 4461 Series Integrated Services Router", "Cisco 4321 Integrated Services Router"].indexOf(deviceDetails[0].type) == -1) {
            SimSwimData.manualAssignmentDeviceToimage(deviceRecord);
          }
          data[i].deviceInfo.lastContact = timestamp;
          data[i].deviceInfo.lastUpdateOn = timestamp;
        }
        SimLokiDatabaseActions.update('onboarding-pnp-device', data);
        return isClaimRepeated;
      },


      claimDeviceDemo: function (data, siteRecord, demoName) {
        var timestamp = UtilityFunctions.getTimeStamp();
        var deviceRecord = { type: "Cisco Catalyst 9300 Switch", series: "Cisco Catalyst 9300 Series Switches", macAddr: "00:aa:6e:ef:bc:60", platformid: "c9300-24P", swversion: "17.9.1", family: "Switches and Hubs", serialno: "JPG2227015A", ipAddress: "10.41.54.184", role: "ACCESS" };
        var sensorDevRecord = { type: "Cisco Aironet 1800S Active Sensor", series: "Cisco Aironet 1800S Active Sensor", platformid: "AIR-AP1800S-B-K9", swversion: "1.3.3.0", family: "Wireless Sensor", serialno: "KWC212100QQ", ipAddress: "10.32.168.118", macAddr: "70:f3:5a:78:6b:00", role: "ACCESS" };
        for (var i = 0; i < data.length; i++) {
          var deviceDetails = [], isPreClaimNameExist = false;
          data[i].deviceInfo.siteId = siteRecord[0].id;
          data[i].deviceInfo.siteName = siteRecord[0].groupNameHierarchy;
          deviceRecord.hostname = data[i].deviceInfo.hostname;
          var ipaddr = data[i].deviceInfo.ipAddress;
          if (demoName == SF12_DEMO) {//SF12Demo
            var pnpDevices = DefaultConfig.PNP_DEVICE_DETAILS[demoName];
            Object.keys(pnpDevices.network).forEach(function (key) {
              var ntwkObjs = pnpDevices.network[key];
              for (var j = 0; j < ntwkObjs.length; j++) {
                if(ntwkObjs[j].preClaimDeviceName && ntwkObjs[j].preClaimDeviceName.length == ntwkObjs[j].hostnames.length) {
                  isPreClaimNameExist = true;
                }
                if(isPreClaimNameExist) {
                  if (ntwkObjs[j].preClaimDeviceName && ntwkObjs[j].preClaimDeviceName.indexOf(data[i].deviceInfo.hostname) > -1) {
                    deviceRecord = ntwkObjs[j].deviceInfo;
                  }
                } else {
                  if (ntwkObjs[j].hostnames.indexOf(data[i].deviceInfo.hostname) > -1) {
                    deviceRecord = ntwkObjs[j].deviceInfo;
                  }
                }
              }
            });
          }
          if(isPreClaimNameExist && data[i].deviceInfo.postClaimDeviceName) {
            deviceRecord.hostname = data[i].deviceInfo.postClaimDeviceName;
          }
          var id1 = UtilityFunctions.generate_uuid();
          var id2 = UtilityFunctions.generate_uuid();
          deviceDetails.push({
            id: id1, isDefaultFD: false, deviceId: id1,
            deviceInfoId: id2, hostname: deviceRecord.hostname, type: deviceRecord.type,
            platformid: deviceRecord.platformid, macAddress: deviceRecord.macAddr, managementIpAddress: ipaddr,
            serialnumber: data[i].deviceInfo.serialNumber, swversion: deviceRecord.swversion, family: deviceRecord.family,
            role: deviceRecord.role, healthScore: [{ healthType: "OVERALL", reason: "", score: 10 }], series: deviceRecord.series
          });

          SimNetworkDeviceData.addDevice(deviceDetails);
          addMoreDeviceDetails(id1);
          updatePoeData(deviceDetails);
          data[i].deviceInfo.state = "Claimed"; //actually "Planned"
          data[i].deviceInfo.onbState = "Executed Workflow";
          updateValidActions(data[i]);

          var recordObj = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': id1 });
          recordObj[0].siteId = siteRecord[0].id;
          SimLokiDatabaseActions.update('network-device', recordObj[0]);
          SimNetworkDeviceData.updateMemberInfoForAllSites();

          var deviceConfigObj = {};
          deviceConfigObj.deviceId = deviceDetails[0].id;
          deviceConfigObj.status = 'Success';
          deviceConfigObj.type = deviceDetails[0].type;
          deviceConfigObj.role = deviceDetails[0].role;
          deviceConfigObj.family = deviceDetails[0].family;
          deviceConfigObj.hostname = deviceDetails[0].hostname;
          //  Creating the device config
          this.createDeviceConfigStatus(deviceConfigObj);

          var workflowDetail = {};
          if (demoName == SF12_DEMO) {
            workflowDetail = this.updateWorkflow(data[i].id, "default");            
          } else {
            workflowDetail = this.updateWorkflow(data[i].id, "other");
          }
          data[i].deviceInfo.workflowId = workflowDetail.workflowId;
          data[i].deviceInfo.workflowName = workflowDetail.workflowName;
          data[i].deviceInfo.projectId = UtilityFunctions.generateId(24);
          data[i].deviceInfo.projectName = 'Proj';
          var wfRecord = SimLokiDatabaseActions.getFilteredRecordHandler('pnp-workflow', {'id': workflowDetail.workflowId});
          if(wfRecord && wfRecord.length) {
            var wfRecord = SimLokiDatabaseActions.getFilteredRecordHandler('pnp-workflow', {'id': workflowDetail.workflowId});
            if(data[i].deviceInfo.deviceType == 'Sensor') {
              wfRecord[0].tasks[0].sensorProfile = pnpRecord[0].dayZeroConfigPreview.sensorProfile;
            }
          }

          var deviceRecord = { type: deviceDetails[0].type, id: deviceDetails[0].id, softwareVersion: deviceDetails[0].swversion, platformId: deviceDetails[0].platformid, name: deviceDetails[0].hostname };
          if (deviceDetails[0].hostname != updatedDeviceName("SFO12-ISR4461-01") &&
            deviceDetails[0].hostname != updatedDeviceName("SFO12-ISR4461-02")) {
            SimSwimData.manualAssignmentDeviceToimage(deviceRecord);
          }
          data[i].deviceInfo.lastContact = timestamp;
          data[i].deviceInfo.lastUpdateOn = timestamp;
        }
        SimLokiDatabaseActions.update('onboarding-pnp-device', data);
      },

      createDeviceConfigStatus: function (jsonObj) {
        var tJson = JSON.parse(JSON.stringify(DeviceConfigStatusTemplate.Device_Config_Status_Template));
        var id = UtilityFunctions.generate_uuid();
        tJson.id = id;
        tJson.instanceUuid = id;
        tJson.deviceId = jsonObj.deviceId;
        tJson.status = jsonObj.status;
        tJson.role = jsonObj.role;
        tJson.family = jsonObj.family;
        tJson.hostname = jsonObj.hostname;
        tJson.managementIpAddress = jsonObj.managementIpAddress;
        //Need this setting to display the Image version and name
        tJson.type = jsonObj.type;
        tJson.lastUpdateTime = UtilityFunctions.getTimeStamp();
        tJson.lastProvisionedTime = UtilityFunctions.getTimeStamp();
        tJson.workflowId = UtilityFunctions.generate_uuid();
        SimLokiDatabaseActions.insert('DeviceConfigStatus', tJson);
      },

      provisionDevices: function (demoName) {
        var timestamp = UtilityFunctions.getTimeStamp();
        var site = updatedLocationName("SFO12");
        if (demoName == MOBEXP_DEMO) {
          site = updatedLocationName("SFO10");
        }
        var siteRecord = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name': site });
        var data = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device',
          { '$and': [{ 'deviceInfo.state': { '$in': ["Claimed", "Planned"] } }, { 'deviceInfo.siteName': siteRecord[0].groupNameHierarchy }] });

        if (site == updatedLocationName("SFO12")) {
          createTopology(data);
        } else {
          createTopologyMobility(data);
        }

        for (var i = 0; i < data.length; i++) {
          // Creating the device info
          var devName = data[i].deviceInfo.postClaimDeviceName!= undefined ? data[i].deviceInfo.postClaimDeviceName : data[i].deviceInfo.hostname;
          var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', {'hostname': devName});
          SimNetworkDeviceData.addDeviceInfo({ 'networkDeviceId': deviceDetails[0].id, 'name': deviceDetails[0].hostname, "siteId": deviceDetails[0].siteId });
          var record = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus',
            { 'deviceId': deviceDetails[0].id });
          //This is called after provisioning is done for devices
          if (record && record.length) {
            record[0].aggregatedStatus = "Success";
            record[0].status = "Success";
            record[0].lastUpdateTime = timestamp;
            record[0].lastProvisionedTime = timestamp;
            SimLokiDatabaseActions.update('DeviceConfigStatus', record);
          }
          data[i].deviceInfo.state = "Provisioned";
          data[i].deviceInfo.onbState = "Provisioned";
          data[i].deviceInfo.lastContact = timestamp;
          data[i].deviceInfo.lastUpdateOn = timestamp;
        }
        SimLokiDatabaseActions.update('onboarding-pnp-device', data);
      },

      addingDeviceToFabric: function () {
        // adding device to fabric
        var ipPool = "Border_Pool_SFO12_Sub";
        ipPool = ipPool.replace('SFO12',updatedLocationName('SFO12'));
        var deviceList = [{ "siteName": updatedFabricName(),
                          "deviceList": [
                            { "deviceName": updatedDeviceName("SFO12-C9500-01"), "roles": ["MAPSERVER", "BORDERNODE"], "ipPoolName": ipPool },
                            { "deviceName": updatedDeviceName("SFO12-C9500-02"), "roles": ["MAPSERVER", "BORDERNODE"], "ipPoolName": ipPool },
                            { "deviceName": updatedDeviceName("SFO12-C9200-01"), "roles": ["EDGENODE"] },
                            { "deviceName": updatedDeviceName("SFO12-C9200-02"), "roles": ["EDGENODE"] },
                            { "deviceName": updatedDeviceName("SFO12-C9200-03"), "roles": ["EDGENODE"] },
                            { "deviceName": updatedDeviceName("SFO12-C9300-01"), "roles": ["EDGENODE"] },
                            { "deviceName": updatedDeviceName("SFO12-C9800-01"), "roles": ["EDGENODE"] }] }];
        SimConnectivityDomainData.addingDeviceToFabric(deviceList);

         var issueData= {"switch_port_flapping": {
                            devices: [{hostName: 'SFO12-C9200-01', daysOld: 1}],
                            issueDetailFileName: 'switch_port_flapping.json',
                            triggerExists:false,
                            triggerFileName: '',
                            identifier:"managementIpAddress"
                            }};
         SimAssuranceDataGenerator.createDeviceIssues(issueData);
      },

      //This method is used only for markForReplacement. Hence we will not add this PnP device to inventory.
      claimAndProvisionPnP: function(serialNumber, siteRecord) {
        var pnpRecord = SimLokiDatabaseActions.getFilteredRecordHandler('onboarding-pnp-device', {'deviceInfo.serialNumber':serialNumber});
        pnpRecord[0].deviceInfo.siteId = siteRecord[0].id;
        pnpRecord[0].deviceInfo.siteName = siteRecord[0].groupNameHierarchy;
        pnpRecord[0].deviceInfo.state = "Provisioned";
        pnpRecord[0].deviceInfo.onbState = "Provisioned";
        updateValidActions(pnpRecord[0]);
        var workflowDetail = this.updateWorkflow(pnpRecord[0].id, "default");  
        pnpRecord[0].deviceInfo.workflowId = workflowDetail.workflowId;
        pnpRecord[0].deviceInfo.workflowName = workflowDetail.workflowName;
        pnpRecord[0].deviceInfo.projectId = UtilityFunctions.generateId(24);
        pnpRecord[0].deviceInfo.projectName = 'Proj';
        var timestamp = UtilityFunctions.getTimeStamp();
        pnpRecord[0].deviceInfo.lastContact = timestamp;
        pnpRecord[0].deviceInfo.lastUpdateOn = timestamp;
        SimLokiDatabaseActions.update('onboarding-pnp-device', pnpRecord);
      }

    };
  });