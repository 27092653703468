define({
"Template":
{
  "request": {
    "entity": {
      "intfName": "TenGigabitEthernet1/0/23",
      "managementIpAddr": "10.30.255.101",
      "rootCause": "504",
      "type": "event"
    },
    "dimensions": [

    ],
    "timeRange": {
      "start": "2018-09-10T11:41:32.465Z",
      "end": "2018-09-11T11:47:20.624Z"
    },
    "values": [

    ]
  },
  "pagination": {
    "links": [
      {
        "rel": "self",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"TenGigabitEthernet1/0/23\",\"managementIpAddr\":\"10.30.255.101\",\"rootCause\":\"504\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-10T11:41:32.465Z\",\"end\":\"2018-09-11T11:47:20.624Z\"},\"values\":[]}"
      },
      {
        "rel": "first",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=1&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"TenGigabitEthernet1/0/23\",\"managementIpAddr\":\"10.30.255.101\",\"rootCause\":\"504\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-10T11:41:32.465Z\",\"end\":\"2018-09-11T11:47:20.624Z\"},\"values\":[]}"
      },
      {
        "rel": "next",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=2&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"TenGigabitEthernet1/0/23\",\"managementIpAddr\":\"10.30.255.101\",\"rootCause\":\"504\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-10T11:41:32.465Z\",\"end\":\"2018-09-11T11:47:20.624Z\"},\"values\":[]}"
      },
      {
        "rel": "last",
        "url": "http://queryengine.ndp.svc.cluster.local:8006/api/ndp/v1/data/triggers?page=3&pageSize=300&order=DESC",
        "method": "POST",
        "body": "{\"entity\":{\"intfName\":\"TenGigabitEthernet1/0/23\",\"managementIpAddr\":\"10.30.255.101\",\"rootCause\":\"504\",\"type\":\"event\"},\"dimensions\":[],\"timeRange\":{\"start\":\"2018-09-10T11:41:32.465Z\",\"end\":\"2018-09-11T11:47:20.624Z\"},\"values\":[]}"
      }
    ],
    "page": 1,
    "pageSize": 300,
    "order": "DESC",
    "totalResults": 831
  },
  "warnings": null,
  "values": [
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWXIdLToWD9O_0nZQLn7",
      "time": "2018-09-11T11:46:55.217+0000",
      "endTime": "2018-09-11T11:46:55.217+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.30.255.101"
        },
        {
          "key": "remoteIP",
          "value": "10.30.255.2"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "504"
        },
        {
          "key": "syslogMsg",
          "value": "Process 100, Nbr 10.30.255.2 on TenGigabitEthernet1/0/23 from EXSTART to DOWN, Neighbor Down: Too many retransmissions"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.30.255.101"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Down"
        },
        {
          "key": "siteId",
          "value": "NA"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "TenGigabitEthernet1/0/23"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWXIb8hSWD9O_0nZQLcg",
      "time": "2018-09-11T11:41:32.465+0000",
      "endTime": "2018-09-11T11:41:32.465+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.30.255.101"
        },
        {
          "key": "remoteIP",
          "value": "10.30.255.2"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "504"
        },
        {
          "key": "syslogMsg",
          "value": "Process 100, Nbr 10.30.255.2 on TenGigabitEthernet1/0/23 from DOWN to DOWN, Neighbor Down: Ignore timer expired"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.30.255.101"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Down"
        },
        {
          "key": "siteId",
          "value": "NA"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "TenGigabitEthernet1/0/23"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWXIbt28WD9O_0nZQLaj",
      "time": "2018-09-11T11:40:32.464+0000",
      "endTime": "2018-09-11T11:40:32.464+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.30.255.101"
        },
        {
          "key": "remoteIP",
          "value": "10.30.255.2"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "504"
        },
        {
          "key": "syslogMsg",
          "value": "Process 100, Nbr 10.30.255.2 on TenGigabitEthernet1/0/23 from EXSTART to DOWN, Neighbor Down: Too many retransmissions"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.30.255.101"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Down"
        },
        {
          "key": "siteId",
          "value": "NA"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "TenGigabitEthernet1/0/23"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWXIbOzHWD9O_0nZQLZV",
      "time": "2018-09-11T11:38:25.290+0000",
      "endTime": "2018-09-11T11:38:25.290+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.30.255.101"
        },
        {
          "key": "remoteIP",
          "value": "10.30.255.2"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "504"
        },
        {
          "key": "syslogMsg",
          "value": "Process 100, Nbr 10.30.255.2 on TenGigabitEthernet1/0/23 from DOWN to DOWN, Neighbor Down: Ignore timer expired"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.30.255.101"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Down"
        },
        {
          "key": "siteId",
          "value": "NA"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "TenGigabitEthernet1/0/23"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    },
    {
      "name": "network_connectivity_trigger",
      "severity": "HIGH",
      "id": "AWXIbAKRWD9O_0nZQLYw",
      "time": "2018-09-11T11:37:25.290+0000",
      "endTime": "2018-09-11T11:37:25.290+0000",
      "count": 1,
      "attributes": [
        {
          "key": "managementIpAddr",
          "value": "10.30.255.101"
        },
        {
          "key": "remoteIP",
          "value": "10.30.255.2"
        },
        {
          "key": "type",
          "value": "event"
        },
        {
          "key": "scope",
          "value": "global"
        },
        {
          "key": "rootCause",
          "value": "504"
        },
        {
          "key": "syslogMsg",
          "value": "Process 100, Nbr 10.30.255.2 on TenGigabitEthernet1/0/23 from EXSTART to DOWN, Neighbor Down: Too many retransmissions"
        },
        {
          "key": "entityType",
          "value": "network_device"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "entityId",
          "value": "10.30.255.101"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "failureReason",
          "value": "Issue"
        },
        {
          "key": "eventStatus",
          "value": "Down"
        },
        {
          "key": "siteId",
          "value": "NA"
        },
        {
          "key": "category",
          "value": "connectivity"
        },
        {
          "key": "intfName",
          "value": "TenGigabitEthernet1/0/23"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "source": "internal",
      "isActive": false
    }
  ]
}
})