
define({
  "Template":
  {
  "request": {
    "key": "MedicalRecords",
    "type": "Application",
    "label": "MedicalRecords",
    "collection": false,
    "componentContent": [
      {
        "component": "fusion",
        "additional": "46d879bf-a492-4528-8a35-50408534776d"
      }
    ]
  },
  "data": {
    "response": {
      "id": "46d879bf-a492-4528-8a35-50408534776d",
      "instanceId": 4373,
      "authEntityId": 4373,
      "displayName": "4373",
      "authEntityClass": -1909073334,
      "instanceTenantId": "SYS0",
      "deployPending": "NONE",
      "instanceVersion": 0,
      "createTime": 1528265511959,
      "deployed": false,
      "isSeeded": true,
      "isStale": false,
      "lastUpdateTime": 1528265511959,
      "name": "MedicalRecords",
      "namespace": "scalablegroup:application",
      "provisioningState": "DEFINED",
      "qualifier": "application",
      "resourceVersion": 0,
      "targetIdList": [],
      "type": "scalablegroup",
      "cfsChangeInfo": [],
      "customProvisions": [],
      "scalableGroupExternalHandle": "MedicalRecords",
      "scalableGroupType": "APPLICATION",
      "securityGroupTag": 0,
      "state": "ACTIVE",
      "vnAgnostic": true,
      "identitySource": {
        "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
        "instanceId": 10010,
        "authEntityId": 10010,
        "displayName": "10010",
        "authEntityClass": 1738953278,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "state": "INACTIVE",
        "type": "NBAR"
      },
      "indicativeNetworkIdentity": [],
      "networkApplications": [
        {
          "id": "5de605d9-e5ab-4838-9a05-bb936a9ed178",
          "instanceId": 11323,
          "authEntityId": 11323,
          "displayName": "11323",
          "authEntityClass": -217092956,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "appProtocol": "tcp/udp",
          "applicationGroup": "other",
          "applicationType": "DEFAULT",
          "categoryId": "853dcc3d-9711-4e87-a294-dd3e71f60006",
          "createTime": 1528265511959,
          "encrypted": "false",
          "engineId": "13",
          "helpString": "MedicalRecords - Official MedicalRecords website.",
          "ignoreConflict": false,
          "lastUpdateTime": 1528265511959,
          "longDescription": "MedicalRecords - Official MedicalRecords website.",
          "name": "MedicalRecords",
          "nbarId": "1687",
          "p2pTechnology": "false",
          "popularity": 3,
          "rank": 65535,
          "references": "http://MedicalRecords.com",
          "selectorId": "757",
          "subCategory": "3e937526-a8b3-4856-a80c-c1facca48b6c",
          "trafficClass": "TRANSACTIONAL_DATA",
          "tunnel": "false"
        }
      ],
      "networkIdentity": [
        {
          "id": "aac6978e-32cb-4ca7-aec5-b7711002879f",
          "instanceId": 12901,
          "authEntityId": 12901,
          "displayName": "12901",
          "authEntityClass": 124090504,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "53,5353",
          "protocol": "UDP",
          "upperPort": 0
        },
        {
          "id": "564b662b-a3ff-44f4-9d81-0a7b80eecfcc",
          "instanceId": 12902,
          "authEntityId": 12902,
          "displayName": "12902",
          "authEntityClass": 124090504,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "ipv4Subnet": [],
          "ipv6Subnet": [],
          "lowerPort": 0,
          "ports": "53,80,443,5353",
          "protocol": "TCP",
          "upperPort": 0
        }
      ],
      "parentScalableGroup": {
        "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
      },
      "user": [],
      "userGroup": []
    }
  },
  "result": [
    {
      "bookID": {
        "namespace": "fusion",
        "name": "core",
        "version": "1.0.1"
      },
      "response": {
        "id": "46d879bf-a492-4528-8a35-50408534776d",
        "instanceId": 4373,
        "authEntityId": 4373,
        "displayName": "4373",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1528265511959,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1528265511959,
        "name": "MedicalRecords",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "MedicalRecords",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "5de605d9-e5ab-4838-9a05-bb936a9ed178",
            "instanceId": 11323,
            "authEntityId": 11323,
            "displayName": "11323",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "other",
            "applicationType": "DEFAULT",
            "categoryId": "853dcc3d-9711-4e87-a294-dd3e71f60006",
            "createTime": 1528265511959,
            "encrypted": "false",
            "engineId": "13",
            "helpString": "Disney - Official Disney website.",
            "ignoreConflict": false,
            "lastUpdateTime": 1528265511959,
            "longDescription": "Disney - Official Disney website.",
            "name": "MedicalRecords",
            "nbarId": "1687",
            "p2pTechnology": "false",
            "popularity": 3,
            "rank": 65535,
            "references": "http://disney.com",
            "selectorId": "757",
            "subCategory": "3e937526-a8b3-4856-a80c-c1facca48b6c",
            "trafficClass": "TRANSACTIONAL_DATA",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "aac6978e-32cb-4ca7-aec5-b7711002879f",
            "instanceId": 12901,
            "authEntityId": 12901,
            "displayName": "12901",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,5353",
            "protocol": "UDP",
            "upperPort": 0
          },
          {
            "id": "564b662b-a3ff-44f4-9d81-0a7b80eecfcc",
            "instanceId": 12902,
            "authEntityId": 12902,
            "displayName": "12902",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,80,443,5353",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "a431ac87-190b-4e81-bad0-3f8b1e0e1471"
        },
        "user": [],
        "userGroup": []
      }
    }
  ]
}
})