define({
"Template":
{
  "name": "radio_util_trigger",
  "severity": "HIGH",
  "id": "AWaoPEXUbhIriG8x4-ze",
  "time": "2018-10-24T22:35:00.000+0000",
  "endTime": "2018-10-24T22:40:00.000+0000",
  "count": 2,
  "attributes": [
    {
      "key": "channel",
      "value": "36.0"
    },
    {
      "key": "threshold",
      "value": "20.0"
    },
    {
      "key": "slot",
      "value": "1"
    },
    {
      "key": "type",
      "value": "Issue"
    },
    {
      "key": "entityName",
      "value": "macAddr"
    },
    {
      "key": "rootCause",
      "value": "1"
    },
    {
      "key": "apname",
      "value": "APF4DB.E683.922C"
    },
    {
      "key": "entityType",
      "value": "network_device"
    },
    {
      "key": "__entity_type__",
      "value": "NetworkDevice"
    },
    {
      "key": "utilization",
      "value": "23.0"
    },
    {
      "key": "entityId",
      "value": "F4:DB:E6:87:39:E0"
    },
    {
      "key": "priority",
      "value": "P1"
    },
    {
      "key": "macAddress",
      "value": "F4:DB:E6:87:39:E0"
    },
    {
      "key": "category",
      "value": "Utilization"
    },
    {
      "key": "status",
      "value": "active"
    }
  ],
  "source": "internal",
  "isActive": false
}
})