define({
    "GBACPolicies":
    {
        "response": [
          {
            "acaGBPSummary": [
              {
                "id": "05978486-c691-4f30-bff1-8f8ccef430b9",
                "instanceId": 201210,
                "displayName": "201210",
                "instanceVersion": 0,
                "consumerId": "b0b122bc-8fa7-42e6-8f75-021e23a4145a",
                "consumerName": "BYOD",
                "consumerSgIseInstanceUuid": "935d4cc0-8c01-11e6-996c-525400b48521",
                "contractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "contractIseInstanceUuid": "92919850-8c01-11e6-996c-525400b48521",
                "contractName": "Deny IP",
                "isDeleted": false,
                "iseInstanceUuid": "d8b2b331-685c-11e8-bf4d-005056b83f27",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:03.148128000",
                "isenabled": true,
                "isseeded": false,
                "name": "Auditors-BYOD",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "356a1413-b079-4e9d-a1b9-58ebc2144876",
                "producerName": "Auditors",
                "producerSgIseInstanceUuid": "934557f0-8c01-11e6-996c-525400b48521",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400407,
                  "version": 0
                },
                "$loki": 1
              },
              {
                "id": "f117407e-7ab0-403b-988e-bbcb1bff8c7f",
                "instanceId": 201215,
                "displayName": "201215",
                "instanceVersion": 0,
                "consumerId": "2b5c9171-9f7e-42a9-b83a-f206fc416969",
                "consumerName": "PCI_Servers",
                "consumerSgIseInstanceUuid": "93f91790-8c01-11e6-996c-525400b48521",
                "contractId": "e719e6d7-5a7e-40da-bf5d-7c47cf906eaf",
                "contractIseInstanceUuid": "5bea2c20-3dd2-11e8-a04e-005056b83f27",
                "contractName": "PCI_Allowed_Traffic",
                "isDeleted": false,
                "iseInstanceUuid": "5c150cb0-3dd2-11e8-a04e-005056b83f27",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:06.134346000",
                "isenabled": true,
                "isseeded": false,
                "name": "Auditors-PCI_Servers",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "356a1413-b079-4e9d-a1b9-58ebc2144876",
                "producerName": "Auditors",
                "producerSgIseInstanceUuid": "934557f0-8c01-11e6-996c-525400b48521",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400407,
                  "version": 0
                },
                "$loki": 2
              },
              {
                "id": "5b0ae525-35e5-4d46-aef5-5b85bfbda212",
                "instanceId": 201212,
                "displayName": "201212",
                "instanceVersion": 0,
                "consumerId": "356a1413-b079-4e9d-a1b9-58ebc2144876",
                "consumerName": "Auditors",
                "consumerSgIseInstanceUuid": "934557f0-8c01-11e6-996c-525400b48521",
                "contractId": "e719e6d7-5a7e-40da-bf5d-7c47cf906eaf",
                "contractIseInstanceUuid": "5bea2c20-3dd2-11e8-a04e-005056b83f27",
                "contractName": "PCI_Allowed_Traffic",
                "isDeleted": false,
                "iseInstanceUuid": "74e49680-8d62-11e9-8113-7e37aa426a03",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:04.546380000",
                "isenabled": true,
                "isseeded": false,
                "name": "Auditor_SDA-Auditors",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "51698bcd-e633-4460-ac34-d15ffaf7b69c",
                "producerName": "Auditor_SDA",
                "producerSgIseInstanceUuid": "6aecbda0-685c-11e8-bf4d-005056b83f27",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400407,
                  "version": 0
                },
                "$loki": 3
              },
              {
                "id": "06c1fc22-8480-4ed9-84cf-cf6207bb94ce",
                "instanceId": 201214,
                "displayName": "201214",
                "instanceVersion": 0,
                "consumerId": "34375a16-be39-4436-9919-9ca7aee57dea",
                "consumerName": "Contractors",
                "consumerSgIseInstanceUuid": "9370d4c0-8c01-11e6-996c-525400b48521",
                "contractId": "e719e6d7-5a7e-40da-bf5d-7c47cf906eaf",
                "contractIseInstanceUuid": "5bea2c20-3dd2-11e8-a04e-005056b83f27",
                "contractName": "PCI_Allowed_Traffic",
                "isDeleted": false,
                "iseInstanceUuid": "68b36e70-8d5f-11e9-8113-7e37aa426a03",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:05.432664000",
                "isenabled": true,
                "isseeded": false,
                "name": "Auditor_SDA-Contractors",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "51698bcd-e633-4460-ac34-d15ffaf7b69c",
                "producerName": "Auditor_SDA",
                "producerSgIseInstanceUuid": "6aecbda0-685c-11e8-bf4d-005056b83f27",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400407,
                  "version": 0
                },
                "$loki": 4
              },
              {
                "id": "476724bb-93bd-4c56-99fd-77669ef0cf82",
                "instanceId": 201211,
                "displayName": "201211",
                "instanceVersion": 0,
                "consumerId": "579f67ba-0412-4d56-8253-33be1e8b1935",
                "consumerName": "Development_Servers",
                "consumerSgIseInstanceUuid": "9396d350-8c01-11e6-996c-525400b48521",
                "contractId": "e8cb0c74-0633-4aae-8b6f-c9a4769af04e",
                "contractIseInstanceUuid": "92951ac0-8c01-11e6-996c-525400b48521",
                "contractName": "Permit IP",
                "isDeleted": false,
                "iseInstanceUuid": "696293e1-98c8-11e9-8113-7e37aa426a03",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:03.832611000",
                "isenabled": true,
                "isseeded": false,
                "name": "BYOD-Development_Servers",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "b0b122bc-8fa7-42e6-8f75-021e23a4145a",
                "producerName": "BYOD",
                "producerSgIseInstanceUuid": "935d4cc0-8c01-11e6-996c-525400b48521",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400407,
                  "version": 0
                },
                "$loki": 5
              },
              {
                "id": "9534e534-33fa-4454-bfd2-c01a1aee42ae",
                "instanceId": 705710,
                "displayName": "705710",
                "instanceVersion": 0,
                "consumerId": "4c4a3930-9112-4e67-9c67-2a2e09ad1c32",
                "consumerName": "HVAC",
                "consumerSgIseInstanceUuid": "32047010-5e01-11e8-a04e-005056b83f27",
                "contractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "contractIseInstanceUuid": "92919850-8c01-11e6-996c-525400b48521",
                "contractName": "Deny IP",
                "isDeleted": false,
                "iseInstanceUuid": "8ea2b651-c440-11e9-9bec-0e00a2ace41c",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-08-21 18:21:36.258871000",
                "isenabled": true,
                "isseeded": false,
                "name": "0aa3946b-9522-48b7-8a41-13e3df5bf1ba",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 65535,
                "producerId": "7856fcd8-951e-4b89-a870-f858aaf889e0",
                "producerName": "Cameras",
                "producerSgIseInstanceUuid": "b59eb480-7df4-11e9-bbce-feb756a02146",
                "provisioningstate": "1",
                "pushed": false,
                "reverseContractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "reverseContractName": "Deny IP",
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400408,
                  "version": 0
                },
                "$loki": 6
              },
              {
                "id": "24b1cc4d-fd73-4572-b070-8676aca197b6",
                "instanceId": 201209,
                "displayName": "201209",
                "instanceVersion": 0,
                "consumerId": "21263b36-48a8-46e5-bcfe-6fd50f96bdf9",
                "consumerName": "Employees",
                "consumerSgIseInstanceUuid": "93ad6890-8c01-11e6-996c-525400b48521",
                "contractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "contractIseInstanceUuid": "92919850-8c01-11e6-996c-525400b48521",
                "contractName": "Deny IP",
                "isDeleted": false,
                "iseInstanceUuid": "718f1621-6e4d-11e8-bf4d-005056b83f27",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:02.653285000",
                "isenabled": true,
                "isseeded": false,
                "name": "Employees-Employees",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "21263b36-48a8-46e5-bcfe-6fd50f96bdf9",
                "producerName": "Employees",
                "producerSgIseInstanceUuid": "93ad6890-8c01-11e6-996c-525400b48521",
                "provisioningstate": "0",
                "pushed": false,
                "reverseContractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "reverseContractName": "Deny IP",
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400410,
                  "version": 0
                },
                "$loki": 7
              },
              {
                "id": "334746a5-c807-45ae-bc97-497be7d59eb5",
                "instanceId": 201208,
                "displayName": "201208",
                "instanceVersion": 0,
                "consumerId": "4c4a3930-9112-4e67-9c67-2a2e09ad1c32",
                "consumerName": "HVAC",
                "consumerSgIseInstanceUuid": "32047010-5e01-11e8-a04e-005056b83f27",
                "contractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "contractIseInstanceUuid": "92919850-8c01-11e6-996c-525400b48521",
                "contractName": "Deny IP",
                "isDeleted": false,
                "iseInstanceUuid": "5116d620-f8aa-11e8-8171-005056b83f27",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:02.144429000",
                "isenabled": true,
                "isseeded": false,
                "name": "Employees-HVAC",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "21263b36-48a8-46e5-bcfe-6fd50f96bdf9",
                "producerName": "Employees",
                "producerSgIseInstanceUuid": "93ad6890-8c01-11e6-996c-525400b48521",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400410,
                  "version": 0
                },
                "$loki": 8
              },
              {
                "id": "8896bbca-3606-4636-87f2-f14e6dbccbff",
                "instanceId": 201216,
                "displayName": "201216",
                "instanceVersion": 0,
                "consumerId": "ef9dc6a6-105e-4845-a85b-c8ae1567c66d",
                "consumerName": "Unknown",
                "consumerSgIseInstanceUuid": "92adf9f0-8c01-11e6-996c-525400b48521",
                "contractId": "e8cb0c74-0633-4aae-8b6f-c9a4769af04e",
                "contractIseInstanceUuid": "92951ac0-8c01-11e6-996c-525400b48521",
                "contractName": "Permit IP",
                "isDeleted": false,
                "iseInstanceUuid": "8b6d3d81-8256-11e7-ba2b-005056b83f27",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:06.431539000",
                "isenabled": true,
                "isseeded": false,
                "name": "Employees-Unknown",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "21263b36-48a8-46e5-bcfe-6fd50f96bdf9",
                "producerName": "Employees",
                "producerSgIseInstanceUuid": "93ad6890-8c01-11e6-996c-525400b48521",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400410,
                  "version": 0
                },
                "$loki": 9
              },
              {
                "id": "de80cd86-2084-438c-b5ab-26a7b46878a9",
                "instanceId": 705708,
                "displayName": "705708",
                "instanceVersion": 0,
                "consumerId": "7856fcd8-951e-4b89-a870-f858aaf889e0",
                "consumerName": "Cameras",
                "consumerSgIseInstanceUuid": "b59eb480-7df4-11e9-bbce-feb756a02146",
                "contractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "contractIseInstanceUuid": "92919850-8c01-11e6-996c-525400b48521",
                "contractName": "Deny IP",
                "isDeleted": false,
                "iseInstanceUuid": "bf141d91-c438-11e9-9bec-0e00a2ace41c",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-08-21 17:25:41.596467000",
                "isenabled": true,
                "isseeded": false,
                "name": "249d7473-b916-4c4c-9464-01485b23da38",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 65535,
                "producerId": "4c4a3930-9112-4e67-9c67-2a2e09ad1c32",
                "producerName": "HVAC",
                "producerSgIseInstanceUuid": "32047010-5e01-11e8-a04e-005056b83f27",
                "provisioningstate": "1",
                "pushed": false,
                "reverseContractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "reverseContractName": "Deny IP",
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400410,
                  "version": 0
                },
                "$loki": 10
              },
              {
                "id": "98fef522-f106-4acc-b244-1d963a70d58e",
                "instanceId": 201213,
                "displayName": "201213",
                "instanceVersion": 0,
                "consumerId": "2b5c9171-9f7e-42a9-b83a-f206fc416969",
                "consumerName": "PCI_Servers",
                "consumerSgIseInstanceUuid": "93f91790-8c01-11e6-996c-525400b48521",
                "contractId": "0c1ebb60-f2aa-4c8c-af16-f32dc055ef97",
                "contractIseInstanceUuid": "92919850-8c01-11e6-996c-525400b48521",
                "contractName": "Deny IP",
                "isDeleted": false,
                "iseInstanceUuid": "02b14ef0-f1d6-11e8-8171-005056b83f27",
                "iseReserved": false,
                "iseRetryCount": 0,
                "iseTimeStamp": "2019-06-29 16:44:04.949102000",
                "isenabled": true,
                "isseeded": false,
                "name": "Quarantined_Systems-PCI_Servers",
                "policyStatus": "ENABLED",
                "policyscope": "2827e5bf-d291-3d54-aeda-3e21b29a9d5d",
                "priority": 1,
                "producerId": "5294317d-c3a1-415b-ae69-c8e3e2f985b7",
                "producerName": "Quarantined_Systems",
                "producerSgIseInstanceUuid": "944b2f30-8c01-11e6-996c-525400b48521",
                "provisioningstate": "0",
                "pushed": false,
                "syncMessage": "",
                "syncStatus": "SYNCED",
                "tenantintsegment": -932881005,
                "tenantlongsegment": 6707744713420772000,
                "meta": {
                  "revision": 0,
                  "created": 1572334400410,
                  "version": 0
                },
                "$loki": 11
              }
            ],
            "instanceId": 0,
            "displayName": "0",
            "instanceVersion": 0,
            "totalPolicyCount": 11
          }
        ],
        "id": "a74bb512-56bc-a402-4769-90349918bce2",
        "version": "1.0"
      }
})