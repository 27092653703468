define({
  "License_Overview_Template": {
    "deployedDNALicenseData": [
      {
        "title": "Essentials",
        "value": 0
      },
      {
        "title": "Advantage",
        "value": 4
      },
      {
        "title": "Premier",
        "value": 0
      }],
    "deployedLicensePercentage": 0.83,
    "deployedNWLicenseData": [],
    "cssmMessageType": "success",
    "advantageUsage": "success",
    "deployedLicenseType": "success",
    "totalDevices": 7,
    "deployedLicenseDescription": "You have 764 licenses which are not deployed",
    "essentialLicense": "91 Essentials",
    "licensesEntitlementDescription": "You have purchased licenses that are not being consumed by devices",
    "advantageLicense": "673 Advantage",
    "dnaExpiryMessageType": null,
    "CSSMMessage": null,
    "dnaLicenseExpiryDescription": "",
    "essentialUsage": "success",
    "entitledDNALicenseData": [
      {
        "title": "Essentials",
        "value": 91
      },
      {
        "title": "Advantage",
        "value": 677
      },
      {
        "title": "Premier",
        "value": 0
      }],
    "isCSSMEnabled": true,
    "entitledNWLicenseData": [],
    "premierLicense": "0 Premier",
    "premierUsage": "success",
    "virtualLicense": null,
    "virtualUsage": null
  },

  "DashboardSummaryTemplate": {
    "totalDeployedDNALicenses": 4,
    "totalEntitledDNALicenses": 0,
    "switchDeployedDNALicenses": 2,
    "switchEntitledDNALicenses": 0,
    "routerDeployedDNALicenses": 0,    
    "routerEntitledDNALicenses": 0,
    "iseDeployedDNALicenses": 0,
    "iseEntitledDNALicenses": 0, 
    "wlcDeployedDNALicenses": 2,
    "wlcEntitledDNALicenses": 0,
    "isCSSMEnabled": true    
  }

});