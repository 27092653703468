define({
  "SD_ACCESS_Template":
    {
      "response": {
        "serviceGroups": [
          "fusion/sda:2.1.6.60014",
          "maglev-system/sda-ui:2.1.6.60014",
          "fusion/copy-policy-spf-app-bundle:2.1.6.60014"
        ],
        "fqn": "sd-access:2.1.6.60014",
        "_id": "5aabde4acdebcdd9be8b2e1f",
        "requiresPull": false,
        "_pullStatus": {
          "manifestPullCompleted": true,
          "modified": 1521155877.051654,
          "timestamps": {
            "pull_build_start": 1521155507.8867185,
            "pull_elapsed": 500.6995279788971,
            "pull_build_elapsed": 369.1649158000946,
            "pull_artifact_elapsed": 13.570531606674194,
            "pull_artifact_start": 1521155507.8867185,
            "pull_start": 1521155376.352011
          }
        },
        "errorDetails": "",
        "_capabilityStatus": {
          "modified": 1521155524.561356
        },
        "error": "",
        "manifestVersion": "v2",
        "description": "DNA Center automates SD Access, through provisioning of campus fabric overlay\nand user access policy, so organizations can make sure the right policies are\nestablished for any user or device with any application across the network.\nThis package provides the SD Access automation workflows in DNA Center.\n",
        "_provides": {
          "capabilities": [
            {
              "level": 3,
              "name": "ncp:wired-fabric-provisioning",
              "minLevel": 0
            },
            {
              "level": 3,
              "name": "ncp:wireless-fabric-provisioning",
              "minLevel": 0
            }
          ]
        },
        "status": "DEPLOYED",
        "name": "sd-access",
        "_dependsOn": {
          "capabilities": [
            {
              "level": 3,
              "name": "ncp:wired-base-provisioning"
            }
          ]
        },
        "abstract": "Provisions campus fabric overlay and user access policy.",
        "displayName": "Automation - SD Access",
        "kind": "Package",
        "tenantId": "SYS0",
        "version": "2.1.6.60014"
      },
      "version": "0.2.1"
    }

});