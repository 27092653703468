define([    
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions'    
], function (SimLokiDatabaseActions) {

    return {
        init: function() {
        },

        fetchResponseData: function(requestData) {
            let relevantSite = 'SJC01'
            let sitesFound = SimLokiDatabaseActions.getFilteredRecordHandler('site', {'name': relevantSite})
            let siteId = sitesFound.length>0?sitesFound[0].id:''
            let givenSiteId = requestData.action.id

            let complianceResult = siteId===givenSiteId?[{"deviceUuid":"e5df3334-c405-25ae-8ea1-cb153f36f660","hostName":"p1.edge1-sda1.local","deviceRole":["EDGENODE"],"deviceValidationResults":[{"namespace":"sda-validation-base-config","resultTaskUuid":"15f76053-284b-4d4c-b7fc-789733032764","parentTaskUuid":"e2cd4510-4bf9-4127-b9a1-b8f1f9f24147","validationBindingUuid":"f829738a-2927-4a37-ba3d-f3fe49d8bc25","validatorName":"Fabric Role","operationName":"Verify","resultStatus":"SUCCESS","createdTime":"2019-05-02 18:19:49.634","updatedTime":"2019-05-02 18:19:50.128","validatorType":"BULK_JAVA_BEAN"},{"namespace":"sda-validation-host-onboarding","resultTaskUuid":"f8b43dd6-7be3-4112-85a7-477e2415ced5","parentTaskUuid":"fb4ba484-efda-4e81-8da3-6739a28e5664","validationBindingUuid":"66ae2f79-fb97-4aac-a622-af88288bb494","validatorName":"Port Assignment","operationName":"Verify","resultStatus":"SUCCESS","createdTime":"2019-05-02 18:19:50.124","updatedTime":"2019-05-02 18:19:50.321","validatorType":"BULK_JAVA_BEAN"},{"namespace":"sda-validation-host-onboarding","resultTaskUuid":"f8b43dd6-7be3-4112-85a7-477e2415ced5","parentTaskUuid":"fb4ba484-efda-4e81-8da3-6739a28e5664","validationBindingUuid":"a2b92e5e-874f-4d63-9de6-25facd0ee9a0","validatorName":"Segment","operationName":"Verify","resultStatus":"SUCCESS","createdTime":"2019-05-02 18:19:50.024","updatedTime":"2019-05-02 18:19:50.605","validatorType":"BULK_JAVA_BEAN"},{"namespace":"sda-validation-base-config","resultTaskUuid":"15f76053-284b-4d4c-b7fc-789733032764","parentTaskUuid":"e2cd4510-4bf9-4127-b9a1-b8f1f9f24147","validationBindingUuid":"5ebf6498-c1ba-4908-b8c8-1ec15c7aae86","validatorName":"Virtual Network","operationName":"Verify","resultStatus":"FAILED","createdTime":"2019-05-02 18:19:49.727","updatedTime":"2019-05-02 18:19:50.426","validatorType":"BULK_JAVA_BEAN"}]}]:[]
            let complianceCount = siteId===givenSiteId?{"totalCount":77,"successCount":76,"failedCount":1}:{}
            //console.log(sitesFound, siteId, requestData)

            if(requestData.url.indexOf('fabric-compliance-check/results')>-1)
                return {"response":complianceResult,"version":"1.0"}
            if(requestData.url.indexOf('fabric-compliance-check/count')>-1)
                return {"response":complianceCount,"version":"1.0"}
            if(requestData.url.indexOf('fabric-readiness-check/results')>-1)
                return {"response":[],"version":"1.0"}
            if(requestData.url.indexOf('fabric-readiness-check/count')>-1)
                return {"response":{},"version":"1.0"}
        }
     };

});
