define({
    "NetworkDevice_Score_Template": {
        device:{
          id:"",
          label: "NetworkDevice",
          timestamp:"2017-10-14T10:26:58.392+0000",
          isDeleted:false,
          type:"vertex",
          properties:{
           nwDeviceRole:[{"value":""}],
           lifeStart:[{"value":1507976754248}],
           managementIpAddr:[{"value":""}],
           nwDeviceFamily:[{"value":""}],
           macAddress:[{"value":""}],
           nwDeviceName:[{"value":""}],
           platformId:[{"value":""}],
           softwareVersion:[{"value":""}],
           nwDeviceId:[{"value":""}],
           nwDeviceType:[{"value":""}]
          }
        },
        score:-1
    }
});
