define({
  "Template": {
    "version": "1.0",
    "response": {
      "id": "AWW1IwnVtN77WAoieBrI",
      "name": "tcam_util_high",
      "entityType": "Unknown",
      "entity": "10.4.30.60",
      "enabled": true,
      "flattened": true,
      "groupBy": "Unknown",
      "category": "Device",
      "severity": "HIGH",
      "summary": "This Device is Experiencing TCAM Exhaustion currVal%. SGACL TCAM Usage is 3976/512",
      "scope": "GLOBAL",
      "priority": "P1",
      "groupId": "tcam_utilization_high_grouping",
      "groupName": "TCAM Utilization High Issues",
      "rootCause": "sgacl",
      "timestamp": 1536339600000,
      "description": "The Device DeviceNamePlaceHolder is Experiencing TCAM Exhaustion currVal%. SGACL TCAM Usage is 3976/512",
      "suggestions": [
        {
          "message": "Verify if the configuration is compliant on the device.",
          "steps": [
            {
              "entityId": "DeviceIdPlaceHolder",
              "description": "Verify configuration on the device",
              "command": "show running-config",
              "stepType": "command-Runner"
            }
          ]
        },
        {
          "message": "Verify the device logs for TCAM programming error messages",
          "steps": [

          ]
        },
        {
          "message": "Check the release notes of latest software maintenance release and if its a known issue, apply the patch on device",
          "steps": [

          ]
        },
        {
          "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
          "steps": [

          ]
        }
      ],
      "additionalParams": [
        {
          "key": "managementIpAddr",
          "value": "10.4.30.60"
        },
        {
          "key": "__entity_type__",
          "value": "NetworkDevice"
        },
        {
          "key": "tcam_actual",
          "value": "3976/512"
        },
        {
          "key": "entityId",
          "value": "10.4.30.60"
        },
        {
          "key": "threshold",
          "value": "95"
        },
        {
          "key": "message",
          "value": "This Device is Experiencing TCAM Exhaustion"
        },
        {
          "key": "type",
          "value": "Issue"
        },
        {
          "key": "priority",
          "value": "P1"
        },
        {
          "key": "max_tcam_util",
          "value": "97.0"
        },
        {
          "key": "rootCause",
          "value": "sgacl"
        },
        {
          "key": "category",
          "value": "Device"
        },
        {
          "key": "timestring",
          "value": "2018-09-07T17:00:00.000+0000"
        },
        {
          "key": "status",
          "value": "active"
        }
      ],
      "status": {
        "status": "ACTIVE",
        "updatedBy": "Unknown",
        "notes": "Unknown",
        "source": "Unknown",
        "updatedAt": null,
        "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
      },
      "siteQulaifiedName": null
    }
  }
});