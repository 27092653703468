define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/snmp_cpu_high_80',

    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/radio_util_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/fabric_reachability_session_edge',

    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/network_connectivity_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/snmp_memory_high_80',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/network_connectivity_trigger_ospf',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/fabric_reachability_session_cp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/fabric_reachability_session_dhcp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/fabric_reachability_session_dns_pn',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/fabric_reachability_session_dns_vn',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/fabric_reachability_session',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/fabric_wlc_reachability',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/wlc_reboot_crash_trigger',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/network_connectivity_trigger_eigrp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/radio_util_trigger_ap',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/issue1/device_trigger/ap_cpu_threshold_trigger',

], function (snmp_cpu_high_80, radio_util_trigger, fabric_reachability_session_edge, network_connectivity_trigger,
             snmp_memory_high_80, network_connectivity_trigger_ospf, fabric_reachability_session_cp, fabric_reachability_session_dhcp,
              fabric_reachability_session_dns_pn, fabric_reachability_session_dns_vn,fabric_reachability_session,fabric_wlc_reachability,
              wlc_reboot_crash_trigger,network_connectivity_trigger_eigrp,radio_util_trigger_ap,ap_cpu_threshold_trigger) {

    return {
        init: function() {
        },
//fabric_reachability_session_trigger.js  snmp_cpu_high_80_trigger.js

        getDeviceTrigger: function(triggerName) {
           console.log(triggerName);
            return eval(triggerName);
        }
    };

});
