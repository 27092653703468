define({
    "Template":
    {
        "request": {
          "key": "ms-services",
          "type": "Application",
          "label": "ms-services",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "553064dd-badd-4cdf-8f4c-07fa28477a30"
            }
          ]
        },
        "data": {
          "response": {
            "id": "553064dd-badd-4cdf-8f4c-07fa28477a30",
            "instanceId": 4788,
            "authEntityId": 4788,
            "displayName": "4788",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265513431,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265513431,
            "name": "ms-services",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "ms-services",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [],
            "networkApplications": [
              {
                "id": "2a8a61f5-e603-4adb-bbca-91200a759dc5",
                "instanceId": 11736,
                "authEntityId": 11736,
                "displayName": "11736",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "ms-cloud-group",
                "applicationType": "DEFAULT",
                "categoryId": "0d89fbac-6fdc-4e4d-9bd8-68a8f4bbb839",
                "createTime": 1528265513431,
                "encrypted": "true",
                "engineId": "13",
                "helpString": "Microsoft Services",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265513431,
                "longDescription": "ms-services is a set of tools, APIs and web services used by Microsoft applications.",
                "name": "ms-services",
                "nbarId": "1737",
                "p2pTechnology": "false",
                "popularity": 7,
                "rank": 65535,
                "references": "http://www.microsoft.com",
                "selectorId": "777",
                "subCategory": "3e937526-a8b3-4856-a80c-c1facca48b6c",
                "trafficClass": "TRANSACTIONAL_DATA",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "fdabe26c-bd11-4250-bed6-f787bc6d41fd",
                "instanceId": 14004,
                "authEntityId": 14004,
                "displayName": "14004",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,5353",
                "protocol": "TCP",
                "upperPort": 0
              },
              {
                "id": "c82c08d6-3ac6-4325-88ba-88529a9c6246",
                "instanceId": 14005,
                "authEntityId": 14005,
                "displayName": "14005",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "66d40d0f-007d-48f5-994e-dcd8228ad773"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "553064dd-badd-4cdf-8f4c-07fa28477a30",
              "instanceId": 4788,
              "authEntityId": 4788,
              "displayName": "4788",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265513431,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265513431,
              "name": "ms-services",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "ms-services",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [],
              "networkApplications": [
                {
                  "id": "2a8a61f5-e603-4adb-bbca-91200a759dc5",
                  "instanceId": 11736,
                  "authEntityId": 11736,
                  "displayName": "11736",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "ms-cloud-group",
                  "applicationType": "DEFAULT",
                  "categoryId": "0d89fbac-6fdc-4e4d-9bd8-68a8f4bbb839",
                  "createTime": 1528265513431,
                  "encrypted": "true",
                  "engineId": "13",
                  "helpString": "Microsoft Services",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265513431,
                  "longDescription": "ms-services is a set of tools, APIs and web services used by Microsoft applications.",
                  "name": "ms-services",
                  "nbarId": "1737",
                  "p2pTechnology": "false",
                  "popularity": 7,
                  "rank": 65535,
                  "references": "http://www.microsoft.com",
                  "selectorId": "777",
                  "subCategory": "3e937526-a8b3-4856-a80c-c1facca48b6c",
                  "trafficClass": "TRANSACTIONAL_DATA",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "fdabe26c-bd11-4250-bed6-f787bc6d41fd",
                  "instanceId": 14004,
                  "authEntityId": 14004,
                  "displayName": "14004",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                },
                {
                  "id": "c82c08d6-3ac6-4325-88ba-88529a9c6246",
                  "instanceId": 14005,
                  "authEntityId": 14005,
                  "displayName": "14005",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "66d40d0f-007d-48f5-994e-dcd8228ad773"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });