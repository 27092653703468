define({
   "AppExperience_Template": {
      "version": "1.0",
      "response": {
        "business-irrelevant": [
        {
            "applicationName": "netflix",
            "sourceLocation": null,
            "numberOfClients": null,
            "averageRate": 20726.29,
            "averageUtilization": 0.03,
            "trafficClass": "multimedia-streaming",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 10,
            "averageLatency": 1.234,
            "maxLatency": 26,
            "averagePacketLoss": 2.348170995670996,
            "maxPacketLoss": 50,
            "protocol": null,
            "servicePort": "8080",
            "serviceId": null,
            "healthScore": 1,
            "averageApplicationDelay": 88.13685770750989,
            "maxApplicationDelay": 525,
            "domainName": "www.netflix.com",
            "businessRelevance": "business-irrelevant",
            "exporterIpAddress": "10.30.255.1",
            "serverAddress": "192.168.139.169",
            "clientSiteId": null,
            "dscpPreservation": false,
            "usage": 220126046,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 6.0,
            "maxServerNetworkDelay": 10.0

          },
          {
            "applicationName": "disney-web-portal",
            "sourceLocation": null,
            "numberOfClients": null,
            "averageRate": 312.82,
            "averageUtilization": 0,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 9,
            "averageLatency": 224.72289666854886,
            "maxLatency": 9228,
            "averagePacketLoss": 1.2342701863354042,
            "maxPacketLoss": 75,
            "protocol": null,
            "servicePort": "8080",
            "serviceId": null,
            "healthScore": 10,
            "averageApplicationDelay": 71.39316299642387,
            "maxApplicationDelay": 471,
            "domainName": "www.disney.com",
            "businessRelevance": "business-irrelevant",
            "exporterIpAddress": "10.30.255.1",
            "serverAddress": "192.168.139.169",
            "clientSiteId": null,
            "dscpPreservation": false,
            "usage": 3329002,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 12.0,
            "maxServerNetworkDelay": 15.0
          },
          {
            "applicationName": "espn-browsing",
            "applicationGroup": "control-plane",
            "Category": "net-admin",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 22933.716,
            "averageUtilization": null,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 6,
            "averageLatency": 1.23,
            "maxLatency": 3,
            "averagePacketLoss": 4.032,
            "maxPacketLoss": 1,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 6,
            "averageApplicationDelay": 0,
            "maxApplicationDelay": 0,
            "domainName": null,
            "businessRelevance": "business-irrelevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 247686624,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "maxClientNetworkDelay": 15.0,
            "averageServerNetworkDelay": 4.6032608695652173,
            "maxServerNetworkDelay": 12.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "apple-services",
            "applicationGroup": "data",
            "Category":  "consumer-internet",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 13745.08,
            "averageUtilization": null,
            "trafficClass": "bulk-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 7,
            "averageLatency": 2,
            "maxLatency": 13,
            "averagePacketLoss": 2,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 9,
            "averageApplicationDelay": 27,
            "maxApplicationDelay": 56,
            "domainName": null,
            "businessRelevance": "business-irrelevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 148448832,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.0,
            "maxClientNetworkDelay": 12.0,
            "averageServerNetworkDelay": 4.6032608695652173,
            "maxServerNetworkDelay": 10.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "ms-live-accounts",
            "applicationGroup": "data",
            "Category": "consumer-internet",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 12456.712,
            "averageUtilization": null,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 7,
            "averageLatency": 1.345,
            "maxLatency": 143,
            "averagePacketLoss": 6.19765432133345,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 9,
            "averageApplicationDelay": 26,
            "maxApplicationDelay": 34,
            "domainName": null,
            "businessRelevance": "business-irrelevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 134535360,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.0,
            "maxClientNetworkDelay": 16.0,
            "averageServerNetworkDelay": 4.6032608695652173,
            "maxServerNetworkDelay": 13.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "skype",
            "applicationGroup": "control-plane",
            "Category":  "consumer-messaging",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 7570.136,
            "averageUtilization": null,
            "trafficClass": "multimedia-conferencing",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 1.903,
            "maxLatency": 3,
            "averagePacketLoss": 8.90972222222444446,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 10,
            "averageApplicationDelay": 5.988194444444443,
            "maxApplicationDelay": 9,
            "domainName": null,
            "businessRelevance": "business-irrelevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 81753984,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 3.9088164251207729,
            "maxClientNetworkDelay": 12.0,
            "averageServerNetworkDelay": 3.9088164251207729,
            "maxServerNetworkDelay": 9.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "youtube",
            "applicationGroup": "data",
            "Category": "consumer-streaming",
            "sourceLocation": 'North America/USA/California/San Jose/SJC01/Flr-SJC1-1',
            "numberOfClients": null,
            "averageRate": 199.039,
            "averageUtilization": 1.4052918393421472,
            "trafficClass": "multimedia-streaming",
            "dscpMarking": "CS1",
            "expectedDscp": "CS1",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 9,
            "averageLatency": 1.234,
            "maxLatency": 3,
            "averagePacketLoss": 0,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 9,
            "averageApplicationDelay": 0,
            "maxApplicationDelay": 0,
            "domainName": "youtubei.googleapis.com",
            "businessRelevance": "business-irrelevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 2149824,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.6032608695652173,
            "maxClientNetworkDelay": 17.0,
            "averageServerNetworkDelay": 4.6032608695652173,
            "maxServerNetworkDelay": 15.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "bing",
            "applicationGroup": "control-plane",
            "Category": "browsing",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 118518.518,
            "averageUtilization": null,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 6,
            "averageLatency": 1.34,
            "maxLatency": 5,
            "averagePacketLoss": 5,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 10,
            "averageApplicationDelay": 38.4,
            "maxApplicationDelay": 67,
            "domainName": null,
            "businessRelevance": "business-irrelevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 1369140288,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "maxClientNetworkDelay": 12.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 16.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          }

        ],
        "business-relevant": [
          {
            "applicationName": "ssh",
            "sourceLocation": "USA/SM",
            "numberOfClients": null,
            "averageRate": 927467.93,
            "averageUtilization": 0.09,
            "trafficClass": "ops-admin-mgmt",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 5,
            "averageLatency": 1704.614121989122,
            "maxLatency": 74031,
            "averagePacketLoss": 7.361615190365192,
            "maxPacketLoss": 100,
            "protocol": null,
            "servicePort": "22",
            "serviceId": null,
            "healthScore": 9,
            "averageApplicationDelay": 11.924630924630927,
            "maxApplicationDelay": 807,
            "domainName": "ssh",
            "businessRelevance": "business-relevant",
            "exporterIpAddress": "10.30.255.1",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 5135368118,
            "interfaceName": "GigabitEthernet0/0/1",
            "applicationGroup": null,
            "averageClientNetworkDelay": 3.9088164251207729,
            "maxClientNetworkDelay": 10.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 17.0,
            "exporterName": "AP0081.C424.3CE2",
            "exporterUUID": "ccfb74a9-af9b-44ed-ad54-6ab9b9a9c886"
          },
          {
            "applicationName": "outlook-web-service",
            "sourceLocation": null,
            "numberOfClients": null,
            "averageRate": 2808.64,
            "averageUtilization": 4,
            "trafficClass": "bulk-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 1.234,
            "maxLatency": 29,
            "averagePacketLoss": 1.6435787220026352,
            "maxPacketLoss": 10.81,
            "protocol": null,
            "servicePort": "3128",
            "serviceId": null,
            "healthScore": 5,
            "averageApplicationDelay": 15.952827969132311,
            "maxApplicationDelay": 112,
            "domainName": "outlook.live.com",
            "businessRelevance": "business-relevant",
            "exporterIpAddress": "10.30.255.1",
            "serverAddress": "192.168.139.169",
            "clientSiteId": null,
            "dscpPreservation": false,
            "usage": 29837969,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 15.0,
            "maxServerNetworkDelay": 15.0

          },
          {
            "applicationName": "ms-update",
            "sourceLocation": null,
            "numberOfClients": null,
            "averageRate":  5.39,
            "averageUtilization": 5,
            "trafficClass": "bulk-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 7,
            "averageLatency": 1.8125,
            "maxLatency": 1924,
            "averagePacketLoss":  3.348170995670996,
            "maxPacketLoss": 9,
            "protocol": null,
            "servicePort": "123",
            "serviceId": null,
            "healthScore": 5,
            "averageApplicationDelay": 10.541666666666668,
            "maxApplicationDelay": 75,
            "domainName": "ms-update",
            "businessRelevance": "business-relevant",
            "exporterIpAddress": "10.30.255.1",
            "serverAddress": "192.168.139.192",
            "clientSiteId": null,
            "dscpPreservation": false,
            "usage": 58214,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 18.0,
            "maxServerNetworkDelay": 16.0
          },
          {
            "applicationName": "ms-office-365",
            "sourceLocation": "Canada/TO",
            "numberOfClients": null,
            "averageRate": 10,
            "averageUtilization": 0,
            "trafficClass": "ops-admin-mgmt",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 6,
            "averageLatency": 43,
            "maxLatency": 88,
            "averagePacketLoss": 2,
            "maxPacketLoss": 4,
            "protocol": null,
            "servicePort": "123",
            "serviceId": null,
            "healthScore": 2,
            "averageApplicationDelay": 18,
            "maxApplicationDelay": 42,
            "domainName": "ms-office-365",
            "businessRelevance": "business-relevant",
            "exporterIpAddress": "10.31.255.1",
            "serverAddress": "91.189.89.199",
            "clientSiteId": "d79826b6-7d2c-4073-8217-7517052253ec",
            "dscpPreservation": false,
            "usage":107600,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 19.0,
            "maxServerNetworkDelay": 15.0
          },
          {
            "applicationName": "webex-meeting",
            "sourceLocation": "USA",
            "numberOfClients": null,
            "averageRate": 5760.86,
            "averageUtilization": 0,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 7112.08,
            "maxLatency": 82672,
            "averagePacketLoss": 4.5042,
            "maxPacketLoss": 66.67,
            "protocol": null,
            "servicePort": "55140",
            "serviceId": null,
            "healthScore": 2,
            "averageApplicationDelay": 0,
            "maxApplicationDelay": 0,
            "domainName": "webex",
            "businessRelevance": "business-relevant",
            "exporterIpAddress": "10.30.255.1",
            "serverAddress": "10.30.100.10",
            "clientSiteId": "2ee30832-f50d-4ac3-b88a-4f5fd130501f",
            "dscpPreservation": false,
            "usage": 3491399,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 18.0,
            "maxServerNetworkDelay": 17.0
          },
          {
            "applicationName": "ms-lync",
            "sourceLocation": null,
            "numberOfClients": 2,
            "averageRate": 32.38,
            "averageUtilization": 3,
            "trafficClass": "multimedia-conferencing",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 6,
            "averageLatency":3.8194444444444446,
            "maxLatency": 2550,
            "averagePacketLoss":  0.348170995670996,
            "maxPacketLoss": 1,
            "protocol": null,
            "servicePort": "53",
            "serviceId": null,
            "healthScore": 5,
            "averageApplicationDelay": 4.430555555555555,
            "maxApplicationDelay": 98,
            "domainName": "ms-lync",
            "businessRelevance": "business-relevant",
            "exporterIpAddress": "10.30.255.1",
            "serverAddress": "192.168.139.192",
            "clientSiteId": null,
            "dscpPreservation": false,
            "usage":  349709,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 17.0,
            "maxServerNetworkDelay": 13.0
          },
          {
            "applicationName": "ntp",
            "applicationGroup": "control-plane",
            "Category": "net-admin",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 97951.56,
            "averageUtilization": null,
            "trafficClass": "ops-admin-mgmt",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 7,
            "averageLatency": 3,
            "maxLatency": 67,
            "averagePacketLoss": 10,
            "maxPacketLoss": 5,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 4,
            "averageApplicationDelay": 18,
            "maxApplicationDelay": 34,
            "domainName": null,
            "businessRelevance": "business-relevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 1057871904,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "maxClientNetworkDelay": 15.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 15.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null

          },
          {
            "applicationName": "netbios-ns",
            "applicationGroup": "control-plane",
            "Category": "net-admin",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 46848.04,
            "averageUtilization": null,
            "trafficClass": "ops-admin-mgmt",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 4,
            "averageLatency": 2.6754535798987,
            "maxLatency": 4,
            "averagePacketLoss": 2.6754535798987,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 4,
            "averageApplicationDelay": 13,
            "maxApplicationDelay": 22,
            "domainName": null,
            "businessRelevance": "business-relevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 505958016,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "maxClientNetworkDelay": 15.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 15.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "ms-office-web-apps",
            "applicationGroup": "control-plane",
            "Category": "business-and-productivity-tools",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 24884.259,
            "averageUtilization": null,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 3.0987643545766,
            "maxLatency": 5,
            "averagePacketLoss": 8.0987643545766,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 10,
            "averageApplicationDelay": 47.73717948717948,
            "maxApplicationDelay": 56,
            "domainName": null,
            "businessRelevance": "business-relevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 268747104,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 2.9088164251207729,
            "maxClientNetworkDelay": 15.0,
            "averageServerNetworkDelay": 3.9088164251207729,
            "maxServerNetworkDelay": 15.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "dns",
            "applicationGroup": "control-plane",
            "Category": "net-admin",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 1766.076,
            "averageUtilization": null,
            "trafficClass": "ops-admin-mgmt",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 7,
            "averageLatency": 3.6745382987634676,
            "maxLatency": 0,
            "averagePacketLoss": 3.6745382987634676,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 10,
            "averageApplicationDelay": 0,
            "maxApplicationDelay": 0,
            "domainName": null,
            "businessRelevance": "business-relevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 19074432,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "maxClientNetworkDelay": 13.0,
            "averageServerNetworkDelay": 3.9088164251207729,
            "maxServerNetworkDelay": 16.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "telnet",
            "applicationGroup": "control-plane",
            "Category": "net-admin",
            "sourceLocation": 'North America/USA/California/San Jose/SJC01/Flr-SJC1-1',
            "numberOfClients": null,
            "averageRate": 151.458,
            "averageUtilization": 100,
            "trafficClass": "ops-admin-mgmt",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 2.89759865466878,
            "maxLatency": 0,
            "averagePacketLoss": 2.148,
            "maxPacketLoss": 12.5,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 9,
            "averageApplicationDelay": 0,
            "maxApplicationDelay": 0,
            "domainName": "",
            "businessRelevance": "business-relevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "unknownSiteId",
            "dscpPreservation": false,
            "usage": 1638528,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "maxClientNetworkDelay": 12.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 13.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "citrix-static",
            "applicationGroup": "control-plane",
            "Category": "net-admin",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 187962.962,
            "averageUtilization": null,
            "trafficClass": "multimedia-streaming",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 2.30489134934782615,
            "maxLatency": 120,
            "averagePacketLoss": 6.30489134934782615,
            "maxPacketLoss": 4,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 10,
            "averageApplicationDelay": 44.2142857,
            "maxApplicationDelay": 67,
            "domainName": null,
            "businessRelevance": "business-relevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 2183386848,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "maxClientNetworkDelay": 17.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 19.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "snmp",
            "applicationGroup": "control-plane",
            "Category": "net-admin",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 138888.88,
            "averageUtilization": null,
            "trafficClass": "ops-admin-mgmt",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 9,
            "averageLatency": 4.0129456789327115,
            "maxLatency": 4,
            "averagePacketLoss": 4.0129456789327115,
            "maxPacketLoss": 0,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 4,
            "averageApplicationDelay": 72,
            "maxApplicationDelay": 80,
            "domainName": null,
            "businessRelevance": "business-relevant",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 1607039904,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.9088164251207729,
            "maxClientNetworkDelay": 17.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 19.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "microsoft-teams",
            "Category": "video",
            "sourceLocation": "Israel/Site 2",
            "averageRate": 418078.58,
            "averageUtilization": null,
            "trafficClass": "multimedia-conferencing",
            "dscpMarking": "DF",
            "averageJitter": 24876.9880952381,
            "maxJitter": null,
            "averageLatency": 37.263888888888886,
            "maxLatency": null,
            "averagePacketLoss": 2.4867548000252038,
            "maxPacketLoss": null,
            "healthScore": 8,
            "averageApplicationDelay": 211.37594505004145,
            "maxApplicationDelay": null,
            "domainName": null,
            "businessRelevance": "business-relevant",
            "exporterIpAddress": null,
            "serverAddress": "192.168.139.16",
            "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
            "expectedDscp": "null",
            "usage": 10089467271,
            "interfaceName": "GigabitEthernet0/0/1",
            "applicationGroup": "video",
            "averageClientNetworkDelay": 0.6140211640211639,
            "maxClientNetworkDelay": null,
            "averageServerNetworkDelay": 4.6032608695652173,
            "maxServerNetworkDelay": 36.55555555555556,
            "exporterName": "ASR1K_Site2.cisco.com",
            "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3",
            "children": [{
              "applicationName": "ms-teams-video",
              "applicationGroup": "video",
              "Category": "video",
              "sourceLocation": "Israel/Site 2",
              "averageRate": 197552.02,
              "averageUtilization": null,
              "trafficClass": "multimedia-conferencing",
              "dscpMarking": "DF",
              "averageJitter": 903.6462626262626,
              "maxJitter": null,
              "averageLatency": 36.12592592592593,
              "maxLatency": null,
              "averagePacketLoss": 3.118524208949623,
              "maxPacketLoss": null,
              "healthScore": 9,
              "averageApplicationDelay": 1.041904761904762,
              "maxApplicationDelay": null,
              "domainName": null,
              "businessRelevance": "business-relevant",
              "exporterIpAddress": null,
              "serverAddress": "192.168.139.16",
              "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
              "expectedDscp": "AF",
              "usage": 595686947,
              "interfaceName": "GigabitEthernet0/0/1",
              "averageClientNetworkDelay": 0.9037037037037037,
              "maxClientNetworkDelay": null,
              "averageServerNetworkDelay": 35.27777777777778,
              "maxServerNetworkDelay": null,
              "exporterName": "ASR1K_Site2.cisco.com",
              "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"
            },
            {
            "applicationName": "ms-teams-app-sharing",
              "applicationGroup": "video",
              "Category": "video",
              "sourceLocation": "Israel/Site 2",
              "averageRate": 197552.02,
              "averageUtilization": null,
              "trafficClass": "multimedia-conferencing",
              "dscpMarking": "DF",
              "averageJitter": 24876.9880952381,
              "maxJitter": null,
              "averageLatency": 37.263888888888886,
              "maxLatency": null,
              "averagePacketLoss": 3.1767523842791685,
              "maxPacketLoss": null,
              "healthScore": 9,
              "averageApplicationDelay": 3.895604395604395,
              "maxApplicationDelay": null,
              "domainName": null,
              "businessRelevance":"business-relevant",
              "exporterIpAddress": null,
              "serverAddress": "192.168.139.16",
              "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
              "expectedDscp": "AF",
              "usage": 595686947,
              "interfaceName": "GigabitEthernet0/0/1",
              "averageClientNetworkDelay": 0.7083333333333334,
              "maxClientNetworkDelay": null,
              "averageServerNetworkDelay": 36.55555555555556,
              "maxServerNetworkDelay": null,
              "exporterName": "ASR1K_Site2.cisco.com",
              "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"
            },
            {
              "applicationName": "ms-teams-audio",
                "applicationGroup": "video",
                "Category": "video",
                "sourceLocation": "Israel/Site 2",
                "averageRate": 138495.47,
                "averageUtilization": null,
                "trafficClass": "multimedia-conferencing",
                "dscpMarking": "DF",
                "averageJitter": 714.2783605283605,
                "maxJitter": null,
                "averageLatency": 34.57698412698412,
                "maxLatency": null,
                "averagePacketLoss": 1.4903522704422738,
                "maxPacketLoss": null,
                "healthScore": 8,
                "averageApplicationDelay": 3.1085470085470086,
                "maxApplicationDelay": null,
                "domainName": null,
                "businessRelevance":"business-relevant",
                "exporterIpAddress": null,
                "serverAddress": "192.168.139.16",
                "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
                "expectedDscp": "AF",
                "usage": 595686947,
                "interfaceName": "GigabitEthernet0/0/1",
                "averageClientNetworkDelay": 0.6140211640211639,
                "maxClientNetworkDelay": null,
                "averageServerNetworkDelay": 34,
                "maxServerNetworkDelay": null,
                "exporterName": "ASR1K_Site2.cisco.com",
                "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"
              },
              {
                "applicationName": "ms-teams-media",
                  "applicationGroup": "video",
                  "Category": "video",
                  "sourceLocation": "Israel/Site 2",
                  "averageRate": 4336.95,
                  "averageUtilization": null,
                  "trafficClass": "multimedia-conferencing",
                  "dscpMarking": "DF",
                  "averageJitter": null,
                  "maxJitter": null,
                  "averageLatency": null,
                  "maxLatency": null,
                  "averagePacketLoss": null,
                  "maxPacketLoss": null,
                  "healthScore": null,
                  "averageApplicationDelay": null,
                  "maxApplicationDelay": null,
                  "domainName": null,
                  "businessRelevance":"business-relevant",
                  "exporterIpAddress": null,
                  "serverAddress": "192.168.139.16",
                  "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
                  "expectedDscp": "AF",
                  "usage": 42089787,
                  "interfaceName": "GigabitEthernet0/0/1",
                  "averageClientNetworkDelay": null,
                  "maxClientNetworkDelay": null,
                  "averageServerNetworkDelay": null,
                  "maxServerNetworkDelay": null,
                  "exporterName": "ASR1K_Site2.cisco.com",
                  "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"
                },
                {
                  "applicationName": "ms-teams",
                    "applicationGroup": "video",
                    "Category": "video",
                    "sourceLocation": "Israel/Site 2",
                    "averageRate": 26836.35,
                    "averageUtilization": null,
                    "trafficClass": "multimedia-conferencing",
                    "dscpMarking": "DF",
                    "averageJitter": 714.2783605283605,
                    "maxJitter": null,
                    "averageLatency": 13.7090574634228,
                    "maxLatency": null,
                    "averagePacketLoss": 2.161390336429749,
                    "maxPacketLoss": null,
                    "healthScore": 8,
                    "averageApplicationDelay": 211.37594505004145,
                    "maxApplicationDelay": null,
                    "domainName": null,
                    "businessRelevance":"business-relevant",
                    "exporterIpAddress": null,
                    "serverAddress": "192.168.139.16",
                    "exporterSiteId": "c45cd74b-3dd3-40ed-af12-c0d145188cb2",
                    "expectedDscp": "AF",
                    "usage": 3752396416,
                    "interfaceName": "GigabitEthernet0/0/1",
                    "averageClientNetworkDelay": 0.2613226966765956,
                    "maxClientNetworkDelay": null,
                    "averageServerNetworkDelay": 13.48774553031089,
                    "maxServerNetworkDelay": null,
                    "exporterName": "ASR1K_Site2.cisco.com",
                    "exporterUUID": "563f30b3-b70e-4a78-8740-f678572bcbf3"
                  },
          ]
          }
        ],
        "default": [
          {
            "applicationName": "ms-services",
            "sourceLocation": null,
            "numberOfClients": 1,
            "averageRate": 5398.91,
            "averageUtilization": null,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 6,
            "averageLatency": 1.6138888888888887,
            "maxLatency": 30,
            "averagePacketLoss": 0.05498872655122655,
            "maxPacketLoss": 0.96,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 5,
            "averageApplicationDelay": 62.00107323232324,
            "maxApplicationDelay": null,
            "domainName": null,
            "businessRelevance": "default",
            "exporterIpAddress": null,
            "serverAddress": null,
            "clientSiteId": null,
            "dscpPreservation": false,
            "usage": 1707291,
            "interfaceName": null,
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 19.0,
            "maxServerNetworkDelay": 10.0
          },
          {
            "applicationName": "http",
            "sourceLocation": null,
            "numberOfClients": 3,
            "averageRate": 3808.25,
            "averageUtilization": null,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 208.58696338383837,
            "maxLatency": 10.310,
            "averagePacketLoss": 7.3024981586700335,
            "maxPacketLoss": 100,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 9,
            "averageApplicationDelay": 61.46296296296296,
            "maxApplicationDelay": null,
            "domainName": null,
            "businessRelevance": "default",
            "exporterIpAddress": null,
            "serverAddress": null,
            "clientSiteId": null,
            "dscpPreservation": false,
            "usage": 143606,
            "interfaceName": null,
            "averageClientNetworkDelay": 2.9088164251207729,
            "averageServerNetworkDelay": 4.15040726817042605,
            "maxClientNetworkDelay": 17.0,
            "maxServerNetworkDelay": 18.0
          },
          {
            "applicationName": "ssl",
            "applicationGroup": "data",
            "Category": "browsing",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 10090.25,
            "averageUtilization": null,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 8,
            "averageLatency": 2.500510799963925,
            "maxLatency": 20,
            "averagePacketLoss": 2.500510799963925,
            "maxPacketLoss": 8,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 8,
            "averageApplicationDelay": 56.214285714285715,
            "maxApplicationDelay": 60,
            "domainName": null,
            "businessRelevance": "default",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 1170843936,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay":4.9088164251207729,
            "maxClientNetworkDelay": 17.0,
            "averageServerNetworkDelay": 4.9088164251207729,
            "maxServerNetworkDelay": 17.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "binary-over-http",
            "applicationGroup": "data",
            "Category": "net-admin",
            "sourceLocation": "North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
            "numberOfClients": null,
            "averageRate": 599074.07,
            "averageUtilization": null,
            "trafficClass": "bulk-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 9,
            "averageLatency": 10.236249999999998,
            "maxLatency": 5,
            "averagePacketLoss": 2.236249999999998,
            "maxPacketLoss": 20,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 10,
            "averageApplicationDelay": 119.20833333333333,
            "maxApplicationDelay": 140,
            "domainName": "xtrapath2.izatcloud.net",
            "businessRelevance": "default",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 6944255328,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 4.6032608695652173,
            "maxClientNetworkDelay": 15.0,
            "averageServerNetworkDelay": 4.6032608695652173,
            "maxServerNetworkDelay": 15.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          },
          {
            "applicationName": "google-services",
            "applicationGroup": "control-plane",
            "Category":  "other",
            "sourceLocation": 'North America/USA/California/San Jose/SJC01/Flr-SJC1-1',
            "numberOfClients": null,
            "averageRate": 11518.80,
            "averageUtilization": 81.32243270990269,
            "trafficClass": "transactional-data",
            "dscpMarking": "DF",
            "expectedDscp": "AF",
            "averageJitter": 1.9088164251207729,
            "maxJitter": 5,
            "averageLatency": 0.05296296296296297,
            "maxLatency": 0,
            "averagePacketLoss": 10.05296296296296297,
            "maxPacketLoss": 11.11111111111111,
            "protocol": null,
            "servicePort": null,
            "serviceId": null,
            "healthScore": 9,
            "averageApplicationDelay": 0,
            "maxApplicationDelay": 0,
            "domainName": "www.google.com",
            "businessRelevance": "default",
            "serverAddress": "192.168.139.169",
            "clientSiteId": "a6b9fe3b-0e26-4ce6-81e7-a1f94760485a",
            "dscpPreservation": false,
            "usage": 124407552,
            "interfaceName": "GigabitEthernet0/0/1",
            "averageClientNetworkDelay": 3.6032608695652173,
            "maxClientNetworkDelay": 12.0,
            "averageServerNetworkDelay": 3.6032608695652173,
            "maxServerNetworkDelay": 15.0,
            "exporterIpAddress": null,
            "exporterUUID": null,
            "exporterSiteId": null,
            "exporterName": null
          }
        ]
      }

  }
});
