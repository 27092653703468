define({
"Template":
{
  "response": [
    {
      "field": "family",
      "values": [
        {
          "id": "nfvis",
          "text": "NFVIS",
          "count": 8
        },
        {
          "id": "routers",
          "text": "Routers",
          "count": 5
        },
        {
          "id": "security and vpn",
          "text": "Security and VPN",
          "count": 1
        },
        {
          "id": "switches and hubs",
          "text": "Switches and Hubs",
          "count": 1
        },
        {
          "id": "unified ap",
          "text": "Unified AP",
          "count": 6
        },
        {
          "id": "wireless controller",
          "text": "Wireless Controller",
          "count": 6
        },
        {
          "id": "wireless sensor",
          "text": "Wireless Sensor",
          "count": 6
        },
        {
          "id": "eca switch",
          "text": "ECA Switch",
          "count": 6
        }

      ]
    },
    {
      "field": "reachabilityStatus",
      "values": [
        {
          "id": "reachable",
          "text": "Reachable",
          "count": 14
        },
        {
          "id": "unreachable",
          "text": "UnReachable",
          "count": 3
        }
      ]
    },
    {
      "field": "deviceSupportLevel",
      "values": [
        {
          "id": "supported",
          "text": "Supported",
          "count": 15
        },
        {
          "id": "unsupported",
          "text": "Unsupported",
          "count": 2
        }
      ]
    },
    {
      "field": "collectionStatus",
      "values": [
        {
          "id": "could not synchronize",
          "text": "Could Not Synchronize",
          "count": 2
        },
        {
          "id": "in progress",
          "text": "In Progress",
          "count": 0
        },
        {
          "id": "managed",
          "text": "Managed",
          "count": 13
        },
        {
          "id": "partial collection failure",
          "text": "Partial Collection Failure",
          "count": 2
        }
      ]
    },
    {
      "field": "role",
      "values": [
        {
          "id": "access",
          "text": "ACCESS",
          "count": 1
        },
        {
          "id": "border router",
          "text": "BORDER ROUTER",
          "count": 3
        },
        {
          "id": "core",
          "text": "CORE",
          "count": 11
        },
        {
          "id": "unknown",
          "text": "UNKNOWN",
          "count": 2
        }
      ]
    },
    {
      "field": "deviceImage.upgradeStatus",
      "values": [
        {
          "id": "tag golden",
          "text": "Tag Golden",
          "count": 15
        },
        {
          "id": "unknown",
          "text": "UNKNOWN",
          "count": 3
        }
      ]
    },
    {
      "field": "deviceImage.deviceImageUpgradeStatus",
      "values": [
        {
          "id": "unknown",
          "text": "UNKNOWN",
          "count": 18
        }
      ]
    },
    {
      "field": "deviceImage.readyForUpgrade",
      "values": [
        {
          "id": "unknown",
          "text": "UNKNOWN",
          "count": 18
        }
      ]
    },
    {
      "field": "provisionStatus.aggregatedStatus",
      "values": [
        {
          "id": "not provisioned",
          "text": "Not Provisioned",
          "count": 6
        },
        {
          "id": "success",
          "text": "Success",
          "count": 12
        }
      ]
    },
    {
      "field": "provisionStatus.credentialStatus",
      "values": [
        {
          "id": "failed",
          "text": "Failed",
          "count": 1
        },
        {
          "id": "not provisioned",
          "text": "Not Provisioned",
          "count": 17
        }
      ]
    }
  ]
}
})