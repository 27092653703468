define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/database/SimLokiDatabaseActions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimNetworkDeviceData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/SimTaskDataGenerator',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimInventoryData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/DeviceCredentialTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/HATemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimOnboardingData',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/UtilityFunctions',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ChassisViewTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ChassisFileTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/inventory/ChassisSeriesFileTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/configuration/DefaultConfig',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/services/audit-log/SimauditLogModels',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/vlans/deviceVlansTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/vlans/deviceModulesTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/vlans/deviceStackInPortView',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/virtualnetwork/ciscoDnaProvision_VirtualNetwork_Template',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/assurance/enhancedRRM/EnhancedRRMTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/utilities/SimulationUtility'
], function (SimLokiDatabaseActions, SimNetworkDeviceData, SimTaskDataGenerator,
            SimInventoryData, DeviceCredentialTemplate, HATemplate, SimOnboardingData, UtilityFunctions,
            ChassisViewTemplate,ChassisFileTemplate,ChassisSeriesFileTemplate, DefaultConfig, SimauditLogModels,deviceVlansTemplate,
            deviceModulesTemplate, deviceStackInPortView, ciscoDnaProvision_VirtualNetwork_Template, EnhancedRRMTemplate, SimulationUtility) {

    var filterSupported = ['id', 'hostname', 'family', 'serialNumber', 'collectionStatus', 'managementIpAddress', 'platformId',
        'softwareVersion', 'series'];

    return {
        init: function () {
        },

        doProcessRestRequest: function (urlAction) {
            var data;
            switch (urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }

            return data;
        }
    };

    function processPostRequest(urlAction) {

        if (urlAction.service.indexOf('cisco.dna.provision') > -1) {
            return {
                "_id": "5eaa7ec43ce03b1706f5cedc",
                "key": "GRAPHTABLE-PERSISTENCE-devices",
                "plugin": "cisco.dna.provision",
                "user": "admin",
                "value": urlAction.restPayload.value,
                "__v": 0
            }
        } else if (urlAction.service.indexOf('cisco.dna.provision.uci') > -1) {
            var validCurrentActions = ['trigger_import_image','import_image','ditribute_image','activate_image'];
            if(typeof(urlAction.restPayload.value)=='string') 
                urlAction.restPayload.value =JSON.parse(urlAction.restPayload.value);
            var idx = validCurrentActions.indexOf(urlAction.restPayload.value.currentAction);
            idx = idx==validCurrentActions.length-1 ? idx : idx+1;
            var respValue = {"currentAction":validCurrentActions[idx], "imageName":"C9800-40-universalk9_wlc.17.02.01.SPA.bin",
                "distributeOrActivateTaskId":UtilityFunctions.generate_uuid(), "timerRef": 3553};
            if(urlAction.restPayload.value.imageName) respValue.imageName = urlAction.restPayload.value.imageName;
            return {
                "_id": "5c4c5e534b4ec5dab2d7c814",
                "key": "embedded_wireless_f2532b8d-a66b-41dd-a082-92493867b3c6",
                "plugin": "cisco.dna.provision.uci",
                "user": "admin",
                "value": JSON.stringify(respValue),
                "__v": 0
            }
        } else if (urlAction.service.indexOf('cisco.dna.provision.uci.virtual_networks') > -1) { // call related to VirtualNw
            urlAction.restPayload.value =JSON.parse(urlAction.restPayload.value);
            return {
                "_id": "6130dbf5d5f993815bf0f571",
                "key": urlAction.restPayload.key,
                "plugin": "cisco.dna.provision.uci.virtual_networks",
                "user": "admin",
                "__v": 0,
                "value": JSON.stringify(urlAction.restPayload.value)
              }
        }else if (urlAction.service.indexOf('dna') >= 0 || urlAction.service.indexOf('onboarding') >= 0) {
            return SimOnboardingData.processPost(urlAction);
        } else if (urlAction.service.indexOf('file') > -1) {
            //export inventory operation from Provision
            //var fileId=UtilityFunctions.generate_uuid();
            var fileId="f82e6811-9c00-40b4-8c12-c800be94cee3";
            var resultObj = SimTaskDataGenerator.createTask('Inventory service',{ id :fileId});
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
           /* SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, {
                isError: true, failureReason: "Error in exporting devices",
                errorCode: "Inventory collection error", progress: "Inventory service exporting devices has failed."
            });*/
            return resultObj;
        }
        var taskObj={};var validateOnly=false;
        var progress="";
        if(urlAction.filter.indexOf("onlyValidateCredential")>-1){
             validateOnly=urlAction.filter.onlyValidateCredential==="true"?true:false;
             if(validateOnly){
             progress = "{\"id\":\""+UtilityFunctions.generate_uuid()+"\",\"progress\":\"Validation completed successfully.\"}";
             }
         }
        var resultObj = SimTaskDataGenerator.createTask('Inventory service',{'progress':progress});
        var taskObj = SimInventoryData.createNetworkDevice(urlAction.restPayload,validateOnly);
        if (taskObj == undefined) {
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        }
        else if (taskObj.errorCode == "ResourceConflict") {
            //ResourceConflict error appears if device already discovered but user tries to discover the same device again
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });//Creating a parent task
            var childTaskObj = SimTaskDataGenerator.createChildTask('Inventory service', resultObj.taskId);
            SimTaskDataGenerator.updateTaskCompletion(childTaskObj.taskId, taskObj);
        } else if (taskObj.errorCode == "Bad Request") {
            //Bad Request appears, when the ip address given is wrong.
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, taskObj);
        }
        return resultObj;
    }

    function processGetRequest(urlAction) {

        if (urlAction.service.indexOf('cisco.dna.provision.uci') >= 0) {
            if(urlAction.url.indexOf('embedded_wireless') > -1) {
                return {
                    "_id": "5c4c5e534b4ec5dab2d7c814",
                    "key": "embedded_wireless_f2532b8d-a66b-41dd-a082-92493867b3c6",
                    "plugin": "cisco.dna.provision.uci",
                    "user": "admin",
                    "value": '{"currentAction":"activate_image", "imageName":"'+DefaultConfig.EMBEDDED_WIRELESS_IMAGENAME+
                        '", "source":"CCO", "distributeOrActivateTaskId":"'+UtilityFunctions.generate_uuid()+'"}',
                    "__v": 0
                }
            }
            else if(urlAction.url.indexOf('fabricSiteDefaultView') > -1) {
                return {
                    "key": "fabricSiteDefaultView",
                    "plugin": "cisco.dna.provision.uci",
                    "user": "admin",
                    "value": "topology"
                  }
            }
            else if(urlAction.url.indexOf('fabricLanDefaultView') > -1) {
                return{
                    "key": "fabricLanDefaultView",
                    "plugin": "cisco.dna.provision.uci",
                    "user": "admin",
                    "value": "topology"
                  }
            }
            else return [];
        } else if (urlAction.service.indexOf('cisco.dna.provision') > -1) {
            //to show certain additional columns by default on first load, in provision page, so that filtering happens.
            return {"key":"GRAPHTABLE-PERSISTENCE-devices","plugin":"cisco.dna.provision","user":"admin","value":"{\"inventoryView\":{\"family\":{\"visible\":true},\"reachabilityStatus\":{\"visible\":false},\"location\":{\"visible\":true},\"macAddress\":{\"visible\":true},\"role\":{\"visible\":true},\"health\":{\"visible\":false},\"provOsVersion\":{\"visible\":true},\"upTime\":{\"visible\":true},\"collectionStatus\":{\"visible\":true},\"lastUpdated\":{\"visible\":true},\"collectionInterval\":{\"visible\":true},\"serialNumber\":{\"visible\":false},\"series\":{\"visible\":true},\"platformId\":{\"visible\":true},\"supportType\":{\"visible\":false},\"provOsImage\":{\"visible\":true},\"provOutdatedImage\":{\"visible\":true},\"provDeviceUpgradeStatus\":{\"visible\":true},\"provPreCheckStatus\":{\"visible\":true},\"softwareVersion\":{\"visible\":true},\"provisionStatus\":{\"visible\":false},\"credentialStatus\":{\"visible\":true},\"lastProvisionTime\":{\"visible\":false},\"associatedWlcIp\":{\"visible\":false},\"apGroupName\":{\"visible\":false},\"flexGroupName\":{\"visible\":false},\"applicationTelemetry\":{\"visible\":false}}}"}
        }else if (urlAction.service.indexOf('cisco.dna.provision.uci.virtual_networks') > -1) {
            // var data = JSON.parse(JSON.stringify(ciscoDnaProvision_VirtualNetwork_Template.Provision_VirtualNetwork_Template));
            var data = {"key": "5f35ea6015de5300c6a0162d_take_a_tour_transit_peer_network",
            "plugin": "cisco.dna.provision.uci.virtual_networks",
            "user": "admin",
            "value": "true"}
            return data;
        }

        if (urlAction.service.indexOf('wireless-info') >= 0) {
            var deviceId = urlAction.filter.id.includes(',') ? urlAction.filter.id.split(',')[0] : urlAction.filter.id;
            var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': deviceId });
            var wirelessFeatureEnabled = false;
            var subPackageInfo = false;
            var netconfEnabled = false;
            if (deviceDetails.length > 0 && deviceDetails[0].type == "Cisco Catalyst 9300 Switch" || deviceDetails[0].type == "Cisco Catalyst 9500 switch") {
                var tObj = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceInfo', { 'networkDeviceId': deviceDetails[0].id });

                if (tObj.length &&
                    (tObj[0].roles.indexOf("BORDERNODE") >= 0 && tObj[0].roles.indexOf("MAPSERVER") >= 0) || tObj[0].name =="SJC04-C9300Stack-1") {
                    wirelessFeatureEnabled = true;
                    subPackageInfo = true;
                    netconfEnabled = true;
                }

                //It is a excpetion for demo, unless page refresh 'Wirelss Configure' is not shown
                //To avoid page refresh for this device this feature is enabled statically..
                if (tObj[0].name == "SFO11-C9300-Edge-01") {
                    netconfEnabled = true;
                }

            }
            return [{ "deviceId": deviceId, "adminEnabledPorts": null, "apGroupName": null, "ethMacAddress": null, "flexGroupName": null, "lagModeEnabled": false, "netconfEnabled": netconfEnabled, "wirelessLicenseInfo": null, "wirelessFeatureEnabled": wirelessFeatureEnabled, "wirelessPackageInstalled": false, "instanceTenantId": "5b2d1c30fe5f7f0088660642", "instanceUuid": null, "id": null, "subPackageInfo": subPackageInfo, "rlanList":null }];
        }

        if (urlAction.service.indexOf('collection-schedule') >= 0) {
            if (urlAction.service.indexOf('global') >= 0) {
                var data = 1500;
                return data;
            }
            else {
                var deviceId = urlAction.action.id;
                var deviceDetails = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': deviceId });
                var time = deviceDetails[0].collectionInterval;
                if (time === "Global Default") {
                    var data = 1500;
                    return data;
                }
                var t = time.split(':');
                var h = t[0];
                var m = t[1];
                var s = t[2];
                var data = parseInt(h) * 360 + parseInt(m) * 60 + parseInt(s);
                return data;
            }
        }
        else if (urlAction.url.indexOf('network-device/access-point') > -1) {

            return SimNetworkDeviceData.getAPforAirsense(urlAction);
        }
        else if (urlAction.url.indexOf('network-device/wireless/accesspoint/detail') > -1) {
            return SimNetworkDeviceData.getAPforAirsense13(urlAction);
        }
        else if (urlAction.service.indexOf('onboarding') >= 0) {
            return SimOnboardingData.processGet(urlAction);
        }
        else if (urlAction.service.indexOf('redundancy-info') >= 0) {
            //api/v1/network-device/6ec38edc-772a-45cf-b853-d073639e6121/redundancy-info
            var data = JSON.parse(JSON.stringify(HATemplate.Redundancy_Error_Template));
            return data;
        }
        if (urlAction.service.indexOf('event') >=0 && urlAction.service.indexOf('event-series') >=0 ){
            return SimauditLogModels.doProcessRestRequest(urlAction)
        }
        if (urlAction.service.indexOf('dna') >= 0 && urlAction.service.indexOf('ROGUE') >= 0) {
            var data = JSON.parse(JSON.stringify(HATemplate.ROGUE_Telemetry_Subscription));
            return data;
        }else if (urlAction.service.indexOf('dna') >= 0 && urlAction.service.indexOf('AWIPS') >= 0) {
            var data = JSON.parse(JSON.stringify(HATemplate.AWIPS_Telemetry_Subscription));
            return data;
        } else if (urlAction.service.indexOf('telemetry') > -1 && urlAction.service.indexOf('getMigrationStatus') > -1) {
            //api/v1/dna/telemetry/provision/subscription/getMigrationStatus
            return {};
        }
        // Calls to Handle Enhanced RRM
        if (urlAction.service.indexOf('dna') >= 0 && urlAction.service.indexOf('sunray') >= 0) {
            if (urlAction.filter.siteId && urlAction.service.indexOf('airfprofilesettingbysite') >= 0) {
                var siteData = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': urlAction.filter['siteId'] });
                if (siteData[0].name == 'SJC01') {
                    var rrmData = SimLokiDatabaseActions.getFilteredRecordHandler('wlan', { 'key': 'rfprofile.info' })[0].value
                    data = {};
                    for (var i = 0; i < rrmData.length; i++) {
                        if (rrmData[i].rfProfileName == 'RRM_RF_PROFILE') {
                            data['aiRfProfileSetting'] = rrmData[i]
                        }
                    }
                    data['aiRfProfileName'] = "RRM_RF_PROFILE"
                    var assignedSites = []
                    var assigneddata = { 'groupId': 29059, 'groupNameHierarchy': siteData[0].groupNameHierarchy, 'instanceUUID': siteData[0].id, siteName: siteData[0].name }
                    assignedSites.push(assigneddata)
                    data['assignedSites'] = assignedSites;
                    return data
                } else {
                    return { "aiRfProfileName": null, "assignedSites": null, "aiRfProfileSetting": null }
                }
            } else if (urlAction.service.indexOf('provisioningenvvar') > -1) {
                return { "SUNRAY_6GHZ_SUPPORT": "true" }
            }
            else if (urlAction.service.indexOf('airfprofilesitesinfo') >= 0 && urlAction.filter['profileName'] == 'RRM_RF_PROFILE') {
                var data = JSON.parse(JSON.stringify(EnhancedRRMTemplate.aiRfProfileName_template));
                var siteObj = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'name': 'SJC01' })
                data[0].associatedBuildings[0].instanceUUID = siteObj[0].id
                return { "response": data }
            } else if (urlAction.service.indexOf('nextsunraycomputetime') >= 0) {
                var minutesToAdd = 15;
                var currentDate = new Date();
                var futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
                var compTime = new Date(futureDate).getTime()
                return { "nextComputeTimeMillis": compTime }
            } else if (urlAction.service.indexOf('deviceprovisionstatus') >= 0) {
                var childrenSites = [], locations = [], tempList = [];
                var siteData1 = SimLokiDatabaseActions.getFilteredRecordHandler('site', { 'id': urlAction.filter['siteId'] });
                SimulationUtility.getChildrenSites(siteData1[0].id, childrenSites);
                for (var i = 0; i < childrenSites.length; i++) {
                    locations.push(childrenSites[i].id);
                }
                locations = [...new Set(locations)]

                for (var j = 0; j < locations.length; j++) {
                    var records = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { '$and': [{ 'family': 'Unified AP' }, { 'siteId': { '$in': locations[j] } }] });
                    for (var k = 0; k < records.length; k++) {
                        var tempDict = {};
                        tempDict['aiRrmConfigProvStatus'] = "Provisioned";
                        tempDict['deviceFamily'] = "Unified AP";
                        tempDict['deviceName'] = records[k].name;
                        tempDict['instanceUuid'] = records[k].instanceUuid;
                        tempList.push(tempDict)
                    }
                }
                var returnData = { "isAllApsProvisioned": true, "siteMembers": tempList, "totalApCount": tempList.length, "totalControllerCount": 1, "isAllWlcProvisioned": true }
                return returnData
            } else if (urlAction.service.indexOf('sitesinfo') >= 0) {
                var response = []
                var sites = SimLokiDatabaseActions.getAll('site')
                sites.map(siteObj => {
                    var responseTemplate = {
                        "aiRfProfileName": "",
                        "groupNameHierarchy": "Global/North_America/United_States/California/San_Jose/Bldg-22",
                        "isAiRrmEnabled": false,
                        "siteId": "1a1f1318-1408-4158-bd67-a0584d27f615"
                    }
                    responseTemplate.groupNameHierarchy = siteObj.groupNameHierarchy;
                    responseTemplate.siteId = siteObj.id;
                    response.push(JSON.parse(JSON.stringify(responseTemplate)))
                })
                return { "response": response };
            }
        }
        if (urlAction.action.count && urlAction.filter['reachabilityStatus'] != undefined) {
            if(urlAction.filter['reachabilityStatus'] ==="Unreachable"){
            var devList = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'reachabilityStatus': 'UnReachable' });;
            return devList.length;
            }
            else{
                var devList = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'reachabilityStatus': 'Reachable' });;
                return devList.length;  
            }
        }

        if(urlAction.service.indexOf('event') > -1 && ( urlAction.service.indexOf('artifact') > -1 || 
            urlAction.service.indexOf('subscription') > -1 || (urlAction.service.indexOf('webhook') > -1) ||
            (urlAction.service.indexOf('subscription-details') > -1) || (urlAction.service.indexOf('email-config') > -1) ) ) {
            //Settings page - Event Catalog
            if(urlAction.action.count) {
                return {"response":0};
            } else if(urlAction.service.indexOf('webhook') > -1) {
                return {"errorMessage":null,"apiStatus":"SUCCESS","statusMessage":[]};
            } else {
                return [];
            }
        }

        if(urlAction.service.indexOf('vlan') > -1){
            let dev = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': urlAction.action.id })[0];
            let devInterfaces = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', { 'deviceId': urlAction.action.id })
            let data, assocInterfaces;
            if(dev.family == "Switches and Hubs") {
                data = JSON.parse(JSON.stringify(deviceVlansTemplate.SwitchVlan_Template))
            } else if (dev.family == "Routers") {
                data = JSON.parse(JSON.stringify(deviceVlansTemplate.RoutersVlans_Template))
            } else if(dev.family == "Wireless Controller") {
                data = JSON.parse(JSON.stringify(deviceVlansTemplate.WlcVlans_template))
            }
            assocInterfaces = devInterfaces.filter(e => e.interfaceType == 'Physical').map(e => e.portName);
            data = data.map(e => {
                if(e.vlanName == "default"){
                    e.associatedPhysicalPorts = assocInterfaces
                }
                return e;
            })

            return data;
        }

        if(urlAction.service.indexOf('module') > -1){
            let dev = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': urlAction.filter['deviceId'] })[0];
            let data;
            if(dev.family == "Switches and Hubs") {
                data = JSON.parse(JSON.stringify(deviceModulesTemplate.switchTemplate))
            } else if (dev.family == "Routers") {
                data = JSON.parse(JSON.stringify(deviceModulesTemplate.routerTemplate))
            } else if(dev.family == "Wireless Controller") {
                data = JSON.parse(JSON.stringify(deviceModulesTemplate.wlcTemplate))
            }
            return data;
        }

        if(urlAction.service.indexOf('interface') > -1 && urlAction.service.indexOf('poe-detail') > -1) {
            let devInterfaces = SimLokiDatabaseActions.getFilteredRecordHandler('device-interface', { 'deviceId': urlAction.action.id })
            let deviceConfig = SimLokiDatabaseActions.getFilteredRecordHandler('DeviceConfigStatus', { 'deviceId': urlAction.action.id })[0]
            if(['Cisco Catalyst 9300 Switch','Cisco Catalyst WS-C3850-12X48U-S Switch',
                             'Cisco Catalyst 9404R Switch','Cisco Catalyst 9300L Switch Stack'].indexOf(deviceConfig.type) > -1){
                let gigBitInt = devInterfaces.filter(e => e.portName.startsWith('Giga'));
                let data = []
                gigBitInt.forEach(iface => {
                    let returnObj = {"interfaceName": "", "operStatus": "","adminStatus": "AUTO","allocatedPower": "0.0","maxPortPower": "60.0","portPowerDrawn": "0"};
                    returnObj.interfaceName = iface.portName
                    returnObj.operStatus = (iface.adminStatus == 'UP' && iface.status == 'up') ? 'ON': 'OFF';
                    data.push(returnObj)
                })
                return data;
            }else return []

        }

        if(urlAction.service.includes('equipment')) {
            return SimNetworkDeviceData.getEquipmentDetails(urlAction.filter.type, urlAction.action.id);
        }

        if (urlAction.action.count) {
            if(urlAction.service.indexOf('family') > -1) {
                //api/v1/network-device/count/family
                return SimNetworkDeviceData.getFamilyCount();
            } else if(urlAction.service.indexOf('insight')>-1) {
                return SimNetworkDeviceData.getInventoryInsight(urlAction);
            }
            return getDeviceCount();
        }
        else if (urlAction.service.indexOf('autocomplete') > -1 && urlAction.filter.length > 0) {
            ///api/v1/network-device/autocomplete?hostname=.*a.*&limit=20&offset=1
            return getAutoCompleteData(urlAction);
        }
        else if (urlAction.service.indexOf('config') > -1) {
            return SimNetworkDeviceData.getDeviceConfig(urlAction.action.id);
        }
        else if (urlAction.service.indexOf('functional-capability') >= 0) {
            return SimNetworkDeviceData.getFunctionalCapability(urlAction)
        }
        else if (urlAction.service.length == 1 && urlAction.action.id) {
            //api/v1/network-device/<dev-id>
            // commented by Karan for 1.3.1
            // var idToFetch = urlAction.service[1];
            // var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': idToFetch });
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'id': urlAction.action.id });
            return record.length>0 ? record[0] : {};
        }
        else if (isFilterPassed(urlAction)) {
            //api/v1/network-device?managementIpAddress=
            //api/v1/network-device?family=Wireless%20Controller
            return fetchFilteredData(urlAction);
        }

        /*else if(urlAction.filter.indexOf('offset')>0 &&urlAction.filter['offset']==101) {
            return [];
        } */

        else if(urlAction.url.indexOf('deviceview') > -1){
            if(urlAction.url.indexOf('getDevice3') > -1 && urlAction.url.indexOf('deviceUUId') > -1){
                var tjson=ChassisViewTemplate.Chassis_View_Template;
                var deviceId=urlAction.filter.deviceUUId;
                var deviceInterfaces=SimLokiDatabaseActions.getFilteredRecordHandler('device-interface' ,{'deviceId' : deviceId});
                var device=SimLokiDatabaseActions.getFilteredRecordHandler('network-device' ,{'id' : deviceId});
                tjson.deviceSeries=device[0].series;
                tjson.chassisModel=device[0].platformId;
                Object.keys(tjson.cardPorts).forEach(function (key) {
                    tjson.cardPorts[key].model=device[0].platformId;
                    tjson.cardPorts[key].ports=[];
                    for(var j=0;j<deviceInterfaces.length;j++){
                        var obj= {};
                        obj["portNumber"]=j+1;
                        obj["portName"]=deviceInterfaces[j].portName;
                        obj["portType"]=deviceInterfaces[j].portType;
                        obj["adminState"]=1;
                        obj["operationalState"]=1;
                        obj["owningEntityId"]="154156_154156";
                        obj["description"]="";
                        obj["portMode"]=deviceInterfaces[j].portMode;
                        obj["managementPort"]=false;
                        obj["optionType"]=2;
                        obj["dot1xEnabled"]=false;
                        tjson.cardPorts[key].ports.push(obj);
                    }
                });
                return tjson;
            }
            else if(urlAction.url.indexOf('getFile')>-1){
                if(urlAction.filter.fileName==="9300-Parts.json" || urlAction.filter.fileName==="9400-Parts.json"){
                    var tjson=ChassisSeriesFiletemplate.Chassis_Series_File_Template;
                    return tjson;
                }
                else{
                    if (urlAction.url.indexOf('deviceUUId') > -1) {
                    var tjson=ChassisFileTemplate.Chassis_File_Template;
                    var deviceId=urlAction.filter.deviceUUId;
                    var deviceInterfaces=SimLokiDatabaseActions.getFilteredRecordHandler('device-interface' ,{'deviceId' : deviceId});
                    var device=SimLokiDatabaseActions.getFilteredRecordHandler('network-device' ,{'id' : deviceId});
                    tjson.baseModel=device[0].platformId+".glb";
                    if(device[0].series.indexOf('9300')>-1){
                        tjson.partsMap="9300-Parts.json"
                        tjson.stacks[0].chassisModel="/Cat9300-"+deviceInterfaces.length+"T-3D-model.gltf";
                    }
                    else if(device[0].series.indexOf('9400')>-1){
                        tjson.partsMap="9300-Parts.json"
                        tjson.stacks[0].chassisModel="/Cat9400-"+deviceInterfaces.length+"T-3D-model.gltf"
                   }
                   tjson.stacks[0].deviceSeries=device[0].series;
                   tjson.stacks[0].model=device[0].platformId;
                   tjson.stacks[0].portCount=deviceInterfaces.length;
                   var ports=tjson.stacks[0].ports;
                   tjson.stacks[0].ports=ports.slice(0,deviceInterfaces.length);
                   return tjson;
                }
                }     
            }
            else if(urlAction.url.indexOf('getAllVlan')>-1 && urlAction.url.indexOf('deviceUUId') > -1){
                return { "response": [] };;
            }
        }
        //moving this code down as the url in if-clause is common for this model file
        /* else if (urlAction.url.indexOf('/api/v1/network-device/') > -1) {
            if (urlAction.url.indexOf('ip-address') > -1) {
                //topology wlc-info related data
                var ipToFetch = urlAction.service[2];
                var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': ipToFetch });
                return record[0];
            }
            else if (urlAction.url.indexOf('\?') > -1){
                return [];
            }
            else {
                return { reachabilityStatus: 'Reachable' }
            }
        } */
        if(urlAction.service.indexOf('vmanage-service')>-1) {
            if(urlAction.service.indexOf('vmanage')>-1 && urlAction.service.indexOf('deviceuuid')>-1) 
                return SimLokiDatabaseActions.getAll('vmanage-devices')
            else 
                return [];
        }
        if (urlAction.service.indexOf('ip-address') > -1) {
            //topology wlc-info related data
            var ipToFetch = urlAction.service[urlAction.service.indexOf('ip-address')+1];
            var record = SimLokiDatabaseActions.getFilteredRecordHandler('network-device', { 'managementIpAddress': ipToFetch });
            if(record[0] == undefined) {
               record = SimLokiDatabaseActions.getFilteredRecordHandler('host', { 'hostIpV4': ipToFetch });
            }
            return record[0] ? record[0] : [];
        }
        //isnt this clause already handled by fetchFilteredData?
        /* else if(urlAction.filter[0] == "hostname" || urlAction.filter[0]== "managementIpAddress"){
            let filterKey = urlAction.filter[0]
            let filterval = (filterKey == "hostname")? urlAction.filter.hostname :
                            (filterKey == "managementIpAddress") ? urlAction.filter.managementIpAddress : undefined;
            let devices = SimLokiDatabaseActions.getAll('network-device');
            let result = devices.filter(obj => {
                obj[filterKey] == filterval;
                return obj;
            })
            return result;
        } */
        else if(urlAction.service.indexOf("stack")>-1) {
            let nwDev = SimLokiDatabaseActions.getFilteredRecordHandler("network-device", {"id":urlAction.action.id});
            let platformId = nwDev[0].platformId.split(',');
            let serialNumber = nwDev[0].serialNumber.split(',');
            let tJson = JSON.parse(JSON.stringify(deviceStackInPortView.Template)), len = platformId.length
            tJson.deviceId = urlAction.action.id;
            for(let i=0; i<len; i++){
                let stackSwitchInfoObj = JSON.parse(JSON.stringify(tJson.stackSwitchInfo[0]))
                stackSwitchInfoObj.macAddress = (i== 0) ? nwDev[0].macAddress: UtilityFunctions.incrementMacAddrres(nwDev[0].macAddress,i)
                //stackSwitchInfoObj.softwareImage =
                stackSwitchInfoObj.stackMemberNumber = i
                stackSwitchInfoObj.serialNumber = serialNumber[i]
                stackSwitchInfoObj.platformId = platformId[i]
                for(let j=0; j<2;j++){
                    let stackPortInfoObj = JSON.parse(JSON.stringify(deviceStackInPortView.stackPortInfo_Template))
                    stackPortInfoObj.name = `StackSub-St1-${j+1}`
                    stackPortInfoObj.switchPort = (j==0)? `${i+1}/1`: `${i+1}/2`
                    stackPortInfoObj.neighborPort = (len == 1)? `NONE/NONE`: (j==0)?`${i==0?len:i}/2`: `${(i+2)>len?1:(i+2)}/1`
                    tJson.stackPortInfo.push(stackPortInfoObj)
                }
                tJson.stackSwitchInfo.push(stackSwitchInfoObj)
            }
            return tJson;
        } else if(urlAction.service.indexOf("line-card")>-1) {
            return []
        } else if(urlAction.service.indexOf("supervisor-card")>-1) {
            return []
        } else if(urlAction.service.indexOf('insight')>-1) {
            return SimNetworkDeviceData.getInventoryInsight(urlAction);
        } else if (urlAction.service.indexOf("network-device")>-1) {
            if(urlAction.service.indexOf('sviInterface') > -1) {
                return [{
                    "deviceUUID": urlAction.action.id,
                    "instanceUUID": "504d4965-ad5a-4e9a-952b-0117aa90caf7",
                    "vlanId": "1005",
                    "netMask": "0.0.0.0",
                    "interfaceName": "trnet-default",
                    "ipAddress": "0.0.0.0",
                    "gateway": "0.0.0.0"
                }];
            } else if(urlAction.service.indexOf('role') > -1 && urlAction.service.indexOf("ENDPOINT_PROXY") > -1) {
                return []
            }
            var data = JSON.parse(JSON.stringify(SimLokiDatabaseActions.getAll('network-device')));
            var camera1Details = SimLokiDatabaseActions.getFilteredRecordHandler('host', {"hostName": "Camera1"})
            camera1Details[0].name = camera1Details[0].hostName
            camera1Details[0].hostname = camera1Details[0].hostName
            camera1Details[0].family = 'Video Camera'
            camera1Details[0].managementIpAddress = camera1Details[0].hostIpV4
            data.push(camera1Details[0]);
            var IpPhoneDetails = SimLokiDatabaseActions.getFilteredRecordHandler('host', {"hostName": "SEP191C5D21F520"})
            IpPhoneDetails[0].name = IpPhoneDetails[0].hostName
            IpPhoneDetails[0].hostname = IpPhoneDetails[0].hostName
            IpPhoneDetails[0].family = 'IP Phone'
            IpPhoneDetails[0].managementIpAddress = IpPhoneDetails[0].hostIpV4;            
            data.push(IpPhoneDetails[0]);
            data = data.reverse();      
            return data;
        }
        else if(urlAction.service.indexOf('eox-status')>-1) {
            let data = SimLokiDatabaseActions.getFilteredRecordHandler('deviceEox', {'deviceId':urlAction.action.id});
            return data[0];
        }
        else if (urlAction.url.indexOf('\?') > -1){
            return [];
        }

        if(urlAction.service.indexOf("pkcs-cert")>-1 && urlAction.service.indexOf("upgrade")>-1) {
            return [];
        }

        var data = SimLokiDatabaseActions.getAll('network-device');
        return data;
    }



        function processPutRequest(urlAction) {
        if (urlAction.service.indexOf('sync') >= 0) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            SimInventoryData.syncNetworkDevice(urlAction.restPayload);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            return resultObj;
        }

        if (urlAction.service.indexOf('collection-schedule') >= 0) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            SimInventoryData.updateCollectionInterval(urlAction.restPayload);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            return resultObj;
        }

        if (urlAction.service.indexOf('pnp-device') >= 0 || urlAction.service.indexOf('onboarding') > -1) {
            return SimOnboardingData.processPutRequest(urlAction);
            //return SimOnboardingData.updatePnpDevice(urlAction);
        }

        if (urlAction.service.indexOf('brief') >= 0) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            SimNetworkDeviceData.updateRoleOnDevice(urlAction.restPayload);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            return resultObj;
        }

        if (urlAction.restPayload.updateMgmtIPaddressList != undefined) {
            var resultObj = SimTaskDataGenerator.createTask('SPFService');
            var result=SimNetworkDeviceData.updateMgmtIpAddressOnDevice(urlAction.restPayload);
            if(result==="success"){
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            }
            else{
                SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: true });
            }
            return resultObj;
        }
        //provision-edit device- validate credentials
        if(urlAction.filter.indexOf("onlyValidateCredential")>-1){
            var progress = "{\"httpsStatus\":true,\"snmpStatus\":true,\"cliStatus\":true,\"netconfStatus\":true}";
            var resultObj = SimTaskDataGenerator.createTask('Inventory service',{'progress':progress});
            SimInventoryData.updateDeviceCrential(urlAction.restPayload);
            SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
            return resultObj;
        }
        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        SimInventoryData.updateDeviceCrential(urlAction.restPayload);
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        return resultObj;
    }

    function processDeleteRequest(urlAction) {

        if (urlAction.service.indexOf('pnp-device') >= 0) {
            return SimOnboardingData.deletePnpDevice(urlAction);
        }
        var resultObj = SimTaskDataGenerator.createTask('SPFService');
        var jsonObj = { id: urlAction.action.id };
        SimNetworkDeviceData.deleteNetworkDevice(jsonObj);
        SimTaskDataGenerator.updateTaskCompletion(resultObj.taskId, { isError: false });
        return resultObj;
    }

    function getDeviceCount() {
        var len = SimLokiDatabaseActions.getCount('network-device');
        return len;
    }

    function getAutoCompleteData(urlAction) {
        var allResults = fetchFilteredData(urlAction)
        if (allResults.length == 0) return allResults

        else {
            let relevantFilter = urlAction.filter.filter(function (d) { return Object.keys(allResults[0]).indexOf(d) > -1 })[0]
            let uniqueValues = [...new Set(allResults.map(function (r) { return r[relevantFilter] }))]
            if(urlAction.filter.indexOf('deviceSupportLevel')>-1){
                uniqueValues=['Supported'];
            }
            //return uniqueValues.map(function(v){ return allResults.find(function(r){return r[relevantFilter]===v})})
            return uniqueValues;
        }
    }

    function isFilterPassed(urlAction) {
        for (var i = 0; i < filterSupported.length; i++) {
            if (urlAction.filter[filterSupported[i]] != undefined) {
                return true;
            }
        }
        return false;
    }

    function fetchFilteredData(urlAction) {
        // var records = SimLokiDatabaseActions.getAll('network-device');

        // for(var i = 0; i < filterSupported.length; i++) {
        //     var f = filterSupported[i];
        //     var pattern = urlAction.filter[f];
        //     if(pattern != undefined && pattern != '') {
        //         pattern = pattern.replace(/\.\*/g, '');
        //         records = records.filter(function(v) { var t = v[f]; return t != undefined && t.indexOf(pattern) >= 0; })
        //         if(records.length == 0) return records;
        //     }
        // }

        // return records;
        var records = SimLokiDatabaseActions.getAll('network-device');
        let relevantFilters = urlAction.filter.filter(function(d){return Object.keys(records[0]).indexOf(d)>-1});
        var results=records;
        if(relevantFilters.length>0){
            results =  records.filter(
            function(r){
                return relevantFilters
                        .map(function(f){
                                var filterVal = urlAction.filter[f].replace('.*','').replace('.*','').toLowerCase();
                                filterVal = decodeURIComponent(filterVal);
                                return ((r[f].toLowerCase().indexOf(filterVal) > -1) || (filterVal.indexOf(r[f].toLowerCase()) > -1));
                            }) // return an array of booleans, one for each filter condition
                        .reduce(function(acc,val){
                               return acc && val
                            }) // chain the array with AND operator
            })}
        return results;
    }
});
