define({
"Template":
{
  "name": "radio_util_trigger",
  "severity": "HIGH",
  "id": "AWSSZ3VI_UA_GniDkLPg",
  "time": "2018-07-13T06:45:00.000+0000",
  "endTime": "2018-07-13T06:50:00.000+0000",
  "count": 2,
  "attributes": [
    {
      "key": "entityType",
      "value": "network_device"
    },
    {
      "key": "__entity_type__",
      "value": "NetworkDevice"
    },
    {
      "key": "channel",
      "value": "6.0"
    },
    {
      "key": "threshold",
      "value": "85.0"
    },
    {
      "key": "utilization",
      "value": "83.0"
    },
    {
      "key": "entityId",
      "value": "70:69:5A:38:26:A0"
    },
    {
      "key": "slot",
      "value": "0"
    },
    {
      "key": "type",
      "value": "Issue"
    },
    {
      "key": "priority",
      "value": "P1"
    },
    {
      "key": "macAddress",
      "value": "70:69:5A:38:26:A0"
    },
    {
      "key": "entityName",
      "value": "macAddr"
    },
    {
      "key": "rootCause",
      "value": "0"
    },
    {
      "key": "apname",
      "value": "AP4800-4"
    },
    {
      "key": "category",
      "value": "Utilization"
    },
    {
      "key": "status",
      "value": "active"
    }
  ],
  "source": "internal",
  "isActive": true
}
})
