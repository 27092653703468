define({
"Template":{success:true,result:"show interface CmdIfNamePlaceHolder\n"
+"CmdIfNamePlaceHolder is up, line protocol is up (connected)\n"
+"Hardware is Two Gigabit Ethernet, address is ec1d.8b9a.5392 (bia ec1d.8b9a.5392)\n"
+"MTU 1500 bytes, BW 2500000 Kbit/sec, DLY 10 usec,\n"
+"reliability 255/255, txload 1/255, rxload 1/255\n"
+"Encapsulation ARPA, loopback not set\n"
+"Keepalive set (10 sec)\n"
+"Full-duplex, 2500Mb/s, media type is 100/1000/2.5GBaseTX\n"
+"input flow-control is off, output flow-control is unsupported\n"
+"ARP type: ARPA, ARP Timeout 04:00:00\n"
+"Last input 00:00:13, output 00:00:00, output hang never\n"
+"Last clearing of show interface counters never\n"
+"Input queue: 0/2000/0/0 (size/max/drops/flushes); Total output drops:0\n"
+"Queueing strategy: Class-based queueing\n"
+"Output queue: 0/40 (size/max)\n"
+"5 minute input rate 336000 bits/sec, 28 packets/sec\n"
+"5 minute output rate 234000 bits/sec, 40 packets/sec\n"
+"271039955 packets input, 230577062037 bytes, 0 no buffer\n"
+"Received 333274 broadcasts (333021 multicasts)\n"
+"0 runts, 0 giants, 0 throttles\n"
+"0 input errors, 0 CRC, 0 frame, 0 overrun, 0 ignored\n"
+"0 watchdog, 333021 multicast, 0 pause input\n"
+"0 input packets with dribble condition detected\n"
+"287558279 packets output, 153447654481 bytes, 0 underruns\n"
+"0 output errors, 0 collisions, 2 interface resets\n"
+"0 unknown protocol drops\n"
+"0 babbles, 0 late collision, 0 deferred\n"
+"0 lost carrier, 0 no carrier, 0 pause output\n"
+"0 output buffer failures, 0 output buffers swapped out\n"+"DeviceNamePlaceHolder#"}
})