define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/data-generation/services/SimTemplateProgrammerData'
], function (SimTemplateProgrammerData) {

    return {
        init: function() {
        },

        doProcessRestRequest: function(urlAction){
            var data = {};
            switch(urlAction.method) {
                case 'GET':
                    data = processGetRequest(urlAction);
                    break;

                case 'POST':
                    data = processPostRequest(urlAction);
                    break;

                case 'PUT':
                    data = processPutRequest(urlAction);
                    break;

                case 'DELETE':
                    data = processDeleteRequest(urlAction);
                    break;

                default:
                    break;
            }
            return data;
        }
    };

    function processPostRequest(urlAction){
        if(urlAction.service.indexOf('validate-design') > -1) {
            //api/v1/template-programmer/template/validate-design
            return {"templateErrors":[],"rollbackTemplateErrors":[]};
        } else if(urlAction.service.indexOf('version') > -1) {
            //to be handled before template clause
            //api/v1/template-programmer/template/version
            return SimTemplateProgrammerData.commit(urlAction);
        } else if(urlAction.service.indexOf('name') > -1) {
            //to be handled before template clause
            //api/v1/template-programmer/name/Cloned%20Template%20sw/project/75dc31d6-4bbc-4e59-a249-751a6aadf15a/template/cbad038d-99fb-497d-b9e9-c7a99d7f3a60?projectId=3caba004-8114-4e2b-85fe-47c3c43f27fd
            return SimTemplateProgrammerData.cloneTemplate(urlAction);
        } else if(urlAction.service.indexOf('exporttemplates') > -1) {
            //to be handled before template clause
            //api/v1/template-programmer/template/exporttemplates
            return SimTemplateProgrammerData.exportTemplates(urlAction);
        } else if(urlAction.service.indexOf('importtemplates') > -1) {
            //to be handled before template clause
            //api/v1/template-programmer/project/3dd51633-f551-464b-9ed1-fbd4e4ac0f53/template/importtemplates?doVersion=true or doVersion=false
            return SimTemplateProgrammerData.importTemplates(urlAction);
        } else if(urlAction.service.indexOf('compatible-devices') > -1) {
            //to be handled before template creation clause
            //api/v1/template-programmer/template/compatible-devices
            return SimTemplateProgrammerData.getCompatibleDevices(urlAction.restPayload);
        } else if(urlAction.service.indexOf('template') > -1) {
            //to be handled before project creation clause
            //api/v1/template-programmer/project/c02ab3c9-dd61-4e72-a243-c21b3ae6299b/template
            return SimTemplateProgrammerData.createTemplate(urlAction);
        } else if(urlAction.service.indexOf('importprojects') > -1) {
            //to be handled before project creation clause
            //api/v1/template-programmer/project/importprojects?doVersion=true or doVersion=false
            return SimTemplateProgrammerData.importProjects(urlAction);
        } else if(urlAction.service.indexOf('exportprojects') > -1 || urlAction.service.indexOf('exportspecifictemplates') > -1) {
            //to be handled before project creation clause
            //api/v1/template-programmer/project/exportprojects
            return SimTemplateProgrammerData.exportProjects(urlAction);
        } else if(urlAction.service.indexOf('project') > -1) {
            //api/v1/template-programmer/project
            return SimTemplateProgrammerData.createProject(urlAction);
        }
        return {};
    }

    function processGetRequest(urlAction){
        var data = SimTemplateProgrammerData.getTemplateDetails(urlAction);
        return data;
    }

    function processPutRequest(urlAction){
        return SimTemplateProgrammerData.processPutRequest(urlAction);
    }

    function  processDeleteRequest(urlAction){
        if(urlAction.service.indexOf('template') > -1) {
            return SimTemplateProgrammerData.deleteTemplate(urlAction.action.id);
        } else if(urlAction.service.indexOf('project') > -1) {
            return SimTemplateProgrammerData.deleteProject(urlAction.action.id);
        }
    }

});
