define({
    "TASK_START_MSG": {
        taskId: "",
        url: "/api/v1/task/"
    },
    "TASK_POLL_MSG": {
        progress: "",
        version: 1493440323984,
        startTime: 0,
        isError: false,
        rootId: "",
        serviceType: "",
        id: ""
    },
    "TASK_POLL_CFS_MSG": {
        progress: "",
        data: "workflow_id=ba5a538b-65e4-4c4a-bdcd-09e8a5099346;cfs_id=c7eb11cd-5bb1-45c0-862a-17515ef60707;rollback_status=not_supported;rollback_taskid=0;failure_task=NA;processcfs_complete=true",
        version: 1493440323984,
        startTime: 0,
        endTime: 0,
        isError: false,
        lastUpdate: 1493553136693,
        rootId: "",
        serviceType: "SPFService",
        userName: "",
        id: ""
    },    
    // "TASK_POLL_CFS_MSG_1": {
    //     progress: "",
    //     data: "workflow_id=ba5a538b-65e4-4c4a-bdcd-09e8a5099346;cfs_id=c7eb11cd-5bb1-45c0-862a-17515ef60707;rollback_status=not_supported;rollback_taskid=0;failure_task=NA;processcfs_complete=true",
    //     version: 1493440323984,
    //     startTime: 0,
    //     isError: false,
    //     lastUpdate: 1493553136693,
    //     rootId: "",
    //     serviceType: "SPFService_1",
    //     userName: "",
    //     id: ""
    // },
    "TASK_POOL_GROUPING_MSG": {
        startTime: 0,
        data: "",
        version:1507050395516,
        serviceType: "Grouping Service",
        isError: false,
        operationIdList: ["0eb13767-e03f-4e8c-9260-88063be1ee94"],
        progress: "",
        rootId: "",
        id: ""
    },
    "TASK_POOL_IPAM_MSG": {
      id:"",
      isError:false,
      lastUpdate:1508756453291,
      progress:"",
      rootId:"",
      serviceType:"Ipam Service",
      startTime:1508756453228,
      version:1508756453291
    },
    "TASK_DNA_MAPS_MSG": {
      version: 1508821273810,
      data: "",
      startTime: 1508821273810,
      endTime: 1508821274011,
      progress: "Service domain is created successfully.",
      rootId: "",
      serviceType: "DNA Maps Service",
      isError: false,
      id: ""
    },
     "SPService_MSG":{
        "startTime":0,
        "endTime":0,
        "version":0,
        "serviceType":"Site Profile Service",
        "isError":false,
        "progress":"Network Profile [] Successfully Created",
        "rootId":"",
        "id":""
    },
    "TASK_COMM_SETTING_MSG":{
        "startTime":0,
        "endTime":0,
        "version":0,
        "serviceType":"Common Settings Service",
        "isError":false,
        "progress":"Created Common Settings successfully.",
        "rootId":"",
        "id":""
    },
    "TASK_DNA_MAPS_MSG": {
        version: 1508821273810,
        data: "",
        startTime: 1508821273810,
        endTime: 1508821274011,
        progress: "MAP is created successfully.",
        rootId: "",
        serviceType: "DNA Maps Service",
        isError: false,
        id: ""
    },
  /*  "TASK_INVENTORY_MSG": {
      id:"",
      isError:false,
      lastUpdate:1508756453291,
      progress:"",
      rootId:"",
      serviceType:"Inventory service",
      startTime:1508756453228,
      version:1508756453291
    },*/
    "TASK_INVENTORY_MSG": {
        "version": 1566799535600,
        "endTime": 1566799535995,
        "progress": "",
        "startTime": 1566799535598,
        "serviceType": "Inventory service",
        "additionalStatusURL": "",
        "lastUpdate": 1566799535600,
        "isError": false,
        "rootId": "",
        "instanceTenantId": "5d51c31c9fc34600c8b45705",
        "id": ""
      },
    "TASK_ASSURANCE_MSG": {
      id:"",
      isError:false,
      lastUpdate:1508756453291,
      progress:"",
      rootId:"",
      serviceType:"Command Runner Service",
      startTime:1508756453228,
      username: "admin",
      version:1508756453291
    },
    "TASK_DNA_WIRELESS_MSG": {
        "version":1518612901031,
        "startTime":1518612901031,
        "endTime":1518612913330,
        "isError":false,
        "progress":"SUCCESS created Guest Portal on ISE",
        "rootId":"",
        "serviceType":"DNA Wireless Service",
        "id":""
    },
    "TASK_SWIM_TASKLIST_INDV": {
        "taskUuid":"0d371f5d-a024-4ded-b947-7023ce14a84e",
        "deviceTaskUuid":"",
        "taskType":"import",
        "startTime":"1524719905543",
        "completionTime":"1524719969024",
        "taskStatus":"SUCCESS",
        "operation":"",
        "deviceId":"",
        "imageName":"AIR-CT5520-K9-8-7-102-0.aes",
        "logDetails":""
    },
    "TASK_SWIM_IMAGE_ACTIVATE": {
        "taskUuid":"b1e0d360-3b60-4e1d-8a61-f6a0362f092f",
        "deviceTaskUuid":"eabfbf7b-44b4-4150-8f5c-f57b286a156d",
        "unitTaskUuid":"e40112b4-4358-444c-ad77-54073fa51a17",
        "taskType":"distribute",
        "startTime":"1531711574929",
        "completionTime":"",
        "taskStatus":"In Progress",
        "operation":"",
        "deviceId":"0741042b-b84d-4dbc-a0ea-76e151d25671",
        "imageName":"cat3k_caa-universalk9.16.08.01s.SPA.bin",
        "deviceIp":"10.16.31.34",
        "hostName":"",
        "workflowId":"NA",
        "inProgressCount": 1,
        "successCount": 0,
        "failureCount": 0
    },
    "TASK_IMAGE_ACTIVATE_PARENT_REQ": [{
		"startTime": 1531711574929,
		"endTime": 1531711973293,
		"data": "distribute",
		"version": 1531711621727,
		"serviceType": "Swim Service",
		"isError": false,
		"lastUpdate": 1531711621727,
		"parentId": "eabfbf7b-44b4-4150-8f5c-f57b286a156d",
		"progress": "Distribution of image: [] on device: [] with protocol: HTTPS completed successfully",
		"rootId": "b1e0d360-3b60-4e1d-8a61-f6a0362f092f",
		"instanceTenantId": "5b2d1c30fe5f7f0088660642",
		"id": "e40112b4-4358-444c-ad77-54073fa51a17"
	}, {
		"startTime": 1531711973572,
		"endTime": 1531712669872,
		"data": "activate",
		"version": 1531711973572,
		"serviceType": "Swim Service",
		"isError": false,
		"parentId": "eabfbf7b-44b4-4150-8f5c-f57b286a156d",
		"progress": "Activation of image: [] on device: [] completed successfully.",
		"rootId": "b1e0d360-3b60-4e1d-8a61-f6a0362f092f",
		"instanceTenantId": "5b2d1c30fe5f7f0088660642",
        "id": "417c8fa2-28b8-474d-9654-386358de7be4"
    }, {
        "startTime": 1619606346320,
        "version": 1619606346320,
        "endTime": 1619606346866,
        "data": "RAP Upgrade",
        "parentId": "c8a6d68d-1b3e-4ae2-99b6-b8a57c893bf0",
        "rootId": "29fb3bf2-08d1-4253-b5d0-063377b6bcfb",
        "additionalStatusURL": "/dna-wireless-service/rolling-ap-status/62f4cb08-ff42-4878-b434-b53539efff8b",
        "serviceType": "DNA_WIRLESS_SERVICE_RAPUPGRADE_HANDLER",
        "progress": "Rolling AP Upgrade Success",
        "isError": false,
        "instanceTenantId": "5b2d1c30fe5f7f0088660642",
        "id": "62f4cb08-ff42-4878-b434-b53539efff8b"
    }],
    "TASK_TAG" : {
        "startTime": 1538390707762,
        "endTime": 1538390707804,
        "version": 1538390707762,
        "data": "0d632920-b50d-43c5-a08a-819a24090ce6",
        "serviceType": "Grouping Service",
        "isError": false,
        "operationIdList": [
            "f9cd33c7-3066-4f05-8a19-2a67bccbe0e8"
        ],
        "progress": "Group DTO saved successfully MemberPreviewCache [MemberPreviewCache=com.cisco.grapevine.collections.impl.GrapevineHazelcastMapImpl@7ef0a986]",
        "rootId": "216316b6-0e1d-4085-8adf-45c41beb130c",
        "instanceTenantId": "5bb0145f841f5f004c767d53",
        "id": "216316b6-0e1d-4085-8adf-45c41beb130c"
    },
    "TASK_SCHEDULER" : {
        "startTime": 1539229852462,
        "endTime": 1539229875080,
        "data": "{\"schedulingHooks\":[\"OS_UPDATE_POST_{}\"]}",
        "version": 1539229852462,
        "serviceType": "Scheduler Service",
        "isError": false,
        "progress": "Successfully created task schedules. ParentTaskId : {}",
        "rootId": "7ab7d006-bb22-40f3-9af3-29e4d6779b0f",
        "instanceTenantId": "5b2d1c30fe5f7f0088660642",
        "id": "7ab7d006-bb22-40f3-9af3-29e4d6779b0f"
    },
    "TASK_UPGRADE_READINESS" : {
        "data": "196b082d-4ea3-4a69-b868-9f012e792789",
        "startTime": 1539833741243,
        "endTime": 1539833741775,
        "version": 1539833741252,
        "serviceType": "Swim Service",
        "additionalStatusURL": "/api/v1/simulation/upgardereadiness/file/196b082d-4ea3-4a69-b868-9f012e792789",
        "isError": false,
        "lastUpdate": 1539833741252,
        "progress": "Upgarde Readiness report exported successfully and the file id is: 196b082d-4ea3-4a69-b868-9f012e792789",
        "rootId": "783b7a92-e9a0-4abe-8826-4a29a8e14a87",
        "instanceTenantId": "5b161c0f04a351008902742f",
        "id": "783b7a92-e9a0-4abe-8826-4a29a8e14a87"
    },
    "NFV_PROV_DUMMY" : {
        "version":1540546103025,
        "startTime":1540546103025,
        "endTime":1540546103084,
        "serviceType":"Network Design Service",
        "username":"admin",
        "isError":false,
        "progress":"All Provisioning Parameters Successfully Saved",
        "rootId":"940ff7e0-3f75-4cde-b20e-47c2d7a45fd5",
        "instanceTenantId":"5bc9a705ec3eed004c80082a",
        "id":"940ff7e0-3f75-4cde-b20e-47c2d7a45fd5"
    },
    "LICENSE_MANAGER" : {
        "data": "Success",
        "version": 1545196761644,
        "startTime": 1545196744973,
        "endTime": 1545196790363,
        "isError": false,
        "lastUpdate": 1545196761644,
        "progress": "{\"deviceFamily\": \"Switches and Hubs\", \"progress\": \"License Cancel or return successfully done with license feature sync completed\", \"deviceIP\": \"172.26.197.70\", \"deviceType\": \"Cisco Catalyst 9300 Switch\", \"deviceName\": \"C9300.cisco.com\", \"licenseLevel\": \"Return License\", \"operation\": \"Return License\", \"parentTask\": null, \"deviceUUId\": \"2c9b4251-5a2b-478e-b473-0a92b142aaec\", \"deviceId\": \"50323275\", \"mode\": null}",
        "rootId": "ecf8ca3e-ba70-49d3-a254-0a11f4320462",
        "serviceType": "License Manager",
        "instanceTenantId": "SYS0",
        "id": "ecf8ca3e-ba70-49d3-a254-0a11f4320462"
    },

    "LICENSE_TASK_TEMPLATE": {"data": [], "isTaskInProgress": false, "version": "1.0.0"},

    "TASK_ONBOARDING_DAYZERO": {
        "version": 1548215531971,
        "startTime": 1548215531971,
        "endTime": 1548215532204,
        "serviceType": "onboarding",
        "username": "system",
        "isError": false,
        "progress": "Day 0 Config Preview Saved in Device",
        "rootId": "",
        "instanceTenantId": "SYS0",
        "id": ""
    },

    "TASK_TEMPLATE_EDITOR": {
        "version": 1548347563491,
        "data": "36cbc01c-0fba-44ed-aaa0-df3cafaaadba",
        "startTime": 1548347563491,
        "endTime": 1548347563535,
        "serviceType": "NCTP",//"SDN Programmer Service",
        "isError": false,
        "progress": "Successfully updated template with name {}",
        "rootId": "106254c7-e45c-49f2-8805-fea9feffc7ea",
        "instanceTenantId": "5d48c2c25f818b00cbaa85b6",
        "id": "106254c7-e45c-49f2-8805-fea9feffc7ea",
        "username":"admin"
    },

    "TASK_DeviceInterfaceInfoContainer_MSG": {
        "progress":"TASK_MODIFY_PUT","version":1559815059020,
        "data":"workflow_id={};cfs_id={};rollback_status=not_supported;rollback_taskid=0;failure_task=NA;processcfs_complete=true",
        "endTime":1559815067316,"startTime":1559815057514,
        "rootId":"234b78e4-9ba5-4741-9308-44f2392c45fe",
        "serviceType":"NCSP",
        "lastUpdate":1559815059020,"isError":false,"username":"","instanceTenantId":"5cec9a5a0bc3a300b385d500",
        "id":"234b78e4-9ba5-4741-9308-44f2392c45fe"
    },

    "TASK_ETA_SCHEDULER": {
        "progress": "New Schedules were added. Successfully created a task schedule. {}",
        "endTime": 1559824019155,
        "version": 1559824018562,
        "username": "admin",
        "startTime": 1559824018562,
        "rootId": "0f2400b4-ec95-41f2-9b5e-9810471679c3",
        "serviceType": "NCSS",
        "isError": false,
        "instanceTenantId": "5cda831fdcb07600cbc3ad7a",
        "id": "0f2400b4-ec95-41f2-9b5e-9810471679c3"
    },

    "DUMMY_SIM_OS_FAILURE" : {
        progress: "",
        version: 1493440323984,
        startTime: 0,
        isError: false,
        rootId: "",
        serviceType: "Swim Dummy",
        id: "",
        failureReason : "Insufficient Memory on device or Memory Utilization is high!"
    },

    "MARK_FOR_REPLACEMENT" : {
        "endTime": 1570170734813,
        "version": 1570170734739,
        "progress": "Device(s) Marked For Replacement Successfully.",
        "startTime": 1570170734739,
        "isError": false,
        "rootId": "8b3330d7-1c21-4fab-97c4-c8f36d18507d",
        "operationIdList": [
          "e6873898-683b-4c90-9d00-22b62b3c449a"
        ],
        "serviceType": "NCRM",
        "instanceTenantId": "5d3a2de4d2551300b1188fca",
        "id": "8b3330d7-1c21-4fab-97c4-c8f36d18507d"
    },
    
    "BONJOUR" : {
        "version": 1571123031174,
        "endTime": 1571123033218,
        "startTime": 1571123031174,
        "progress": "Completed",
        "data": "c95fb578-6946-4c82-80f3-cba653496fa0",
        "serviceType": "SDG Service",
        "isError": false,
        "rootId": "f9aaf757-27e5-46cd-9f27-08bf8a5a1ec1",
        "instanceTenantId": "5d48c2c25f818b00cbaa85b6",
        "id": "f9aaf757-27e5-46cd-9f27-08bf8a5a1ec1"
      },

    "NCCS" : {
        'data': "deployStatus=Deployed successfully",
        'endTime': 1573107710479,
        'id': "0055d724-a291-4038-8862-abe1d8a87389",
        'instanceTenantId': "5d48c2c25f818b00cbaa85b6",
        'progress': "Deployed successfully!",
        'rootId': "0055d724-a291-4038-8862-abe1d8a87389",
        'serviceType': "NCCS",
        'startTime': 1573107710371,
        'username': "admin",
        'version': 1573107710371,
        'completed':true
    },

    "UNDEFINED_TASKID" : {
        "errorCode":"Bad Request",
        "message":"Invalid parameters specified for required operation",
        "detail":"undefined is not a valid UUID",
        "isError": true,
        "statusCode":400
    },

    "MLCN" : {
        "startTime": 1582778547542,
        "version": 0,
        "progress": "{}", //"9c95cb27-43e9-47fc-9355-c5a2ff7a06ed",
        "serviceType": "MLCN",
        "isError": false,
        "username": "admin",
        "rootId": "f852a71d-d083-4d18-9eeb-96b0836ff133",
        "instanceTenantId": "5d5c80cc635d2700c748d238",
        "id": "f852a71d-d083-4d18-9eeb-96b0836ff133"
    },

    "ROGUE_AWIPS_TASK":[
        {
          "progress": "Telemetry configuration processed successfully on all devices.",
          "startTime": 1589201838378,
          "version": 1589201838835,
          "endTime": 1589201843803,
          "data": "TELEMETRY_SUBSCRIPTION-AppName:ROGUE",
          "lastUpdate": 1589201838835,
          "rootId": "9c0a0720-332d-4e29-9ac2-7bfb31a3bbc8",
          "serviceType": "DNA Common Service",
          "isError": false,
          "instanceTenantId": "5eb90ccc14f82100bca7f070",
          "id": "9c0a0720-332d-4e29-9ac2-7bfb31a3bbc8"
        },
        {
          "progress": "MBC CONFIG PUSH SUCCESS FOR THE DEVICE WITH DEVICEID 237237",
          "startTime": 1589201838763,
          "version": 1589201838763,
          "endTime": 1589201839403,
          "data": "{Operation:CREATE,DeviceUuid:{},DeviceIp:{}}",
          "parentId": "9c0a0720-332d-4e29-9ac2-7bfb31a3bbc8",
          "rootId": "9c0a0720-332d-4e29-9ac2-7bfb31a3bbc8",
          "serviceType": "NCND",
          "isError": false,
          "instanceTenantId": "5eb90ccc14f82100bca7f070",
          "id": "d34fb8bb-3e0d-488e-9ee6-918f173aeca8"
        }
    ],
    "INTERFACE_TASK_NCIM":{
        "startTime": 1656678612342,
        "version": 1656678612342,
        "endTime": 1656678614716,
        "data": "interface GigabitEthernet1/0/5\n  shutdown\n  exit\n",
        "serviceType": "NCIM",
        "progress": "MBC CONFIG PUSH SUCCESS FOR THE DEVICE WITH DEVICEID 277282",
        "username": "admin",
        "isError": false,
        "rootId": "a17a91ab-730a-427b-a807-e19d0f7a332d",
        "instanceTenantId": "5f35ea5f15de5300c6a0162a",
        "id": "a17a91ab-730a-427b-a807-e19d0f7a332d"
      },
      "CMX_STATUS_TASK_RESPONSE" :{
        "version": new Date().getTime(),
        "data": "[{\"statusCode\":0,\"message\":\"Success\",\"operationType\":\"CMX\"}]",
        "startTime": new Date().getTime(),
        "endTime": new Date().getTime() + 1000,
        "progress": "finished",
        "serviceType": "DNA Maps Service",
        "isError": false,
        "instanceTenantId": "614699e69546d628e2ece475",
        "id": "6b7d1947-f69d-4a55-a8f1-8dc5f051d5e9"
    },
    "CMX_INFO_TASK_RESPONSE" :{
        "version": new Date().getTime(),
        "endTime": new Date().getTime() + 2000,
        "startTime": new Date().getTime(),
        "data": "null",
        "progress": "finished",
        "serviceType": "DNA Maps Service",
        "isError": false,
        "instanceTenantId": "5f35ea5f15de5300c6a0162a",
        "id": "3495b396-002d-4f85-9c0b-87bc7a5154ac"
    }

});
