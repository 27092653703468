define({
    "Assurance_CD_Template": {
      id:"",
      label:"DeviceGroup",
      timestamp:"",
      isDeleted:false,
      type:"vertex",
      properties:{
        groupName:[{
          value: ""
        }],
	groupType:[{
          value:"FABRIC"}
	]
      }
    }
});
