define({
"Template":
 {
  "version": "1.0",
  "response": {
    "id": "c0ef2099-79af-40b2-9b7a-419e24d87c03",
    "name": "fabric_reachability_ise_trigger",
    "enabled": true,
    "flattened": true,
    "entityType": "network_device",
    "entityName": "Unknown",
    "entity": "172.28.110.31",
    "groupBy": "Unknown",
    "category": "Connected",
    "severity": "HIGH",
    "summary": "Fabric Edge DeviceNamePlaceHolder Lost Connectivity to the ISE Server dcloud-ise-ctf-inst-xxx.cisco.com in the Physical Network",
    "groupId": "fabric_reachability_grouping",
    "groupName": "Fabric Reachability Failure",
    "scope": "GLOBAL",
    "priority": "P1",
    "rootCause": "172.28.110.38_172.25.33.59_Ise",
    "timestamp": 1588975677977,
    "description": "The Fabric Edge DeviceNamePlaceHolder cannot reach the ISE server dcloud-ise-ctf-inst-xxx.cisco.com in the Physical Network",
    "suggestions": [
      {
        "message": "Verify whether the ISE server is up."
      },
      {
        "message": "Verify the route to the ISE server.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ip route to dcloud-ise-ctf-inst-xxx.cisco.com",
            "command": "show ip route dcloud-ise-ctf-inst-xxx.cisco.com",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ip cef route to dcloud-ise-ctf-inst-xxx.cisco.com",
            "command": "show ip cef dcloud-ise-ctf-inst-xxx.cisco.com",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Verify IGP adjacencies from this node to the upstream switches.",
        "steps": [
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check ISIS adjacencies",
            "command": "show isis neighbors",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check OSPF adjacencies",
            "command": "show ospf neighbor",
            "stepType": "command-Runner"
          },
          {
            "entityId": "DeviceIdPlaceHolder",
            "description": "Check EIGRP adjacencies",
            "command": "sh eigrp address-family ipv4 neighbors",
            "stepType": "command-Runner"
          }
        ]
      },
      {
        "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html"
      }
    ],
    "additionalParams": [
      {
        "key": "destIp",
        "value": "dcloud-ise-ctf-inst-xxx.cisco.comssssss"
      },
      {
        "key": "managementIpAddr",
        "value": "172.28.110.31"
      },
      {
        "key": "_name",
        "value": "fabric_reachability_ise_trigger"
      },
      {
        "key": "_entity_type",
        "value": "NetworkDevice"
      },
      {
        "key": "eventSource",
        "value": "Network Device"
      },
      {
        "key": "type",
        "value": "issue"
      },
      {
        "key": "_key",
        "value": "NetworkDevice:172.28.110.31"
      },
      {
        "key": "uuid",
        "value": "5a0f2137-b415-4247-80e4-28cd7006c52c"
      },
      {
        "key": "sourceRole",
        "value": "Fabric Edge"
      },
      {
        "key": "floorId",
        "value": ""
      },
      {
        "key": "_count",
        "value": "4"
      },
      {
        "key": "areaName",
        "value": "Global"
      },
      {
        "key": "scope",
        "value": "global"
      },
      {
        "key": "rootCause",
        "value": "172.28.110.38_172.25.33.59_Ise"
      },
      {
        "key": "_primary_key",
        "value": "NetworkDevice:172.28.110.31"
      },
      {
        "key": "sessionType",
        "value": "Ise"
      },
      {
        "key": "floorName",
        "value": ""
      },
      {
        "key": "siteType",
        "value": "building"
      },
      {
        "key": "deviceType",
        "value": "Cisco Catalyst38xx stack-able ethernet switch"
      },
      {
        "key": "severity",
        "value": "HIGH"
      },
      {
        "key": "deviceUuid",
        "value": "5a0f2137-b415-4247-80e4-28cd7006c52c"
      },
      {
        "key": "deviceRole",
        "value": "ACCESS"
      },
      {
        "key": "_suppression",
        "value": "3600"
      },
      {
        "key": "deviceOS",
        "value": "16.12.3sprd1"
      },
      {
        "key": "entityType",
        "value": "network_device"
      },
      {
        "key": "destMgmtIp",
        "value": "NA"
      },
      {
        "key": "nwDeviceName",
        "value": "stack-3850"
      },
      {
        "key": "_eventTime",
        "value": "1588973867010"
      },
      {
        "key": "_mostRecent",
        "value": "1588975677977"
      },
      {
        "key": "entityId",
        "value": "172.28.110.31"
      },
      {
        "key": "eventUniqueId",
        "value": "172.28.110.31"
      },
      {
        "key": "vrf",
        "value": ""
      },
      {
        "key": "deviceFamily",
        "value": "Switches and Hubs"
      },
      {
        "key": "priority",
        "value": "P1"
      },
      {
        "key": "sessionIdentifier",
        "value": "172.28.110.38_172.25.33.59_Ise"
      },
      {
        "key": "buildingId",
        "value": "91288ca2-968a-4794-b53a-ee64c9b4464e"
      },
      {
        "key": "buildingName",
        "value": "Global/RAJ-SJC20-3K"
      },
      {
        "key": "areaId",
        "value": "Global"
      },
      {
        "key": "sourceIp",
        "value": "172.28.110.38"
      },
      {
        "key": "siteHierarchy",
        "value": "Global/RAJ-SJC20-3K"
      },
      {
        "key": "deviceModel",
        "value": "FOC2123L2DG"
      },
      {
        "key": "category",
        "value": "connected"
      },
      {
        "key": "timestring",
        "value": "2020-05-08T21:37:47.010+0000"
      },
      {
        "key": "status",
        "value": "active"
      }
    ],
    "status": {
      "status": "ACTIVE",
      "updatedBy": "Unknown",
      "notes": "Unknown",
      "source": "Unknown",
      "updatedAt": null,
      "ignoreSource": "Unknown",
      "ignoreValue": "Unknown",
      "ignoreStartTime": null,
      "ignoreEndTime": null
    },
    "siteQulaifiedName": null
  }
}

})