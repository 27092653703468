define({
    "ApModeChangeTemplate":
    [
        {
         "id": "bafd3f17-03ba-4293-a89a-121ba3f4a5b5",
         "instanceId": 1360359,
         "authEntityId": 1360359,
         "displayName": "0",
         "authEntityClass": 526395126,
         "deployPending": "NONE",
         "instanceVersion": 0,
         "deployed": false,
         "isSeeded": false,
         "isStale": false,
         "name": "2c:d0:2d:8b:d3:60",
         "namespace": "apModeConversion",
         "provisioningState": "UNKNOWN",
         "resourceVersion": 0,
         "targetIdList": [],
         "type": "APModeChange",
         "cfsChangeInfo": [],
         "customProvisions": [],
         "macAddress": "2c:d0:2d:8b:d3:60",
         "targetMode": "sensor"
        }
       ]

 });