define({
    "Template":
    {
        "request": {
          "key": "ms-update",
          "type": "Application",
          "label": "ms-update",
          "collection": false,
          "componentContent": [
            {
              "component": "fusion",
              "additional": "86a69d26-1adc-4964-8c73-eff57fc7565b"
            }
          ]
        },
        "data": {
          "response": {
            "id": "86a69d26-1adc-4964-8c73-eff57fc7565b",
            "instanceId": 4793,
            "authEntityId": 4793,
            "displayName": "4793",
            "authEntityClass": -1909073334,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "createTime": 1528265513444,
            "deployed": false,
            "isSeeded": true,
            "isStale": false,
            "lastUpdateTime": 1528265513444,
            "name": "ms-update",
            "namespace": "scalablegroup:application",
            "provisioningState": "DEFINED",
            "qualifier": "application",
            "resourceVersion": 0,
            "targetIdList": [],
            "type": "scalablegroup",
            "cfsChangeInfo": [],
            "customProvisions": [],
            "scalableGroupExternalHandle": "ms-update",
            "scalableGroupType": "APPLICATION",
            "securityGroupTag": 0,
            "state": "ACTIVE",
            "vnAgnostic": true,
            "identitySource": {
              "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
              "instanceId": 10010,
              "authEntityId": 10010,
              "displayName": "10010",
              "authEntityClass": 1738953278,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "state": "INACTIVE",
              "type": "NBAR"
            },
            "indicativeNetworkIdentity": [],
            "networkApplications": [
              {
                "id": "594d9a94-c22d-4f76-8a0d-9db7188859e1",
                "instanceId": 11741,
                "authEntityId": 11741,
                "displayName": "11741",
                "authEntityClass": -217092956,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "appProtocol": "tcp/udp",
                "applicationGroup": "other",
                "applicationType": "DEFAULT",
                "categoryId": "e30e6137-25d1-4b6a-9308-ac31987f1d9c",
                "createTime": 1528265513444,
                "encrypted": "true",
                "engineId": "13",
                "helpString": "Microsoft Update Service",
                "ignoreConflict": false,
                "lastUpdateTime": 1528265513444,
                "longDescription": "Microsoft Windows Update Service provides updates for the Microsoft Windows operating system and its installed components.",
                "name": "ms-update",
                "nbarId": "1432",
                "p2pTechnology": "false",
                "popularity": 8,
                "rank": 65535,
                "references": "http://windows.microsoft.com/en-us/windows/help/windows-update",
                "selectorId": "497",
                "subCategory": "4838ecd7-3df7-4346-a9a3-abb8965eb5b3",
                "trafficClass": "BULK_DATA",
                "tunnel": "false"
              }
            ],
            "networkIdentity": [
              {
                "id": "16d79c12-fa8f-4150-8cbf-ce31ed44d1d3",
                "instanceId": 14020,
                "authEntityId": 14020,
                "displayName": "14020",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,80,443,5353",
                "protocol": "TCP",
                "upperPort": 0
              },
              {
                "id": "0b84c50e-79d6-414e-a505-5627322fd4f0",
                "instanceId": 14021,
                "authEntityId": 14021,
                "displayName": "14021",
                "authEntityClass": 124090504,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "ipv4Subnet": [],
                "ipv6Subnet": [],
                "lowerPort": 0,
                "ports": "53,5353",
                "protocol": "UDP",
                "upperPort": 0
              }
            ],
            "parentScalableGroup": {
              "idRef": "7190f2b7-944b-408f-a774-284476d5829e"
            },
            "user": [],
            "userGroup": []
          }
        },
        "result": [
          {
            "bookID": {
              "namespace": "fusion",
              "name": "core",
              "version": "1.0.1"
            },
            "response": {
              "id": "86a69d26-1adc-4964-8c73-eff57fc7565b",
              "instanceId": 4793,
              "authEntityId": 4793,
              "displayName": "4793",
              "authEntityClass": -1909073334,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "createTime": 1528265513444,
              "deployed": false,
              "isSeeded": true,
              "isStale": false,
              "lastUpdateTime": 1528265513444,
              "name": "ms-update",
              "namespace": "scalablegroup:application",
              "provisioningState": "DEFINED",
              "qualifier": "application",
              "resourceVersion": 0,
              "targetIdList": [],
              "type": "scalablegroup",
              "cfsChangeInfo": [],
              "customProvisions": [],
              "scalableGroupExternalHandle": "ms-update",
              "scalableGroupType": "APPLICATION",
              "securityGroupTag": 0,
              "state": "ACTIVE",
              "vnAgnostic": true,
              "identitySource": {
                "id": "607e5f6a-d4bd-41e6-9861-bcd939c316b6",
                "instanceId": 10010,
                "authEntityId": 10010,
                "displayName": "10010",
                "authEntityClass": 1738953278,
                "instanceTenantId": "SYS0",
                "deployPending": "NONE",
                "instanceVersion": 0,
                "state": "INACTIVE",
                "type": "NBAR"
              },
              "indicativeNetworkIdentity": [],
              "networkApplications": [
                {
                  "id": "594d9a94-c22d-4f76-8a0d-9db7188859e1",
                  "instanceId": 11741,
                  "authEntityId": 11741,
                  "displayName": "11741",
                  "authEntityClass": -217092956,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "appProtocol": "tcp/udp",
                  "applicationGroup": "other",
                  "applicationType": "DEFAULT",
                  "categoryId": "e30e6137-25d1-4b6a-9308-ac31987f1d9c",
                  "createTime": 1528265513444,
                  "encrypted": "true",
                  "engineId": "13",
                  "helpString": "Microsoft Update Service",
                  "ignoreConflict": false,
                  "lastUpdateTime": 1528265513444,
                  "longDescription": "Microsoft Windows Update Service provides updates for the Microsoft Windows operating system and its installed components.",
                  "name": "ms-update",
                  "nbarId": "1432",
                  "p2pTechnology": "false",
                  "popularity": 8,
                  "rank": 65535,
                  "references": "http://windows.microsoft.com/en-us/windows/help/windows-update",
                  "selectorId": "497",
                  "subCategory": "4838ecd7-3df7-4346-a9a3-abb8965eb5b3",
                  "trafficClass": "BULK_DATA",
                  "tunnel": "false"
                }
              ],
              "networkIdentity": [
                {
                  "id": "16d79c12-fa8f-4150-8cbf-ce31ed44d1d3",
                  "instanceId": 14020,
                  "authEntityId": 14020,
                  "displayName": "14020",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,80,443,5353",
                  "protocol": "TCP",
                  "upperPort": 0
                },
                {
                  "id": "0b84c50e-79d6-414e-a505-5627322fd4f0",
                  "instanceId": 14021,
                  "authEntityId": 14021,
                  "displayName": "14021",
                  "authEntityClass": 124090504,
                  "instanceTenantId": "SYS0",
                  "deployPending": "NONE",
                  "instanceVersion": 0,
                  "ipv4Subnet": [],
                  "ipv6Subnet": [],
                  "lowerPort": 0,
                  "ports": "53,5353",
                  "protocol": "UDP",
                  "upperPort": 0
                }
              ],
              "parentScalableGroup": {
                "idRef": "7190f2b7-944b-408f-a774-284476d5829e"
              },
              "user": [],
              "userGroup": []
            }
          }
        ]
      }
    });