define({
    "issues": {
        "cpu_issues": [
            "snmp_cpu_high_80",
            "ap_cpu_threshold_trigger"
        ],
        "memory_issues": [
            "snmp_map_cache_limit_reached",
            "snmp_memory_high_80",
        ],
        "interface_issues": [
            "switch_port_flaps",
            "switch_port_flapping"
        ],
        "link_issues": [
            "switch_interface_input_output_errors",
            "FabricPhysicalLink_Issue"
        ],
        "connectivity_issues": [
            "network_connectivity_trigger",
            "network_connectivity_trigger_ospf",
            "network_connectivity_trigger_eigrp"
        ],
        "availability_issues": [
            "snmp_device_down",
            "snmp_device_down_edge",
            "ap_down",
            "switch_issue_power"
        ],
        "reachability_issues": [
            "fabric_reachability_session",
            "fabric_reachability_session_edge",
            "fabric_reachability_session_cp",
            "fabric_reachability_session_dhcp",
            "fabric_reachability_session_dns_vn",
            "fabric_reachability_session_dns_pn",
            "fabric_wlc_reachability"
        ],
        "utilization_issues": [
            "radio_util_trigger",
            "radio_util_trigger_ap",
            "tcam_util_high"
        ],
        "noise_issues": [
            "radio_util_trigger",
            "radio_util_trigger_ap",
        ],
        "unknown": [
            "default_syslog_event_trigger",
            "default_syslog_event_trigger_edge",
            "ap_ilm_event",
            "wlc_monitor"
        ]

    }

});