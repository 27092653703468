define({
    "Template":

{
    "request": {
      "key": "youtube",
      "type": "Application",
      "label": "youtube",
      "collection": false,
      "componentContent": [
        {
          "component": "fusion",
          "additional": "a140636c-947d-4c47-9788-b40a680cddb4"
        }
      ]
    },
    "data": {
      "response": {
        "id": "a140636c-947d-4c47-9788-b40a680cddb4",
        "instanceId": 15464,
        "authEntityId": 15464,
        "displayName": "15464",
        "authEntityClass": -1909073334,
        "instanceTenantId": "SYS0",
        "deployPending": "NONE",
        "instanceVersion": 0,
        "createTime": 1528178812081,
        "deployed": false,
        "isSeeded": true,
        "isStale": false,
        "lastUpdateTime": 1528178812081,
        "name": "youtube",
        "namespace": "scalablegroup:application",
        "provisioningState": "DEFINED",
        "qualifier": "application",
        "resourceVersion": 0,
        "targetIdList": [],
        "type": "scalablegroup",
        "cfsChangeInfo": [],
        "customProvisions": [],
        "scalableGroupExternalHandle": "youtube",
        "scalableGroupType": "APPLICATION",
        "securityGroupTag": 0,
        "state": "ACTIVE",
        "vnAgnostic": true,
        "identitySource": {
          "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
          "instanceId": 10010,
          "authEntityId": 10010,
          "displayName": "10010",
          "authEntityClass": 1738953278,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "state": "INACTIVE",
          "type": "NBAR"
        },
        "indicativeNetworkIdentity": [],
        "networkApplications": [
          {
            "id": "b4dd143a-487c-4aae-98a7-1ff58b7a6e49",
            "instanceId": 16406,
            "authEntityId": 16406,
            "displayName": "16406",
            "authEntityClass": -217092956,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "appProtocol": "tcp/udp",
            "applicationGroup": "flash-group",
            "applicationType": "DEFAULT",
            "categoryId": "2b054f75-21ea-4711-be45-4d0c6c680c7b",
            "createTime": 1528178812081,
            "encrypted": "true",
            "engineId": "13",
            "helpString": "Youtube, video streaming",
            "ignoreConflict": false,
            "lastUpdateTime": 1528178812081,
            "longDescription": "YouTube is a video-sharing website. The user can watch videos or upload videos online to share with other users. Youtube works cross platform on: PC, iPhone, Android and BlackBerry. Youtube is using HTTP and RTSP as underlying protocols.",
            "name": "youtube",
            "nbarId": "82",
            "p2pTechnology": "false",
            "popularity": 10,
            "rank": 65535,
            "references": "http://www.youtube.com/t/about_youtube",
            "selectorId": "82",
            "subCategory": "064b1d34-9c21-4412-ab9b-75c258d08e27",
            "trafficClass": "MULTIMEDIA_STREAMING",
            "tunnel": "false"
          }
        ],
        "networkIdentity": [
          {
            "id": "7071c111-70d9-4d65-911f-5c6613b9405c",
            "instanceId": 17962,
            "authEntityId": 17962,
            "displayName": "17962",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,5353",
            "protocol": "UDP",
            "upperPort": 0
          },
          {
            "id": "3250b8a3-8dd5-4f3a-8dec-a7f12114b989",
            "instanceId": 17963,
            "authEntityId": 17963,
            "displayName": "17963",
            "authEntityClass": 124090504,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "ipv4Subnet": [],
            "ipv6Subnet": [],
            "lowerPort": 0,
            "ports": "53,80,443,554,5353,8554",
            "protocol": "TCP",
            "upperPort": 0
          }
        ],
        "parentScalableGroup": {
          "idRef": "6e2465ba-79bc-4118-b2bd-86d5b29ba2f1"
        },
        "user": [],
        "userGroup": []
      }
    },
    "result": [
      {
        "bookID": {
          "namespace": "fusion",
          "name": "core",
          "version": "1.0.1"
        },
        "response": {
          "id": "a140636c-947d-4c47-9788-b40a680cddb4",
          "instanceId": 15464,
          "authEntityId": 15464,
          "displayName": "15464",
          "authEntityClass": -1909073334,
          "instanceTenantId": "SYS0",
          "deployPending": "NONE",
          "instanceVersion": 0,
          "createTime": 1528178812081,
          "deployed": false,
          "isSeeded": true,
          "isStale": false,
          "lastUpdateTime": 1528178812081,
          "name": "youtube",
          "namespace": "scalablegroup:application",
          "provisioningState": "DEFINED",
          "qualifier": "application",
          "resourceVersion": 0,
          "targetIdList": [],
          "type": "scalablegroup",
          "cfsChangeInfo": [],
          "customProvisions": [],
          "scalableGroupExternalHandle": "youtube",
          "scalableGroupType": "APPLICATION",
          "securityGroupTag": 0,
          "state": "ACTIVE",
          "vnAgnostic": true,
          "identitySource": {
            "id": "2689858e-a1e6-4c66-85d1-8bd282f8cf29",
            "instanceId": 10010,
            "authEntityId": 10010,
            "displayName": "10010",
            "authEntityClass": 1738953278,
            "instanceTenantId": "SYS0",
            "deployPending": "NONE",
            "instanceVersion": 0,
            "state": "INACTIVE",
            "type": "NBAR"
          },
          "indicativeNetworkIdentity": [],
          "networkApplications": [
            {
              "id": "b4dd143a-487c-4aae-98a7-1ff58b7a6e49",
              "instanceId": 16406,
              "authEntityId": 16406,
              "displayName": "16406",
              "authEntityClass": -217092956,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "appProtocol": "tcp/udp",
              "applicationGroup": "flash-group",
              "applicationType": "DEFAULT",
              "categoryId": "2b054f75-21ea-4711-be45-4d0c6c680c7b",
              "createTime": 1528178812081,
              "encrypted": "true",
              "engineId": "13",
              "helpString": "Youtube, video streaming",
              "ignoreConflict": false,
              "lastUpdateTime": 1528178812081,
              "longDescription": "YouTube is a video-sharing website. The user can watch videos or upload videos online to share with other users. Youtube works cross platform on: PC, iPhone, Android and BlackBerry. Youtube is using HTTP and RTSP as underlying protocols.",
              "name": "youtube",
              "nbarId": "82",
              "p2pTechnology": "false",
              "popularity": 10,
              "rank": 65535,
              "references": "http://www.youtube.com/t/about_youtube",
              "selectorId": "82",
              "subCategory": "064b1d34-9c21-4412-ab9b-75c258d08e27",
              "trafficClass": "MULTIMEDIA_STREAMING",
              "tunnel": "false"
            }
          ],
          "networkIdentity": [
            {
              "id": "7071c111-70d9-4d65-911f-5c6613b9405c",
              "instanceId": 17962,
              "authEntityId": 17962,
              "displayName": "17962",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "53,5353",
              "protocol": "UDP",
              "upperPort": 0
            },
            {
              "id": "3250b8a3-8dd5-4f3a-8dec-a7f12114b989",
              "instanceId": 17963,
              "authEntityId": 17963,
              "displayName": "17963",
              "authEntityClass": 124090504,
              "instanceTenantId": "SYS0",
              "deployPending": "NONE",
              "instanceVersion": 0,
              "ipv4Subnet": [],
              "ipv6Subnet": [],
              "lowerPort": 0,
              "ports": "53,80,443,554,5353,8554",
              "protocol": "TCP",
              "upperPort": 0
            }
          ],
          "parentScalableGroup": {
            "idRef": "6e2465ba-79bc-4118-b2bd-86d5b29ba2f1"
          },
          "user": [],
          "userGroup": []
        }
      }
    ]
  }
});