define([
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/CommandResponseListing',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/pwd',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/sh_run_int',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_controller_ethernet_controller',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_interface_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_interfaces_transceiver_detail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ip_route',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ip_cef',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_isis_neighbors',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ospf_neighbor',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_controllers_cpu_interface',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_platform_qos_queue_stats_internal_cpu_policer',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_proc_cpu_history',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/sh_eigrp_address_family_ipv4_neighbors',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_802.11b_cleanair_device_ap',

    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/sh_ip_bgp_neighbors',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_processes_memory_platform_sorted',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_memory_platform',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_environment_power',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_running_config',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_interface_TenGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_power_inline',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_isis_neighbor',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_int_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ip_arp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_interface_Tunnel',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/ping',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/traceroute',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ip_ospf_neighbor',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_run_sec_ospf',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ip_interface_TenGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_int_TenGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_fabric_map_server_summary',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_fabric_map_server_detailed',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_run_',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_run_sec_lisp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_lisp_session',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/sh_int_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_lisp_service_ipv4_summary',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_stack_power_detail',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_tech_support',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_power_inline_police',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_platform_software_status_control_processor_brief',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_network_assurance_summary',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_run_interface_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/test_cable_diagnostics_tdr_interface_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_cable_diagnostics_tdr_interface_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_power_inline_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ip_eigrp_neighbors',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_802.11a_cleanair_device_ap',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ntp_status',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_ntp_associations',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_clock',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_interface_TwoGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_running_config_interface_TwoGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/dir',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/where',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/systat',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_config',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_interfaces',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/cd',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/help',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_voltage',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_cable_diagnostics_tdr_interface_TwoGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_power_inline_TwoGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/test_cable_diagnostics_tdr_interface_TwoGigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_interface_Two_GigabitEthernet_summary',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_run_sec_eigrp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_run_sec_domain',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_running_config_s_ntp',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/sh_power_inline',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_env_power',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_cdp_neighbors',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_cdp_neighbor_GigabitEthernet',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/CommandSupportedTemplate',
    'core/../../../public/app/devices/dnac_sinon_sim/lib/infrastructure/json-template/command_runner/response/show_switch'





], function (CommandResponseListing, pwd, sh_run_int, show_controller_ethernet_controller,
             show_interface_GigabitEthernet, show_interfaces_transceiver_detail, show_ip_route, show_ip_cef,
             show_isis_neighbors, show_ospf_neighbor, show_controllers_cpu_interface,
             show_platform_qos_queue_stats_internal_cpu_policer, show_proc_cpu_history,
             sh_eigrp_address_family_ipv4_neighbors,show_80211b_cleanair_device_ap,sh_ip_bgp_neighbors,
              show_processes_memory_platform_sorted, show_memory_platform, show_environment_power,show_running_config,
              show_interface_TenGigabitEthernet,show_power_inline,show_isis_neighbor,show_int_GigabitEthernet,
              show_ip_arp,show_interface_Tunnel,ping,traceroute,show_ip_ospf_neighbor,show_run_sec_ospf,
              show_ip_interface_TenGigabitEthernet,show_int_TenGigabitEthernet,show_fabric_map_server_summary,show_fabric_map_server_detailed,show_run_,show_run_sec_lisp,
              show_lisp_session,sh_int_GigabitEthernet,show_lisp_service_ipv4_summary,show_stack_power_detail,show_tech_support,
              show_power_inline_police,show_platform_software_status_control_processor_brief,show_network_assurance_summary,
              show_run_interface_GigabitEthernet,test_cable_diagnostics_tdr_interface_GigabitEthernet,show_cable_diagnostics_tdr_interface_GigabitEthernet,
              show_power_inline_GigabitEthernet,show_ip_eigrp_neighbors,show_80211a_cleanair_device_ap,show_ntp_status,show_ntp_associations,show_clock,
              show_interface_TwoGigabitEthernet,show_running_config_interface_TwoGigabitEthernet,dir,where,systat,show_config,
              show_interfaces,cd,help,show,show_voltage,show_cable_diagnostics_tdr_interface_TwoGigabitEthernet,
              show_power_inline_TwoGigabitEthernet,test_cable_diagnostics_tdr_interface_TwoGigabitEthernet,show_interface_Two_GigabitEthernet_summary,
              show_run_sec_eigrp,show_run_sec_domain,show_running_config_s_ntp,sh_power_inline,show_env_power,show_cdp_neighbors,
              show_cdp_neighbor_GigabitEthernet, CommandSupportedTemplate, show_switch) {

    var self;

    function mapCommandResponses() {
        for(var cmd in CommandResponseListing.commandsTemplate) {
            self.cmdResponse[cmd] = eval(cmd);
        }
    }

    return {
        init: function() {
            this.cmdResponse = {};
            self = this;
            mapCommandResponses();
        },

        getCommandResponse: function(cmd) {
            console.log(cmd);
            var data = self.cmdResponse[cmd] == undefined ? undefined : JSON.parse(JSON.stringify( self.cmdResponse[cmd] ));
            return data;
        }
    };

});
