define({
  "Template": {
    "version": "1.0",
    "response": [
      {
        "id": "AWKfgIEfUVFRaeYF-M-b",
        "name": "Application_Experience_Issues_trigger",
        "entityType": "Unknown",
        "entity": "disney-web-portal",
        "groupBy": "Unknown",
        "category": "Application",
        "severity": "HIGH",
        "priority": "P1",
        "summary": "Network Latency for Application 'disney-web-portal' is Above the Threshold Value of 262ms.",
        "scope": "GLOBAL",
        "rootCause": "latency_issue",
        "timestamp": 1523094000000,
        "description": "This client is experiencing high network latency for application 'disney-web-portal' located at 'North America/USA/California/San Jose/SJC01/Flr-SJC1-1'.",
        "suggestions": [
          {
            "message": "Check for any wireless LAN issues.",
            "steps": [

            ]
          },
          {
            "message": "Contact Cisco TAC World Wide – https://www.cisco.com/c/en/us/support/web/tsd-cisco-worldwide-contacts.html",
            "steps": [

            ]
          }
        ],
        "additionalParams": [
          {
            "key": "latency",
            "value": "831"
          },
          {
            "key": "exporterIpAddress",
            "value": "10.30.255.1"
          },
          {
            "key": "serverAddress",
            "value": "192.168.139.169"
          },
          {
            "key": "threshold",
            "value": "262"
          },
          {
            "key": "type",
            "value": "Issue"
          },
          {
            "key": "serverPort",
            "value": "8080"
          },
          {
            "key": "clientMacAddress",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "tenantid",
            "value": "TNT0"
          },
          {
            "key": "rootCause",
            "value": "latency_issue"
          },
          {
            "key": "applicationName",
            "value": "disney-web-portal"
          },
          {
            "key": "serverSiteId",
            "value": "0457f2e0-8b6c-4aa2-93a8-e5cb160346cf"
          },
          {
            "key": "__entity_type__",
            "value": "Interface"
          },
          {
            "key": "entityId",
            "value": "disney-web-portal"
          },
          {
            "key": "message",
            "value": "This application is experiencing high average latency"
          },
          {
            "key": "priority",
            "value": "P1"
          },
          {
            "key": "category",
            "value": "Application"
          },
          {
            "key": "macAddr",
            "value": "B8:27:EB:D8:BB:5E"
          },
          {
            "key": "status",
            "value": "active"
          },
          {
            "key": "timestring",
            "value": "2018-04-07T09:40:00.000+0000"
          },
          {
            "key": "deviceType",
            "value": "WIRELESS"
          }
        ],
        "status": {
          "status": "ACTIVE",
          "updatedBy": "Unknown",
          "notes": "Unknown",
          "source": "Unknown",
          "updatedAt": null
        },
        "siteQulaifiedName": null
      }
    ]
  }
})
