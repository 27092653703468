define({
  "Template":

        {
      "issueCount": 0,
      "testType": "webserver",
      "testResultDetails": {
       "passCount": 2471,
        "failureCount": 67,
        "slowCount": 0
      },
      "worstPerformingSite": {
        "tagName": "Global/North America/USA/California/San Jose/SJC01/Flr-SJC1-1",
        "count": 2538
      },
      "KpiPerformanceInterval": [
        {
          "kpi": "responseTimeMillis",
          "performanceThreshold": 2000,
          "averagePerformanceValue": 0.0,
          "minPerformanceList": [
            {
          "intervalIndex": "interval-0",
          "value": 0.0,
          "endTime": 1527332400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 6.0,
          "endTime": 1527328800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 6.0,
          "endTime": 1527325200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 0.0,
          "endTime": 1527321600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 0.0,
          "endTime": 1527318000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 0.0,
          "endTime": 1527314400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 5.0,
          "endTime": 1527310800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 6.0,
          "endTime": 1527307200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 0.0,
          "endTime": 1527303600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 0.0,
          "endTime": 1527300000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 0.0,
          "endTime": 1527296400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 6.0,
          "endTime": 1527292800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 6.0,
          "endTime": 1527289200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 6.0,
          "endTime": 1527285600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 0.0,
          "endTime": 1527282000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 6.0,
          "endTime": 1527278400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 0.0,
          "endTime": 1527274800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 6.0,
          "endTime": 1527271200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 6.0,
          "endTime": 1527267600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 6.0,
          "endTime": 1527264000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 0.0,
          "endTime": 1527260400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 5.0,
          "endTime": 1527256800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 0.0,
          "endTime": 1527253200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 0.0,
          "endTime": 1527249600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 0.0,
          "endTime": 1527246000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 5.0,
          "endTime": 1527242400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 5.0,
          "endTime": 1527238800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 0.0,
          "endTime": 1527235200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 6.0,
          "endTime": 1527231600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 6.0,
          "endTime": 1527228000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 0.0,
          "endTime": 1527224400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 6.0,
          "endTime": 1527220800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 5.0,
          "endTime": 1527217200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 6.0,
          "endTime": 1527213600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 6.0,
          "endTime": 1527210000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 0.0,
          "endTime": 1527206400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 0.0,
          "endTime": 1527202800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 6.0,
          "endTime": 1527199200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 6.0,
          "endTime": 1527195600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 5.0,
          "endTime": 1527192000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 0.0,
          "endTime": 1527188400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 0.0,
          "endTime": 1527184800000
        },
        {
          "intervalIndex": "interval-42",
          "value": 6.0,
          "endTime": 1527181200000
        },
        {
          "intervalIndex": "interval-43",
          "value": 6.0,
          "endTime": 1527177600000
        },
        {
          "intervalIndex": "interval-44",
          "value": 0.0,
          "endTime": 1527174000000
        },
        {
          "intervalIndex": "interval-45",
          "value": 6.0,
          "endTime": 1527170400000
        },
        {
          "intervalIndex": "interval-46",
          "value": 6.0,
          "endTime": 1527166800000
        },
        {
          "intervalIndex": "interval-47",
          "value": 5.0,
          "endTime": 1527163200000
        },
        {
          "intervalIndex": "interval-48",
          "value": 6.0,
          "endTime": 1527159600000
        },
        {
          "intervalIndex": "interval-49",
          "value": 0.0,
          "endTime": 1527156000000
        },
        {
          "intervalIndex": "interval-50",
          "value": 5.0,
          "endTime": 1527152400000
        },
        {
          "intervalIndex": "interval-51",
          "value": 5.0,
          "endTime": 1527148800000
        },
        {
          "intervalIndex": "interval-52",
          "value": 0.0,
          "endTime": 1527145200000
        },
        {
          "intervalIndex": "interval-53",
          "value": 0.0,
          "endTime": 1527141600000
        },
        {
          "intervalIndex": "interval-54",
          "value": 0.0,
          "endTime": 1527138000000
        },
        {
          "intervalIndex": "interval-55",
          "value": 6.0,
          "endTime": 1527134400000
        },
        {
          "intervalIndex": "interval-56",
          "value": 6.0,
          "endTime": 1527130800000
        },
        {
          "intervalIndex": "interval-57",
          "value": 6.0,
          "endTime": 1527127200000
        },
        {
          "intervalIndex": "interval-58",
          "value": 0.0,
          "endTime": 1527123600000
        },
        {
          "intervalIndex": "interval-59",
          "value": 6.0,
          "endTime": 1527120000000
        },
        {
          "intervalIndex": "interval-60",
          "value": 5.0,
          "endTime": 1527116400000
        },
        {
          "intervalIndex": "interval-61",
          "value": 0.0,
          "endTime": 1527112800000
        },
        {
          "intervalIndex": "interval-62",
          "value": 6.0,
          "endTime": 1527109200000
        },
        {
          "intervalIndex": "interval-63",
          "value": 5.0,
          "endTime": 1527105600000
        },
        {
          "intervalIndex": "interval-64",
          "value": 6.0,
          "endTime": 1527102000000
        },
        {
          "intervalIndex": "interval-65",
          "value": 5.0,
          "endTime": 1527098400000
        },
        {
          "intervalIndex": "interval-66",
          "value": 0.0,
          "endTime": 1527094800000
        },
        {
          "intervalIndex": "interval-67",
          "value": 0.0,
          "endTime": 1527091200000
        },
        {
          "intervalIndex": "interval-68",
          "value": 0.0,
          "endTime": 1527087600000
        },
        {
          "intervalIndex": "interval-69",
          "value": 6.0,
          "endTime": 1527084000000
        },
        {
          "intervalIndex": "interval-70",
          "value": 0.0,
          "endTime": 1527080400000
        },
        {
          "intervalIndex": "interval-71",
          "value": 6.0,
          "endTime": 1527076800000
        },
        {
          "intervalIndex": "interval-72",
          "value": 6.0,
          "endTime": 1527073200000
        },
        {
          "intervalIndex": "interval-73",
          "value": 0.0,
          "endTime": 1527069600000
        },
        {
          "intervalIndex": "interval-74",
          "value": 0.0,
          "endTime": 1527066000000
        },
        {
          "intervalIndex": "interval-75",
          "value": 0.0,
          "endTime": 1527062400000
        },
        {
          "intervalIndex": "interval-76",
          "value": 0.0,
          "endTime": 1527058800000
        },
        {
          "intervalIndex": "interval-77",
          "value": 6.0,
          "endTime": 1527055200000
        },
        {
          "intervalIndex": "interval-78",
          "value": 6.0,
          "endTime": 1527051600000
        },
        {
          "intervalIndex": "interval-79",
          "value": 6.0,
          "endTime": 1527048000000
        },
        {
          "intervalIndex": "interval-80",
          "value": 0.0,
          "endTime": 1527044400000
        },
        {
          "intervalIndex": "interval-81",
          "value": 5.0,
          "endTime": 1527040800000
        },
        {
          "intervalIndex": "interval-82",
          "value": 6.0,
          "endTime": 1527037200000
        },
        {
          "intervalIndex": "interval-83",
          "value": 6.0,
          "endTime": 1527033600000
        },
        {
          "intervalIndex": "interval-84",
          "value": 6.0,
          "endTime": 1527030000000
        },
        {
          "intervalIndex": "interval-85",
          "value": 6.0,
          "endTime": 1527026400000
        },
        {
          "intervalIndex": "interval-86",
          "value": 5.0,
          "endTime": 1527022800000
        },
        {
          "intervalIndex": "interval-87",
          "value": 5.0,
          "endTime": 1527019200000
        },
        {
          "intervalIndex": "interval-88",
          "value": 0.0,
          "endTime": 1527015600000
        },
        {
          "intervalIndex": "interval-89",
          "value": 0.0,
          "endTime": 1527012000000
        },
        {
          "intervalIndex": "interval-90",
          "value": 6.0,
          "endTime": 1527008400000
        },
        {
          "intervalIndex": "interval-91",
          "value": 6.0,
          "endTime": 1527004800000
        },
        {
          "intervalIndex": "interval-92",
          "value": 6.0,
          "endTime": 1527001200000
        },
        {
          "intervalIndex": "interval-93",
          "value": 6.0,
          "endTime": 1526997600000
        },
        {
          "intervalIndex": "interval-94",
          "value": 6.0,
          "endTime": 1526994000000
        },
        {
          "intervalIndex": "interval-95",
          "value": 6.0,
          "endTime": 1526990400000
        },
        {
          "intervalIndex": "interval-96",
          "value": 6.0,
          "endTime": 1526986800000
        },
        {
          "intervalIndex": "interval-97",
          "value": 0.0,
          "endTime": 1526983200000
        },
        {
          "intervalIndex": "interval-98",
          "value": 6.0,
          "endTime": 1526979600000
        },
        {
          "intervalIndex": "interval-99",
          "value": 0.0,
          "endTime": 1526976000000
        },
        {
          "intervalIndex": "interval-100",
          "value": 6.0,
          "endTime": 1526972400000
        },
        {
          "intervalIndex": "interval-101",
          "value": 6.0,
          "endTime": 1526968800000
        },
        {
          "intervalIndex": "interval-102",
          "value": 6.0,
          "endTime": 1526965200000
        },
        {
          "intervalIndex": "interval-103",
          "value": 5.0,
          "endTime": 1526961600000
        },
        {
          "intervalIndex": "interval-104",
          "value": 5.0,
          "endTime": 1526958000000
        },
        {
          "intervalIndex": "interval-105",
          "value": 6.0,
          "endTime": 1526954400000
        },
        {
          "intervalIndex": "interval-106",
          "value": 0.0,
          "endTime": 1526950800000
        },
        {
          "intervalIndex": "interval-107",
          "value": 5.0,
          "endTime": 1526947200000
        },
        {
          "intervalIndex": "interval-108",
          "value": 6.0,
          "endTime": 1526943600000
        },
        {
          "intervalIndex": "interval-109",
          "value": 0.0,
          "endTime": 1526940000000
        },
        {
          "intervalIndex": "interval-110",
          "value": 0.0,
          "endTime": 1526936400000
        },
        {
          "intervalIndex": "interval-111",
          "value": 6.0,
          "endTime": 1526932800000
        },
        {
          "intervalIndex": "interval-112",
          "value": 5.0,
          "endTime": 1526929200000
        },
        {
          "intervalIndex": "interval-113",
          "value": 0.0,
          "endTime": 1526925600000
        },
        {
          "intervalIndex": "interval-114",
          "value": 6.0,
          "endTime": 1526922000000
        },
        {
          "intervalIndex": "interval-115",
          "value": 6.0,
          "endTime": 1526918400000
        },
        {
          "intervalIndex": "interval-116",
          "value": 6.0,
          "endTime": 1526914800000
        },
        {
          "intervalIndex": "interval-117",
          "value": 0.0,
          "endTime": 1526911200000
        },
        {
          "intervalIndex": "interval-118",
          "value": 6.0,
          "endTime": 1526907600000
        },
        {
          "intervalIndex": "interval-119",
          "value": 6.0,
          "endTime": 1526904000000
        },
        {
          "intervalIndex": "interval-120",
          "value": 0.0,
          "endTime": 1526900400000
        },
        {
          "intervalIndex": "interval-121",
          "value": 0.0,
          "endTime": 1526896800000
        },
        {
          "intervalIndex": "interval-122",
          "value": 6.0,
          "endTime": 1526893200000
        },
        {
          "intervalIndex": "interval-123",
          "value": 6.0,
          "endTime": 1526889600000
        },
        {
          "intervalIndex": "interval-124",
          "value": 6.0,
          "endTime": 1526886000000
        },
        {
          "intervalIndex": "interval-125",
          "value": 6.0,
          "endTime": 1526882400000
        },
        {
          "intervalIndex": "interval-126",
          "value": 6.0,
          "endTime": 1526878800000
        },
        {
          "intervalIndex": "interval-127",
          "value": 6.0,
          "endTime": 1526875200000
        },
        {
          "intervalIndex": "interval-128",
          "value": 6.0,
          "endTime": 1526871600000
        },
        {
          "intervalIndex": "interval-129",
          "value": 0.0,
          "endTime": 1526868000000
        },
        {
          "intervalIndex": "interval-130",
          "value": 6.0,
          "endTime": 1526864400000
        },
        {
          "intervalIndex": "interval-131",
          "value": 6.0,
          "endTime": 1526860800000
        },
        {
          "intervalIndex": "interval-132",
          "value": 0.0,
          "endTime": 1526857200000
        },
        {
          "intervalIndex": "interval-133",
          "value": 6.0,
          "endTime": 1526853600000
        },
        {
          "intervalIndex": "interval-134",
          "value": 0.0,
          "endTime": 1526850000000
        },
        {
          "intervalIndex": "interval-135",
          "value": 5.0,
          "endTime": 1526846400000
        },
        {
          "intervalIndex": "interval-136",
          "value": 0.0,
          "endTime": 1526842800000
        },
        {
          "intervalIndex": "interval-137",
          "value": 0.0,
          "endTime": 1526839200000
        },
        {
          "intervalIndex": "interval-138",
          "value": 5.0,
          "endTime": 1526835600000
        },
        {
          "intervalIndex": "interval-139",
          "value": 0.0,
          "endTime": 1526832000000
        },
        {
          "intervalIndex": "interval-140",
          "value": 6.0,
          "endTime": 1526828400000
        },
        {
          "intervalIndex": "interval-141",
          "value": 6.0,
          "endTime": 1526824800000
        },
        {
          "intervalIndex": "interval-142",
          "value": 0.0,
          "endTime": 1526821200000
        },
        {
          "intervalIndex": "interval-143",
          "value": 6.0,
          "endTime": 1526817600000
        },
        {
          "intervalIndex": "interval-144",
          "value": 0.0,
          "endTime": 1526814000000
        },
        {
          "intervalIndex": "interval-145",
          "value": 6.0,
          "endTime": 1526810400000
        },
        {
          "intervalIndex": "interval-146",
          "value": 0.0,
          "endTime": 1526806800000
        },
        {
          "intervalIndex": "interval-147",
          "value": 5.0,
          "endTime": 1526803200000
        },
        {
          "intervalIndex": "interval-148",
          "value": 5.0,
          "endTime": 1526799600000
        },
        {
          "intervalIndex": "interval-149",
          "value": 6.0,
          "endTime": 1526796000000
        },
        {
          "intervalIndex": "interval-150",
          "value": 5.0,
          "endTime": 1526792400000
        },
        {
          "intervalIndex": "interval-151",
          "value": 5.0,
          "endTime": 1526788800000
        },
        {
          "intervalIndex": "interval-152",
          "value": 0.0,
          "endTime": 1526785200000
        },
        {
          "intervalIndex": "interval-153",
          "value": 6.0,
          "endTime": 1526781600000
        },
        {
          "intervalIndex": "interval-154",
          "value": 5.0,
          "endTime": 1526778000000
        },
        {
          "intervalIndex": "interval-155",
          "value": 6.0,
          "endTime": 1526774400000
        },
        {
          "intervalIndex": "interval-156",
          "value": 0.0,
          "endTime": 1526770800000
        },
        {
          "intervalIndex": "interval-157",
          "value": 6.0,
          "endTime": 1526767200000
        },
        {
          "intervalIndex": "interval-158",
          "value": 6.0,
          "endTime": 1526763600000
        },
        {
          "intervalIndex": "interval-159",
          "value": 6.0,
          "endTime": 1526760000000
        },
        {
          "intervalIndex": "interval-160",
          "value": 0.0,
          "endTime": 1526756400000
        },
        {
          "intervalIndex": "interval-161",
          "value": 0.0,
          "endTime": 1526752800000
        },
        {
          "intervalIndex": "interval-162",
          "value": 6.0,
          "endTime": 1526749200000
        },
        {
          "intervalIndex": "interval-163",
          "value": 0.0,
          "endTime": 1526745600000
        },
        {
          "intervalIndex": "interval-164",
          "value": 6.0,
          "endTime": 1526742000000
        },
        {
          "intervalIndex": "interval-165",
          "value": 6.0,
          "endTime": 1526738400000
        },
        {
          "intervalIndex": "interval-166",
          "value": 6.0,
          "endTime": 1526734800000
        },
        {
          "intervalIndex": "interval-167",
          "value": 0.0,
          "endTime": 1526731200000
        },
        {
          "intervalIndex": "interval-168",
          "value": 0.0,
          "endTime": 1526727600000
        }
          ],
          "averagePerformanceList": [
            {
          "intervalIndex": "interval-0",
          "value": 5.25,
          "endTime": 1527332400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 6.0,
          "endTime": 1527328800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 6.0,
          "endTime": 1527325200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 5.533333333333333,
          "endTime": 1527321600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 5.625,
          "endTime": 1527318000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 5.6,
          "endTime": 1527314400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 6.2,
          "endTime": 1527310800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 6.0,
          "endTime": 1527307200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 5.666666666666667,
          "endTime": 1527303600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 5.466666666666667,
          "endTime": 1527300000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 5.6,
          "endTime": 1527296400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 6.0,
          "endTime": 1527292800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 6.133333333333334,
          "endTime": 1527289200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 6.0,
          "endTime": 1527285600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 5.647058823529412,
          "endTime": 1527282000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 6.0,
          "endTime": 1527278400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 5.6,
          "endTime": 1527274800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 6.0,
          "endTime": 1527271200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 6.0,
          "endTime": 1527267600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 6.0,
          "endTime": 1527264000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 5.588235294117647,
          "endTime": 1527260400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 6.0,
          "endTime": 1527256800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 5.823529411764706,
          "endTime": 1527253200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 5.6,
          "endTime": 1527249600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 4.733333333333333,
          "endTime": 1527246000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 5.9375,
          "endTime": 1527242400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 5.933333333333334,
          "endTime": 1527238800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 5.6875,
          "endTime": 1527235200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 6.133333333333334,
          "endTime": 1527231600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 6.0,
          "endTime": 1527228000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 5.6,
          "endTime": 1527224400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 6.0,
          "endTime": 1527220800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 5.9411764705882355,
          "endTime": 1527217200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 6.466666666666667,
          "endTime": 1527213600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 6.0625,
          "endTime": 1527210000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 5.538461538461538,
          "endTime": 1527206400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 5.714285714285714,
          "endTime": 1527202800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 6.0,
          "endTime": 1527199200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 6.0,
          "endTime": 1527195600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 5.933333333333334,
          "endTime": 1527192000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 5.6875,
          "endTime": 1527188400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 5.588235294117647,
          "endTime": 1527184800000
        },
        {
          "intervalIndex": "interval-42",
          "value": 6.0,
          "endTime": 1527181200000
        },
        {
          "intervalIndex": "interval-43",
          "value": 6.0,
          "endTime": 1527177600000
        },
        {
          "intervalIndex": "interval-44",
          "value": 5.6,
          "endTime": 1527174000000
        },
        {
          "intervalIndex": "interval-45",
          "value": 6.066666666666666,
          "endTime": 1527170400000
        },
        {
          "intervalIndex": "interval-46",
          "value": 6.0625,
          "endTime": 1527166800000
        },
        {
          "intervalIndex": "interval-47",
          "value": 6.0,
          "endTime": 1527163200000
        },
        {
          "intervalIndex": "interval-48",
          "value": 6.0,
          "endTime": 1527159600000
        },
        {
          "intervalIndex": "interval-49",
          "value": 5.533333333333333,
          "endTime": 1527156000000
        },
        {
          "intervalIndex": "interval-50",
          "value": 5.866666666666666,
          "endTime": 1527152400000
        },
        {
          "intervalIndex": "interval-51",
          "value": 5.933333333333334,
          "endTime": 1527148800000
        },
        {
          "intervalIndex": "interval-52",
          "value": 5.666666666666667,
          "endTime": 1527145200000
        },
        {
          "intervalIndex": "interval-53",
          "value": 5.533333333333333,
          "endTime": 1527141600000
        },
        {
          "intervalIndex": "interval-54",
          "value": 5.466666666666667,
          "endTime": 1527138000000
        },
        {
          "intervalIndex": "interval-55",
          "value": 6.0,
          "endTime": 1527134400000
        },
        {
          "intervalIndex": "interval-56",
          "value": 6.0,
          "endTime": 1527130800000
        },
        {
          "intervalIndex": "interval-57",
          "value": 6.0,
          "endTime": 1527127200000
        },
        {
          "intervalIndex": "interval-58",
          "value": 5.6,
          "endTime": 1527123600000
        },
        {
          "intervalIndex": "interval-59",
          "value": 6.0,
          "endTime": 1527120000000
        },
        {
          "intervalIndex": "interval-60",
          "value": 5.866666666666666,
          "endTime": 1527116400000
        },
        {
          "intervalIndex": "interval-61",
          "value": 5.5625,
          "endTime": 1527112800000
        },
        {
          "intervalIndex": "interval-62",
          "value": 6.071428571428571,
          "endTime": 1527109200000
        },
        {
          "intervalIndex": "interval-63",
          "value": 6.0,
          "endTime": 1527105600000
        },
        {
          "intervalIndex": "interval-64",
          "value": 6.0,
          "endTime": 1527102000000
        },
        {
          "intervalIndex": "interval-65",
          "value": 5.928571428571429,
          "endTime": 1527098400000
        },
        {
          "intervalIndex": "interval-66",
          "value": 5.6,
          "endTime": 1527094800000
        },
        {
          "intervalIndex": "interval-67",
          "value": 5.5625,
          "endTime": 1527091200000
        },
        {
          "intervalIndex": "interval-68",
          "value": 5.5,
          "endTime": 1527087600000
        },
        {
          "intervalIndex": "interval-69",
          "value": 6.0,
          "endTime": 1527084000000
        },
        {
          "intervalIndex": "interval-70",
          "value": 5.6,
          "endTime": 1527080400000
        },
        {
          "intervalIndex": "interval-71",
          "value": 6.0,
          "endTime": 1527076800000
        },
        {
          "intervalIndex": "interval-72",
          "value": 6.0625,
          "endTime": 1527073200000
        },
        {
          "intervalIndex": "interval-73",
          "value": 5.571428571428571,
          "endTime": 1527069600000
        },
        {
          "intervalIndex": "interval-74",
          "value": 5.6,
          "endTime": 1527066000000
        },
        {
          "intervalIndex": "interval-75",
          "value": 5.666666666666667,
          "endTime": 1527062400000
        },
        {
          "intervalIndex": "interval-76",
          "value": 5.25,
          "endTime": 1527058800000
        },
        {
          "intervalIndex": "interval-77",
          "value": 6.0,
          "endTime": 1527055200000
        },
        {
          "intervalIndex": "interval-78",
          "value": 6.0,
          "endTime": 1527051600000
        },
        {
          "intervalIndex": "interval-79",
          "value": 6.0,
          "endTime": 1527048000000
        },
        {
          "intervalIndex": "interval-80",
          "value": 5.588235294117647,
          "endTime": 1527044400000
        },
        {
          "intervalIndex": "interval-81",
          "value": 6.0,
          "endTime": 1527040800000
        },
        {
          "intervalIndex": "interval-82",
          "value": 6.0,
          "endTime": 1527037200000
        },
        {
          "intervalIndex": "interval-83",
          "value": 6.066666666666666,
          "endTime": 1527033600000
        },
        {
          "intervalIndex": "interval-84",
          "value": 6.0,
          "endTime": 1527030000000
        },
        {
          "intervalIndex": "interval-85",
          "value": 6.0,
          "endTime": 1527026400000
        },
        {
          "intervalIndex": "interval-86",
          "value": 6.133333333333334,
          "endTime": 1527022800000
        },
        {
          "intervalIndex": "interval-87",
          "value": 6.0,
          "endTime": 1527019200000
        },
        {
          "intervalIndex": "interval-88",
          "value": 5.625,
          "endTime": 1527015600000
        },
        {
          "intervalIndex": "interval-89",
          "value": 5.571428571428571,
          "endTime": 1527012000000
        },
        {
          "intervalIndex": "interval-90",
          "value": 6.0,
          "endTime": 1527008400000
        },
        {
          "intervalIndex": "interval-91",
          "value": 6.066666666666666,
          "endTime": 1527004800000
        },
        {
          "intervalIndex": "interval-92",
          "value": 6.0,
          "endTime": 1527001200000
        },
        {
          "intervalIndex": "interval-93",
          "value": 6.0,
          "endTime": 1526997600000
        },
        {
          "intervalIndex": "interval-94",
          "value": 6.0,
          "endTime": 1526994000000
        },
        {
          "intervalIndex": "interval-95",
          "value": 6.0,
          "endTime": 1526990400000
        },
        {
          "intervalIndex": "interval-96",
          "value": 6.0,
          "endTime": 1526986800000
        },
        {
          "intervalIndex": "interval-97",
          "value": 5.6,
          "endTime": 1526983200000
        },
        {
          "intervalIndex": "interval-98",
          "value": 6.0,
          "endTime": 1526979600000
        },
        {
          "intervalIndex": "interval-99",
          "value": 5.6,
          "endTime": 1526976000000
        },
        {
          "intervalIndex": "interval-100",
          "value": 6.0,
          "endTime": 1526972400000
        },
        {
          "intervalIndex": "interval-101",
          "value": 6.0,
          "endTime": 1526968800000
        },
        {
          "intervalIndex": "interval-102",
          "value": 6.0,
          "endTime": 1526965200000
        },
        {
          "intervalIndex": "interval-103",
          "value": 5.933333333333334,
          "endTime": 1526961600000
        },
        {
          "intervalIndex": "interval-104",
          "value": 5.9375,
          "endTime": 1526958000000
        },
        {
          "intervalIndex": "interval-105",
          "value": 6.0,
          "endTime": 1526954400000
        },
        {
          "intervalIndex": "interval-106",
          "value": 5.6,
          "endTime": 1526950800000
        },
        {
          "intervalIndex": "interval-107",
          "value": 5.933333333333334,
          "endTime": 1526947200000
        },
        {
          "intervalIndex": "interval-108",
          "value": 6.0,
          "endTime": 1526943600000
        },
        {
          "intervalIndex": "interval-109",
          "value": 5.625,
          "endTime": 1526940000000
        },
        {
          "intervalIndex": "interval-110",
          "value": 5.533333333333333,
          "endTime": 1526936400000
        },
        {
          "intervalIndex": "interval-111",
          "value": 6.066666666666666,
          "endTime": 1526932800000
        },
        {
          "intervalIndex": "interval-112",
          "value": 5.933333333333334,
          "endTime": 1526929200000
        },
        {
          "intervalIndex": "interval-113",
          "value": 5.625,
          "endTime": 1526925600000
        },
        {
          "intervalIndex": "interval-114",
          "value": 6.066666666666666,
          "endTime": 1526922000000
        },
        {
          "intervalIndex": "interval-115",
          "value": 6.066666666666666,
          "endTime": 1526918400000
        },
        {
          "intervalIndex": "interval-116",
          "value": 6.0,
          "endTime": 1526914800000
        },
        {
          "intervalIndex": "interval-117",
          "value": 5.2,
          "endTime": 1526911200000
        },
        {
          "intervalIndex": "interval-118",
          "value": 6.764705882352941,
          "endTime": 1526907600000
        },
        {
          "intervalIndex": "interval-119",
          "value": 6.0,
          "endTime": 1526904000000
        },
        {
          "intervalIndex": "interval-120",
          "value": 5.533333333333333,
          "endTime": 1526900400000
        },
        {
          "intervalIndex": "interval-121",
          "value": 5.5625,
          "endTime": 1526896800000
        },
        {
          "intervalIndex": "interval-122",
          "value": 6.071428571428571,
          "endTime": 1526893200000
        },
        {
          "intervalIndex": "interval-123",
          "value": 6.066666666666666,
          "endTime": 1526889600000
        },
        {
          "intervalIndex": "interval-124",
          "value": 6.066666666666666,
          "endTime": 1526886000000
        },
        {
          "intervalIndex": "interval-125",
          "value": 6.066666666666666,
          "endTime": 1526882400000
        },
        {
          "intervalIndex": "interval-126",
          "value": 6.066666666666666,
          "endTime": 1526878800000
        },
        {
          "intervalIndex": "interval-127",
          "value": 6.0,
          "endTime": 1526875200000
        },
        {
          "intervalIndex": "interval-128",
          "value": 6.066666666666666,
          "endTime": 1526871600000
        },
        {
          "intervalIndex": "interval-129",
          "value": 5.2,
          "endTime": 1526868000000
        },
        {
          "intervalIndex": "interval-130",
          "value": 6.0,
          "endTime": 1526864400000
        },
        {
          "intervalIndex": "interval-131",
          "value": 6.0,
          "endTime": 1526860800000
        },
        {
          "intervalIndex": "interval-132",
          "value": 5.666666666666667,
          "endTime": 1526857200000
        },
        {
          "intervalIndex": "interval-133",
          "value": 6.071428571428571,
          "endTime": 1526853600000
        },
        {
          "intervalIndex": "interval-134",
          "value": 5.2,
          "endTime": 1526850000000
        },
        {
          "intervalIndex": "interval-135",
          "value": 5.933333333333334,
          "endTime": 1526846400000
        },
        {
          "intervalIndex": "interval-136",
          "value": 5.571428571428571,
          "endTime": 1526842800000
        },
        {
          "intervalIndex": "interval-137",
          "value": 5.733333333333333,
          "endTime": 1526839200000
        },
        {
          "intervalIndex": "interval-138",
          "value": 5.933333333333334,
          "endTime": 1526835600000
        },
        {
          "intervalIndex": "interval-139",
          "value": 5.6,
          "endTime": 1526832000000
        },
        {
          "intervalIndex": "interval-140",
          "value": 6.0,
          "endTime": 1526828400000
        },
        {
          "intervalIndex": "interval-141",
          "value": 6.0,
          "endTime": 1526824800000
        },
        {
          "intervalIndex": "interval-142",
          "value": 5.666666666666667,
          "endTime": 1526821200000
        },
        {
          "intervalIndex": "interval-143",
          "value": 6.0,
          "endTime": 1526817600000
        },
        {
          "intervalIndex": "interval-144",
          "value": 5.6,
          "endTime": 1526814000000
        },
        {
          "intervalIndex": "interval-145",
          "value": 6.0,
          "endTime": 1526810400000
        },
        {
          "intervalIndex": "interval-146",
          "value": 6.0,
          "endTime": 1526806800000
        },
        {
          "intervalIndex": "interval-147",
          "value": 6.0,
          "endTime": 1526803200000
        },
        {
          "intervalIndex": "interval-148",
          "value": 5.9375,
          "endTime": 1526799600000
        },
        {
          "intervalIndex": "interval-149",
          "value": 6.066666666666666,
          "endTime": 1526796000000
        },
        {
          "intervalIndex": "interval-150",
          "value": 5.933333333333334,
          "endTime": 1526792400000
        },
        {
          "intervalIndex": "interval-151",
          "value": 5.928571428571429,
          "endTime": 1526788800000
        },
        {
          "intervalIndex": "interval-152",
          "value": 5.588235294117647,
          "endTime": 1526785200000
        },
        {
          "intervalIndex": "interval-153",
          "value": 6.0,
          "endTime": 1526781600000
        },
        {
          "intervalIndex": "interval-154",
          "value": 5.866666666666666,
          "endTime": 1526778000000
        },
        {
          "intervalIndex": "interval-155",
          "value": 6.133333333333334,
          "endTime": 1526774400000
        },
        {
          "intervalIndex": "interval-156",
          "value": 5.6,
          "endTime": 1526770800000
        },
        {
          "intervalIndex": "interval-157",
          "value": 6.0,
          "endTime": 1526767200000
        },
        {
          "intervalIndex": "interval-158",
          "value": 6.066666666666666,
          "endTime": 1526763600000
        },
        {
          "intervalIndex": "interval-159",
          "value": 6.0625,
          "endTime": 1526760000000
        },
        {
          "intervalIndex": "interval-160",
          "value": 5.642857142857143,
          "endTime": 1526756400000
        },
        {
          "intervalIndex": "interval-161",
          "value": 5.6,
          "endTime": 1526752800000
        },
        {
          "intervalIndex": "interval-162",
          "value": 6.066666666666666,
          "endTime": 1526749200000
        },
        {
          "intervalIndex": "interval-163",
          "value": 5.666666666666667,
          "endTime": 1526745600000
        },
        {
          "intervalIndex": "interval-164",
          "value": 6.0,
          "endTime": 1526742000000
        },
        {
          "intervalIndex": "interval-165",
          "value": 6.0,
          "endTime": 1526738400000
        },
        {
          "intervalIndex": "interval-166",
          "value": 6.066666666666666,
          "endTime": 1526734800000
        },
        {
          "intervalIndex": "interval-167",
          "value": 5.625,
          "endTime": 1526731200000
        },
        {
          "intervalIndex": "interval-168",
          "value": 5.666666666666667,
          "endTime": 1526727600000
        }
          ],
          "maxPerformanceList": [
            {
          "intervalIndex": "interval-0",
          "value": 6.0,
          "endTime": 1527332400000
        },
        {
          "intervalIndex": "interval-1",
          "value": 6.0,
          "endTime": 1527328800000
        },
        {
          "intervalIndex": "interval-2",
          "value": 6.0,
          "endTime": 1527325200000
        },
        {
          "intervalIndex": "interval-3",
          "value": 6.0,
          "endTime": 1527321600000
        },
        {
          "intervalIndex": "interval-4",
          "value": 6.0,
          "endTime": 1527318000000
        },
        {
          "intervalIndex": "interval-5",
          "value": 6.0,
          "endTime": 1527314400000
        },
        {
          "intervalIndex": "interval-6",
          "value": 9.0,
          "endTime": 1527310800000
        },
        {
          "intervalIndex": "interval-7",
          "value": 6.0,
          "endTime": 1527307200000
        },
        {
          "intervalIndex": "interval-8",
          "value": 7.0,
          "endTime": 1527303600000
        },
        {
          "intervalIndex": "interval-9",
          "value": 6.0,
          "endTime": 1527300000000
        },
        {
          "intervalIndex": "interval-10",
          "value": 6.0,
          "endTime": 1527296400000
        },
        {
          "intervalIndex": "interval-11",
          "value": 6.0,
          "endTime": 1527292800000
        },
        {
          "intervalIndex": "interval-12",
          "value": 7.0,
          "endTime": 1527289200000
        },
        {
          "intervalIndex": "interval-13",
          "value": 6.0,
          "endTime": 1527285600000
        },
        {
          "intervalIndex": "interval-14",
          "value": 6.0,
          "endTime": 1527282000000
        },
        {
          "intervalIndex": "interval-15",
          "value": 6.0,
          "endTime": 1527278400000
        },
        {
          "intervalIndex": "interval-16",
          "value": 6.0,
          "endTime": 1527274800000
        },
        {
          "intervalIndex": "interval-17",
          "value": 6.0,
          "endTime": 1527271200000
        },
        {
          "intervalIndex": "interval-18",
          "value": 6.0,
          "endTime": 1527267600000
        },
        {
          "intervalIndex": "interval-19",
          "value": 6.0,
          "endTime": 1527264000000
        },
        {
          "intervalIndex": "interval-20",
          "value": 6.0,
          "endTime": 1527260400000
        },
        {
          "intervalIndex": "interval-21",
          "value": 7.0,
          "endTime": 1527256800000
        },
        {
          "intervalIndex": "interval-22",
          "value": 9.0,
          "endTime": 1527253200000
        },
        {
          "intervalIndex": "interval-23",
          "value": 6.0,
          "endTime": 1527249600000
        },
        {
          "intervalIndex": "interval-24",
          "value": 6.0,
          "endTime": 1527246000000
        },
        {
          "intervalIndex": "interval-25",
          "value": 6.0,
          "endTime": 1527242400000
        },
        {
          "intervalIndex": "interval-26",
          "value": 6.0,
          "endTime": 1527238800000
        },
        {
          "intervalIndex": "interval-27",
          "value": 8.0,
          "endTime": 1527235200000
        },
        {
          "intervalIndex": "interval-28",
          "value": 8.0,
          "endTime": 1527231600000
        },
        {
          "intervalIndex": "interval-29",
          "value": 6.0,
          "endTime": 1527228000000
        },
        {
          "intervalIndex": "interval-30",
          "value": 7.0,
          "endTime": 1527224400000
        },
        {
          "intervalIndex": "interval-31",
          "value": 6.0,
          "endTime": 1527220800000
        },
        {
          "intervalIndex": "interval-32",
          "value": 7.0,
          "endTime": 1527217200000
        },
        {
          "intervalIndex": "interval-33",
          "value": 10.0,
          "endTime": 1527213600000
        },
        {
          "intervalIndex": "interval-34",
          "value": 7.0,
          "endTime": 1527210000000
        },
        {
          "intervalIndex": "interval-35",
          "value": 6.0,
          "endTime": 1527206400000
        },
        {
          "intervalIndex": "interval-36",
          "value": 7.0,
          "endTime": 1527202800000
        },
        {
          "intervalIndex": "interval-37",
          "value": 6.0,
          "endTime": 1527199200000
        },
        {
          "intervalIndex": "interval-38",
          "value": 6.0,
          "endTime": 1527195600000
        },
        {
          "intervalIndex": "interval-39",
          "value": 6.0,
          "endTime": 1527192000000
        },
        {
          "intervalIndex": "interval-40",
          "value": 7.0,
          "endTime": 1527188400000
        },
        {
          "intervalIndex": "interval-41",
          "value": 6.0,
          "endTime": 1527184800000
        },
        {
          "intervalIndex": "interval-42",
          "value": 6.0,
          "endTime": 1527181200000
        },
        {
          "intervalIndex": "interval-43",
          "value": 6.0,
          "endTime": 1527177600000
        },
        {
          "intervalIndex": "interval-44",
          "value": 7.0,
          "endTime": 1527174000000
        },
        {
          "intervalIndex": "interval-45",
          "value": 7.0,
          "endTime": 1527170400000
        },
        {
          "intervalIndex": "interval-46",
          "value": 7.0,
          "endTime": 1527166800000
        },
        {
          "intervalIndex": "interval-47",
          "value": 7.0,
          "endTime": 1527163200000
        },
        {
          "intervalIndex": "interval-48",
          "value": 6.0,
          "endTime": 1527159600000
        },
        {
          "intervalIndex": "interval-49",
          "value": 6.0,
          "endTime": 1527156000000
        },
        {
          "intervalIndex": "interval-50",
          "value": 6.0,
          "endTime": 1527152400000
        },
        {
          "intervalIndex": "interval-51",
          "value": 6.0,
          "endTime": 1527148800000
        },
        {
          "intervalIndex": "interval-52",
          "value": 7.0,
          "endTime": 1527145200000
        },
        {
          "intervalIndex": "interval-53",
          "value": 6.0,
          "endTime": 1527141600000
        },
        {
          "intervalIndex": "interval-54",
          "value": 6.0,
          "endTime": 1527138000000
        },
        {
          "intervalIndex": "interval-55",
          "value": 6.0,
          "endTime": 1527134400000
        },
        {
          "intervalIndex": "interval-56",
          "value": 6.0,
          "endTime": 1527130800000
        },
        {
          "intervalIndex": "interval-57",
          "value": 6.0,
          "endTime": 1527127200000
        },
        {
          "intervalIndex": "interval-58",
          "value": 6.0,
          "endTime": 1527123600000
        },
        {
          "intervalIndex": "interval-59",
          "value": 6.0,
          "endTime": 1527120000000
        },
        {
          "intervalIndex": "interval-60",
          "value": 6.0,
          "endTime": 1527116400000
        },
        {
          "intervalIndex": "interval-61",
          "value": 6.0,
          "endTime": 1527112800000
        },
        {
          "intervalIndex": "interval-62",
          "value": 7.0,
          "endTime": 1527109200000
        },
        {
          "intervalIndex": "interval-63",
          "value": 7.0,
          "endTime": 1527105600000
        },
        {
          "intervalIndex": "interval-64",
          "value": 6.0,
          "endTime": 1527102000000
        },
        {
          "intervalIndex": "interval-65",
          "value": 6.0,
          "endTime": 1527098400000
        },
        {
          "intervalIndex": "interval-66",
          "value": 6.0,
          "endTime": 1527094800000
        },
        {
          "intervalIndex": "interval-67",
          "value": 6.0,
          "endTime": 1527091200000
        },
        {
          "intervalIndex": "interval-68",
          "value": 6.0,
          "endTime": 1527087600000
        },
        {
          "intervalIndex": "interval-69",
          "value": 6.0,
          "endTime": 1527084000000
        },
        {
          "intervalIndex": "interval-70",
          "value": 6.0,
          "endTime": 1527080400000
        },
        {
          "intervalIndex": "interval-71",
          "value": 6.0,
          "endTime": 1527076800000
        },
        {
          "intervalIndex": "interval-72",
          "value": 7.0,
          "endTime": 1527073200000
        },
        {
          "intervalIndex": "interval-73",
          "value": 6.0,
          "endTime": 1527069600000
        },
        {
          "intervalIndex": "interval-74",
          "value": 6.0,
          "endTime": 1527066000000
        },
        {
          "intervalIndex": "interval-75",
          "value": 7.0,
          "endTime": 1527062400000
        },
        {
          "intervalIndex": "interval-76",
          "value": 6.0,
          "endTime": 1527058800000
        },
        {
          "intervalIndex": "interval-77",
          "value": 6.0,
          "endTime": 1527055200000
        },
        {
          "intervalIndex": "interval-78",
          "value": 6.0,
          "endTime": 1527051600000
        },
        {
          "intervalIndex": "interval-79",
          "value": 6.0,
          "endTime": 1527048000000
        },
        {
          "intervalIndex": "interval-80",
          "value": 6.0,
          "endTime": 1527044400000
        },
        {
          "intervalIndex": "interval-81",
          "value": 7.0,
          "endTime": 1527040800000
        },
        {
          "intervalIndex": "interval-82",
          "value": 6.0,
          "endTime": 1527037200000
        },
        {
          "intervalIndex": "interval-83",
          "value": 7.0,
          "endTime": 1527033600000
        },
        {
          "intervalIndex": "interval-84",
          "value": 6.0,
          "endTime": 1527030000000
        },
        {
          "intervalIndex": "interval-85",
          "value": 6.0,
          "endTime": 1527026400000
        },
        {
          "intervalIndex": "interval-86",
          "value": 9.0,
          "endTime": 1527022800000
        },
        {
          "intervalIndex": "interval-87",
          "value": 7.0,
          "endTime": 1527019200000
        },
        {
          "intervalIndex": "interval-88",
          "value": 6.0,
          "endTime": 1527015600000
        },
        {
          "intervalIndex": "interval-89",
          "value": 6.0,
          "endTime": 1527012000000
        },
        {
          "intervalIndex": "interval-90",
          "value": 6.0,
          "endTime": 1527008400000
        },
        {
          "intervalIndex": "interval-91",
          "value": 7.0,
          "endTime": 1527004800000
        },
        {
          "intervalIndex": "interval-92",
          "value": 6.0,
          "endTime": 1527001200000
        },
        {
          "intervalIndex": "interval-93",
          "value": 6.0,
          "endTime": 1526997600000
        },
        {
          "intervalIndex": "interval-94",
          "value": 6.0,
          "endTime": 1526994000000
        },
        {
          "intervalIndex": "interval-95",
          "value": 6.0,
          "endTime": 1526990400000
        },
        {
          "intervalIndex": "interval-96",
          "value": 6.0,
          "endTime": 1526986800000
        },
        {
          "intervalIndex": "interval-97",
          "value": 6.0,
          "endTime": 1526983200000
        },
        {
          "intervalIndex": "interval-98",
          "value": 6.0,
          "endTime": 1526979600000
        },
        {
          "intervalIndex": "interval-99",
          "value": 6.0,
          "endTime": 1526976000000
        },
        {
          "intervalIndex": "interval-100",
          "value": 6.0,
          "endTime": 1526972400000
        },
        {
          "intervalIndex": "interval-101",
          "value": 6.0,
          "endTime": 1526968800000
        },
        {
          "intervalIndex": "interval-102",
          "value": 6.0,
          "endTime": 1526965200000
        },
        {
          "intervalIndex": "interval-103",
          "value": 6.0,
          "endTime": 1526961600000
        },
        {
          "intervalIndex": "interval-104",
          "value": 6.0,
          "endTime": 1526958000000
        },
        {
          "intervalIndex": "interval-105",
          "value": 6.0,
          "endTime": 1526954400000
        },
        {
          "intervalIndex": "interval-106",
          "value": 6.0,
          "endTime": 1526950800000
        },
        {
          "intervalIndex": "interval-107",
          "value": 6.0,
          "endTime": 1526947200000
        },
        {
          "intervalIndex": "interval-108",
          "value": 6.0,
          "endTime": 1526943600000
        },
        {
          "intervalIndex": "interval-109",
          "value": 6.0,
          "endTime": 1526940000000
        },
        {
          "intervalIndex": "interval-110",
          "value": 6.0,
          "endTime": 1526936400000
        },
        {
          "intervalIndex": "interval-111",
          "value": 7.0,
          "endTime": 1526932800000
        },
        {
          "intervalIndex": "interval-112",
          "value": 6.0,
          "endTime": 1526929200000
        },
        {
          "intervalIndex": "interval-113",
          "value": 6.0,
          "endTime": 1526925600000
        },
        {
          "intervalIndex": "interval-114",
          "value": 7.0,
          "endTime": 1526922000000
        },
        {
          "intervalIndex": "interval-115",
          "value": 7.0,
          "endTime": 1526918400000
        },
        {
          "intervalIndex": "interval-116",
          "value": 6.0,
          "endTime": 1526914800000
        },
        {
          "intervalIndex": "interval-117",
          "value": 6.0,
          "endTime": 1526911200000
        },
        {
          "intervalIndex": "interval-118",
          "value": 19.0,
          "endTime": 1526907600000
        },
        {
          "intervalIndex": "interval-119",
          "value": 6.0,
          "endTime": 1526904000000
        },
        {
          "intervalIndex": "interval-120",
          "value": 6.0,
          "endTime": 1526900400000
        },
        {
          "intervalIndex": "interval-121",
          "value": 6.0,
          "endTime": 1526896800000
        },
        {
          "intervalIndex": "interval-122",
          "value": 7.0,
          "endTime": 1526893200000
        },
        {
          "intervalIndex": "interval-123",
          "value": 7.0,
          "endTime": 1526889600000
        },
        {
          "intervalIndex": "interval-124",
          "value": 7.0,
          "endTime": 1526886000000
        },
        {
          "intervalIndex": "interval-125",
          "value": 7.0,
          "endTime": 1526882400000
        },
        {
          "intervalIndex": "interval-126",
          "value": 7.0,
          "endTime": 1526878800000
        },
        {
          "intervalIndex": "interval-127",
          "value": 6.0,
          "endTime": 1526875200000
        },
        {
          "intervalIndex": "interval-128",
          "value": 7.0,
          "endTime": 1526871600000
        },
        {
          "intervalIndex": "interval-129",
          "value": 6.0,
          "endTime": 1526868000000
        },
        {
          "intervalIndex": "interval-130",
          "value": 6.0,
          "endTime": 1526864400000
        },
        {
          "intervalIndex": "interval-131",
          "value": 6.0,
          "endTime": 1526860800000
        },
        {
          "intervalIndex": "interval-132",
          "value": 7.0,
          "endTime": 1526857200000
        },
        {
          "intervalIndex": "interval-133",
          "value": 7.0,
          "endTime": 1526853600000
        },
        {
          "intervalIndex": "interval-134",
          "value": 6.0,
          "endTime": 1526850000000
        },
        {
          "intervalIndex": "interval-135",
          "value": 6.0,
          "endTime": 1526846400000
        },
        {
          "intervalIndex": "interval-136",
          "value": 6.0,
          "endTime": 1526842800000
        },
        {
          "intervalIndex": "interval-137",
          "value": 8.0,
          "endTime": 1526839200000
        },
        {
          "intervalIndex": "interval-138",
          "value": 6.0,
          "endTime": 1526835600000
        },
        {
          "intervalIndex": "interval-139",
          "value": 6.0,
          "endTime": 1526832000000
        },
        {
          "intervalIndex": "interval-140",
          "value": 6.0,
          "endTime": 1526828400000
        },
        {
          "intervalIndex": "interval-141",
          "value": 6.0,
          "endTime": 1526824800000
        },
        {
          "intervalIndex": "interval-142",
          "value": 7.0,
          "endTime": 1526821200000
        },
        {
          "intervalIndex": "interval-143",
          "value": 6.0,
          "endTime": 1526817600000
        },
        {
          "intervalIndex": "interval-144",
          "value": 6.0,
          "endTime": 1526814000000
        },
        {
          "intervalIndex": "interval-145",
          "value": 6.0,
          "endTime": 1526810400000
        },
        {
          "intervalIndex": "interval-146",
          "value": 13.0,
          "endTime": 1526806800000
        },
        {
          "intervalIndex": "interval-147",
          "value": 7.0,
          "endTime": 1526803200000
        },
        {
          "intervalIndex": "interval-148",
          "value": 7.0,
          "endTime": 1526799600000
        },
        {
          "intervalIndex": "interval-149",
          "value": 7.0,
          "endTime": 1526796000000
        },
        {
          "intervalIndex": "interval-150",
          "value": 6.0,
          "endTime": 1526792400000
        },
        {
          "intervalIndex": "interval-151",
          "value": 7.0,
          "endTime": 1526788800000
        },
        {
          "intervalIndex": "interval-152",
          "value": 6.0,
          "endTime": 1526785200000
        },
        {
          "intervalIndex": "interval-153",
          "value": 6.0,
          "endTime": 1526781600000
        },
        {
          "intervalIndex": "interval-154",
          "value": 6.0,
          "endTime": 1526778000000
        },
        {
          "intervalIndex": "interval-155",
          "value": 7.0,
          "endTime": 1526774400000
        },
        {
          "intervalIndex": "interval-156",
          "value": 6.0,
          "endTime": 1526770800000
        },
        {
          "intervalIndex": "interval-157",
          "value": 6.0,
          "endTime": 1526767200000
        },
        {
          "intervalIndex": "interval-158",
          "value": 7.0,
          "endTime": 1526763600000
        },
        {
          "intervalIndex": "interval-159",
          "value": 7.0,
          "endTime": 1526760000000
        },
        {
          "intervalIndex": "interval-160",
          "value": 7.0,
          "endTime": 1526756400000
        },
        {
          "intervalIndex": "interval-161",
          "value": 6.0,
          "endTime": 1526752800000
        },
        {
          "intervalIndex": "interval-162",
          "value": 7.0,
          "endTime": 1526749200000
        },
        {
          "intervalIndex": "interval-163",
          "value": 7.0,
          "endTime": 1526745600000
        },
        {
          "intervalIndex": "interval-164",
          "value": 6.0,
          "endTime": 1526742000000
        },
        {
          "intervalIndex": "interval-165",
          "value": 6.0,
          "endTime": 1526738400000
        },
        {
          "intervalIndex": "interval-166",
          "value": 7.0,
          "endTime": 1526734800000
        },
        {
          "intervalIndex": "interval-167",
          "value": 6.0,
          "endTime": 1526731200000
        },
        {
          "intervalIndex": "interval-168",
          "value": 7.0,
          "endTime": 1526727600000
        }

          ]
        }
      ]
    }
    });
